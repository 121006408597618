.survey {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 42.3rem;
    height: fit-content;
    background: $lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain;
  }
  &__description {
    @include description;
    height: 4.2rem;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
    line-height: 2rem;
    text-align: center;
    margin-right: 5rem;
    margin-left: 5rem;
    margin-top: 9.5rem;
    margin-bottom: 0.5rem;
  }
  &__additional-text {    
    min-height: 2rem;
    height: fit-content;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    line-height: 1.4rem;
    text-align: center;
    margin-right: 5rem;
    margin-left: 5rem;
    color: $cyprus;
    font-family: 'Janda Manatee Solid';
  }
  &__image {
    position: absolute;
    height: 20rem;
    width: 20rem;
    margin-top: -9rem;
    margin-left: -4rem;
    background: $earnTokens center no-repeat;
    background-size: contain;
  }
  &__accept {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
