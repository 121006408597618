.b2b-login {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 42.3rem;
    height: 33rem;
    background: $lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain;
  }
  &__button-box {
    display: block;
    width: 35rem;
    margin: 0 auto;
    font-size: 1.5rem;
    padding: 0.4rem;
    padding-top: 2rem;
  }
  &__container {
    display: flex;
    justify-content: center;
  }
  &__description {
    height: 4.5rem;
    width: 35rem;
    line-height: 2.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.5rem;
    letter-spacing: 0.07rem;
    font-family: 'Janda Manatee Solid';
    color: $white;
    &--error {
      color: $cinnabar;
      text-align: center;
      margin-top: 0rem;
      width: 100%;
      color: rgb(223, 180, 101);
    }
  }
  &__input {
    align-self: center;
    height: 3rem;
    width: 15rem;
    margin-top: -3rem;
    padding-left: 0.5rem;
  }
  &__button {
    @include dialog-button-accept;
    margin-top: 0rem;
    margin-left: 15rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
