.association {
  position: absolute;
  height: 42.7rem;
  padding: 1rem 4rem;
  margin: -1rem auto 0 auto;
  &__column {
    height: 18.2rem;
    width: 26.6rem;
    position: absolute;        
    &--a {
      margin-left: -0.5rem;
    }
    &--b {
      margin-left: 38.6rem;
    }
    &--c {
      margin-top: 23.1rem;
      margin-left: 10rem;
    }
    &--d {
      margin-top: 23.1rem;
      margin-left: 28rem;
    }
  }
  &__hint-box {
    height: 2.7rem;
    width: 16rem;
  }
  &__hint {
    height: 2.7rem;
    width: 16rem;
    margin-top: 1rem;
    font-size: 1.4rem;
    padding-top: 0rem;
    &__result {
      border-radius: 2rem;
      padding-top: 0.4rem;
      &:hover {        
      border-radius: 2rem;
      }
    }
    &__guess_tool {
      pointer-events: none;
    }
  }
  &__result_button_box {
    height: 2.7rem;
    width: 16rem;
    border-radius: 1.3rem;
    &--a {
      margin-left: 10.4rem;
    }
    &--b {
      margin-left: -10.5rem;
    }
    &--final {
      height: 4rem;
      width: 26rem;
      border-radius: 2rem;
      margin-left: 15rem;
    }
  }
  &__final-result {
    height: 3.2rem;
    width: 24rem;
    margin-top: 19.7rem;
    border-radius: 2rem;
    &:hover {        
      border-radius: 2rem;
      }
  }
  &__point {
    opacity: 0;
    height: 3rem;
    width: 3.9rem;
    color: $color-white;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: 0rem;
    z-index: 2;
    &--a {
      margin-left: 6rem;
      margin-top: -3.7rem;
    }
    &--b {
      margin-left: 6rem;
      margin-top: -3.7rem;
    }
    &--c {
      margin-left: -4rem;
      margin-top: -3.7rem;
    }
    &--d {
      margin-left: 16rem;
      margin-top: -3.7rem;
    }
    &--final {
      margin-left: 12rem;
      margin-top: -3.7rem;
    }
  }

  &__skip-button {
    height: 5rem;
    width: 13.1rem;
    position: absolute;
    margin-top: -5rem;
    margin-left: 40.5rem;
    background: $gameButtonSkip no-repeat center;
    background-size: contain;
    &:hover {
      background: $gameButtonSkipOver no-repeat center;
      background-size: contain;
      cursor: pointer;
    }
    &--disabled {
      background: $gameButtonSkipDisabled no-repeat center;
      background-size: contain;
      &:hover {
        background: $gameButtonSkipDisabled no-repeat center;
        background-size: contain;
        cursor: default;
      }
    }
  }
  &__guess {
    @include dialog-inner;
    background-color: rgba($color: $saphire-var3, $alpha: 0.95);
    height: 41.5rem;
    width: 56.1rem;
    margin-top: -0.5rem;
    margin-left: 7rem;
    &__button-box {
      width: 16rem;
      margin: 0 auto;
      margin-top: 1.5rem;
    }
    &__close {
      position: absolute;
      width: 3.3rem;
      height: 3.2rem;
      margin-left: 51rem;
      margin-top: 1rem;
      background: $dialogCloseButton no-repeat center;
      background-size: contain;
      &:hover {
        background: $dialogCloseButtonOver no-repeat center;
        background-size: contain;
      }
    }
    &__field {
      margin: 0 auto;
      height: 5rem;
      width: 51.1rem;
      border-radius: 2rem;
      margin-top: 1.3rem;
      background-color: #3d669a;
      &:hover {
        background-color: #3d669a;
      }
    }
    &__letter {
      margin-top: 0.2rem;
      margin-left: 0.2rem;
      height: 4.2rem;
      width: 4.2rem;
      font-size: 1.8rem;
    }
    &__choosen_letter {
      height: 4.2rem;
      width: 4.2rem;
      padding-top: 0.2rem;
      margin-right: 0rem;
      font-size: 1.8rem;
    }

    &__submit_word_button {
      @include button;
      position: absolute;
      height: 4.5rem;
      width: 10rem;
      font-size: 1.6rem;
      margin-left: 43rem;
      margin-top: -5rem;
    }
    &__hard-mode-box {
      display: flex;
      justify-content: center;
      height: 4.2rem;
      margin-top: 0.4rem;
      display: flex;
    }
    &__hard-mode {
      height: 5.9rem;
      width: 45.5rem;
      margin: 0 auto;
      border-radius: 2rem;
      margin-top: 1rem;
      margin-left: 4rem;
      &--letter {
        width: 4.2rem;
        height: 4.2rem;
        margin-left: 0.5rem;
        background: $letterEmpty no-repeat center;
        background-size: contain;
      }
    }
    &__erase-button {
      position: absolute;
      z-index: 1;
      height: 3rem;
      width: 3.2rem;
      margin-top: -4rem;
      margin-left: 48.5rem;
      background: $eraseButton no-repeat center;
      background-size: contain;
      &:disabled {
        cursor: default;
        background: $eraseButtonOver no-repeat center;
        background-size: contain;
        &:hover {
          background: $eraseButtonOver no-repeat center;
          background-size: contain;
        }
      }
      &:hover {
        @include hoverAfter {
          background: $eraseButtonOver no-repeat center;
          background-size: contain;
        }
      }
    }
    &__rounded-erase-button {
      position: absolute;
      z-index: 1;
      height: 5rem;
      width: 5rem;
      margin-top: -5.5rem;
      margin-left: 50rem;
      background: $roundedEraseButton no-repeat center;
      background-size: contain;
      &:disabled {
        cursor: default;
        background: $roundedEraseButtonOver no-repeat center;
        background-size: contain;
        &:hover {
          background: $roundedEraseButtonOver no-repeat center;
          background-size: contain;
        }
      }
      &:hover {
        @include hoverAfter {
          background: $roundedEraseButtonOver no-repeat center;
          background-size: contain;
        }
      }
    }
    &__game-buton-box {
      position: absolute;
    }

    &__letters-box {
      height: 9.6rem;
      width: 28.2rem;
      margin: 0 auto;
      margin-top: 2.3rem;
      &--second {
        margin-top: -5rem;
      }
    }
  }

  &__result-input {
    height: 3.4rem;
    width: 16rem;
    margin-top: -0.1rem;
    position: absolute;
    font-size: 1.4rem;
    background-color: transparent;
    border-color: transparent;
    border-radius: 2rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
    text-transform: uppercase;
    outline: none;
    &-final {
      width: 24rem;
      padding-top: 0.2rem;
    }
    &--disabled-navy {
      color: $saphire-var4;
    }
    &--disabled-white {
      color: white;
    }
  }
}
