.tile.small {
  align-items: center;
  width: 18rem;
  margin-left: -2rem;

}


.tile-name{
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  text-align: center;
  background-color: black;
  color: white;
  opacity: 0.55;
}

.tile-captain{
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 3rem;
  text-align: center;
  background-image: url(./../../../../public/assets/images/b2b/captain-mark4.png);
  background-repeat: no-repeat;
  background-position: center;
}

.tile.large {
  position: relative;
  width:100%;
  max-height: 12rem;
}

.tile video {
  width: 100%;
}

.tile.local video {
  transform: scale(-1, 1);
}

.tile.small video {
  border-radius: 4px;
  padding-left: 0.6rem;
}

.tile.small .background {
  border-radius: 4px;
}

.tile .loading {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 17px;
}
