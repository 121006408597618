.buy-earn-tokens {
  display: flex;

  &__buy-tokens {
    background: $stickersBuyTokens no-repeat center;
    background-size: contain;
    height: 11.7rem;
    width: 15.4rem;
    position: relative;
    margin: 0 2.6rem 0 5.8rem;
  }
}
