.chat {
  &__container {
    position: absolute;
    margin-top: 1.5rem;
    z-index: 1000;
    opacity: 70%;
  }
  &__button {
    background: $semaphoreChet no-repeat center;
    background-size: 3.4rem 3.5rem;
    height: 3.5rem;
    width: 3.4rem;
    margin-left: 11.5rem;
    margin-top: 0.4rem;
    &--red {
      margin-left: 39rem;
      background: $semaphoreChetFlipped no-repeat center;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__tooltip {
    @include tooltip;
    height: 32.7rem;
    width: 18.5rem;
    margin-left: 13rem;
    margin-top: 3rem;
    visibility: visible;
    opacity: 1;
    z-index: 2;
    animation: fade-in 0.35s ease-in both;

    &--red {
      margin-left: 27rem;
    }

    &__tab-button {
      height: 4.4rem;
      width: 4rem;
      margin-left: 0.2rem;
      background: $chatTabButton no-repeat center;
      background-size: contain;
      &:hover {
        background: $chatTabButtonOver no-repeat center;
        background-size: contain;
      }
      &__tab-icon {
        position: absolute;
        height: 3rem;
        width: 3.3rem;
        margin-top: -1.8rem;
        margin-left: 0.35rem;
        &--text {
          background: $chatIconText no-repeat center;
          background-size: 3rem 3.3rem;
        }
        &--emoticon {
          background: $chatIconEmoticon no-repeat center;
          background-size: 3rem 3.3rem;
        }
        &--sticker {
          background: $chatIconSticker no-repeat center;
          background-size: 3rem 3.3rem;
        }
        &--sound {
          background: $chatIconSound no-repeat center;
          background-size: 3rem 3.3rem;
        }
      }
    }

    &__tab-box {
      display: flex;
    }
    &__tab-button--selected {
      height: 4.4rem;
      width: 4rem;
      background: $chatTabButtonSelected no-repeat center;
      background-size: contain;
    }

    &__get-more {
      height: 3.6rem;
      width: 18rem;
      margin-left: -0.2rem;
      cursor: pointer;
      background: $chatShop no-repeat center;
      background-size: 18rem 3.6rem;
      &:hover {
        background: $chatShopOver no-repeat center;
        background-size: 18rem 3.6rem;
      }
    }
  }
  &__type {
    height: 23.5rem;
    width: 18rem;
    margin-top: 0.2rem;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-color: #fc0 rgba(105, 148, 199, 0.2);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: rgba(105, 148, 199, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background: #fc0;
    }
    &__sound {
      height: 13.1rem;
      width: 16.4rem;
      background: $chatSoundsBacground no-repeat center;
      background-size: contain;
    }

    &__message_no_background {
      z-index: 2;
      opacity: 0;
      position: absolute;
      height: 7.2rem;
      width: 8.9rem;
      margin-left: 11rem;
      margin-top: -6rem;
      text-align: center;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      pointer-events: none;
      align-items: center;
    }
    &__message_no_background_red {
      z-index: 2;
      opacity: 0;
      position: absolute;
      height: 7.2rem;
      width: 8.9rem;
      margin-left: 34rem;
      margin-top: -6rem;
      text-align: center;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      pointer-events: none;
      align-items: center;
    }

    &__message {
      z-index: 2;
      opacity: 0;
      position: absolute;
      height: 7.2rem;
      width: 8.9rem;
      margin-left: 11rem;
      margin-top: -6rem;
      text-align: center;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;
      color: $saphire;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      pointer-events: none;
      background: $chatTextBackground no-repeat center;
      background-size: contain;
      &--red {
        margin-left: 34rem !important;
        background: $chatTextBackgroundFlipped no-repeat center;
        background-size: contain;
      }
      &--sticker {
        margin-top: -0.5rem;
        height: 7.2rem;
        width: 8.9rem;
      }
      &--sticker_red {
        margin-top: -0.5rem;
        height: 7.2rem;
        width: 8.9rem;
        transform: scaleX(-1);
      }
      &--emotions {
        margin-top: -0.5rem;
        height: 4.5rem;
        width: 4.5rem;
      }
      &__sound {
        margin-top: -1rem;
        height: 4.5rem;
        width: 4.5rem;
        &--1 {
          background: $dialogsShopSounds1 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
        &--2 {
          background: $dialogsShopSounds2 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
        &--3 {
          background: $dialogsShopSounds3 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
        &--4 {
          background: $dialogsShopSounds4 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
        &--5 {
          background: $dialogsShopSounds5 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
        &--6 {
          background: $dialogsShopSounds6 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
        &--7 {
          background: $dialogsShopSounds7 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
        &--8 {
          background: $dialogsShopSounds8 no-repeat center;
          background-size: 4.5rem 4.5rem;
        }
      }
      &--no_image {
        width: 0;
        height: 0;
      }
    }
    &__element {
      cursor: pointer;
      background-color: $black;
      &:hover {
        border: 0.2rem solid #709eec;
      }
      &--sound-box {
        height: 1.7rem;
        width: 1.7rem;
        margin-left: 1rem;
        margin-right: 1rem;
        background: $chatLock1 no-repeat center;
        background-size: contain;
        &--dark {
          background: $chatSoundIconNoteDark no-repeat center;
          background-size: 1.7rem 1.7rem;
        }
        &--light {
          background: $chatSoundIconNoteLight no-repeat center;
          background-size: 1.7rem 1.7rem;
        }
      }
      &--text {
        margin: 0 auto;
        height: 2.3rem;
        width: 15rem;
        font-family: 'Janda Manatee Solid';
        font-size: 1.2rem;
        line-height: 2.6rem;
        &--disable {
          border: none !important;
          cursor: auto;
          opacity: 0.3;
        }
        &--yellow {
          color: #ffea74;
        }
        &--egyptian-blue {
          color: $egyptian-blue;
        }
        &--cinnabar {
          color: $cinnabar;
        }
        &--lime-green {
          color: $lime-green;
        }
      }
      &--emotion {
        height: 2.4rem;
        width: 2.5rem;
        margin-left: 0.3rem;
        &--disable {
          height: 2.4rem;
          width: 2.5rem;
          border: none !important;
          cursor: auto;
          opacity: 0.3;
        }
      }
      &--sticker {
        height: 5.2rem;
        width: 5.7rem;
        &--disable {
          height: 5.2rem;
          width: 5.7rem;
          border: none !important;
          cursor: auto;
          opacity: 0.3;
        }
      }
      &--sound {
        background-color: transparent;
        margin: 0 auto;
        height: 2.3rem;
        width: '90%';
        border-radius: 1rem;
        color: #ffea74;
        font-family: 'Janda Manatee Solid';
        font-size: 1.2rem;
        line-height: 2.6rem;
        margin-top: 1rem;
        margin-bottom: -0.5rem;
        &--disable {
          border: none !important;
          cursor: auto;
          opacity: 0.3;
        }
      }
    }
  }
}
