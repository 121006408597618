.tray {
  width: 100%;
  flex: none;
  height: 4rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.tray-container{
  margin: auto;
  width: 50%;
  margin-top: 1rem;
  border-radius: 2rem;
  background-color: #f2f2f2;
  display: flex;

}
