.b2b-container {
  position: relative;
  display: flex;
  flex: 0 0 70%;
  height: 70rem;
  width: 104rem;
  margin: 1rem auto;
  border-radius: 2rem;
}

.container {
  background: #234779;
  position: relative;
  display: flex;
  flex: 0 0 76rem;
  height: 70rem;
  max-width: 76rem;
  border-radius: 2rem;
  border-bottom-right-radius: 0rem;
}

.align-center {
  text-align: center;
}
