.new-payment-info {
    @include dialog;
    &__inner {
      @include dialog-inner;
      width: 48.2rem;
      height: 35rem;
      background:$message-read;
      background-size: contain; 
    }
    &__description {
      @include description;
      height: 14.5rem;
      width: 28rem;
      margin-left: 15rem;
      margin-top: 3rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: 0.07rem;
      white-space: pre-wrap;
    }
    &__image {
      position: absolute;
      height: 12.8rem;
      width: 9.5rem;
      margin-top: 3rem;
      margin-left: 3.4rem;
      background:$owlTokensIcon center no-repeat;
      background-size: contain; 
    }
    &__confirm {
      @include dialog-button-accept;
      display: block;
      margin: 0 auto;
      margin-top: 5.3rem;
      opacity: 0;
      &:hover {
        @include dialog-button-accept-over;
      }
    }
    &__banner {
      height: 34rem;
      width: 28rem;
      left: 28rem;
      top: 3rem;
      background-size: contain;
      position: absolute;
      pointer-events: none;
      background: $tokensBanner no-repeat;
    }    
    &__single-token {
      height: 4rem;
      width: 4rem;
      left: 14rem;
      top: 32rem;
      background-size: contain;
      position: absolute;
      pointer-events: none;
      background: $tokenBannerSingle no-repeat;
    }
  }
  