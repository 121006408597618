.matching {
  position: absolute;
  height: 45.7rem;
  width: 62rem;
  padding: 1rem 4.7rem;
  margin: -1rem auto 0 auto;
  overflow: hidden;
  &__progress {
    height: 22rem;
    width: 52.2rem;
    z-index: -1;
    margin-top: -12rem;
    background: $questionProgressBackground no-repeat center;
    background-size: contain;    
    &__question {
        position: absolute;
        width: 83%;
        height: 7rem;
        text-align: center;
        margin: 0 auto;
        margin-top: 12rem;
        border-radius: 1rem;
        font-family: "Janda Manatee Solid";	
        font-size: 1.8rem;
        color: $white;
    }    
  }
  &__column {
    position: absolute;
    margin-top: -1rem;
    width: 22.5rem;
    &--B {
      margin-left: 27rem;
    }
  }
  &__option-button {
    height: 3.2rem;
    width: 21.5rem;
    margin-left: 2rem;
    margin-top: 1rem;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    padding-left: 0.2rem;
  }
  &__point {
    opacity: 0;
    height: 3.1rem;
    width: 5.6rem;
    color: $color-white;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: -4rem;
    margin-left: 24rem;
    &--red {
      margin-top: -5.2rem;
      margin-left: 48.5rem;
    }
  }
  &__solving {
    margin-top: 10rem;
    margin-left: 1rem;
    position: absolute;
  }
  &__semaphore-box-blue {
    height: 6.3rem;
    width: 16.6rem;
    opacity: 0;
    margin-top: 38rem;
    margin-left: 4.5rem;
  }
  &__semaphore-box-red {
    height: 6.3rem;
    width: 16.6rem;
    opacity: 0;
    margin-top: -6.3rem;
    margin-left: 31.5rem;
  }
  &__semaphore {
    height: 6.3rem;
    width: 16.6rem;
    z-index: 10;
    position: absolute;
    &--blue {
      background: $blueSemaphore no-repeat center;
      background-size: contain;
    }
    &--red {
      background: $redSemaphore no-repeat center;
      background-size: contain;
    }
    &__image {
      width: 4rem;
      height: 4rem;
      position: absolute;
      border-radius: 1.8rem;
      margin-top: 1rem;
      &--my {
        margin-left: 1rem;
      }
      &--opponent {
        margin-left: 11.5rem;
      }
    }
    &__count {
      color: $color-white;
      font-family: 'Janda Manatee Solid';
      font-size: 1.5rem;
      margin-top: 2rem;
      &--blue {
        margin-left: 7.5rem;
      }
      &--red {
        opacity: 0;
        margin-left: 2.5rem;
      }
    }
  }
}
