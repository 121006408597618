.game-button-hidden {
  visibility: hidden;
}

.game-button {
  @include button;
  color: $saphire-var4;
  background: $light-cyan-var2;
  border: solid 0.5rem $button-border;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  padding-top: 0rem;
  border-color: $sky-light-blue $sky-light-blue $sky-normal-blue $sky-normal-blue;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem $button-border !important;
  &:disabled {
    cursor: default;
    color: $saphire-var4;
    background: $light-cyan-var3;
    border: solid 0.5rem $button-border;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: $light-cyan-var3;
    background-clip: padding-box;
  }
  &:hover {
    color: $saphire-var4;
    background: $light-cyan-var3;
    border: solid 0.5rem $button-border;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: $light-cyan-var3;
    background-clip: padding-box;
  }
}

.game-button-disabled {
  @include button;
  border: solid 0.5rem $button-border;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  background-color: $seagull-var2;
  border-color: $seagull-var2;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem $button-border !important;
  color: $saphire-var4;
  padding-top: 0rem;
  &:disabled {
    cursor: default;
    color: $saphire-var4;
    background: $seagull-var2;
    &:hover {
      background: $seagull-var2;
      color: $saphire-var4;
    }
  }
}

.game-button-blue {
  @include button;
  color: $white;
  background-color: $deep-sky-blue;
  border: solid 0.5rem $button-border;
  border-color: $deep-sky-blue;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem $button-border !important;
  padding-top: 0rem;
  &:disabled {
    color: $white;
  }
}

.game-button-red {
  @include button;
  color: $white;
  padding-top: 0rem;
  background-color: $cinnabar;
  border: solid 0.5rem $button-border;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  border-color: $cinnabar;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem $button-border !important;
  &:disabled {
    color: $white;
  }
}

.game-button-yellow {
  @include button;
  color: $saphire-var4;
  border: solid 0.5rem $button-border;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  border-color: $golden-poppy;
  background-clip: padding-box;
  padding-top: 0rem;
  box-shadow: 0 0 0 0.25rem $button-border !important;
  background-color: $golden-poppy;
}

.game-button-darkblue {
  @include button;
  color: $saphire-var4;
  background-color: $bahama-blue-var;
  border: solid 0.5rem $button-border;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  padding-top: 0rem;
  border-color: $bahama-blue-var;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem $button-border !important;
}

.game-button-blue-red {
  @include button;
  color: $white;
  border: 0rem;
  padding-top: 0rem;
  border-radius: 0.5rem;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem $button-border !important;
  background: linear-gradient(90deg, $deep-sky-blue, 50%, $cinnabar 50%);
  background-size: cover;
}

.game-button-focused {
  @include button;
  border-color: #fe9303;
  background: $light-cyan-var2;
}

.rounded-game-button-over {
  @include rounded_button;
}

.rounded-game-button-disabled {
  @include rounded_button;
  background-color: $light-cyan-var3;
}

.rounded-game-button-blue {
  @include rounded_button;
  background-color: $deep-sky-blue;
}

.rounded-game-button-red {
  @include rounded_button;
  background-color: $cinnabar;
}

.rounded-game-button-yellow {
  @include rounded_button;
  background-color: $golden-poppy;
}

.rounded-game-button-darkblue {
  @include rounded_button;
  background-color: $bahama-blue-var;
}
