.inbox {
  @include dialog;
  &__inner {
    @include absCenter;
    box-sizing: border-box;
    height: 55.9rem;
    width: 72.8rem;
    border: 0.7rem solid $saphire-var3;
    border-radius: 1.8rem;
    background-color: $lochmara;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: $lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain;
  }

  &__content_background {
    position: absolute;
    box-sizing: border-box;
    height: 39.8rem;
    width: 69.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    border: 0.6rem solid $danube;
    border-radius: 2rem;
    z-index: 3;
    pointer-events: none;
  }

  &__content_holder {
    position: absolute;
    box-sizing: border-box;
    height: 39.4rem;
    width: 68.8rem;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top: 1.2rem;
    border: 0.4rem solid $danube;
    border-radius: 2rem;
    overflow: hidden;
    background-color: #082551;
    padding-right: 2rem;
    overflow-y: scroll;
    scrollbar-color: #fc0 rgba(105, 148, 199, 0.2);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 0.5rem;      
    }

    &::-webkit-scrollbar-track {
      background: rgba(105, 148, 199, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background: #fc0;
      border-radius: 0.3rem;
    }
  }

  &__scroll_content {
    height: 38.6rem;
    width: 1.3rem;
    margin-top: -16rem;
    background-color: rgba(105, 148, 199, 0.2);
    float: right;
  }

  &__arrow-up {
    height: 1rem;
    width: 0.7rem;
    margin-top: 0.7rem;
    margin-left: 0.3rem;
    background: $scrollArrow center no-repeat;
    background-size: contain;
    &:hover {
      background: $scrollArrowOver center no-repeat;
      background-size: contain;
    }
  }

  &__arrow-down {
    height: 1rem;
    width: 0.7rem;
    margin-top: 35rem;
    margin-left: 0.3rem;
    background: $scrollArrowDown center no-repeat;
    background-size: contain;
    &:hover {
      background: $scrollArrowDownOver center no-repeat;
      background-size: contain;
    }
  }

  &__scroll {
    position: absolute;
    height: 1.9rem;
    width: 1.3rem;
    margin-top: 0.2rem;
    background: $scrollButton center no-repeat;
    background-size: contain;
  }

  &__description {
    position: absolute;
    height: 5.7rem;
    width: 42.1rem;
    margin-left: 13.5rem;
    margin-top: 17.1rem;
    color: #6893c6;
    font-family: 'Janda Manatee Solid';
    font-size: 2.5rem;
    text-align: center;
    z-index: 1000;
  }

  &__accept {
    @include dialog-button-accept;
    display: block;
    margin-left: 29rem;
    margin-top: 44rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
