.quiz {
  position: absolute;
  height: 42.7rem;
  padding: 2rem 4rem;
  margin: -1rem auto 0 auto;
  &__question-progress {
    height: 18.1rem;
    width: 54.4rem;
    z-index: -1;
    margin: 0 auto;
    margin-top: -4rem;
    background: $questionProgressBackground no-repeat center;
    background-size: contain;
  }
  &__question {
    text-align: center;
    color: #ecf8fe;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    line-height: 3.6rem;
    margin-top: -14rem;
    width: 49rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 0.05rem;
    position: absolute;
  }
  &__question--progress {
    position: absolute;
    margin-left: 6rem;
    margin-top: -1.2rem;
    display: flex;
  }
  &__answers-box {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    margin-left: 0.2rem;
    margin-top: 2.6rem;
  }
  &__answer-box {
    display: flex;
    position: relative;
    margin-top: 3.5rem;
  }

  &__point {
    opacity: 0;
    z-index: 10;
    height: 3rem;
    width: 3.9rem;
    color: $color-white;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: 0rem;
    pointer-events: none;
    &--my-point {
      margin-left: 1rem;
    }
    &--opponent-point {
      margin-left: 23rem;
    }
  }
  &__option-button-box {
    height: 4rem;
    width: 24rem;
    margin-left: 1.8rem;
  }
  &__option-button {
    height: 4rem;
    width: 24rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-left: 0.2rem;
  }
  &__time-text {
    color: $color-white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.1rem;
    text-align: center;
    display: inline-block;
  }
  &__skip-button {
    height: 5rem;
    width: 13.1rem;
    position: absolute;
    margin-top: 20rem;
    margin-left: 47rem;
    background: $gameButtonSkip no-repeat center;
    background-size: contain;
    &:hover {
      background: $gameButtonSkipOver no-repeat center;
      background-size: contain;
      cursor: pointer;
    }
    &--disabled {
      background: $gameButtonSkipDisabled no-repeat center;
      background-size: contain;
      &:hover {
        background: $gameButtonSkipDisabled no-repeat center;
        background-size: contain;
        cursor: default;
      }
    }
  }

  &__skip-text {
    height: 3.2rem;
    width: 12rem;
    color: #12224f;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    line-height: 3.2rem;
    text-align: center;
    margin-left: 1rem;
    margin-top: 1rem;
    padding-right: 3rem;
    position: absolute;
  }
  &__time-box {
    width: 11.9rem;
    height: 1.5rem;
    margin-left: 2rem;
    position: absolute;
    border-radius: 0.5rem;
    &--my-time {
      margin-top: -1.9rem;
      margin-left: 1.8rem;
      padding-left: 0.3rem;
      left: 0;
      background-color: $deep-sky-blue;
      text-align: left !important;
      background-size: contain;
    }
    &--opponent-time {
      margin-top: -1.9rem;
      right: 0;
      padding-right: 0.3rem;
      background-color: $cinnabar;
      text-align: right !important;
      background-size: contain;
    }
  }
}

.question {
  width: 2.2rem;
  height: 2.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background: $nobodyAnsweredQuestion no-repeat center;
  background-size: contain;
  &__not-answered {
    background: $nobodyAnsweredQuestion no-repeat center;
    background-size: contain;
  }
  &__red-answered {
    background: $redAnsweredQuestion no-repeat center;
    background-size: contain;
  }
  &__blue-answered {
    background: $blueAnsweredQuestion no-repeat center;
    background-size: contain;
  }
  &__current {
    width: 3rem;
    height: 3rem;
    margin-top: -0.5rem;
  }
  &__current-not-answered {
    background: $currentNobodyAnswerQuestion no-repeat center;
    background-size: contain;
  }
  &__current-red-answered {
    background: $currentRedAnswerQuestion no-repeat center;
    background-size: contain;
  }
  &__current-blue-answered {
    background: $currentBlueAnswerQuestion no-repeat center;
    background-size: contain;
  }

  &__text {
    height: 1.6rem;
    width: 1.8rem;
    color: #ecf8fe;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 3.6rem;
    margin-top: -0.6rem;
    margin-left: 0.2rem;
    opacity: 0;
    text-align: center;
  }
  &__text-big {
    margin-left: 0.7rem;
  }
}
