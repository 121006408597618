.resume {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 43.2rem;
    height: 22rem;
    background:$lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain; 
  }
  &__description {
    @include description;
    height: 2.5rem;
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.07rem;
    text-align: center;
  }
  &__resume-game {
    height: 8.7rem;
    width: 31.2rem;
    margin: 3rem auto 1rem auto;
    background:$resumeGameButton no-repeat center;
    background-size: contain; 
    position: relative;
    cursor: pointer;
    &__button {
      width: 100%;
      height: 8.7rem;
      display: block;
      &:hover {
        @include hoverAfter {
          background:$resumeGameButtonOver no-repeat center;
          background-size: contain; 
        }
      }
    }
    &--label {
      position: absolute;
      top: 2.5rem;
      left: 9.5rem;
      height: 3.4rem;
      z-index: 2;
      background:$resumeGameLabel center no-repeat;
      background-size: contain; 
    }
  }
}
