.semaphore {
  position: relative;
  display: flex;
  height: 12.5rem;
  width: 53.7rem;
  margin: 0 auto;
  margin-top: -1rem;
  z-index: 200;
  transform: translateY(-0.6rem);
}
