.puzzle {
  position: absolute;
  height: 42.7rem;
  padding: 1rem 4rem;
  margin: -1rem auto 0 auto;
  &__available_letters_container {
    display: flex;
    justify-content: space-between;
    margin: 1.6rem 0 3rem 0;
    height: 3.8rem;
  }

  &__choosen_letters_container {
    position: relative;
    margin-top: 0.2rem;
    margin: 0 0 0 0;
    padding: 0.5rem 0 0 0.4rem;
    display: flex;
    height: 5rem;
    width: 54rem;
    background: $puzzleAnswerBackground no-repeat;
    background-size: contain;
    &--max-word {
      margin: 6.5rem 0;
      background: $maxWord no-repeat 0.4rem center, $puzzleAnswerBackground no-repeat center;
      background-size: contain;
    }
  }

  &__player_image {
    position: relative;
    margin: 0 0.7rem 0.5rem 0.1rem;
    border-radius: 0.4rem;
    display: flex;
    height: 4rem;
    width: 4rem;
  }

  &__choosen_letters_container_computer {
    position: relative;
    margin: 7rem 0 0 0;
    padding: 0.5rem 0 0 0.4rem;
    display: flex;
    height: 5rem;
    width: 54rem;
    font-size: 1.8rem;
    background: $puzzleAnswerBackground no-repeat;
    background-size: contain;
  }

  &__letter {
    padding-top: 0.1rem;
    height: 3.6rem;
    width: 3.6rem;
    font-size: 1.8rem;
  }

  &__choosen_letter {
    height: 3.5rem;
    width: 3.5rem;
    padding-top: 0rem;
    margin-right: 0.2rem;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
    font-size: 1.8rem;
    &-computer {
      color: $white;
    }
  }
  &__word-status {
    height: 3rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.3rem;
    letter-spacing: 0.05em;
    color: $french-pass;
    margin-bottom: 0.4rem;
    line-height: 2.769230769230769;
  }
  &__word-status-image {
    height: 2rem;
    width: 2rem;
    padding-right: 0.2rem;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.4rem;
    margin-right: 0.5rem;
  }
  &__word-status-text {
    &--correct {
      color: $bahia;
    }
    &--incorrect {
      color: $saphire-var4;
    }
    height: 2.4rem;
    display: inline-block;
  }

  &__erase-all-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    z-index: 1;
    height: 3rem;
    width: 3.2rem;
    background: $eraseAllButton no-repeat center;
    background-size: contain;
    &:disabled {
      cursor: default;
      background: $eraseAllButtonOver no-repeat center;
      background-size: contain;
      &:hover {
        background: $eraseAllButtonOver no-repeat center;
        background-size: contain;
      }
    }
    &:hover {
      @include hoverAfter {
        background: $eraseAllButtonOver no-repeat center;
        background-size: contain;
      }
    }
  }

  &__erase-button {
    position: absolute;
    top: 10.4rem;    
    right: 0.7rem;
    z-index: 1;
    height: 3rem;
    width: 3.2rem;
    background: $eraseButton no-repeat center;
    background-size: contain;
    &:disabled {
      cursor: default;
      background: $eraseButtonOver no-repeat center;
      background-size: contain;
      &:hover {
        background: $eraseButtonOver no-repeat center;
        background-size: contain;
      }
    }
    &:hover {
      @include hoverAfter {
        background: $eraseButtonOver no-repeat center;
        background-size: contain;
      }
    }
  }

  &__button-random-word {
    color: $verdun-green;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.035em;
    line-height: 2.769230769230769;
    padding: 0.9rem 1.6rem 0.1rem 6.8rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 26rem;
    height: 6.5rem;
    transform: translate(-50%, 45%);
    background: $helpkitButton no-repeat center;
    background-size: contain;
    &:hover {
      @include hoverBefore {
        background: $helpkitButtonOver no-repeat center;
        background-size: contain;
      }
    }
  }

  &__bonus {
    height: 8.3rem;
    width: 8.5rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(70%, -20%);
    z-index: 2;
    &--longer-word {
      background: $longerWord no-repeat center/cover;
      background-size: contain;
    }
    &--my-game {
      background: $myGame no-repeat center/cover;
      background-size: contain;
    }
  }

  &__extra-bonus {
    height: 8.3rem;
    width: 8.5rem;
    position: absolute;
    right: 0;
    top: 7rem;
    transform: translate(70%, -20%);
    z-index: 3;
    &--comp_same {
      background: $longerAsCompWordBonus no-repeat center/cover;
      background-size: contain;
    }
    &--comp_longer {
      background: $longerThanCompWordBonus no-repeat center/cover;
      background-size: contain;
    }
  }

  &__submit_word_button {
    @include button3d;
    position: relative;
    height: 4.225rem;
    width: 12.875rem;
    top: 0.2rem;
    padding-top: 0.1rem;
    left: 50%;
    transform: translate(-50%, 0%);    
  }

  &__stop_button {
    @include button3d;
    height: 4.225rem;
    width: 14rem;
    margin-bottom: 5rem;
    padding-top: 0.1rem;
    top: 0.4rem;
    left: 42.1%;
    transform: translate(-50%, 0%);
    vertical-align: middle;
    position: absolute;
    color: red;
    margin-top: 26rem;
    &:disabled {
      color: red;
      &:hover {
        color: red;
      }
    }
    &:hover {
      color: red;
    }

    &-icon {
      width: 2.4rem;
      height: 3rem;
      margin-left: 0.6rem;
    }
  }


  &__opponent_word_solving_progress {
    position: absolute;
    top: 30.8rem;
    width: 50rem;
    height: 5rem;
    pointer-events: none;
  }

  &__opponent_word_solving_text {
    height: 3rem;
    vertical-align: middle;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    color: $white;
    line-height: 2.769230769230769;
    display: inline-block;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
  }

  &__point {
    opacity: 0;
    height: 3rem;
    width: 3.9rem;
    color: $color-white;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: 0rem;
    z-index: 4;
    pointer-events: none;
    &--my-point {
      margin-left: 1rem;
    }
    &--opponent-point {
      margin-left: 1rem;
    }
    &--bonus-point {
      margin-left: 53rem;
      margin-top: 3.6rem;
    }
    &--opponent-bonus-point {
      margin-left: 53rem;
      margin-top: 16rem;
    }
    &--extra-bonus-point {
      margin-left: 53rem;
      margin-top: 10.6rem;
    }
    &--extra-opponent-bonus-point {
      margin-left: 53rem;
      margin-top: 23rem;
    }
  }

  &__bonus_point_holder {
    position: absolute;
  }

  &__appeal_opponent_word {
    position: absolute;
    margin-top: 4.8rem;
    background: $reportWrongWord no-repeat bottom left;
    height: 1.6rem;
    width: 16.5rem;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    color: white;
    text-align: left;
    padding-left: 2.2rem;
    padding-top: 0.1rem;
    cursor: pointer;
    &__icon {
      width: 1.5rem;
      background-image: $tooltip-name;
    }

    &__text {
      
      font-size: 1rem;
    }
  }
}
