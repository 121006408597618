.search-opponent {
  @include dialog;
  background: $dialogBlackBackground center no-repeat;
  background-size: contain;
  &__inner {
    @include absCenter;
    box-sizing: border-box;
    width: 56.8rem;
    height: 44rem;
    border: 0.7rem solid $saphire-var3;
    border-radius: 1.8rem;
    background-color: $lochmara;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: $lochmara, $dialogBlackBackground center no-repeat;
  }

  &__content {
    display: flex;
    padding: 2rem 0 0 0;
    justify-content: center;
  }

  &__central-content {
    width: 13rem;
    margin-top: 2.2rem;
  }

  &__matching-arrows {
    text-align: center;
    padding-top: 3.4rem;
    margin: 5rem 1.2rem;
    flex: 0 0 9.5rem;
    height: 10.1rem;
    background: $matchingDialogArrows center no-repeat;
    background-size: contain;
    transform-origin: center;
  }

  &__matching-vs {
    text-align: center;
    padding-top: 3.4rem;
    margin: 5rem 1.2rem;
    flex: 0 0 9.5rem;
    height: 3rem;
    margin-top: -11.5rem;
    background: $matchingVs center no-repeat;
    background-size: contain;
  }

  &__searching {
    text-align: center;
    margin: 3rem 0;
    color: $turbo;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.01em;
    line-height: 1.6111;
    position: absolute;
    margin-top: 27rem;
    margin-left: 20rem;
    pointer-events: none;
  }

  &__timer {
    text-align: center;
    align-items: center;
    margin-top: 3.4rem;
    padding-top: 1rem;
    margin: 5rem 1.2rem;
    flex: 0 0 9.5rem;
    font-family: 'Janda Manatee Solid';
    height: 7rem;
    margin-top: -11rem;
    font-size: 3rem;
    color: white;
    background: $timerMatching center no-repeat;
  }

  &__my_hat {
    top: 3.2rem;
    left: -3.2rem;
    position: absolute;
    width: 17rem;
    height: 17.2rem;
  }

  &__opponents_hat {
    top: 3.2rem;
    right: -3rem;
    position: absolute;
    width: 17rem;
    height: 17.2rem;
    transform: rotateY(180deg);
  }

  &__acceptance_holder {
    height: 10rem;
    &__btn {
      @include dialog-button-accept;
      margin-top: 2rem;
      &:hover {
        @include dialog-button-accept-over;
      }
      &--accept {
        margin-left: 12rem;
        &--disabled {
          @include dialog-button-accept-over;
        }
      }
      &--decline {
        margin-left: 6rem;
        &--disabled {
          @include dialog-button-accept-over;
        }
      }
    }
    &__decline-text {
      color: $turbo;
      font-size: 1.2rem;
      margin-top: 1.4rem;
      font-family: 'Janda Manatee Solid';
      text-align: center;
    }
    &__waiting-text {
      color: $white;
      height: 2rem;
      margin-top: 2rem;
      font-size: 1.8rem;
      font-family: 'Janda Manatee Solid';
      text-align: center;
    }
  }
}
