$imagesPrefix: '../../public/assets/images';
$imageHash: '?15';

$avatar: url($imagesPrefix+'/avatar/avatar.png'+$imageHash);

//achievements

$achievementArowDownDisabled: url($imagesPrefix+'/achievements/achievement-arow-down-disabled.png'+$imageHash);
$achievementArowDownOver: url($imagesPrefix+'/achievements/achievement-arow-down-over.png'+$imageHash);
$achievementArowDown: url($imagesPrefix+'/achievements/achievement-arow-down.png'+$imageHash);
$achievementArowUpDisabled: url($imagesPrefix+'/achievements/achievement-arow-up-disabled.png'+$imageHash);
$achievementArowUpOver: url($imagesPrefix+'/achievements/achievement-arow-up-over.png'+$imageHash);
$achievementArowUp: url($imagesPrefix+'/achievements/achievement-arow-up.png'+$imageHash);
$achievementCardBackgroundOver: url($imagesPrefix+'/achievements/achievement-card-background-over.png'+$imageHash);
$achievementCardBackground: url($imagesPrefix+'/achievements/achievement-card-background.png'+$imageHash);
$achievementComingSoon: url($imagesPrefix+'/achievements/achievement-coming-soon.png'+$imageHash);
$achievementLocked: url($imagesPrefix+'/achievements/achievement-locked.png'+$imageHash);
$achievementProgress: url($imagesPrefix+'/achievements/achievement-progress.png'+$imageHash);
$achievementsDialog: url($imagesPrefix+'/achievements/achievements-dialog.png'+$imageHash);

$pilecePero: url($imagesPrefix+'achievements/pilece-pero.png'+$imageHash);
$petlovoPero: url($imagesPrefix+'/achievements/petlovo-pero.png'+$imageHash);
$sokolovoPero: url($imagesPrefix+'/achievements/sokolovo-pero.png'+$imageHash);
$orlovoPero: url($imagesPrefix+'/achievements/orlovo-pero.png'+$imageHash);
$paunovoPero: url($imagesPrefix+'/achievements/paunovo-pero.png'+$imageHash);
$drveniPehar: url($imagesPrefix+'/achievements/drveni-pehar.png'+$imageHash);
$kameniPehar: url($imagesPrefix+'/achievements/kameni-pehar.png'+$imageHash);
$bronzaniPehar: url($imagesPrefix+'/achievements/bronzani-pehar.png'+$imageHash);
$srebrniPehar: url($imagesPrefix+'/achievements/srebrni-pehar.png'+$imageHash);
$zlatniPehar: url($imagesPrefix+'/achievements/zlatni-pehar.png'+$imageHash);
$zlatnoSlovo: url($imagesPrefix+'/achievements/zlatno-slovo.png'+$imageHash);
$zlatniAs: url($imagesPrefix+'/achievements/zlatni-as.png'+$imageHash);
$zlatnaSova: url($imagesPrefix+'/achievements/zlatna-sova.png'+$imageHash);
$sijalicaZaNocno: url($imagesPrefix+'/achievements/sijalica-za-nocno-strebanje.png'+$imageHash);
$zlatnoSito: url($imagesPrefix+'/achievements/zlatno-sito.png'+$imageHash);
$dediniPehari: url($imagesPrefix+'/achievements/dedini-pehari.png'+$imageHash);
$pradediniPehari: url($imagesPrefix+'/achievements/pradedini-pehari.png'+$imageHash);
$cukundediniPehari: url($imagesPrefix+'/achievements/cukundedini-pehari.png'+$imageHash);
$navrdediniPehari: url($imagesPrefix+'/achievements/navrdedini-pehari.png'+$imageHash);
$askurdjeloviPehari: url($imagesPrefix+'/achievements/askurdjelovi-pehari.png'+$imageHash);
$drvenaZnacka: url($imagesPrefix+'/achievements/drvena-znacka.png'+$imageHash);
$kamenaZnacka: url($imagesPrefix+'/achievements/kamena-znacka.png'+$imageHash);
$bronzanaZnacka: url($imagesPrefix+'/achievements/bronzana-znacka.png'+$imageHash);
$srebrnaZnacka: url($imagesPrefix+'/achievements/srebrna-znacka.png'+$imageHash);
$zlatnaZnacka: url($imagesPrefix+'/achievements/zlatna-znacka.png'+$imageHash);

//buttons
$dialogButtonAcceptOver: url($imagesPrefix+'/buttons/dialog-button-accept-over.png'+$imageHash);
$dialogButtonAccept: url($imagesPrefix+'/buttons/dialog-button-accept.png'+$imageHash);
$eraseButtonDisabled: url($imagesPrefix+'/buttons/erase-button-disabled.png'+$imageHash);
$eraseButtonOver: url($imagesPrefix+'/buttons/erase-button-over.png'+$imageHash);
$eraseButton: url($imagesPrefix+'/buttons/erase-button.png'+$imageHash);
$eraseAllButton: url($imagesPrefix+'/buttons/erase-all-button.png'+$imageHash);
$eraseAllButtonOver: url($imagesPrefix+'/buttons/erase-all-button-over.png'+$imageHash);
$gameButtonSkipOver: url($imagesPrefix+'/buttons/game-button-skip-over.png'+$imageHash);
$gameButtonSkip: url($imagesPrefix+'/buttons/game-button-skip.png'+$imageHash);
$gameButtonSkipDisabled: url($imagesPrefix+'/buttons/game-button-skip-disabled.png'+$imageHash);
$roundedEraseButtonOver: url($imagesPrefix+'/buttons/rounded-erase-button-over.png'+$imageHash);
$roundedEraseButton: url($imagesPrefix+'/buttons/rounded-erase-button.png'+$imageHash);

//game
$helpkitButtonDisabled: url($imagesPrefix+'/game/helpkit-button-disabled.png'+$imageHash);
$helpkitButtonOver: url($imagesPrefix+'/game/helpkit-button-over.png'+$imageHash);
$helpkitButton: url($imagesPrefix+'/game/helpkit-button.png'+$imageHash);

// game/associoation
$letterEmpty: url($imagesPrefix+'/game/association/letter-empty.png'+$imageHash);

// game/combinations
$symClub: url($imagesPrefix+'/game/combination/sym_club.png'+$imageHash);

$symDiamond: url($imagesPrefix+'/game/combination/sym_diamond.png'+$imageHash);
$symHeart: url($imagesPrefix+'/game/combination/sym_heart.png'+$imageHash);
$symOwl: url($imagesPrefix+'/game/combination/sym_owl.png'+$imageHash);
$symSpade: url($imagesPrefix+'/game/combination/sym_spade.png'+$imageHash);
$symStar: url($imagesPrefix+'/game/combination/sym_star.png'+$imageHash);

//game/matching
$blueSemaphore: url($imagesPrefix+'/game/matching/blue-semaphore.png'+$imageHash);
$redSemaphore: url($imagesPrefix+'/game/matching/red-semaphore.png'+$imageHash);

//game/puzzle
$gameButtonDisabled: url($imagesPrefix+'/game/puzzle/game-button-disabled.png'+$imageHash);
$gameButtonOver: url($imagesPrefix+'/game/puzzle/game-button-over.png'+$imageHash);
$gameButton: url($imagesPrefix+'/game/puzzle/game-button.png'+$imageHash);
$gameHolderBackground: url($imagesPrefix+'/game/puzzle/game-holder-background.png'+$imageHash);
$gameTimerBlue: url($imagesPrefix+'/game/puzzle/game-timer-blue.png'+$imageHash);
$gameTimerHighlight: url($imagesPrefix+'/game/puzzle/game-timer-highlight.png'+$imageHash);
$gameTimerOrange: url($imagesPrefix+'/game/puzzle/game-timer-orange.png'+$imageHash);
$gameTimerRed: url($imagesPrefix+'/game/puzzle/game-timer-red.png'+$imageHash);

$longerWord: url($imagesPrefix+'/game/puzzle/longer-word.png'+$imageHash);
$myGame: url($imagesPrefix+'/game/puzzle/my-game.png'+$imageHash);
$longerAsCompWordBonus: url($imagesPrefix+'/game/puzzle/bonus-same-as-computer.png'+$imageHash);
$longerThanCompWordBonus: url($imagesPrefix+'/game/puzzle/bonus-longer-than-computer.png'+$imageHash);

$maxWord: url($imagesPrefix+'/game/puzzle/max-word.png'+$imageHash);
$myPointsWon: url($imagesPrefix+'/game/puzzle/my-points-won.png'+$imageHash);
$opponent12Points: url($imagesPrefix+'/game/puzzle/opponent-12-points.png'+$imageHash);
$opponentWaitingProgress: url($imagesPrefix+'/game/puzzle/opponent-waiting-progress.png'+$imageHash);
$puzzleAnswerBackground: url($imagesPrefix+'/game/puzzle/puzzle-answer-background.png'+$imageHash);
$puzzleHelpkitTooltip: url($imagesPrefix+'/game/puzzle/puzzle-helpkit-tooltip.png'+$imageHash);
$puzzleWordCheckProgress: url($imagesPrefix+'/game/puzzle/puzzle-word-check-progress.png'+$imageHash);
$puzzleWordCorrect: url($imagesPrefix+'/game/puzzle/puzzle-word-correct.png'+$imageHash);
$puzzleWordWrong: url($imagesPrefix+'/game/puzzle/puzzle-word-wrong.png'+$imageHash);
$statusBar: url($imagesPrefix+'/game/puzzle/status-bar.png'+$imageHash);
$reportWrongWord: url($imagesPrefix+'/game/puzzle/report-wrong-word.png'+$imageHash);
$handStop: url($imagesPrefix+'/game/puzzle/hand-stop.png'+$imageHash);

//game/quiz
$blueAnsweredQuestion: url($imagesPrefix+'/game/quiz/blue-answered-question.png'+$imageHash);
$blueTimeBackground: url($imagesPrefix+'/game/quiz/blue-time-background.png'+$imageHash);
$currentBlueAnswerQuestion: url($imagesPrefix+'/game/quiz/current-blue-answer-question.png'+$imageHash);
$currentNobodyAnswerQuestion: url($imagesPrefix+'/game/quiz/current-nobody-answer-question.png'+$imageHash);
$currentQuestion: url($imagesPrefix+'/game/quiz/current-question.png'+$imageHash);
$currentRedAnswerQuestion: url($imagesPrefix+'/game/quiz/current-red-answer-question.png'+$imageHash);
$my6Points: url($imagesPrefix+'/game/quiz/my-6-points.png'+$imageHash);
$nobodyAnsweredQuestion: url($imagesPrefix+'/game/quiz/nobody-answered-question.png'+$imageHash);
$opponent6Points: url($imagesPrefix+'/game/quiz/opponent-6-points.png'+$imageHash);
$questionProgressBackground: url($imagesPrefix+'/game/quiz/question-progress-background.png'+$imageHash);
$redAnsweredQuestion: url($imagesPrefix+'/game/quiz/red-answered-question.png'+$imageHash);
$redTimeBackground: url($imagesPrefix+'/game/quiz/red-time-background.png'+$imageHash);

//game/semaphore
$chatEmojiBackground: url($imagesPrefix+'/game/semaphore/chat-emoji-background.png'+$imageHash);
$gameBackground: url($imagesPrefix+'/game/semaphore/game-background.png'+$imageHash);
$gameStartingTimer: url($imagesPrefix+'/game/semaphore/game-starting-timer.png'+$imageHash);
$gameTimer: url($imagesPrefix+'/game/semaphore/game-timer.png'+$imageHash);
$semaphoreArrowLeftOver: url($imagesPrefix+'/game/semaphore/semaphore-arrow-left-over.png'+$imageHash);
$semaphoreArrowLeft: url($imagesPrefix+'/game/semaphore/semaphore-arrow-left.png'+$imageHash);
$semaphoreArrowRightOver: url($imagesPrefix+'/game/semaphore/semaphore-arrow-right-over.png'+$imageHash);
$semaphoreArrowRight: url($imagesPrefix+'/game/semaphore/semaphore-arrow-right.png'+$imageHash);
$semaphoreBlueBackground: url($imagesPrefix+'/game/semaphore/semaphore-blue-background.png'+$imageHash);
$semaphoreChet: url($imagesPrefix+'/game/semaphore/semaphore-chet.png'+$imageHash);
$semaphoreChetFlipped: url($imagesPrefix+'/game/semaphore/semaphore-chet-flipped.png'+$imageHash);
$semaphoreRedBackground: url($imagesPrefix+'/game/semaphore/semaphore-red-background.png'+$imageHash);

//game/result-page
$backLobbyButtonOver: url($imagesPrefix+'/game/result-page/back-lobby-button-over.png'+$imageHash);
$backLobbyButton: url($imagesPrefix+'/game/result-page/back-lobby-button.png'+$imageHash);
$backLobby: url($imagesPrefix+'/game/result-page/back-to-loby.png'+$imageHash);
$blackFeather: url($imagesPrefix+'/game/result-page/black-feather.png'+$imageHash);
$blackStar: url($imagesPrefix+'/game/result-page/black-star.png'+$imageHash);
$boosterAppliedOff: url($imagesPrefix+'/game/result-page/booster-applied-off.png'+$imageHash);
$boosterAppliedOnDisabled: url($imagesPrefix+'/game/result-page/booster-applied-on-disabled.png'+$imageHash);
$boosterAppliedOn: url($imagesPrefix+'/game/result-page/booster-applied-on.png'+$imageHash);
$boosterX2: url($imagesPrefix+'/game/result-page/booster-x2.png'+$imageHash);
$cupShadow: url($imagesPrefix+'/game/result-page/cup-shadow.png'+$imageHash);
$feather: url($imagesPrefix+'/game/result-page/feather.png'+$imageHash);
$highScoreSticker: url($imagesPrefix+'/game/result-page/high-score-sticker.png'+$imageHash);
$newHighScoreLabel: url($imagesPrefix+'/game/result-page/new-high-score-label.png'+$imageHash);
$losseCup: url($imagesPrefix+'/game/result-page/losse-cup.png'+$imageHash);
$resultArow: url($imagesPrefix+'/game/result-page/result-arow.png'+$imageHash);
$resultBackground: url($imagesPrefix+'/game/result-page/result-background.png'+$imageHash);
$resultHolder: url($imagesPrefix+'/game/result-page/result-holder.png'+$imageHash);
$resultRaiting: url($imagesPrefix+'/game/result-page/result-raiting.png'+$imageHash);
$friendlyResultHolder: url($imagesPrefix+'/game/result-page/friendly-result-holder.png'+$imageHash);
$resultStatusYouLost: url($imagesPrefix+'/game/result-page/result-status-you-lost.png'+$imageHash);
$resultStatusYouWon: url($imagesPrefix+'/game/result-page/result-status-you-won.png'+$imageHash);

$rematch: url($imagesPrefix+'/game/result-page/rematch.png'+$imageHash);
$rematchDefault: url($imagesPrefix+'/game/result-page/rematch-default.png'+$imageHash);
$rematchDefaultOver: url($imagesPrefix+'/game/result-page/rematch-default-over.png'+$imageHash);
$rematchAccept: url($imagesPrefix+'/game/result-page/rematch-accept.png'+$imageHash);
$rematchAcceptOver: url($imagesPrefix+'/game/result-page/rematch-accept-over.png'+$imageHash);
$rematchDisabled: url($imagesPrefix+'/game/result-page/rematch-disabled.png'+$imageHash);
$rematchTextDefault: url($imagesPrefix+'/game/result-page/rematch-text-default.png'+$imageHash);
$rematchTextDisabled: url($imagesPrefix+'/game/result-page/rematch-text-disabled.png'+$imageHash);
$rematchTextAccept: url($imagesPrefix+'/game/result-page/rematch-text-accept.png'+$imageHash);

$scoreHolder: url($imagesPrefix+'/game/result-page/score-holder.png'+$imageHash);
$winerCup: url($imagesPrefix+'/game/result-page/winer-cup.png'+$imageHash);
$friendGameIcon: url($imagesPrefix+'/game/result-page/friend-game-icon.png'+$imageHash);
$trapezeBooster: url($imagesPrefix+'/game/result-page/trapeze-booster.png'+$imageHash);
$trapezeBoosterBlack: url($imagesPrefix+'/game/result-page/trapeze-booster-black.png'+$imageHash);
$gameResultWon: url($imagesPrefix+'/game/result-page/game-result-won.png'+$imageHash);
$gameResultLose: url($imagesPrefix+'/game/result-page/game-result-lose.png'+$imageHash);
$b2bWon: url($imagesPrefix+'/b2b/won.png'+$imageHash);
$b2bLose: url($imagesPrefix+'/b2b/lose.png'+$imageHash);

//game/transition
$descriptionPanel: url($imagesPrefix+'/game/transition/description-panel.png'+$imageHash);
$sequenceCompletedLine: url($imagesPrefix+'/game/transition/sequence-completed-line.png'+$imageHash);
$sequenceCompletedStep: url($imagesPrefix+'/game/transition/sequence-completed-step.png'+$imageHash);
$sequencePointer: url($imagesPrefix+'/game/transition/sequence-pointer.png'+$imageHash);

//loading
$apartekoLoader: url($imagesPrefix+'/loading/aparteko-loader.png'+$imageHash);
$loadingBackground: url($imagesPrefix+'/loading/loading-background.png'+$imageHash);
$loadingProgressBackground: url($imagesPrefix+'/loading/loading-progress-background.png'+$imageHash);
$loadingProgressFrame: url($imagesPrefix+'/loading/loading-progress-frame.png'+$imageHash);
$loadingProgressLine: url($imagesPrefix+'/loading/loading-progress-line.png'+$imageHash);
$logoAparteko: url($imagesPrefix+'/loading/logo-aparteko.png'+$imageHash);

//lobby
$backgroundLobby: url($imagesPrefix+'/lobby/background-lobby.png'+$imageHash);
$playNowBoosterOffOver: url($imagesPrefix+'/lobby/play-now-booster-off-over.png'+$imageHash);
$playNowBoosterOff: url($imagesPrefix+'/lobby/play-now-booster-off.png'+$imageHash);
$playNowBoosterOnOver: url($imagesPrefix+'/lobby/play-now-booster-on-over.png'+$imageHash);
$playNowBoosterOn: url($imagesPrefix+'/lobby/play-now-booster-on.png'+$imageHash);
$playNowOver: url($imagesPrefix+'/lobby/play-now-over.png'+$imageHash);
$playNowText: url($imagesPrefix+'/lobby/play-now-text.png'+$imageHash);
$playNow: url($imagesPrefix+'/lobby/play-now.png'+$imageHash);
$boosterTokenBuy: url($imagesPrefix+'/lobby/booster-token-buy-over.png'+$imageHash);
$boosterTokenBuyOver: url($imagesPrefix+'/lobby/booster-token-buy-over.png'+$imageHash);
$boosterWhiteFlash: url($imagesPrefix+'/lobby/booster-white-flash.png'+$imageHash);
$stickersBuyTokens: url($imagesPrefix+'/lobby/stickers-buy tokens.png'+$imageHash);
$stickersBuyTokensOver: url($imagesPrefix+'/lobby/stickers-buy-tokens-over.png'+$imageHash);
$stickersEarnRewards: url($imagesPrefix+'/lobby/stickers-earn rewards.png'+$imageHash);
$stickersEarnRewardsOver: url($imagesPrefix+'/lobby/stickers-earn-rewards-over.png'+$imageHash);
$todaysHighscoreBck: url($imagesPrefix+'/lobby/todays-highscore-bck.png'+$imageHash);
$b2bHeader: url($imagesPrefix+'/b2b/header-summer.png'+$imageHash);
$b2bHeaderLeague: url($imagesPrefix+'/b2b/SLB-banner.jpg'+$imageHash);
$b2bCollaboration: url($imagesPrefix+'/b2b/collaboration-background.png'+$imageHash);

//lobby/friends
$friendArrowLeftOver: url($imagesPrefix+'/lobby/friends/friend-arrow-left-over.png'+$imageHash);
$friendArrowLeft: url($imagesPrefix+'/lobby/friends/friend-arrow-left.png'+$imageHash);
$friendArrowsLeftOver: url($imagesPrefix+'/lobby/friends/friend-arrows-left-over.png'+$imageHash);
$friendArrowsLeft: url($imagesPrefix+'/lobby/friends/friend-arrows-left.png'+$imageHash);
$friendButtonAcceptOver: url($imagesPrefix+'/lobby/friends/friend-button-accept-over.png'+$imageHash);
$friendButtonAccept: url($imagesPrefix+'/lobby/friends/friend-button-accept.png'+$imageHash);
$friendButtonCallOver: url($imagesPrefix+'/lobby/friends/friend-button-call-over.png'+$imageHash);
$friendButtonCall: url($imagesPrefix+'/lobby/friends/friend-button-call.png'+$imageHash);
$friendButtonCancelOver: url($imagesPrefix+'/lobby/friends/friend-button-cancel-over.png'+$imageHash);
$friendButtonCancel: url($imagesPrefix+'/lobby/friends/friend-button-cancel.png'+$imageHash);
$friendButtonsDeclineOver: url($imagesPrefix+'/lobby/friends/friend-buttons-decline-over.png'+$imageHash);
$friendButtonsDecline: url($imagesPrefix+'/lobby/friends/friend-buttons-decline.png'+$imageHash);
$friendCardDarkBackground: url($imagesPrefix+'/lobby/friends/friend-card-dark-background.png'+$imageHash);
$friendCardLightBackground: url($imagesPrefix+'/lobby/friends/friend-card-light-background.png'+$imageHash);
$friendInviteBackgroundOver: url($imagesPrefix+'/lobby/friends/friend-invite-background-over.png'+$imageHash);
$friendInviteBackground: url($imagesPrefix+'/lobby/friends/friend-invite-background.png'+$imageHash);
$friendSatusBusy: url($imagesPrefix+'/lobby/friends/friend-satus-busy.png'+$imageHash);
$friendSatusOffline: url($imagesPrefix+'/lobby/friends/friend-satus-offline.png'+$imageHash);
$friendSatusOnline: url($imagesPrefix+'/lobby/friends/friend-satus-online.png'+$imageHash);
$friendsBackground: url($imagesPrefix+'/lobby/friends/friends-background.png'+$imageHash);
$pwfButtonsInviteFriendOver: url($imagesPrefix+'/lobby/friends/pwf-buttons-invite-friend-over.png'+$imageHash);
$pwfButtonsInviteFriend: url($imagesPrefix+'/lobby/friends/pwf-buttons-invite-friend.png'+$imageHash);
$pwfbArrowRightOver: url($imagesPrefix+'/lobby/friends/pwfb-arrow-right-over.png'+$imageHash);
$pwfbArrowRight: url($imagesPrefix+'/lobby/friends/pwfb-arrow-right.png'+$imageHash);
$pwfbArrowsRightOver: url($imagesPrefix+'/lobby/friends/pwfb-arrows-right-over.png'+$imageHash);
$pwfbArrowsRight: url($imagesPrefix+'/lobby/friends/pwfb-arrows-right.png'+$imageHash);
$friendsTabAll: url($imagesPrefix+'/lobby/friends/friends-tab-all.png'+$imageHash);
$friendsTabAvailable: url($imagesPrefix+'/lobby/friends/friends-tab-available.png'+$imageHash);
$b2bTeamBlue: url($imagesPrefix+'/lobby/b2b/b2b-team-blue.png'+$imageHash);
$b2bTeamRed: url($imagesPrefix+'/lobby/b2b/b2b-team-red.png'+$imageHash);
$friendCard: url($imagesPrefix+'/lobby/b2b/friend-card.png'+$imageHash);
$clock: url($imagesPrefix+'/lobby/b2b/clock.png'+$imageHash);

//lobby/matching-players
$matchingDialogArrows: url($imagesPrefix+'/lobby/matching-players/matching-dialog-arrows.png'+$imageHash);
$matchingDialogCard: url($imagesPrefix+'/lobby/matching-players/matching-dialog-card.png'+$imageHash);
$matchingVs: url($imagesPrefix+'/lobby/matching-players/matching-vs.png'+$imageHash);
$acceptMatching: url($imagesPrefix+'/lobby/matching-players/accept.png'+$imageHash);
$declineMatching: url($imagesPrefix+'/lobby/matching-players/decline.png'+$imageHash);
$notComfirmedMatching: url($imagesPrefix+'/lobby/matching-players/not_comfirmed.png'+$imageHash);
$timerMatching: url($imagesPrefix+'/lobby/matching-players/timer-matching.png'+$imageHash);

$avatarI: url($imagesPrefix+'/lobby/matching-players/avatar-i.png'+$imageHash);
$avatarL: url($imagesPrefix+'/lobby/matching-players/avatar-l.png'+$imageHash);
$avatarM: url($imagesPrefix+'/lobby/matching-players/avatar-m.png'+$imageHash);
$avatarO: url($imagesPrefix+'/lobby/matching-players/avatar-o.png'+$imageHash);
$avatarP: url($imagesPrefix+'/lobby/matching-players/avatar-p.png'+$imageHash);
$avatarPP: url($imagesPrefix+'/lobby/matching-players/avatar-pp.png'+$imageHash);
$avatarV: url($imagesPrefix+'/lobby/matching-players/avatar-v.png'+$imageHash);

//lobby/toolbar
$musicOff: url($imagesPrefix+'/toolbar/music-off.png'+$imageHash);
$musicOn: url($imagesPrefix+'/toolbar/music-on.png'+$imageHash);
$progressBarBackground: url($imagesPrefix+'/toolbar/progress-bar-background.png'+$imageHash);
$progressBarContent: url($imagesPrefix+'/toolbar/progress-bar-content.png'+$imageHash);
$progressBarShadow: url($imagesPrefix+'/toolbar/progress-bar-shadow.png'+$imageHash);
$progressBarStar: url($imagesPrefix+'/toolbar/progress-bar-star.png'+$imageHash);
$soundOff: url($imagesPrefix+'/lobby/sound_off.png'+$imageHash);
$soundOffOver: url($imagesPrefix+'/lobby/sound-off-over.png'+$imageHash);
$soundOn: url($imagesPrefix+'/lobby/sound_on.png'+$imageHash);
$soundOnOver: url($imagesPrefix+'/lobby/sound-on-over.png'+$imageHash);
$soundsBackground: url($imagesPrefix+'/toolbar/sounds-background.png'+$imageHash);
$toolbarAchievementsOver: url($imagesPrefix+'/toolbar/toolbar-achievements-over.png'+$imageHash);
$toolbarAchievementsSelectedOver: url($imagesPrefix+'/toolbar/toolbar-achievements-selected-over.png'+$imageHash);
$toolbarAchievementsSelected: url($imagesPrefix+'/toolbar/toolbar-achievements-selected.png'+$imageHash);
$toolbarAchievements: url($imagesPrefix+'/toolbar/toolbar-achievements.png'+$imageHash);
$toolbarStatistics: url($imagesPrefix+'/toolbar/toolbar-statistics.png'+$imageHash);
$toolbarStatisticsOver: url($imagesPrefix+'/toolbar/toolbar-statistics-over.png'+$imageHash);
$toolbarStatisticsSelected: url($imagesPrefix+'/toolbar/toolbar-statistic-selected.png'+$imageHash);
$toolbarBackground: url($imagesPrefix+'/toolbar/toolbar-background.png'+$imageHash);
$toolbarBooster: url($imagesPrefix+'/toolbar/toolbar-booster.png'+$imageHash);
$toolbarBoosterOver: url($imagesPrefix+'/toolbar/toolbar-booster-over.png'+$imageHash);
$toolbarHelpkit: url($imagesPrefix+'/toolbar/toolbar-helpkit.png'+$imageHash);
$toolbarHelpkitOver: url($imagesPrefix+'/toolbar/toolbar-helpkit-over.png'+$imageHash);
$toolbarInboxOver: url($imagesPrefix+'/toolbar/toolbar-inbox-over.png'+$imageHash);
$toolbarInboxSelectedOver: url($imagesPrefix+'/toolbar/toolbar-inbox-selected-over.png'+$imageHash);
$toolbarInboxSelected: url($imagesPrefix+'/toolbar/toolbar-inbox-selected.png'+$imageHash);
$toolbarInbox: url($imagesPrefix+'/toolbar/toolbar-inbox.png'+$imageHash);
$toolbarNextTitleShadow: url($imagesPrefix+'/toolbar/toolbar-next-title-shadow.png'+$imageHash);
$toolbarSeparator: url($imagesPrefix+'/toolbar/toolbar-separator.png'+$imageHash);
$toolbarSettingsOver: url($imagesPrefix+'/toolbar/toolbar-settings-over.png'+$imageHash);
$toolbarSettingsSelectedOver: url($imagesPrefix+'/toolbar/toolbar-settings-selected-over.png'+$imageHash);
$toolbarSettingsSelected: url($imagesPrefix+'/toolbar/toolbar-settings-selected.png'+$imageHash);
$toolbarSettings: url($imagesPrefix+'/toolbar/toolbar-settings.png'+$imageHash);
$toolbarShopOver: url($imagesPrefix+'/toolbar/toolbar-shop-over.png'+$imageHash);
$toolbarShopSelectedOver: url($imagesPrefix+'/toolbar/toolbar-shop-selected-over.png'+$imageHash);
$toolbarShopSelected: url($imagesPrefix+'/toolbar/toolbar-shop-selected.png'+$imageHash);
$toolbarShop: url($imagesPrefix+'/toolbar/toolbar-shop.png'+$imageHash);
$toolbarToken: url($imagesPrefix+'/toolbar/toolbar-token.png'+$imageHash);
$toolbarTokenOver: url($imagesPrefix+'/toolbar/toolbar-token-over.png'+$imageHash);
$tooltipBackground: url($imagesPrefix+'/toolbar/tooltip-background.png'+$imageHash);
$tooltipTokensImage: url($imagesPrefix+'/toolbar/tooltip-tokens-image.png'+$imageHash);
$toolbarTokenSilver: url($imagesPrefix+'/toolbar/toolbar-token-silver.png'+$imageHash);

//lobby/tournament
$dailyHigescoreBackground: url($imagesPrefix+'/lobby/tournament/daily-higescore-background.png.png'+$imageHash);
$dayButtonOver: url($imagesPrefix+'/lobby/tournament/day-button-over.png'+$imageHash);
$dayButtonSelected: url($imagesPrefix+'/lobby/tournament/day-button-selected.png'+$imageHash);
$dayButton: url($imagesPrefix+'/lobby/tournament/day-button.png'+$imageHash);
$expandArow: url($imagesPrefix+'/lobby/tournament/expand-arow.png'+$imageHash);
$myPositionBackground: url($imagesPrefix+'/lobby/tournament/my-position-background.png'+$imageHash);
$tournamentAward1: url($imagesPrefix+'/lobby/tournament/tournament-award-1.png'+$imageHash);
$tournamentAward2: url($imagesPrefix+'/lobby/tournament/tournament-award-2.png'+$imageHash);
$tournamentAward3: url($imagesPrefix+'/lobby/tournament/tournament-award-3.png'+$imageHash);
$tournamentAward4: url($imagesPrefix+'/lobby/tournament/tournament-award-4.png'+$imageHash);
$tournamentAward5: url($imagesPrefix+'/lobby/tournament/tournament-award-5.png'+$imageHash);
$tournamentBlackBackground: url($imagesPrefix+'/lobby/tournament/tournament-black-background.png'+$imageHash);
$tournamentHelpOver: url($imagesPrefix+'/lobby/tournament/tournament-help-over.png'+$imageHash);
$tournamentHelp: url($imagesPrefix+'/lobby/tournament/tournament-help.png'+$imageHash);
$tournamentFeather: url($imagesPrefix+'/lobby/tournament/tournament-feather.png'+$imageHash);
$tournamentTab: url($imagesPrefix+'/lobby/tournament/tournament-tab.png'+$imageHash);
$tournamentTabOver: url($imagesPrefix+'/lobby/tournament/tournament-tab-over.png'+$imageHash);

//lobby/friends/invite
$boosterAskFriend: url($imagesPrefix+'/lobby/friends/invite/booster-ask-friend.png'+$imageHash);
$checkboxSelected: url($imagesPrefix+'/lobby/friends/invite/checkbox-selected.png'+$imageHash);
$closedGift: url($imagesPrefix+'/lobby/friends/invite/closed-gift.png'+$imageHash);
$dialogBlackBackground: url($imagesPrefix+'/lobby/friends/invite/dialog-black-background.png'+$imageHash);
$friends: url($imagesPrefix+'/lobby/friends/invite/friends.png'+$imageHash);
$helpkitAskFriend: url($imagesPrefix+'/lobby/friends/invite/helpkit-ask-friend.png'+$imageHash);
$inviteTabBackground: url($imagesPrefix+'/lobby/friends/invite/invite-tab-background.png'+$imageHash);
$scrollBackground: url($imagesPrefix+'/lobby/friends/invite/scroll-background.png'+$imageHash);

//rating
$rating11: url($imagesPrefix+'/rating/1-1.png'+$imageHash);
$rating12: url($imagesPrefix+'/rating/1-2.png'+$imageHash);
$rating13: url($imagesPrefix+'/rating/1-3.png'+$imageHash);
$rating101: url($imagesPrefix+'/rating/10-1.png'+$imageHash);
$rating102: url($imagesPrefix+'/rating/10-2.png'+$imageHash);
$rating103: url($imagesPrefix+'/rating/10-3.png'+$imageHash);
$rating111: url($imagesPrefix+'/rating/11-1.png'+$imageHash);
$rating112: url($imagesPrefix+'/rating/11-2.png'+$imageHash);
$rating113: url($imagesPrefix+'/rating/11-3.png'+$imageHash);
$rating121: url($imagesPrefix+'/rating/12-1.png'+$imageHash);
$rating122: url($imagesPrefix+'/rating/12-2.png'+$imageHash);
$rating123: url($imagesPrefix+'/rating/12-3.png'+$imageHash);
$rating131: url($imagesPrefix+'/rating/13-1.png'+$imageHash);
$rating132: url($imagesPrefix+'/rating/13-2.png'+$imageHash);
$rating133: url($imagesPrefix+'/rating/13-3.png'+$imageHash);
$rating141: url($imagesPrefix+'/rating/14-1.png'+$imageHash);
$rating142: url($imagesPrefix+'/rating/14-2.png'+$imageHash);
$rating143: url($imagesPrefix+'/rating/14-3.png'+$imageHash);
$rating151: url($imagesPrefix+'/rating/15-1.png'+$imageHash);
$rating152: url($imagesPrefix+'/rating/15-2.png'+$imageHash);
$rating153: url($imagesPrefix+'/rating/15-3.png'+$imageHash);
$rating161: url($imagesPrefix+'/rating/16-1.png'+$imageHash);
$rating162: url($imagesPrefix+'/rating/16-2.png'+$imageHash);
$rating163: url($imagesPrefix+'/rating/16-3.png'+$imageHash);
$rating171: url($imagesPrefix+'/rating/17-1.png'+$imageHash);
$rating172: url($imagesPrefix+'/rating/17-2.png'+$imageHash);
$rating173: url($imagesPrefix+'/rating/17-3.png'+$imageHash);
$rating21: url($imagesPrefix+'/rating/2-1.png'+$imageHash);
$rating22: url($imagesPrefix+'/rating/2-2.png'+$imageHash);
$rating23: url($imagesPrefix+'/rating/2-3.png'+$imageHash);
$rating31: url($imagesPrefix+'/rating/3-1.png'+$imageHash);
$rating32: url($imagesPrefix+'/rating/3-2.png'+$imageHash);
$rating33: url($imagesPrefix+'/rating/3-3.png'+$imageHash);
$rating41: url($imagesPrefix+'/rating/4-1.png'+$imageHash);
$rating42: url($imagesPrefix+'/rating/4-2.png'+$imageHash);
$rating43: url($imagesPrefix+'/rating/4-3.png'+$imageHash);
$rating51: url($imagesPrefix+'/rating/5-1.png'+$imageHash);
$rating52: url($imagesPrefix+'/rating/5-2.png'+$imageHash);
$rating53: url($imagesPrefix+'/rating/5-3.png'+$imageHash);
$rating61: url($imagesPrefix+'/rating/6-1.png'+$imageHash);
$rating62: url($imagesPrefix+'/rating/6-2.png'+$imageHash);
$rating63: url($imagesPrefix+'/rating/6-3.png'+$imageHash);
$rating71: url($imagesPrefix+'/rating/7-1.png'+$imageHash);
$rating72: url($imagesPrefix+'/rating/7-2.png'+$imageHash);
$rating73: url($imagesPrefix+'/rating/7-3.png'+$imageHash);
$rating81: url($imagesPrefix+'/rating/8-1.png'+$imageHash);
$rating82: url($imagesPrefix+'/rating/8-2.png'+$imageHash);
$rating83: url($imagesPrefix+'/rating/8-3.png'+$imageHash);
$rating91: url($imagesPrefix+'/rating/9-1.png'+$imageHash);
$rating92: url($imagesPrefix+'/rating/9-2.png'+$imageHash);
$rating93: url($imagesPrefix+'/rating/9-3.png'+$imageHash);
$unknown: url($imagesPrefix+'/rating/unknown.png'+$imageHash);

//rating/large
$ratingLarge11: url($imagesPrefix+'/rating/large/1-1.png'+$imageHash);
$ratingLarge12: url($imagesPrefix+'/rating/large/1-2.png'+$imageHash);
$ratingLarge13: url($imagesPrefix+'/rating/large/1-3.png'+$imageHash);
$ratingLarge101: url($imagesPrefix+'/rating/large/10-1.png'+$imageHash);
$ratingLarge102: url($imagesPrefix+'/rating/large/10-2.png'+$imageHash);
$ratingLarge103: url($imagesPrefix+'/rating/large/10-3.png'+$imageHash);
$ratingLarge111: url($imagesPrefix+'/rating/large/11-1.png'+$imageHash);
$ratingLarge112: url($imagesPrefix+'/rating/large/11-2.png'+$imageHash);
$ratingLarge113: url($imagesPrefix+'/rating/large/11-3.png'+$imageHash);
$ratingLarge121: url($imagesPrefix+'/rating/large/12-1.png'+$imageHash);
$ratingLarge122: url($imagesPrefix+'/rating/large/12-2.png'+$imageHash);
$ratingLarge123: url($imagesPrefix+'/rating/large/12-3.png'+$imageHash);
$ratingLarge131: url($imagesPrefix+'/rating/large/13-1.png'+$imageHash);
$ratingLarge132: url($imagesPrefix+'/rating/large/13-2.png'+$imageHash);
$ratingLarge133: url($imagesPrefix+'/rating/large/13-3.png'+$imageHash);
$ratingLarge141: url($imagesPrefix+'/rating/large/14-1.png'+$imageHash);
$ratingLarge142: url($imagesPrefix+'/rating/large/14-2.png'+$imageHash);
$ratingLarge143: url($imagesPrefix+'/rating/large/14-3.png'+$imageHash);
$ratingLarge151: url($imagesPrefix+'/rating/large/15-1.png'+$imageHash);
$ratingLarge152: url($imagesPrefix+'/rating/large/15-2.png'+$imageHash);
$ratingLarge153: url($imagesPrefix+'/rating/large/15-3.png'+$imageHash);
$ratingLarge161: url($imagesPrefix+'/rating/large/16-1.png'+$imageHash);
$ratingLarge162: url($imagesPrefix+'/rating/large/16-2.png'+$imageHash);
$ratingLarge163: url($imagesPrefix+'/rating/large/16-3.png'+$imageHash);
$ratingLarge171: url($imagesPrefix+'/rating/large/17-1.png'+$imageHash);
$ratingLarge172: url($imagesPrefix+'/rating/large/17-2.png'+$imageHash);
$ratingLarge173: url($imagesPrefix+'/rating/large/17-3.png'+$imageHash);
$ratingLarge21: url($imagesPrefix+'/rating/large/2-1.png'+$imageHash);
$ratingLarge22: url($imagesPrefix+'/rating/large/2-2.png'+$imageHash);
$ratingLarge23: url($imagesPrefix+'/rating/large/2-3.png'+$imageHash);
$ratingLarge31: url($imagesPrefix+'/rating/large/3-1.png'+$imageHash);
$ratingLarge32: url($imagesPrefix+'/rating/large/3-2.png'+$imageHash);
$ratingLarge33: url($imagesPrefix+'/rating/large/3-3.png'+$imageHash);
$ratingLarge41: url($imagesPrefix+'/rating/large/4-1.png'+$imageHash);
$ratingLarge42: url($imagesPrefix+'/rating/large/4-2.png'+$imageHash);
$ratingLarge43: url($imagesPrefix+'/rating/large/4-3.png'+$imageHash);
$ratingLarge51: url($imagesPrefix+'/rating/large/5-1.png'+$imageHash);
$ratingLarge52: url($imagesPrefix+'/rating/large/5-2.png'+$imageHash);
$ratingLarge53: url($imagesPrefix+'/rating/large/5-3.png'+$imageHash);
$ratingLarge61: url($imagesPrefix+'/rating/large/6-1.png'+$imageHash);
$ratingLarge62: url($imagesPrefix+'/rating/large/6-2.png'+$imageHash);
$ratingLarge63: url($imagesPrefix+'/rating/large/6-3.png'+$imageHash);
$ratingLarge71: url($imagesPrefix+'/rating/large/7-1.png'+$imageHash);
$ratingLarge72: url($imagesPrefix+'/rating/large/7-2.png'+$imageHash);
$ratingLarge73: url($imagesPrefix+'/rating/large/7-3.png'+$imageHash);
$ratingLarge81: url($imagesPrefix+'/rating/large/8-1.png'+$imageHash);
$ratingLarge82: url($imagesPrefix+'/rating/large/8-2.png'+$imageHash);
$ratingLarge83: url($imagesPrefix+'/rating/large/8-3.png'+$imageHash);
$ratingLarge91: url($imagesPrefix+'/rating/large/9-1.png'+$imageHash);
$ratingLarge92: url($imagesPrefix+'/rating/large/9-2.png'+$imageHash);
$ratingLarge93: url($imagesPrefix+'/rating/large/9-3.png'+$imageHash);
$unknown: url($imagesPrefix+'/rating/large/unknown.png'+$imageHash);

//dialogs/daily-tokens
$cardsHolder: url($imagesPrefix+'/dialogs/daily-tokens/cards-holder.png'+$imageHash);
$day1Tokens: url($imagesPrefix+'/dialogs/daily-tokens/day1-tokens.png'+$imageHash);
$day2Tokens: url($imagesPrefix+'/dialogs/daily-tokens/day2-tokens.png'+$imageHash);
$day3Tokens: url($imagesPrefix+'/dialogs/daily-tokens/day3-tokens.png'+$imageHash);
$day4Tokens: url($imagesPrefix+'/dialogs/daily-tokens/day4-tokens.png'+$imageHash);
$day5Tokens: url($imagesPrefix+'/dialogs/daily-tokens/day5-tokens.png'+$imageHash);
$day1TokensSilver: url($imagesPrefix+'/dialogs/daily-tokens/silver-tokens-1.png'+$imageHash);
$day2TokensSilver: url($imagesPrefix+'/dialogs/daily-tokens/silver-tokens-2.png'+$imageHash);
$day3TokensSilver: url($imagesPrefix+'/dialogs/daily-tokens/silver-tokens-3.png'+$imageHash);
$day4TokensSilver: url($imagesPrefix+'/dialogs/daily-tokens/silver-tokens-4.png'+$imageHash);
$day5TokensSilver: url($imagesPrefix+'/dialogs/daily-tokens/silver-tokens-5.png'+$imageHash);
$nextDayBackgroundTop: url($imagesPrefix+'/dialogs/daily-tokens/next-day-background-top.png'+$imageHash);
$nextDayTokenHolder: url($imagesPrefix+'/dialogs/daily-tokens/next-day-token-holder.png'+$imageHash);
$peviousDayBackgroundTop: url($imagesPrefix+'/dialogs/daily-tokens/pevious-day-background-top.png'+$imageHash);
$peviousDayDone: url($imagesPrefix+'/dialogs/daily-tokens/pevious-day-done.png'+$imageHash);
$peviousDayDoneFrame: url($imagesPrefix+'/dialogs/daily-tokens/pevious-day-done-frame.png'+$imageHash);
$peviousDayTokenHolder: url($imagesPrefix+'/dialogs/daily-tokens/pevious-day-token-holder.png'+$imageHash);
$todayTokenHolder: url($imagesPrefix+'/dialogs/daily-tokens/today-token-holder.png'+$imageHash);
$today: url($imagesPrefix+'/dialogs/daily-tokens/today.png'+$imageHash);
$silverTokenIcon: url($imagesPrefix+'/dialogs/daily-tokens/silver-token-icon.png'+$imageHash);

//dialogs/dialog-info
$owlInfo: url($imagesPrefix+'/dialogs/dialog-info/owl-info.png'+$imageHash);
$owlGift: url($imagesPrefix+'/dialogs/dialog-info/gift.png'+$imageHash);

//dialogs/earn-rewards
$referralAdd: url($imagesPrefix+'/dialogs/earn-rewards/referral-add.png'+$imageHash);
$referralIcon: url($imagesPrefix+'/dialogs/earn-rewards/referral-icon.png'+$imageHash);

//dialogs/inbox
$inboxGiftIcon: url($imagesPrefix+'/dialogs/inbox/inbox-gift-icon.png'+$imageHash);
$inboxMessageBackground: url($imagesPrefix+'/dialogs/inbox/inbox-message-background.png'+$imageHash);
$owlSystemNotification: url($imagesPrefix+'/dialogs/inbox/owl-system-notification.png'+$imageHash);
$scrollArrowDownOver: url($imagesPrefix+'/dialogs/inbox/scroll-arrow-down-over.png'+$imageHash);
$scrollArrowDown: url($imagesPrefix+'/dialogs/inbox/scroll-arrow-down.png'+$imageHash);
$scrollArrowOver: url($imagesPrefix+'/dialogs/inbox/scroll-arrow-over.png'+$imageHash);
$scrollArrow: url($imagesPrefix+'/dialogs/inbox/scroll-arrow.png'+$imageHash);
$scrollButton: url($imagesPrefix+'/dialogs/inbox/scroll-button.png'+$imageHash);

//dialogs/feather-booster
$tokensSilverGold: url($imagesPrefix+'/dialogs/feather-booster/icon-tokens-silver-gold.png'+$imageHash);

//dialogs/reconnect
$owlError: url($imagesPrefix+'/dialogs/reconnect/owl-error.png'+$imageHash);

//dialogs/resume-game
$resumeGameButtonOver: url($imagesPrefix+'/dialogs/resume-game/resume-game-button-over.png'+$imageHash);
$resumeGameButton: url($imagesPrefix+'/dialogs/resume-game/resume-game-button.png'+$imageHash);
$resumeGameLabel: url($imagesPrefix+'/dialogs/resume-game/resume-game-label.png'+$imageHash);

//dialogs/tournament-result
$boosterIcon: url($imagesPrefix+'/lobby/booster-icon.png'+$imageHash);
$boosterFlameIcon: url($imagesPrefix+'/lobby/booster-flame-icon.png'+$imageHash);
$boosterAdd: url($imagesPrefix+'/lobby/booster-add.png'+$imageHash);
$boosterAddDisabled: url($imagesPrefix+'/lobby/booster-add-disabled.png'+$imageHash);
$boosterAddOver: url($imagesPrefix+'/lobby/booster-add-over.png'+$imageHash);
$colorFeathersBackground: url($imagesPrefix+'/dialogs/tournament-result/color-feathers-background.png'+$imageHash);
$helpkitIcon: url($imagesPrefix+'/dialogs/tournament-result/helpkit-icon.png'+$imageHash);
$openedGiftbox: url($imagesPrefix+'/dialogs/tournament-result/opened-giftbox.png'+$imageHash);
$profilePictureFrame: url($imagesPrefix+'/dialogs/tournament-result/profile-picture-frame.png'+$imageHash);
$starIcon: url($imagesPrefix+'/dialogs/tournament-result/star-icon.png'+$imageHash);
$tokenIcon: url($imagesPrefix+'/dialogs/tournament-result/token-icon.png'+$imageHash);
$trone: url($imagesPrefix+'/dialogs/tournament-result/trone.png'+$imageHash);
$unknownProfilePicture: url($imagesPrefix+'/dialogs/tournament-result/unknown-profile-picture.png'+$imageHash);

//dialogs/equal-score
$owlScale: url($imagesPrefix+'/dialogs/equal-score/owl-scale.png'+$imageHash);

//dialogs
$dialogCloseButtonOver: url($imagesPrefix+'/dialogs/dialog-close-button-over.png'+$imageHash);
$dialogCloseButton: url($imagesPrefix+'/dialogs/dialog-close-button.png'+$imageHash);

// dialogs/out-of-helpkit
$outOfHelpkitImage: url($imagesPrefix+'/dialogs/out-of-helpkit/help-kit-offer.png'+$imageHash);

// dialogs/survey
$earnTokens: url($imagesPrefix+'/dialogs/survey/earn-tokens.png'+$imageHash);

//dialogs/shop
$addTokensBackground: url($imagesPrefix+'/dialogs/shop/add-tokens-background.png'+$imageHash);
$addTokensButton: url($imagesPrefix+'/dialogs/shop/add-tokens-button.png'+$imageHash);
$bestBuy: url($imagesPrefix+'/dialogs/shop/best-buy.png'+$imageHash);
$buyDone: url($imagesPrefix+'/dialogs/shop/buy-done.png'+$imageHash);
$buyText: url($imagesPrefix+'/dialogs/shop/buy-text.png'+$imageHash);
$dialogBackground: url($imagesPrefix+'/dialogs/shop/dialog-background.png'+$imageHash);
$dialogHeaderLeft: url($imagesPrefix+'/dialogs/shop/dialog-header-left.png'+$imageHash);
$dialogHeaderMiddle: url($imagesPrefix+'/dialogs/shop/dialog-header-middle.png'+$imageHash);
$dialogHeaderRight: url($imagesPrefix+'/dialogs/shop/dialog-header-right.png'+$imageHash);
$shopCardBackground: url($imagesPrefix+'/dialogs/shop/shop-card-background.png'+$imageHash);
$shopCardInnerBackgroundOver: url($imagesPrefix+'/dialogs/shop/shop-card-inner-background-over.png'+$imageHash);
$shopArrowLeft: url($imagesPrefix+'/dialogs/shop/shop-arrow-left.png'+$imageHash);
$shopArrowRightOver: url($imagesPrefix+'/dialogs/shop/shop-arrow-right-over.png'+$imageHash);
$shopArrowRight: url($imagesPrefix+'/dialogs/shop/shop-arrow-right.png'+$imageHash);
$shopBoosterIcon: url($imagesPrefix+'/dialogs/shop/shop-booster-icon.png'+$imageHash);
$shopEmotionIcon: url($imagesPrefix+'/dialogs/shop/shop-emotion-icon.png'+$imageHash);
$shopHatIcon: url($imagesPrefix+'/dialogs/shop/shop-hat-icon.png'+$imageHash);
$shopHelpkitIcon: url($imagesPrefix+'/dialogs/shop/shop-helpkit-icon.png'+$imageHash);
$shopTabButtonSelected: url($imagesPrefix+'/dialogs/shop/shop-tab-button-selected.png'+$imageHash);
$shopTabButton: url($imagesPrefix+'/dialogs/shop/shop-tab-button.png'+$imageHash);
$shopTitle: url($imagesPrefix+'/dialogs/shop/shop-title.png'+$imageHash);
$stickerNew: url($imagesPrefix+'/dialogs/shop/sticker-new.png'+$imageHash);
$tabContentBackground: url($imagesPrefix+'/dialogs/shop/tab-content-background.png'+$imageHash);

//dialogs/shop/booster
$boosterCardImage: url($imagesPrefix+'/dialogs/shop/booster/booster-card-image.png'+$imageHash);
$boosterCardInnerBackground: url($imagesPrefix+'/dialogs/shop/booster/booster-card-inner-background.png'+$imageHash);
$boosterCountHolder: url($imagesPrefix+'/dialogs/shop/booster/booster-count-holder.png'+$imageHash);
$boosterFreeCardOver: url($imagesPrefix+'/dialogs/shop/booster/booster-free-card-over.png'+$imageHash);
$boosterFreeCard: url($imagesPrefix+'/dialogs/shop/booster/booster-free-card.png'+$imageHash);
$shopBoosterSmallIcon: url($imagesPrefix+'/dialogs/shop/booster/icon-booster.png'+$imageHash);

//dialogs/shop/emotions
$dialogsShopEmotions1: url($imagesPrefix+'/dialogs/shop/emotions/1.png'+$imageHash);
$dialogsShopEmotions10: url($imagesPrefix+'/dialogs/shop/emotions/10.png'+$imageHash);
$dialogsShopEmotions11: url($imagesPrefix+'/dialogs/shop/emotions/11.png'+$imageHash);
$dialogsShopEmotions12: url($imagesPrefix+'/dialogs/shop/emotions/12.png'+$imageHash);
$dialogsShopEmotions2: url($imagesPrefix+'/dialogs/shop/emotions/2.png'+$imageHash);
$dialogsShopEmotions3: url($imagesPrefix+'/dialogs/shop/emotions/3.png'+$imageHash);
$dialogsShopEmotions4: url($imagesPrefix+'/dialogs/shop/emotions/4.png'+$imageHash);
$dialogsShopEmotions5: url($imagesPrefix+'/dialogs/shop/emotions/5.png'+$imageHash);
$dialogsShopEmotions6: url($imagesPrefix+'/dialogs/shop/emotions/6.png'+$imageHash);
$dialogsShopEmotions7: url($imagesPrefix+'/dialogs/shop/emotions/7.png'+$imageHash);
$dialogsShopEmotions8: url($imagesPrefix+'/dialogs/shop/emotions/8.png'+$imageHash);
$dialogsShopEmotions9: url($imagesPrefix+'/dialogs/shop/emotions/9.png'+$imageHash);

//dialogs/shop/hats
$blueBackground: url($imagesPrefix+'/dialogs/shop/hats/blue-background.png'+$imageHash);
$hat1: url($imagesPrefix+'/dialogs/shop/hats/hat-1.png'+$imageHash);
$hat10: url($imagesPrefix+'/dialogs/shop/hats/hat-10.png'+$imageHash);
$hat11: url($imagesPrefix+'/dialogs/shop/hats/hat-11.png'+$imageHash);
$hat12: url($imagesPrefix+'/dialogs/shop/hats/hat-12.png'+$imageHash);
$hat13: url($imagesPrefix+'/dialogs/shop/hats/hat-13.png'+$imageHash);
$hat14: url($imagesPrefix+'/dialogs/shop/hats/hat-14.png'+$imageHash);
$hat15: url($imagesPrefix+'/dialogs/shop/hats/hat-15.png'+$imageHash);
$hat16: url($imagesPrefix+'/dialogs/shop/hats/hat-16.png'+$imageHash);
$hat17: url($imagesPrefix+'/dialogs/shop/hats/hat-17.png'+$imageHash);
$hat18: url($imagesPrefix+'/dialogs/shop/hats/hat-18.png'+$imageHash);
$hat19: url($imagesPrefix+'/dialogs/shop/hats/hat-19.png'+$imageHash);
$hat2: url($imagesPrefix+'/dialogs/shop/hats/hat-2.png'+$imageHash);
$hat20: url($imagesPrefix+'/dialogs/shop/hats/hat-20.png'+$imageHash);
$hat21: url($imagesPrefix+'/dialogs/shop/hats/hat-21.png'+$imageHash);
$hat22: url($imagesPrefix+'/dialogs/shop/hats/hat-22.png'+$imageHash);
$hat23: url($imagesPrefix+'/dialogs/shop/hats/hat-23.png'+$imageHash);
$hat24: url($imagesPrefix+'/dialogs/shop/hats/hat-24.png'+$imageHash);
$hat25: url($imagesPrefix+'/dialogs/shop/hats/hat-25.png'+$imageHash);
$hat3: url($imagesPrefix+'/dialogs/shop/hats/hat-3.png'+$imageHash);
$hat4: url($imagesPrefix+'/dialogs/shop/hats/hat-4.png'+$imageHash);
$hat5: url($imagesPrefix+'/dialogs/shop/hats/hat-5.png'+$imageHash);
$hat6: url($imagesPrefix+'/dialogs/shop/hats/hat-6.png'+$imageHash);
$hat7: url($imagesPrefix+'/dialogs/shop/hats/hat-7.png'+$imageHash);
$hat8: url($imagesPrefix+'/dialogs/shop/hats/hat-8.png'+$imageHash);
$hat9: url($imagesPrefix+'/dialogs/shop/hats/hat-9.png'+$imageHash);

//dialogs/shop/sets
$emoticonsSet0: url($imagesPrefix+'/dialogs/shop/sets/emoticons-set-0.png'+$imageHash);
$stickersSet0: url($imagesPrefix+'/dialogs/shop/sets/stickers-set-0.png'+$imageHash);
$stickersSet1: url($imagesPrefix+'/dialogs/shop/sets/stickers-set-1.png'+$imageHash);
$textSet0: url($imagesPrefix+'/dialogs/shop/sets/text-set-0.png'+$imageHash);
$textSet1: url($imagesPrefix+'/dialogs/shop/sets/text-set-1.png'+$imageHash);
$textSet2: url($imagesPrefix+'/dialogs/shop/sets/text-set-2.png'+$imageHash);

//dialogs/shop/helpkits
$countHolder: url($imagesPrefix+'/dialogs/shop/helpkits/count-holder.png'+$imageHash);
$helpkitCardImage: url($imagesPrefix+'/dialogs/shop/helpkits/helpkit-card-image.png'+$imageHash);
$helpkitCardInnerBackground: url($imagesPrefix+'/dialogs/shop/helpkits/helpkit-card-inner-background.png'+$imageHash);
$helpkitFreeCardOver: url($imagesPrefix+'/dialogs/shop/helpkits/helpkit-free-card-over.png'+$imageHash);
$helpkitFreeCard: url($imagesPrefix+'/dialogs/shop/helpkits/helpkit-free-card.png'+$imageHash);
$shopHelpKitSmallIcon: url($imagesPrefix+'/dialogs/shop/helpkits/icon-help-kit.png'+$imageHash);

//dialogs/shop/sounds
$dialogsShopSounds1: url($imagesPrefix+'/dialogs/shop/sounds/1.png'+$imageHash);
$dialogsShopSounds2: url($imagesPrefix+'/dialogs/shop/sounds/2.png'+$imageHash);
$dialogsShopSounds3: url($imagesPrefix+'/dialogs/shop/sounds/3.png'+$imageHash);
$dialogsShopSounds4: url($imagesPrefix+'/dialogs/shop/sounds/4.png'+$imageHash);
$dialogsShopSounds5: url($imagesPrefix+'/dialogs/shop/sounds/5.png'+$imageHash);
$dialogsShopSounds6: url($imagesPrefix+'/dialogs/shop/sounds/6.png'+$imageHash);
$dialogsShopSounds7: url($imagesPrefix+'/dialogs/shop/sounds/7.png'+$imageHash);
$dialogsShopSounds8: url($imagesPrefix+'/dialogs/shop/sounds/8.png'+$imageHash);

//dialogs/shop/stickers
$dialogsShopStickers1: url($imagesPrefix+'/dialogs/shop/stickers/1.png'+$imageHash);
$dialogsShopStickers10: url($imagesPrefix+'/dialogs/shop/stickers/10.png'+$imageHash);
$dialogsShopStickers11: url($imagesPrefix+'/dialogs/shop/stickers/11.png'+$imageHash);
$dialogsShopStickers12: url($imagesPrefix+'/dialogs/shop/stickers/12.png'+$imageHash);
$dialogsShopStickers2: url($imagesPrefix+'/dialogs/shop/stickers/2.png'+$imageHash);
$dialogsShopStickers3: url($imagesPrefix+'/dialogs/shop/stickers/3.png'+$imageHash);
$dialogsShopStickers4: url($imagesPrefix+'/dialogs/shop/stickers/4.png'+$imageHash);
$dialogsShopStickers5: url($imagesPrefix+'/dialogs/shop/stickers/5.png'+$imageHash);
$dialogsShopStickers6: url($imagesPrefix+'/dialogs/shop/stickers/6.png'+$imageHash);
$dialogsShopStickers7: url($imagesPrefix+'/dialogs/shop/stickers/7.png'+$imageHash);
$dialogsShopStickers8: url($imagesPrefix+'/dialogs/shop/stickers/8.png'+$imageHash);
$dialogsShopStickers9: url($imagesPrefix+'/dialogs/shop/stickers/9.png'+$imageHash);
$lock: url($imagesPrefix+'/dialogs/shop/stickers/lock.png'+$imageHash);

//dialogs/shop/tokens
$token: url($imagesPrefix+'/dialogs/shop/tokens/token.png'+$imageHash);
$package1Tokens: url($imagesPrefix+'/dialogs/shop/tokens/package1-tokens.png'+$imageHash);
$package2Tokens: url($imagesPrefix+'/dialogs/shop/tokens/package2-tokens.png'+$imageHash);
$package3Tokens: url($imagesPrefix+'/dialogs/shop/tokens/package3-tokens.png'+$imageHash);
$package4Tokens: url($imagesPrefix+'/dialogs/shop/tokens/package4-tokens.png'+$imageHash);
$package5Tokens: url($imagesPrefix+'/dialogs/shop/tokens/package5-tokens.png'+$imageHash);

$dialogsShopTokensTabMiddle: url($imagesPrefix+'/dialogs/shop/tokens/tokens-shop-middle.png'+$imageHash);
$dialogsShopTokensTabBackground: url($imagesPrefix+'/dialogs/shop/tokens/tokens-shop-tab-background.png'+$imageHash);
$dialogShopTokensLock: url($imagesPrefix+'/dialogs/shop/tokens/lock.png'+$imageHash);

//dialogs/shop/hats/preview
$hatPreview0: url($imagesPrefix+'/dialogs/shop/hats/preview/0.png'+$imageHash);
$hatPreview1: url($imagesPrefix+'/dialogs/shop/hats/preview/1.png'+$imageHash);
$hatPreview10: url($imagesPrefix+'/dialogs/shop/hats/preview/10.png'+$imageHash);
$hatPreview11: url($imagesPrefix+'/dialogs/shop/hats/preview/11.png'+$imageHash);
$hatPreview12: url($imagesPrefix+'/dialogs/shop/hats/preview/12.png'+$imageHash);
$hatPreview13: url($imagesPrefix+'/dialogs/shop/hats/preview/13.png'+$imageHash);
$hatPreview14: url($imagesPrefix+'/dialogs/shop/hats/preview/14.png'+$imageHash);
$hatPreview15: url($imagesPrefix+'/dialogs/shop/hats/preview/15.png'+$imageHash);
$hatPreview16: url($imagesPrefix+'/dialogs/shop/hats/preview/16.png'+$imageHash);
$hatPreview17: url($imagesPrefix+'/dialogs/shop/hats/preview/17.png'+$imageHash);
$hatPreview18: url($imagesPrefix+'/dialogs/shop/hats/preview/18.png'+$imageHash);
$hatPreview19: url($imagesPrefix+'/dialogs/shop/hats/preview/19.png'+$imageHash);
$hatPreview2: url($imagesPrefix+'/dialogs/shop/hats/preview/2.png'+$imageHash);
$hatPreview20: url($imagesPrefix+'/dialogs/shop/hats/preview/20.png'+$imageHash);
$hatPreview21: url($imagesPrefix+'/dialogs/shop/hats/preview/21.png'+$imageHash);
$hatPreview22: url($imagesPrefix+'/dialogs/shop/hats/preview/22.png'+$imageHash);
$hatPreview23: url($imagesPrefix+'/dialogs/shop/hats/preview/23.png'+$imageHash);
$hatPreview24: url($imagesPrefix+'/dialogs/shop/hats/preview/24.png'+$imageHash);
$hatPreview3: url($imagesPrefix+'/dialogs/shop/hats/preview/3.png'+$imageHash);
$hatPreview4: url($imagesPrefix+'/dialogs/shop/hats/preview/4.png'+$imageHash);
$hatPreview5: url($imagesPrefix+'/dialogs/shop/hats/preview/5.png'+$imageHash);
$hatPreview6: url($imagesPrefix+'/dialogs/shop/hats/preview/6.png'+$imageHash);
$hatPreview7: url($imagesPrefix+'/dialogs/shop/hats/preview/7.png'+$imageHash);
$hatPreview8: url($imagesPrefix+'/dialogs/shop/hats/preview/8.png'+$imageHash);
$hatPreview9: url($imagesPrefix+'/dialogs/shop/hats/preview/9.png'+$imageHash);
$semaphore: url($imagesPrefix+'/dialogs/shop/hats/preview/semaphore.png'+$imageHash);

//dialogs/shop/sounds/small
$dialogsShopSoundsSmall1: url($imagesPrefix+'/dialogs/shop/sounds/small/1.png'+$imageHash);
$dialogsShopSoundsSmall2: url($imagesPrefix+'/dialogs/shop/sounds/small/2.png'+$imageHash);
$dialogsShopSoundsSmall3: url($imagesPrefix+'/dialogs/shop/sounds/small/3.png'+$imageHash);
$dialogsShopSoundsSmall4: url($imagesPrefix+'/dialogs/shop/sounds/small/4.png'+$imageHash);
$dialogsShopSoundsSmall5: url($imagesPrefix+'/dialogs/shop/sounds/small/5.png'+$imageHash);
$dialogsShopSoundsSmall6: url($imagesPrefix+'/dialogs/shop/sounds/small/6.png'+$imageHash);
$dialogsShopSoundsSmall7: url($imagesPrefix+'/dialogs/shop/sounds/small/7.png'+$imageHash);
$dialogsShopSoundsSmall8: url($imagesPrefix+'/dialogs/shop/sounds/small/8.png'+$imageHash);

//game/chat
$chatEmojiBackground: url($imagesPrefix+'/game/chat/chat-emoji-background.png'+$imageHash);
$chatEmotionIcons: url($imagesPrefix+'/game/chat/chat-emotion-icons.png'+$imageHash);
$chatScrollBackground: url($imagesPrefix+'/game/chat/chat-scroll-background.png'+$imageHash);
$chatSoundIconLockDark: url($imagesPrefix+'/game/chat/chat-sound-icon-lock-dark.png'+$imageHash);
$chatSoundIconLockLight: url($imagesPrefix+'/game/chat/chat-sound-icon-lock-light.png'+$imageHash);
$chatSoundIconNoteDark: url($imagesPrefix+'/game/chat/chat-sound-icon-note-dark.png'+$imageHash);
$chatSoundIconNoteLight: url($imagesPrefix+'/game/chat/chat-sound-icon-note-light.png'+$imageHash);
$chatSoundsBacground: url($imagesPrefix+'/game/chat/chat-sounds-bacground.png'+$imageHash);
$chatStickerIconsLock: url($imagesPrefix+'/game/chat/chat-sticker-icons-lock.png'+$imageHash);
$chatStickerIcons: url($imagesPrefix+'/game/chat/chat-sticker-icons.png'+$imageHash);
$chatTextBackground: url($imagesPrefix+'/game/chat/chat-text-background.png'+$imageHash);
$chatTextBackgroundFlipped: url($imagesPrefix+'/game/chat/chat-text-background-flipped.png'+$imageHash);
$chatTabButtons: url($imagesPrefix+'/game/chat/chat-tab-buttons.png'+$imageHash);
$chatTabIcons: url($imagesPrefix+'/game/chat/chat-tab-icons.png'+$imageHash);
$chatShop: url($imagesPrefix+'/game/chat/shop.png'+$imageHash);
$chatShopOver: url($imagesPrefix+'/game/chat/shop-over.png'+$imageHash);
$chatTabButton: url($imagesPrefix+'/game/chat/tab-button.png'+$imageHash);
$chatTabButtonOver: url($imagesPrefix+'/game/chat/tab-button-over.png'+$imageHash);
$chatTabButtonSelected: url($imagesPrefix+'/game/chat/tab-button-selected.png'+$imageHash);
$chatLock1: url($imagesPrefix+'/game/chat/lock-1.png'+$imageHash);
$chatIconText: url($imagesPrefix+'/game/chat/chat-tab-icon-text.png'+$imageHash);
$chatIconEmoticon: url($imagesPrefix+'/game/chat/chat-tab-icon-emoticon.png'+$imageHash);
$chatIconSticker: url($imagesPrefix+'/game/chat/chat-tab-icon-sticker.png'+$imageHash);
$chatIconSound: url($imagesPrefix+'/game/chat/chat-tab-icon-sound.png'+$imageHash);

$appealButton: url($imagesPrefix+'/game/result-page/appeal-button.png'+$imageHash);
$appealButtonOver: url($imagesPrefix+'/game/result-page/appeal-button-over.png'+$imageHash);

$disconnected: url($imagesPrefix+'/game/semaphore/disconnected.png'+$imageHash);
$tokensBanner: url($imagesPrefix+'/dialogs/dialog-info/tokens-banner.png'+$imageHash);
$tokenBannerSingle: url($imagesPrefix+'/dialogs/dialog-info/token-banner-single.png'+$imageHash);
$owlTokensIcon: url($imagesPrefix+'/dialogs/dialog-info/owl-tokens-icon.png'+$imageHash);
