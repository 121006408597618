.daily-rewards-cards {
  height: 21.3rem;
  width: 63rem;
  background:$cardsHolder center no-repeat;background-size: contain; 
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1.6rem 2.6rem;
  margin: 0 auto;
}
