.collaboration {
  
  font-family: 'Janda Manatee Solid';

  background: $b2bCollaboration no-repeat center center;
  height: 70rem;
  width: 100%;
  position: relative;
  z-index: 1000;
  background-size: contain;
  top: 0;
  left: -1rem;

  &__hide-picture {
    height: 4rem;
    width: 5rem;
    background: white;
    position: absolute;
    z-index: 1000000;
    bottom: 0;
    top: 36rem;
  }

  &__chat-header{
    display: flex;
    position: absolute;
    z-index: 1000;
    height: 4rem;
    width: 26rem;    
    margin-left: 1rem;
    background-color: #23467E;
  }

  &__chat-header-btn{
    width: 13rem;
    background-color: #A2C9F0; 
    margin: auto;
    text-align: center;
    vertical-align: middle;
    font-size: 1.4rem;
    height: 100%;
    color: white;
    padding-top: 1.5rem;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    &--active{
      font-weight: bold;
      background-color:white;
      color:#23467E;
      text-decoration: underline;
      &:hover {
        cursor: default;
      }
    };
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }

  &__chat{
      position:absolute;
      border: 0; 
      width: 26rem;
      margin-left: 1rem;
      div.arena-chat{
        height:44.5rem;
      }
      div.live-chat-room{
        background:#A2C9F0;
        height: 38rem;
      }
      div.chat-room--chat--header{
        display: none;
        background-color: #23467E !important;
      }
      div.react-input-emoji--container {
        font-size: 1rem !important;
      }
      div.chat-room--private--group-channels--menu--container{
        display: none;
      }
      div.chat-room--tabs--container{
        display: none;
      }
      
      // if we want to disable message sending to someone 
      // div.live-chat-room-input{
      //   display: none;
      // }
   }

   &__tp-cookies{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color:white;
    margin-top: 6rem;
    margin-left: 4rem;
    a:link {
      color: #A2C9F0;
    }
   }
}
