.game-starting-timer {
  height: 22rem;
  width: 20.7rem;
  opacity: 0;
  margin: 0 auto;
  margin-top: 18rem;
  margin-left: 12rem;
  position: absolute;
  background:$gameStartingTimer no-repeat center;
  background-size: contain; 
  &__seconds-to-start {
    color: $egyptian-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 7rem;
    letter-spacing: 0.05em;
    line-height: 1.843;
    text-align: center;
    padding-top: 7rem;
  }
  &__text {
    color: $sherpa-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 2.6rem;
    letter-spacing: 0.05em;
    line-height: 1.385;
    text-align: center;
  }
}
