.happy-birthday {
  @include dialog;
  &__inner {
    @include dialog-inner;
    height: 42.4rem;
    width: 32.3rem;
    background: $lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain;
  }
  &__description {
    @include description;
    height: 1.8rem;
    width: 32rem;
    font-size: 1.8rem;
    text-align: center;
    padding-right: 2rem;
    margin: 0 auto;
    color: white;
    font-family: 'Janda Manatee Solid';
    margin-top: 2.5rem;
  }
  &__icon {
    height: 9.5rem;
    width: 9.1rem;
    margin-left: 10.5rem;
    margin-top: 2rem;
  }
  &__buy-now {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 3.1rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }

  &__rewarded {
    height: 9.2rem;
    width: 26.7rem;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background-color: #1e436f;
    &--open-gift-box {
      height: 5.9rem;
      width: 7.1rem;
      margin: 1.5rem 1.1rem 1.8rem 1.1rem;
      position: absolute;
      background: $openedGiftbox center no-repeat;
      background-size: contain;
    }
    &--award-icon-count {
      float: left;
    }

    &--award-content {
      margin: 0 auto;
      margin-top: 1.5rem;
      position: absolute;
      margin-left: 12rem;
    }

    &--award-tooltip-icon {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 1rem;
      margin-left: 1.25rem;
      &--fly {
        margin-left: -2.8rem;
        position: absolute;
        opacity: 1;
      }
    }

    &--award-tooltip-count {
      height: 1.2rem;
      width: 1.7rem;
      color: $color-white;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      line-height: 2.9rem;
      margin-left: 1.5rem;
      text-align: center;
    }
  }
}
