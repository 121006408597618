.b2b-captain {
  &__background {
    position: absolute;
    height: 30rem;
    width: 50.4rem;
    margin-top: -1rem;
    margin-left: 6rem;
    border-style: solid;
    border-color: $result-holder-border;
    border-width: 0.2rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    background: $result-holder-background;
    z-index: 0;
    padding: 4rem 1rem 1rem 1rem;
    overflow: hidden;
  }
  &__description {
    font-size: 2.1rem;
    color: $sky-blue;
    text-align: center;
    font-family: 'Janda Manatee Solid';
  }
  &__captain-chosen {
    margin-top: -18rem;
    margin-left: 43rem;
    position: absolute;
    height: 10rem;
    width: 50rem;
  }
  &__sign-up {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 3rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
