.b2b-countdown {
  height: 8rem;
  width: 75%;
  margin-top: 3rem;
  align-items: center;
  display: inline-block;
  margin-left: 5rem;
  background-color: rgba($color: black, $alpha: 0.4);
  border-radius: 5rem;
  &__header {
    position: absolute;
    display: block;
    margin-top: -1.5rem;
    margin-left: 6rem;
    z-index: 2;
    color: $anakiwa;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 2.5rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
  }
  &__title {
    position: relative;
    display: block;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    color: $anakiwa;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 1.2rem;
    width: 16rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
  }
  &__time {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    color: $golden-yellow;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 2.8rem;
    width: 16rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
  }
}
