@mixin dialog {
  @include absCenter;
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: $dialogBlackBackground center no-repeat;
  background-size: contain;
}

@mixin dialog-inner {
  @include absCenter;
  box-sizing: border-box;
  height: 30rem;
  width: 43.2rem;
  border: 0.7rem solid $saphire-var3;
  border-radius: 1.8rem;
  background-color: $lochmara;  
  box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
  background: $lochmara, $dialogBlackBackground center no-repeat;
  background-size: contain;
}

@mixin description {
  height: 12.3rem;
  width: 33rem;
  color: $color-white;
  font-family: 'Janda Manatee Solid';
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-left: 5.1rem;
  margin-top: 3.3rem;
}
