.decline-game {
    @include dialog;
    &__inner {
      @include dialog-inner;
      width: 42.3rem;
      height: 26rem;
      background:$lochmara, $dialogBlackBackground center no-repeat;
      background-size: contain; 
    }
    &__description {
      @include description;
      height: 5.2rem;
      width: 40rem;
      line-height: 2.4rem;
      margin-left: 1rem;
      text-align: center;
      margin-right: 1rem;
      font-size: 1.8rem;
      letter-spacing: 0.07rem;
      margin-top: 4rem;
    }
    &__button-box {
        display: flex;
        width: 28rem;
        margin: 0 auto;
    }
    &__button {
      @include dialog-button-accept;
      margin: 0 auto;
      margin-top: 4rem;
      &:hover {
        @include dialog-button-accept-over;
      }
    }
  }
  