.reconnect {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 43.2rem;
    height: 22rem;
    background:$lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain; 
  }
  &__description {
    @include description;
    height: 6rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 3rem;
    letter-spacing: 0.07rem;
  }
  &__image {
    position: absolute;
    height: 12.8rem;
    width: 9.5rem;
    margin-top: 1rem;
    margin-left: 3.4rem;
    background:$owlError center no-repeat;
    background-size: contain; 
  }
  &__recconnect-progres-bar {
    display: block;
    position: absolute;
    margin-left: 14rem;
    margin-top: 3rem;
    height: 1.5rem;
    width: 20.1rem;
    background-color: #111d36;
  }

  &__recconnect-progress {
    height: 1.5rem;
    background-color: #8cc92e;
  }
}
