.emoji-icon {
  &::after {
    content: '';
    @include absCenter;
    display: block;
    height: 2.4rem;
    width: 2.4rem;
  }
  &--0 {
    &::after {
      background:$chatEmotionIcons 0 0 no-repeat;
      background-size: contain; 
    }
  }
  &--1 {
    &::after {
      background:$chatEmotionIcons -2.4rem 0 no-repeat;
      background-size: contain; 
    }
  }
  &--2 {
    &::after {
      background:$chatEmotionIcons -4.8rem 0 no-repeat;
      background-size: contain; 
    }
  }
  &--3 {
    &::after {
      background:$chatEmotionIcons -7.2rem 0 no-repeat;
      background-size: contain; 
    }
  }
  &--4 {
    &::after {
      background:$chatEmotionIcons -9.6rem 0 no-repeat;
      background-size: contain; 
    }
  }
  &--5 {
    &::after {
      background:$chatEmotionIcons -12rem 0 no-repeat;
      background-size: contain; 
    }
  }
  &--6 {
    &::after {
      background:$chatEmotionIcons 0 -3rem no-repeat;
      background-size: contain; 
    }
  }
  &--7 {
    &::after {
      background:$chatEmotionIcons -2.4rem -3rem no-repeat;
      background-size: contain; 
    }
  }
  &--8 {
    &::after {
      background:$chatEmotionIcons -4.8rem -3rem no-repeat;
      background-size: contain; 
    }
  }
  &--9 {
    &::after {
      background:$chatEmotionIcons -7.2rem -3rem no-repeat;
      background-size: contain; 
    }
  }
  &--10 {
    &::after {
      background:$chatEmotionIcons -9.6rem -3rem no-repeat;
      background-size: contain; 
    }
  }
  &--11 {
    &::after {
      background:$chatEmotionIcons -12rem -3rem no-repeat;
      background-size: contain; 
    }
  }
}
