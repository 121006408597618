.b2b-game {
  position: relative;
  margin: 0rem;
  height: 13.5rem;
  width: 16rem;
  margin-top: 1rem;
  margin-left: 1rem;
  display: flex;

  &__time {
    position: absolute;
    top: 10.5rem;
    z-index: 2;
    width: 16rem;
    text-align: center;
  }
  &__time-box {
    position: relative;
    color: $light-sky-blue;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 1.6rem;
    width: 10rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
    background-color: rgba($color: $saphire-var3, $alpha: 0.8);
    border-radius: 1.5rem;
    text-align: center;
    left: 18%;
    margin-top: -0.5rem;
    padding: 0.2rem;
  }
  &__time-clock {
    position: absolute;
    height: 2rem;
    width: 2rem;
    margin-top: -0.3rem;
    margin-left: 3.5rem;
    z-index: 1;
    background: $clock no-repeat center;
    background-size: contain;
  }
}
