.semaphore-red-player {
  flex: 0 0 calc(50% - 0.9rem);
  position: relative;
  margin-left: 0.9em;
  height: 10rem;
  margin-top: 2.6rem;
  background: $semaphoreRedBackground bottom left no-repeat;
  background-size: contain;
  &__player {
    position: absolute;
    height: 6.35rem;
    width: 6.35rem;
    top: 0.75rem;
    border-radius: 0.5rem;
    z-index: -1;
    &--box {
      position: absolute;
      height: 6.35rem;
      width: 6.35rem;
      right: 5.7rem;
      z-index: 1;
      &:hover {
        position: fixed;
      }
    }
  }
  &__hat {
    position: absolute;
    right: -4.8rem;
    top: -4.2rem;
    height: 15.75rem;
    width: 15.535rem;
    transform: rotateY(180deg);
    pointer-events: none;
    z-index: 3;
  }
  &__chat {
    position: absolute;
    right: 12.9rem;
    top: 2.7rem;
    height: 3.5rem;
    width: 3.4rem;
    transform: rotateY(180deg);
    background: $semaphoreChet center no-repeat;
    background-size: contain;
  }
  &__arrows {
    position: absolute;
    bottom: 0.5rem;
    right: 7.8rem;
    display: inline-block;
    list-style: none;
    li {
      display: inline-block;
      padding: 0 1rem;
      a {
        display: inline-block;
        text-decoration: none;
        height: 1.1rem;
        width: 1.2rem;
      }
    }
    li:nth-child(1) {
      a {
        background: $semaphoreArrowRight center no-repeat;
        background-size: contain;
        &:hover {
          background: $semaphoreArrowRightOver center no-repeat;
          background-size: contain;
        }
      }
    }
    li:nth-child(2) {
      a {
        background: $semaphoreArrowLeft center no-repeat;
        background-size: contain;
        &:hover {
          background: $semaphoreArrowLeftOver center no-repeat;
          background-size: contain;
        }
      }
    }
  }

  &__score {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 14rem;
    color: $light-cyan;
    font-family: 'Janda Manatee Solid';
    font-size: 3.6rem;
    letter-spacing: 0.06em;
    text-align: center;
  }

  &__name {
    position: absolute;
    bottom: 2.3rem;
    left: -1rem;
    width: 14rem;
    text-align: center;
    color: $light-cyan;
    font-family: 'Janda Manatee Solid';
    font-size: 1rem;
    letter-spacing: 0.06em;
    text-align: right;
  }

  &__short-name {
    position: absolute;
    bottom: 0.4rem;
    left: 1.5rem;
    width: 19rem;
    text-align: center;
    color: $light-cyan;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.06em;
    text-align: center;
  }

  &__title {
    position: absolute;
    height: 2.6rem;
    width: 2.7rem;
    bottom: 0.6rem;
    left: 1rem;
  }

  &__chat-text {
    position: absolute;
    padding: 1.6rem 1.2rem;
    height: 7.2rem;
    width: 8.9rem;
    right: -10rem;
    bottom: -4rem;
    color: $saphire;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.05em;
    line-height: 1.86;
    text-align: center;
    background: $chatTextBackground center no-repeat;
    background-size: contain;
  }

  &__emoji {
    position: absolute;
    padding: 1.6rem 1.2rem;
    height: 7.2rem;
    width: 8.9rem;
    right: -10rem;
    top: 2rem;
    background: $chatEmojiBackground center no-repeat;
    background-size: contain;
  }

  &__tooltip {
    @include tooltip;
    min-width: 25.7rem;
    height: 33.5rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
    text-align: center;
    pointer-events: none;
  }

  &__player--box &__tooltip {
    visibility: hidden;
    opacity: 0;
  }

  &__player--box:hover &__tooltip {
    visibility: visible;
    opacity: 0.95;
  }

  &__offline {
    position: absolute;
    right: 4rem;
    top: 5rem;
    height: 3rem;
    width: 3rem;
    background: $disconnected center no-repeat;
    background-size: contain;
  }
}
