.friend {
  position: relative;
  margin: 0rem;
  height: 12.5rem;
  width: 16rem;
  margin-top: 1rem;
  display: flex;
  &--dark {
    background: $friendCardDarkBackground no-repeat center;
    background-size: contain;
  }
  &--light {
    background: $friendCardLightBackground no-repeat center;
    background-size: contain;
  }
  &--invite {
    cursor: pointer;
    background: $friendInviteBackground no-repeat center;
    background-size: contain;
    &:hover {
      @include hoverAfter {
        background: $friendInviteBackgroundOver no-repeat center;
        background-size: contain;
      }
    }
  }
  &__invite--box {
    width: 9.6rem;
  }

  &__header {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 2;
    transform: translate(-50%, -34%);
    color: $light-sky-blue;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 2.6rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
  }

  &__status--offline {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: $friendSatusOffline no-repeat center;
    background-size: contain;
  }

  &__status--busy {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: $friendSatusBusy no-repeat center;
    background-size: contain;
  }

  &__status--online {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: $friendSatusOnline no-repeat center;
    background-size: contain;
  }

  &__status--me {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
  }

  &__name {
    position: absolute;
    left: 50%;
    top: 1.6rem;
    transform: translate(-50%);
    color: $light-sky-blue;
    font-size: 1.2rem;
    font-family: 'Janda Manatee Solid';
  }
  &__image {
    position: absolute;
    height: 4.3rem;
    width: 4.3rem;
    margin-top: 3.9rem;
    margin-left: 1.8rem;
    border-radius: 17%;
    overflow: hidden;
    &--box {
      z-index: 1000;
      &:hover {
        position: fixed;
      }
    }
  }
  &__title {
    position: absolute;
    bottom: 1.3rem;
    left: 1.7rem;
    height: 3.1rem;
    width: 3.1rem;
  }
  &__points {
    text-align: center;
    font-family: 'Janda Manatee Solid';
    width: 3rem;
    color: $light-sky-blue;
    position: absolute;
    bottom: 1rem;
    right: 1.4rem;
  }

  &__tooltip {
    @include tooltip;
    min-width: 25.7rem;
    height: 33.5rem;
    border-radius: 0.5rem;
    margin-top: -34rem;
    text-align: center;
    pointer-events: none;
  }

  &__tooltip-name {
    color: $kournikova-var;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    letter-spacing: 0.07em;
    padding: 1rem;
    text-align: center;
    min-width: 13rem;
  }

  &__content {
    display: flex;
    margin-left: 4rem;
  }

  &__content-left {
    flex: 0 0 9rem;
    height: 4.7rem;
    background-size: 4.5rem 4.7rem;
  }

  &__content-right {
    flex: 1 1 100%;
  }

  &__egg-owl,
  &__rating {
    padding: 0.8rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.167;
    height: 2rem;
    margin-top: 0.3rem;
  }

  &__rating {
    background: $progressBarStar no-repeat top right;
    background-size: contain;
    display: inline-block;
    padding-right: 3rem;
  }

  &__collected-feathers {
    padding: 1rem 2rem;
    color: $color-white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.567;
    text-align: center;
    background: $tournamentFeather no-repeat center left;
    background-size: 1.5rem;
    display: inline-block;
    height: 2rem;
    margin: 0 auto;
    padding-bottom: 3rem;
  }

  &__image--box &__tooltip {
    visibility: hidden;
    opacity: 0;
  }

  &__image--box:hover &__tooltip {
    visibility: visible;
    opacity: 0.95;
  }

  &__buttons {
    position: absolute;
    display: flex;
    bottom: 1.5rem;
    margin: 0 1.1rem;
    width: 7.2rem;
    height: 3.1rem;
  }
  &__button {
    display: inline-block;
    height: 3.1rem;
    cursor: pointer;
    &--accept {
      width: 3.5rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: $friendButtonAccept no-repeat center;
      background-size: contain;
      &:hover {
        @include hoverAfter {
          width: 3.5rem;
          background: $friendButtonAcceptOver no-repeat center;
          background-size: contain;
        }
      }
    }

    &--decline {
      width: 3.5rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: $friendButtonsDecline no-repeat center;
      background-size: contain;
      &:hover {
        @include hoverAfter {
          left: initial;
          width: 3.5rem;
          background: $friendButtonsDeclineOver no-repeat center;
          background-size: contain;
        }
      }
    }

    &--call {
      width: 7.2rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: $friendButtonCall no-repeat center;
      background-size: contain;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      color: $prussian-blue;
      font-size: 1.2rem;
      padding-top: 0.8rem;
      &:hover {
        background: $friendButtonCallOver no-repeat center;
        background-size: contain;
      }
    }

    &--cancel {
      width: 7.2rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: $friendButtonCancel no-repeat center;
      background-size: contain;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      color: $white;
      font-size: 1.2rem;
      padding-top: 0.8rem;
      &:hover {
        background: $friendButtonCancelOver no-repeat center;
        background-size: contain;
      }
    }
  }
}
