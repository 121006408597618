@mixin button {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: $saphire-var4 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  //box-shadow: 0 0 0 0.3rem $saphire-var4;
  //margin: 0.375rem;
  &:disabled {
    cursor: default;
  }
}

@mixin game-button-over {
  color: $saphire-var4;
  background: $light-cyan-var2;
}

@mixin rounded_button {
  box-sizing: border-box;
  width: 20.55rem;
  height: 4.5rem;
  border: 0.375rem solid $saphire-var4;
  border-radius: 2.25rem;
}

@mixin dialog-button-disabled {
  @include button;
  color: $saphire-var4;
  background-color: $seagull-var2;
}

@mixin dialog-button-accept {
  box-sizing: border-box;
  // height: 5.625rem;
  // width: 16.875rem;
  height: 4.625rem;
  width: 12.875rem;
  color: $prussian-blue;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  background-color: $school-bus-yellow;
  background: $dialogButtonAccept center no-repeat;
  background-size: 100% 100%;
}

@mixin dialog-button-accept-over {
  @include dialog-button-accept;
  background-color: $school-bus-yellow;
  background: $dialogButtonAcceptOver center no-repeat;
  background-size: 100% 100%;
}

@mixin back-lobby-button {
  box-sizing: border-box;
  height: 5.625rem;
  width: 27.75rem;
  background-color: $forest-green;
  background: $backLobbyButton center no-repeat;
  background-size: contain;
}

@mixin back-lobby-button-over {
  @include back-lobby-button;
  background-color: $mantis;
  background: $backLobbyButtonOver center no-repeat;
  background-size: contain;
}

@mixin button3d {
  @include button;
  color: $saphire-var4;
  background: $light-cyan-var2;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  border-color: $sky-light-blue $sky-light-blue $sky-normal-blue $sky-normal-blue;
  background-clip: padding-box;
  padding-top: 0rem;
  box-shadow: 0 0 0 0.25rem $button-border !important;
  &:disabled {
    cursor: default;
    border: 0rem;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    background-color: $seagull-var2;
    border-color: $seagull-var2;
    background-clip: padding-box;
    padding-top: 0rem;
    box-shadow: 0 0 0 0.25rem $button-border !important;
    color: $saphire-var4;
    &:hover {
      border: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      background-color: $seagull-var2;
      border-color: $seagull-var2;
      background-clip: padding-box;
      padding-top: 0rem;
      box-shadow: 0 0 0 0.25rem $button-border !important;
      color: $saphire-var4;
    }
  }
  &:hover {
    color: $saphire-var4;
    background: $light-cyan-var3;
    border: 0rem;
    padding-top: 0rem;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: $light-cyan-var3;
    background-clip: padding-box;
  }
}
