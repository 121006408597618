.sequence {
  position: absolute;
  padding: 8rem 8rem;
  margin: 0rem 0rem;

  &__progress {
    height: 2.3rem;
    width: 47.1rem;
    margin: auto;
    background: $saphire-var;
    position: relative;
  }
  &__game-number {
    color: $valencia;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.6;
    background: $saphire-var;
    border: 0.5rem solid $saphire-var;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    &--1 {
      left: 0;
      transform: translate(-50%, -50%);
    }
    &--2 {
      left: 33%;
      transform: translate(-50%, -50%);
    }
    &--3 {
      left: 67%;
      transform: translate(-50%, -50%);
    }
    &--4 {
      left: 100%;
      transform: translate(-50%, -50%);
    }
    &--finished {
      background: $lawn-green;
      color: $cyprus;
    }
  }

  &__game-progress {
    height: 1.4rem;
    background: $lawn-green;
    width: 0;
    top: 0.45rem;
    position: absolute;
    z-index: 5;
    &--finished {
      width: 30%;
    }
    &--1 {
      left: 1%;
    }
    &--2 {
      left: 35%;
    }
    &--3 {
      left: 68%;
    }
  }
  &__game-name {
    color: $cyprus;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.8;
    position: absolute;
    z-index: 6;
    top: -0.5rem;
    text-align: center;
    padding-top: 1.5rem;
    width: 15.3rem;
    height: 11.1rem;
    z-index: 100;
    &--1 {
      left: -0.3rem;
      transform: translate(-50%, -50%);
    }
    &--2 {
      left: 32%;
      transform: translate(-50%, -50%);
    }
    &--3 {
      left: 66%;
      transform: translate(-50%, -50%);
    }
    &--4 {
      left: calc(100% - 0.3rem);
      transform: translate(-50%, -50%);
    }
  }

  &__active {
    background:$sequencePointer no-repeat center;
    background-size: contain; 
    height: 11.1rem;
    width: 15.3rem;
    margin-top: -6.04rem;
    position: absolute;
    z-index: 10;
    &--1 {
      margin-left: -7.8rem;
    }
    &--2 {
      margin-left: 7.6rem;
    }
    &--3 {
      margin-left: 23.7rem;
    }
  }

  &__description {
    height: 15.4rem;
    width: 60.6rem;
    border: 0.3rem solid $seagull;
    background-color: $steel-blue;
    position: absolute;
    z-index: 1;
    top: 4.7rem;
    left: -7rem;
    border-radius: 1rem;
    //background: $descriptionPanel no-repeat center;
  }
  &__description-title {
    color: $light-cyan-var;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1;
    text-transform: uppercase;
    padding: 1.8rem 0 0 0;
  }
  &__description-text {
    color: $light-cyan-var;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.075em;
    line-height: 1.3;
    padding: 1.8rem 1rem;
    text-align: center;
    width: 0rem;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    white-space: nowrap;
    display: none;
  }
  &__matching-description {
    display: flex;
    flex-wrap: wrap;
  }
  &__matching-description-half {
    width: 50%;
    padding: 0 1.6rem;
    text-align: center;
  }
  &__matching-description-title {
    color: $light-cyan-var;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.6;
    text-align: center;
    display: block;
    width: 100%;
    &--desciprtion {
      position: absolute;
      top: 78%;
      height: 3rem;
      opacity: 0;
    }
  }
  &__matching-description-found {
    opacity: 0;
    margin-top: 8rem;
  }
  &__matching-description-button {
    color: $light-cyan-var;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.8;
    text-align: center;
    width: 26rem;
    background-color: $saphire-var;
    border-radius: 1rem;
    border: 0.3rem solid $seagull-var;
    height: 4.08rem;
    overflow: hidden;
  }
}
