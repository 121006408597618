.faq {
    @include dialog;
    padding: 10rem;
    &__inner {
      @include dialog-inner;
      width: 55.2rem;
      height: 34.7rem;
      background:$lochmara, $dialogBlackBackground center no-repeat;
      background-size: contain; 
    }
    &__description-box {
      margin-top: 2rem;
    }
    &__question {
      font-family: 'Janda Manatee Solid';
      width: 47rem;
      margin: 0 auto; 
      margin-top: 0.5rem;
      text-align: left;
      font-size: 1.4rem;
      letter-spacing: 0.05rem;
      color: $saphire-var2;
      cursor: pointer;
      &--answer {
        position: relative;
        font-size: 1.2rem;
        color : $white;
        cursor: auto;     
        -moz-user-select: text;
        -webkit-user-select: text;
      }
    }
  }
  