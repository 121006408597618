.search-opponent-card {
  position: relative;
  height: 20rem;
  width: 14.4rem;
  margin-top: 2.2rem;
  &__inner {
    position: absolute;
    height: 20rem;
    width: 14.4rem;
    overflow: hidden;
    background: $matchingDialogCard center no-repeat;
    background-size: 14.4rem 20rem;
    box-shadow: 0 0 1rem #3869a3;
  }
  &__holder {
    overflow: hidden;
    height: 14rem;
    width: 11.875rem;
  }
  &__player-image {
    margin: 0.8rem 0.8rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    height: 54rem;
    z-index: -10;
    margin-top: -57rem;
    &--right {
      position: relative;
      background-size: contain;
    }
    &--left {
      margin-top: -16rem;
    }
  }
  &__my-image {
    width: 11.875rem;
    margin-left: 0.85rem;
    margin-top: 1.1rem;
    border-radius: 0.5rem;
    z-index: -9;
  }
  &__current-image-holder {
    width: 11.875rem;
    margin-left: 1.3rem;
    margin-bottom: 0.5rem;
    top: -65%;
    position: absolute;
    z-index: -10;
    &--image {      
      width: 11.875rem;
    }
  }
  &__friendly-image {
    width: 11.875rem;
    margin-left: 0.78rem;
    margin-top: 0.7rem;
    margin-bottom: 0.5rem;
    top: 77%;
    position: absolute;
    z-index: -10;
  }
  &__name {
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    line-height: 1.8125;
    text-align: center;
    color: $alice-blue;
    margin-top: 13.2rem;
    &--left {
      margin-top: 13.2rem;
    }
    &--right {
      position: relative;
      margin-top: 13.2rem;
    }
  }

  &__footer {
    position: absolute;
    bottom: 1.6rem;
    left: 0;
    right: 0;
    height: 2.8rem;
    display: flex;
    padding: 0 1.2rem;
    width: 100%;
    &--left {
      flex-direction: row;
    }
    &--right {
      flex-direction: row-reverse;
    }
  }

  &__title {
    flex: 0 0 2.8rem;
    height: 2.8rem;
    padding-bottom: 2rem;
    object-fit: contain;
  }

  &__rating {
    padding: 0.6rem 0.5rem;
    flex: 0 0 5rem;
    color: $alice-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.01em;
    line-height: 1.6111;
    &--left {
      text-align: right;
    }
    &--right {
      text-align: left;
    }
  }

  &__star-image {
    flex: 0 0 2.7rem;
    height: 2.6rem;
    margin-top: 0.3rem;
    background: $starIcon center no-repeat;
    background-size: contain;
  }

  &__scrolling_image {
    width: 13.2rem;
    object-fit: contain;
  }  

  &__response_image {
    position: absolute;
    width: 3.1rem;
    height: 3.9rem;
    z-index: 3;
    &--left {
      margin-left: 9.8rem;
      margin-top: 1.6rem;
    }
    &--right {
      margin-left: 1.6rem;
      margin-top: 1.6rem;
    }
  }

  &__tooltip {
    @include tooltip;
    min-width: 25.7rem;
    height: 33.5rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
    text-align: center;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  &:hover &__tooltip {
    visibility: visible;
    opacity: 0.95;
  }
}
