.achievements {
  @include absCenter;
  z-index: 499;
  height: 70rem;
  width: 76rem;
  background: $dialogBlackBackground center no-repeat;
  background-size: contain;

  &__container {
    @include absCenter;
    z-index: 499;
    height: 68.2rem;
    width: 74.8rem;
    background: $achievementsDialog no-repeat center center;
    background-size: contain;
    &__close {
      height: 3.9rem;
      width: 3.9rem;
      position: absolute;
      right: 0.3rem;
      top: 5.6rem;
      background: $dialogCloseButton center no-repeat;
      background-size: contain;
      &:hover {
        @include hoverAfter {
          background: $dialogCloseButtonOver center no-repeat;
          background-size: contain;
        }
      }
    }
  }

  &__season {
    height: 2.6rem;
    position: absolute;
    top: 8.7rem;
    left: 7.6rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    text-align: center;
    &--date {
      width: 22rem;
      left: 22.8rem;
      top: 8.6rem;
      color: #875304;
    }
    &--day {
      width: 23rem;
      left: 47rem;
      top: 8.6rem;
      color: #875304;
    }
  }

  &__title {
    height: 16.4rem;
    width: 12.2rem;
    position: absolute;
    top: 18rem;
    left: 6rem;
  }
  &__title-image {
    width: 100%;
    max-height: 11.9rem;
  }
  &__title-header {
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.3rem;
    margin-top: 1.4rem;
    letter-spacing: 0.035em;
    line-height: 1.384615384615385;
    text-align: center;
    width: 100%;
  }
  &__subtitle-header {
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.035em;
    line-height: 1.384615384615385;
    text-align: center;
    width: 100%;
  }

  &__place-count {
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.035em;
    line-height: 1;
    position: absolute;
    &--first {
      left: 11rem;
      bottom: 11rem;
    }
    &--second {
      left: 6rem;
      bottom: 8.7rem;
    }
    &--third {
      left: 16rem;
      bottom: 7.6rem;
    }
  }
  &__arrow {
    position: absolute;
    right: 1.2rem;
    height: 6.4rem;
    width: 4.4rem;
    &--up {
      top: 25.2rem;
      background: $achievementArowUp no-repeat center center;
      background-size: contain;
      &:hover {
        @include hoverAfter {
          background: $achievementArowUpOver center no-repeat;
          background-size: contain;
        }
      }
      &--disabled {
        top: 25.2rem;
        background: $achievementArowUpDisabled no-repeat center center;
        background-size: contain;
        &:hover {
          @include hoverAfter {
            background: unset;
          }
        }
      }
    }
    &--down {
      top: 32.8rem;
      background: $achievementArowDown no-repeat center center;
      background-size: contain;
      &:hover {
        @include hoverAfter {
          background: $achievementArowDownOver center no-repeat;
          background-size: contain;
        }
      }
      &--disabled {
        top: 32.8rem;
        background: $achievementArowDownDisabled no-repeat center center;
        background-size: contain;
        &:hover {
          @include hoverAfter {
            background: unset;
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 13.2rem;
    left: 22.3rem;
    height: 48rem;
    width: 48rem;
    overflow: hidden;
  }

  &__box {
    background: $achievementCardBackground no-repeat center center;
    background-size: contain;
    width: 9.6rem;
    height: 9.6rem;
    transition: all 1s;
    position: relative;
  }

  &__opacity:hover {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), $achievementCardBackground repeat 0 0;
  }

  &__box:hover &__tooltip,
  &__opacity:hover &__tooltip {
    visibility: visible;
    opacity: 1;
  }

  &__item--locked:hover &__box {
    opacity: 0.4;
  }

  &__item {
    position: relative;
    width: 9.6rem;
    height: 9.6rem;
    &--coming-soon {
      background: $achievementComingSoon no-repeat center center;
      background-size: contain;
    }
    &--locked {
      height: 7.7rem;
      width: 6.3rem;
      margin: auto;
      position: relative;
      margin-top: 0.95rem;
      background: $achievementLocked no-repeat center center;
      background-size: contain;
    }
    &--progress {
      height: 0.5rem;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $achievementProgress;
      background-size: contain;
    }
  }
  &__tooltip {
    @include tooltip;
    visibility: visible;
    height: 30rem;
    width: 18rem;
    top: 75%;
    left: 75%;
    pointer-events: none;
    &__title-icon {
      height: 4.8rem;
      display: table;
    }
    &__icon {
      float: left;
      height: 2.8rem;
      width: 2.6rem;
      margin: 1rem;
      vertical-align: middle;
      display: table-cell;
    }
    &__value {      
      height: 2.5rem;
      width: 15.3rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.5rem;
      text-align: center;
      margin-left: 1.3rem;
      margin-top: 9rem;
      position: absolute;
    }
    &__title {
      height: 1.3rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      vertical-align: middle;
      display: table-cell;
      line-height: 1.8rem;
    }
    &__separator-line {
      height: 0.2rem;
      width: 16rem;
      margin-bottom: 0.5rem;
      background-color: #985914;
      border-radius: 0.1rem;
      &--bottom {
        margin-top: 3rem;
      }
    }
    &__goal-description {
      height: 10rem;
    }
    &__goal {
      height: 1.2rem;
      width: 4rem;
      color: #f7c61e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 2.9rem;
      margin-left: 6.9rem;
      margin-bottom: 1rem;
    }
    &__description {
      height: 3.1rem;
      width: 15.3rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
      margin-left: 1.3rem;
      margin-top: 0.8rem;
      position: absolute;
    }
    &__rewards {
      height: 10.5rem;
    }
    &__reward {
      height: 1.3rem;
      width: 6.9rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.5rem;
      margin-left: 5.6rem;
      margin-top: 1rem;
    }

    &__reward-icon-count {
      float: left;
    }
    &__reward-content {
      display: table;
      margin: 0 auto;
      margin-top: 1.5rem;
    }
    &__reward-icon {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 1rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    &__reward-count {
      height: 1.2rem;
      width: 1.7rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
