.today-booster {
  margin: 2.5rem auto;
  font-family: 'Janda Manatee Solid';
  color: $white;
  font-size: 2.9rem;
  text-align: center;
  height: 8.5rem;
  width: 21rem;
  display: flex;
  &__container {
    display: flex;
    height: 4rem;
  }
  &__button-plus {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 2.5rem;
    margin-left: 13rem;
    background: $boosterAdd no-repeat center;
    background-size: contain;
    position: absolute;
    text-decoration: none;
    color: $white;
    z-index: 1;
    &:hover {
      background: $boosterAddOver no-repeat center;
      background-size: contain;
    }
  }
  &__booster-image {
    width: 6rem;
    height: 6rem;
    background: $boosterFlameIcon no-repeat center;
    background-size: contain;
    z-index: 1;
  }
  &__booster-container {
    width: 11.8rem;
    height: 3.1rem;
    border-radius: 2rem;
    margin-left: -3rem;
    margin-top: 1.4rem;
    border: 0.3rem solid $saphire-var;
    background-color: $bahama-blue;
    overflow: hidden;
    &--progress {
      height: 3.1rem;
      background-image: linear-gradient(to bottom, $turbo, $super-gold, $orange);
    }
  }
  &__text {
    height: 1.7rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    margin-top: -3rem;
    margin-left: -3.5rem;
    text-shadow: -1px 2px 0 $booster-text-shadow, -1px 1px 0 $booster-text-shadow, -1px 0px 0 $booster-text-shadow, 0px -1px 0 $booster-text-shadow, 1px 0px 0 $booster-text-shadow;
  }
  &__tooltip--text {
    @include tooltip;
    position: relative;
    width: max-content;
    margin-left: 3rem;
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  &__tooltip {
    @include tooltip;
    width: 31.5rem;
    height: 18.9rem;
    margin-left: 30rem;
    margin-top: 27rem;
    z-index: 2000;
    &--title {
      margin: 0 auto;
      color: $kournikova-var;
      font-family: 'Janda Manatee Solid';
      font-size: 1.7rem;
      margin-top: 0.5rem;
      &--white {
        font-size: 1.1rem;
        color: $white;
      }
      &--orange {
        font-size: 1.1rem;
        color: $kournikova-var;
        margin-bottom: 1.5rem;
      }
      &--green {
        font-size: 1.1rem;
        color: $bahia;
      }
      &--red {
        font-size: 1.1rem;
        color: $cinnabar;
      }
    }
    &--container {
      display: flex;
      width: 10rem;
      margin: 0 auto;
    }
    &--divider {
      height: 0.2rem;
      width: 29.3rem;
      background-color: $white;
    }
    &--image {
      position: absolute;
      width: 6rem;
      height: 6rem;
      margin-top: 10rem;
      margin-left: 3rem;
      background: $boosterIcon center no-repeat;
      background-size: contain;
      border: 0rem;
    }
    &--plus-image {
      position: absolute;
      width: 2rem;
      height: 2rem;
      margin-top: 14.4rem;
      margin-left: 11.4rem;
      background: $boosterAdd center no-repeat;
      background-size: contain;
      border: 0rem;
    }
  }

  &__button-plus:hover &__tooltip--text,
  &__container:hover &__tooltip {
    visibility: visible;
    opacity: 0.95;
  }
}