.play-now {
  height: 9.2rem;
  width: 26.7rem;
  background: $playNow no-repeat center;
  background-size: contain;
  position: relative;
  cursor: pointer;
  &__button {
    width: 26.7rem;
    height: 9.2rem;
    &:hover {
      @include hoverAfter {
        background: $playNowOver no-repeat center;
        background-size: contain;
      }
    }
  }
  &__text {
    background: $playNowText no-repeat center;
    background-size: contain; 
    position: absolute; 
    width: 19.7rem;
    height: 6.2rem;
    top: 1.5rem;
    left: 3.4rem;
    z-index: 2;
    object-fit: contain;
  }
  &__number-of-players {
    position: absolute;
    text-decoration: none;
    color: $saphire-var4;
    bottom: 1rem;
    left: 15rem;
    font-family: 'Janda Manatee Solid';
    z-index: 3;
    font-size: 1.8rem;
    display: inline-block;
    margin-left: 1.5rem;
  }
}
