.status {


    &__section-title {
        margin-bottom: 0;
        padding: 0.5rem 0;
        color: $background-1;
        background-color: $color-text-gold;
        text-align: center;
        display: flex;
        margin: 0rem auto;
    }  
    
    &__button {
        @include dialog-button-accept;
        display: block;
        margin: 0rem auto;
        &:hover {
          @include dialog-button-accept-over;
          width:11.5rem;
          height: 85%;
        }
        width:11rem;
        height: 80%;
      }
}