.shop-tokens {
  @include absCenter;
  z-index: 499;
  height: 70rem;
  width: 76rem;
  background-size: contain;
  background: $dialogBlackBackground center no-repeat;

  &__tab-content-background {
    position: relative;
    z-index: 600;
    background: $tabContentBackground center no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 35.9rem;
    width: 60rem;
    background-size: 98% 100%;
    margin: auto;
    margin-top: 6.9rem;
    transition: margin 700ms;
    &--notab {
      margin-top: 4.2rem;
    }
  }

  &__tab-background {
    position: relative;
    z-index: 700;
    padding-top: 1.5rem;
    background: $dialogsShopTokensTabBackground center no-repeat;
    background-size: 15rem 6rem;
    height: 6rem;
    width: 15rem;
    float: left;
    margin-left: 7rem;
    margin-right: -4rem;
    margin-top: 2.1rem;
    cursor: pointer;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    text-align: center;
    vertical-align: middle;
    &--double {
      margin-left: 14rem;
      margin-right: -7rem;
      &--selected {
        background: $dialogsShopTokensTabMiddle center no-repeat;
        background-size: 15rem 6rem;
      }
    }
    &--selected {
      background: $dialogsShopTokensTabMiddle center no-repeat;
      background-size: 15rem 6rem;
    }
  }

  &__inner {
    @include absCenter;
    box-sizing: border-box;
    height: 52rem;
    width: 66rem;
    border: 0.7rem solid $saphire-var3;
    border-radius: 1.8rem;
    background-color: $lochmara;
    box-shadow: 0 1.5rem 1rem 1.5rem $saphire-var6;
    background: $dialogBackground center no-repeat;
  }

  &__description {
    height: 2.3rem;
    width: 36.1rem;
    color: $canary;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    margin: 4rem auto;
  }
  &__sms-token {
    position: absolute;
    height: 5.1rem;
    width: 38.6rem;
    margin: 11rem;
    margin-top: 5rem;
    border-radius: 1rem;
    border: 0.3rem solid $saphire-var4;
    background-color: $deep-sky-dark-blue;
    &__text {
      height: 5.1rem;
      color: $saphire-var7;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      margin-top: 1rem;
      letter-spacing: 0.05rem;
      text-align: center;
      &--send {
        position: absolute;
        height: 5.5rem;
        width: 40rem;
        color: $white;
        margin-top: 14rem;
        margin-left: 10rem;
      }
      &--price {
        position: absolute;
        height: 2.5rem;
        width: 35rem;
        color: $saphire-var7;
        font-size: 1rem;
        margin-top: 23rem;
        margin-left: 13rem;
      }
      &--other-buy {
        position: absolute;
        height: 2.2rem;
        width: 22.8rem;
        color: $white;
        font-size: 1.8rem;
        margin-top: 30rem;
        margin-left: 19rem;
        cursor: pointer;
      }
    }
  }
  &__vaucer-text {
    position: absolute;
    height: 2.2rem;
    width: 43.3rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    text-align: center;
    margin-top: 5rem;
    margin-left: 8rem;
  }
  &__vaucer-input {
    height: 6.1rem;
    width: 30.1rem;
    font-size: 2.4rem;
    color: $white;
    border-radius: 1rem;
    border: 0.3rem solid $saphire-var4;
    background-color: $deep-sky-dark-blue;
    font-family: 'Janda Manatee Solid';
    margin-top: 10rem;
    margin-left: 14.8rem;
    text-align: center;
    text-transform: uppercase;
  }
  &__voucher-result {
    position: absolute;
    height: 2.2rem;
    width: 56rem;
    margin-left: 2rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    text-align: center;
    margin-top: 4.5rem;
  }
  &__close {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 5rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
  &__tab-description {
    padding-top: 5.5rem;
    height: 2rem;
    width: 60rem;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    color: white;
    letter-spacing: 0.08rem;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  &__tab-content {
    overflow: hidden;
    height: 22.6rem;
    width: 61.4rem;
    margin: auto;
  }
  &__tab-cards {
    margin-left: 0rem;
    height: 25rem;
    overflow: hidden;
    margin-top: 3.5rem;
    transition: margin 700ms;
    padding-top: 2.5rem;
    padding-left: 6.5rem;
    padding-right: 1.5rem;
  }
  &__footer-text {
    position: absolute;
    height: 1.7rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    margin-top: 1rem;
    margin-left: 3.5rem;
    &--info {
      cursor: pointer;
    }
    &--security {
      margin-left: 25rem;
      color: $danube;
    }
    &--question {
      cursor: pointer;
      margin-left: 53rem;
    }
  }
  &__security-lock {
    position: absolute;
    height: 2.2rem;
    width: 1.7rem;
    background: $dialogShopTokensLock center no-repeat;
    background-size: contain;
    margin-left: 40.5rem;
    margin-top: 0.7rem;
  }
}
