.friend-challenge {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 42.3rem;
    height: 30rem;
    background: $lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain;
  }
  &__image {
    position: absolute;
    height: 14rem;
    width: 14rem;
    margin-top: 2rem;
    margin-left: 13.2rem;
    border-radius: 1rem;
  }
  &__button-box {
    display: flex;
    width: 32rem;
    margin: 0 auto;
  }
  &__button {
    @include dialog-button-accept;
    margin: 0 auto;
    margin-top: 18rem;
    margin-left: 2rem;
    margin-right: 2rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
