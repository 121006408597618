.my-number {
  position: absolute;
  height: 42.7rem;
  padding: 2rem 4rem;
  margin: -1rem auto 0 auto;
  overflow: hidden;
  font-family: 'Janda Manatee Solid';
  &__progress {
    height: 18.1rem;
    width: 54.4rem;
    z-index: -1;
    margin: 0 auto;
    margin-top: -10rem;
    background: $questionProgressBackground no-repeat center;
    background-size: contain;
    &__final-number {
      position: absolute;
      height: 5.6rem;
      width: 11.3rem;
      border: 0.3rem solid $saphire-var4;
      background-color: #e9f8fd;
      margin-left: 22rem;
      margin-top: 10rem;
      border-radius: 1rem;
    }
    &__number {
      text-align: center;
      margin-top: 0.5rem;
      color: $saphire-var4;
      font-family: 'Janda Manatee Solid';
      font-size: 2.8rem;
      &--white {
        margin-top: -0.2rem;
        color: $white;
      }
    }
    &__number-box {
      position: absolute;
      height: 4.1rem;
      width: 10.3rem;
      margin-top: 16rem;
      border-radius: 1rem;
      border: 0.3rem solid $saphire-var4;
      &--blue {
        margin-left: 7rem;
        background-color: $deep-sky-blue;
      }
      &--red {
        margin-left: 38rem;
        background-color: $cinnabar;
      }
    }
  }
  &__choosen_numbers_container {
    position: relative;
    margin: 5rem 0 0 0;
    padding: 0.5rem 0 0 0.4rem;
    display: flex;
    height: 5rem;
    width: 54rem;
    background: $puzzleAnswerBackground no-repeat center;
    background-size: contain;
    &--opponent {
      opacity: 0;
      position: absolute;
      margin: 2.2rem 0 0 0;
    }
    &--selected {
      width: 100%;
      font-size: 2.5rem;
      padding-left: 1rem;
      padding-top: 0.3rem;
      letter-spacing: 0.05rem;
      color: $white;
    }
    &--opponent-solving {
      display: flex;
      float: left;
      position: absolute;
      height: 3rem;
      vertical-align: middle;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      color: $white;
      line-height: 2.769230769230769;
      margin-bottom: 0.4rem;
      margin-left: 0.4rem;
    }
  }
  &__point {
    opacity: 1;
    height: 3.1rem;
    width: 5.6rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
  }
  &__player_image {
    position: relative;
    margin: 0 0.7rem 0.5rem 0.3rem;
    border-radius: 0.4rem;
    height: 4rem;
    width: 4rem;
    background-size: contain;
  }
  &__erase-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    z-index: 1;
    height: 3rem;
    width: 3.2rem;
    background: $eraseButton no-repeat center;
    background-size: contain;
    &:disabled {
      cursor: default;
      background: $eraseButtonOver no-repeat center;
      background-size: contain;
      &:hover {
        background: $eraseButtonOver no-repeat center;
        background-size: contain;
      }
    }
  }
  &__submit_word_button {
    @include button3d;
    position: relative;
    height: 4.225rem;
    width: 12.875rem;
    padding-top: 0.1rem;
    margin-bottom: 5rem;
    top: 1.2rem;
    left: 50%;
    transform: translate(-50%, 0%);
    vertical-align: middle;
  }

  &__stop_button {
    @include button3d;
    margin-bottom: 5rem;
    top: 1.2rem;
    left: 50%;
    transform: translate(-50%, 0%);
    vertical-align: middle;
    height: 4.225rem;
    width: 14rem;
    padding-top: 0.1rem;
    position: absolute;
    margin-top: 20.2rem;
    color: red;
    &:disabled {
      cursor: default;
      color: red;
    }
    &:hover {
      color: red;
    }
    &-icon {
      width: 2.4rem;
      height: 3rem;
      margin-left: 0.6rem;
    }
  }

  &__numbers-button-container {
    display: flex;
    justify-content: center;
    margin: 1.6rem 0 3rem 0;
    &--opponent {
      margin: 1.6rem 0 0rem 0;
    }
  }
  &__number-button {
    padding-top: 0rem;
    height: 3.9rem;
    width: 3.9rem;
    font-size: 2.3rem;
    margin-left: 1.5rem;
    color: $saphire-var4;
    background: $light-cyan-var2;   
    &:hover {
      background: $light-cyan-var3;
    }
    &--middle {
      width: 8.4rem;
    }
    &--big {
      width: 12.5rem;
    }
    &--operation {
      background-color: $danube;
      border: solid 0.5rem $button-border;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      padding-top: 0rem;
      font-size: 2.1rem;
      border-color: $button-dark-right $button-dark-right $button-dark-left $button-dark-left;
      background-clip: padding-box;
      box-shadow: 0 0 0 0.25rem $button-border !important;
      &:disabled {
        cursor: default;
        color: $saphire-var4;
        background-color: $valencia;
        border-color: $valencia;
      }
      &:hover {
        color: $saphire-var4;
        background-color: $seagull-var2;
        border-color: $seagull-var2;
      }
    }
  }
  &__opponent_number_solving_progress {
    position: absolute;
    top: 22.7rem;
    left: 5rem;
    width: 50rem;
    height: 5rem;
    pointer-events: none;
  }

  &__opponent_number_solving_text {
    height: 3rem;
    vertical-align: middle;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    color: $white;
    line-height: 2.769230769230769;
    display: inline-block;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem;
  }
}
