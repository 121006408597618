@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin hoverAfter {
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @content;
  }
}
@mixin hoverBefore {
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @content;
  }
}


@mixin tooltip {
  position: absolute;
  z-index: 1000;
  width: 10rem;
  visibility: hidden;
  opacity: 0;
  top: 0;
  border-radius: 0.5rem;
  left: 0;
  background-color: $transparent-black;
  padding: 0.5rem;
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
  transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
  &:hover {
    visibility: visible;
    opacity: 0.95;
  }
  @content;
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}
