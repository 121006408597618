.game-content {
  position: relative;
  height: 57rem;
  width: 76rem;
  margin-left: 6rem;
  padding-left: 1rem;
  padding-top: 4rem;
  margin-top: -4rem;
  overflow-y: visible;
}
