.shop_card {
  &--background-purchased {
    background: $peviousDayBackgroundTop center no-repeat;
    background-size: contain;
    height: 11.7rem;
    width: 10.3rem;
    position: relative;
    cursor: default;
  }

  &--token {
    background: $token center no-repeat;
    background-size: contain;
    margin: 0.1rem 0.5rem 0.1rem 1rem;
    width: 2.5rem;
    height: 2.3rem;
    background-size: 100% 100%;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
  }
  &--token0 {
    background: $token center no-repeat;
    background-size: contain;
    margin: 0.1rem 0.5rem 0.2rem 1rem;
    width: 1.8rem;
    height: 1.6rem;
    background-size: 100% 100%;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
  }

  &--token1 {
    background: $shopHelpKitSmallIcon center no-repeat;
    background-size: contain;
    margin: 0.1rem 0.5rem 0.2rem 1rem;
    width: 1.8rem;
    height: 1.6rem;
    background-size: 100% 100%;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
  }

  &--token2 {
    background: $shopBoosterSmallIcon center no-repeat;
    background-size: contain;
    margin: 0.1rem 0.5rem 0.2rem 1rem;
    width: 1.8rem;
    height: 1.6rem;
    background-size: 100% 100%;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
  }

  &--type0 {
    border-radius: 0.5rem;
    height: 16.1rem;
    width: 10.9rem;
    background-color: #fff5a4;
    box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
    margin-left: 0.7rem;
    margin-bottom: 0.5rem;
    float: left;
    padding: 0.3rem;
    cursor: pointer;

    .counter--type0 {
      background-size: contain;
      background: $boosterCountHolder center no-repeat;
      margin: 0;
      width: 10rem;
      height: 2rem;
      position: absolute;
      top: 60%;
      z-index: 100;
    }

    .counter-text {
      position: relative;
      color: white;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      line-height: 2rem;
      text-align: left;
    }

    .background {
      background-size: contain;
      background: $boosterCardInnerBackground center no-repeat;
      height: 11.7rem;
      width: 10.3rem;
      position: relative;

      .card {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
      }
    }

    .item-coast {
      text-align: center;
      color: #a15a0c;
      font-family: 'Janda Manatee Solid';
      font-size: 2rem;
      line-height: 4rem;
    }

    .buy-text {
      margin: 0.7rem auto;
      height: 4.5rem;
      width: 7.5rem;
      text-align: center;
      background-size: contain;
      background: $buyText center no-repeat;
    }
  }

  &--type1 {
    border-radius: 0.5rem;
    height: 16.1rem;
    width: 10.9rem;
    background-color: #fff5a4;
    box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
    margin-left: 0.7rem;
    margin-bottom: 0.5rem;
    float: left;
    padding: 0.3rem;
    cursor: pointer;

    &--purchased {
      border-radius: 0.5rem;
      height: 16.1rem;
      width: 10.9rem;
      margin-left: 0.7rem;
      margin-bottom: 0.5rem;
      float: left;
      padding: 0.3rem;
      cursor: pointer;
      background-color: $lavander-blue;
      box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
    }

    .background {
      background-size: contain;
      background: $blueBackground center no-repeat;
      height: 11.7rem;
      width: 10.3rem;
      position: relative;

      .card {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .counter {
        margin: 0;
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .item-coast {
      margin: 0.7rem auto;
      height: 2.1rem;
      color: #a15a0c;
      font-family: 'Janda Manatee Solid';
      font-size: 2rem;
      line-height: 2.9rem;
    }

    .buy-text {
      margin: 0.7rem auto;
      height: 4.5rem;
      width: 7.5rem;
      background-size: contain;
      background: $buyText center no-repeat;
    }
  }

  &--type2 {
    border-radius: 0.5rem;
    height: 16.1rem;
    width: 10.9rem;
    background-color: #fff5a4;
    box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
    margin-left: 0.7rem;
    margin-bottom: 0.5rem;
    float: left;
    padding: 0.3rem;
    cursor: pointer;

    &--purchased {
      background-color: $lavander-blue;
      box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
      border-radius: 0.5rem;
      height: 16.1rem;
      width: 10.9rem;
      margin-left: 0.7rem;
      margin-bottom: 0.5rem;
      float: left;
      padding: 0.3rem;
      cursor: pointer;
    }

    .background {
      background-size: contain;
      background: $blueBackground center no-repeat;
      height: 11.7rem;
      width: 10.3rem;

      .package1 {
        background-size: contain;
        background: $hat1 center no-repeat;
        height: 6.3rem;
        width: 7.2rem;
        padding: 5rem 5.5rem;
      }
      .package2 {
        background-size: contain;
        background: $hat2 center no-repeat;
        height: 6.3rem;
        width: 7.2rem;
        padding: 5rem 5.5rem;
      }
      .package3 {
        background-size: contain;
        background: $hat3 center no-repeat;
        height: 6.3rem;
        width: 7.2rem;
        padding: 5rem 5.5rem;
      }
      .package4 {
        background-size: contain;
        background: $hat4 center no-repeat;
        height: 6.3rem;
        width: 7.2rem;
        padding: 5rem 5.5rem;
      }
      .package5 {
        background-size: contain;
        background: $hat5 center no-repeat;
        height: 6.3rem;
        width: 7.2rem;
        padding: 5rem 5.5rem;
      }
    }
  }
}
