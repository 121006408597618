.settings {
    position: absolute;
    text-align: center;
    font-size: 1.2rem;
    top: 4rem;
    width: 28rem;
    height: 8rem;
    left: 0.5rem;
    background-color: black;
    color: white;
    border-radius: 4px;
    padding: 0 1rem;
    opacity: 0.8;
    z-index: 1000;
    white-space: pre;
  }

.settings-device {
  display: flex;
  margin-top: 0.6rem;
}  

.settings-select {
  width: 70%;
  right: 0.6rem;
  position: absolute;
}