.shop {
  @include absCenter;
  z-index: 499;
  height: 70rem;
  width: 76rem;
  background-size: contain;
  background: $dialogBlackBackground center no-repeat;

  &__container {
    overflow: hidden;
    height: 50.5rem;
    width: 67rem;
    padding: 0rem 0.5rem 0rem 1rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #3569a4;
    border: 0.4rem solid #3569a4;
    border-bottom: 0.55rem solid #6298d7;
  }

  &__title {
    @include absCenter;
    background: $shopTitle center no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 3.3rem;
    width: 20rem;
  }

  &__tab-content-background {
    position: relative;
    z-index: 600;
    background: $tabContentBackground center no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 35.85rem;
    background-size: 98% 100%;
    margin: auto;
    margin-top: -1.5rem;
  }

  &__tab-title {
    text-transform: uppercase;
    padding-top: 4rem;
    height: 2.6rem;
    width: 65.3rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 3.1rem;
    line-height: 1.8rem;
    text-align: center;
  }

  &__tab-description {
    padding-top: 4rem;
    height: 1.8rem;
    width: 65.3rem;
    color: $tropical-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    margin-bottom: -1rem;
  }

  &__tab-content {
    overflow: hidden;
    height: 22.6rem;
    width: 61.4rem;
    margin: auto;
  }

  &__tab-cards {
    margin-left: 0rem;
    height: 25rem;
    overflow: hidden;
    margin-top: 3.5rem;
    transition: margin 700ms;
    padding-top: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__add-tokens-background-png {
    background: $addTokensBackground center no-repeat;
    background-size: contain;
    height: 4rem;
    width: 12.9rem;
    background-size: 80% 80%;
  }
  &__tokens {
    float: left;
    color: #f9d349;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    line-height: 4.4rem;
    margin-left: 5rem;
    width: 28%;
    text-align: center;
  }
  &__add-tokens-button {
    float: left;
    background: $addTokensButton center no-repeat;
    background-size: contain;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0.8rem 0rem 0rem 0.3rem;
  }

  &__tabs {
    margin: auto;
    margin-top: 4rem;
    z-index: 100;
    width: 37.8rem;
    margin-left: 16rem;
  }

  &__tab-background {
    position: relative;
    z-index: 100;
    background: $shopTabButton center no-repeat;
    background-size: contain;
    height: 8.7rem;
    width: 15.5rem;
    float: left;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  &__tab-background-selected {
    position: relative;
    z-index: 601;
    background: $shopTabButtonSelected center no-repeat;
    background-size: contain;
    height: 8.7rem;
    width: 15.5rem;
    float: left;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  &__helpkit-icon {
    background: $shopHelpkitIcon center no-repeat;
    background-size: contain;
    background-size: 60%;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem;
  }
  &__helpkit-icon:hover {
    background-size: 70%;
  }
  &__booster-icon {
    background: $shopBoosterIcon center no-repeat;
    background-size: contain;
    background-size: 60%;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem;
  }
  &__booster-icon:hover {
    background-size: 70%;
  }
  &__hat-icon {
    background: $shopHatIcon center no-repeat;
    background-size: contain;
    background-size: 60%;
    margin-left: 3.5rem;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem;
  }
  &__hat-icon:hover {
    background-size: 70%;
  }
  &__chat-icon {
    background: $shopEmotionIcon center no-repeat;
    background-size: contain;
    background-size: 60%;
    margin-left: 3.5rem;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem;
  }
  &__chat-icon:hover {
    background-size: 70%;
  }

  &__arrow-right {
    background: $shopArrowRight center no-repeat;
    background-size: contain;
    position: absolute;
    left: 59rem;
    top: 15rem;
    height: 10rem;
    width: 8.6rem;
    cursor: pointer;
  }
  &__arrow-right:hover {
    background: $shopArrowRightOver center no-repeat;

    background-size: contain;
  }

  &__arrow-left {
    background: $shopArrowLeft center no-repeat;
    background-size: contain;
    position: absolute;
    left: -3.5rem;
    top: 15rem;
    height: 10rem;
    width: 8.6rem;
    cursor: pointer;
  }

  &__inner {
    @include absCenter;
    box-sizing: border-box;
    height: 55.6rem;
    width: 68.6rem;
    border: 0.7rem solid $saphire-var3;
    border-radius: 1.8rem;
    background-color: $lochmara;   
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: $dialogBackground center no-repeat;
  }

  &__description {
    height: 2.3rem;
    width: 36.1rem;
    color: $canary;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    margin: 4rem auto;
  }

  &__accept {
    display: block;
    height: 4.5rem;
    width: 13.5rem;
    margin: 1rem auto;
    background: $dialogButtonAccept center no-repeat;
    background-size: contain;
    color: $prussian-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
  }

  &__item-coast {
    margin: 0.7rem auto;
    height: 2.1rem;
    color: #a15a0c;
    font-family: 'Janda Manatee Solid';
    font-size: 2.6rem;
    line-height: 2.9rem;
    text-align: center;
    position: relative;
  }

  &__buy-text {
    margin: -0.4rem auto;
    height: 4.5rem;
    width: 7.5rem;
    background: $buyText center no-repeat;
    background-size: contain;
  }

  &__buy-done {
    margin-left: 2rem;
    height: 4rem;
    width: 6.4rem;
    background: $buyDone center no-repeat;
    background-size: contain;
  }

  &__bought-count {
    height: 2.1rem;
    width: 3rem;
    margin-left: 6rem;
    opacity: 0;
    font-family: 'Janda Manatee Solid';
    font-size: 2.4rem;
    position: absolute;
    text-align: right;
    display: inline-block;
    z-index: 110;
    &--booster {
      color: $rust;
    }
  }

  &__animated-buy-done-frame {
    height: 6rem;
    width: 6.4rem;
    margin-left: 2rem;
    margin-top: 10.7rem;
    // background-size: contain; background: $buyDone  center no-repeat;
    position: absolute;
    display: inline-block;
    overflow: hidden;
    z-index: 110;
  }

  &__animated-buy-done {
    height: 6rem;
    width: 65.8rem;
    position: relative;
    opacity: 0;
    background: $peviousDayDoneFrame center no-repeat;
    background-size: contain;
    z-index: 110;
  }
}
