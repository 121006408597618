.feather-booster {
    @include dialog;
    &__inner {
      @include dialog-inner;
      width: 32.3rem;
      height: 31rem;
      background:$lochmara, $dialogBlackBackground center no-repeat;
      background-size: contain; 
    }
    &__description {
      @include description;
      height: 2.4rem;
      width: 32.3rem;
      color: $canary;	
      font-size: 2rem;	 
      margin-left: -0.6rem;   
      text-align: center;
    }
    &__holder {
        height: 8rem;
        width: 27rem;
        margin-left: 2.1rem;
        margin-top: 1.8rem;
        border-radius: 0.5rem;
        display: flex;
        background-color: $saphire-var3;
        &__booster-icon {
            height: 6rem;
            width: 6rem;
            margin-top: 0.5rem;
            margin-left: 1rem;
            &--fly-image {
              position: absolute;
              height: 6rem;
              width: 6rem;
            }
        }
        &__title {
            @include description;
            height: 2rem;
            width: auto;
            margin-left: 1rem;
            font-size: 2.5rem;	    
            text-align: center;      
        }
        &__tokens-silver-icon {
            height: 3.9rem;
            width: 6.2rem;
            margin-top: 1.5rem;
            margin-left: 1.5rem;
            background:$tokensSilverGold center no-repeat;
            background-size: contain; 
        }
    }
    &__accept {
      @include dialog-button-accept;
      display: block;
      margin: 0 auto;
      margin-top: 2rem;
      &:hover {
        @include dialog-button-accept-over;
      }
    }
    &__status {
      font-size: 1rem;
      color: white;
      height: 1.6rem;
      letter-spacing: 0.03rem;
      margin-top: 0.8rem;
      text-align: center;
      font-family: 'Janda Manatee Solid';
    }
  }
  