.tournament-help {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 43.2rem;
    height: 37rem;
    background:$lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain; 
  }
  &__description {
    @include description;
    height: 20rem;
    width: 33rem;
    letter-spacing: 0.09rem;
    font-size: 1rem;
    color: #fefefe;
    margin: 0 auto;
    margin-top: 3.3rem;
  }
  &__close {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 3rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
