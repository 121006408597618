.appeal-opponent {
    @include dialog;
    &__inner {
      @include dialog-inner;
      width: 42.3rem;
      height: 30rem;
      background:$lochmara, $dialogBlackBackground center no-repeat;
      background-size: contain; 
    }
    &__description {
      @include description; 
      height: 5.5rem;
      width: 22rem;
      margin-left: 15rem;
      font-size: 1.4rem;
      line-height: 2rem;
      margin-top: 3.5rem;
      letter-spacing: 0.07rem;
    }
    &__image {
      position: absolute;
      height: 12.8rem;
      width: 10.2rem;
      margin-top: 3rem;
      margin-left: 2.8rem;
      background:$owlInfo center no-repeat;
      background-size: contain; 
    }
    &__button-box {
        display: flex;
        width: 32rem;
        margin: 0 auto;
    }
    &__button {
      @include dialog-button-accept;
      margin: 0 auto;
      margin-top: 9rem;
      margin-left: 2rem;
      margin-right: 2rem;
      &:hover {
        @include dialog-button-accept-over;
      }
    }
  }
  