.server-info {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 43.2rem;
    height: 22rem;
    background:$lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain; 
  }
  &__description {
    @include description;
    height: 13rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 2rem;
    letter-spacing: 0.07rem;
  }
  &__image {
    position: absolute;
    height: 12.8rem;
    width: 10.2rem;
    margin-top: 1rem;
    margin-left: 3.4rem;
    background:$owlInfo center no-repeat;
    background-size: contain; 
  }
  &__confirm {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 5.3rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
