.nav {
  display: flex;
  flex: 1 1 100%;
  list-style: none;
  width: 100%;
  position: relative;
  background: $toolbarBackground no-repeat;
  background-size: contain;
  font-family: 'Janda Manatee Solid';
  z-index: 210;
  &__toolbar {
    height: 2.7rem;
    cursor: pointer;
    background-position: right center;
    background-repeat: no-repeat;
    text-align: right;
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding: 0.8rem 0.8rem 1.8rem 0;
    width: 8.7rem;
    &--count {
      height: 1rem;
      color: #ffd304;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 0.8rem;
      float: right;
      margin-top: 0.2rem;

      &--tokens {
        margin-right: 1rem;
      }
      &--tokens-silver {
        color: $color-text-silver;
      }
    }
    &--tokens {
      background-size: contain;
      background-image: $toolbarToken;
      color: $color-text-yellow;
      margin: 0.5rem 0rem 0.5rem 2.4rem;
      &:hover {
        background-size: contain;
        background-image: $toolbarTokenOver;
      }
    }
    &--tokens-silver {
      background-size: contain;
      background-image: $toolbarTokenSilver;
      color: $color-text-silver;
      margin: 0.5rem 0rem 0.5rem 0.8rem;
    }
  }

  &__progress {
    display: flex;
    flex: 0 0 32rem;
    float: left;
    position: relative;
    overflow: hidden;
    &__current-box {
      display: flex;
      z-index: 1;
    }
    &__next-box {
      margin-left: -6.5rem;
      position: relative;
      width: 29rem;
      opacity: 0;
      display: flex;
    }

    &__current_title {
      display: inline-block;
      text-align: center;
      padding: 0.2rem;
      margin: 0 1rem;
      width: 6.8rem;
      object-fit: contain;
      &--next {
        margin-right: 2.4rem;
      }
    }

    &__current_title_image {
      padding: 0.3rem;
      width: 4rem;
      object-fit: contain;
      margin-top: -0.2rem;
    }

    &__next_title_image {
      padding: 0.3rem;
      width: 4rem;
      object-fit: contain;
      margin-top: -0.2rem;
      &--next {
        position: absolute;
        margin-left: -0.5rem;
      }
    }

    &__bar {
      width: 15rem;
      min-width: 15rem;
      display: flex;
      height: 3.6rem;
      background-image: $progressBarBackground, $progressBarShadow;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom-left-radius: 2rem;
      margin: 0 0.9rem 0 0;
      position: relative;
    }
    &__star {
      position: relative;
      left: -1.5rem;
      z-index: 56;
      height: 2.7rem;
      width: 3.2rem;
      margin-top: 0.5rem;
      background: $progressBarStar center no-repeat;
      background-size: contain;
    }
    &__percentage {
      position: absolute;
      top: 0.6rem;
      height: 2.4rem;
      z-index: 55;
      display: block;
      background-color: $lime-green;
      border-bottom-left-radius: 1rem;
    }
    &__separator {
      background-size: cover;
      background-image: $toolbarSeparator;
      width: 1.5rem;
      height: 4rem;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin-left: 0.5rem;
    }
    &__next_title {
      text-align: center;
      background-size: contain;
      padding: 0.2rem;
      width: 6.8rem;
      opacity: 0.5;
      height: 4rem;
      margin-left: -0.5rem;
      &--image {
        margin-left: -0.5rem;
        position: absolute;
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  &__toolbar-box {
    display: flex;
    margin-left: 54rem;
    position: absolute;
  }

  &__toolbar-menu {
    height: 3.6rem;
    background-position: right center;
    background-repeat: no-repeat;
    text-align: right;
    font-size: 1.2rem;
    background-size: contain;
    line-height: 1.2rem;
    width: 4.5rem;
    margin-left: 0.05rem;
    cursor: pointer;
    transition: all 200ms;
    position: relative;
    &--statistics {
      background-size: contain;
      background-image: $toolbarStatistics;
      &:hover {
        @include hoverAfter {
          background-size: contain;
          background-image: $toolbarStatisticsOver;
        }
      }
      &--active {
        background-size: contain;
        background-image: $toolbarStatisticsSelected;
      }
    }
    &--achievements {
      background-size: contain;
      background-image: $toolbarAchievements;
      &:hover {
        @include hoverAfter {
          background-size: contain;
          background-image: $toolbarAchievementsOver;
        }
      }
      &--active {
        background-size: contain;
        background-image: $toolbarAchievementsSelected;
      }
    }
    &--inbox {
      background-size: contain;
      background-image: $toolbarInbox;
      &:hover {
        @include hoverAfter {
          background-size: contain;
          background-image: $toolbarInboxOver;
        }
      }
      &--active {
        background-size: contain;
        background-image: $toolbarInboxSelected;
      }
    }
    &--shop {
      background-size: contain;
      background-image: $toolbarShop;
      &:hover {
        @include hoverAfter {
          background-size: contain;
          background-image: $toolbarShopOver;
        }
      }
      &--active {
        background-size: contain;
        background-image: $toolbarShopSelected;
      }
    }
    &--settings {
      width: 3.5rem;
      background-size: contain;
      background-image: $toolbarSettings;
      &:hover {
        @include hoverAfter {
          background-size: contain;
          background-image: $toolbarSettingsOver;
        }
      }
      &--active {
        width: 3.5rem;
        background-size: contain;
        background-image: $toolbarSettingsSelected;
        &:hover {
          @include hoverAfter {
            background-size: contain;
            background-image: $toolbarSettingsSelectedOver;
          }
        }
      }
    }
  }

  &__toolbar-sound-container {
    position: absolute;
    top: 3.6rem;
    right: -1px;
    background-image: $soundsBackground;
    background-size: cover;

    height: 2.7rem;
    width: 7.2rem;
    display: none;
    &--active {
      display: block;
    }
  }
  &__toolbar-sound {
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    text-decoration: none;
    &--on {
      background-image: $soundOn;
      background-size: contain;
      &:hover {
        background-image: $soundOnOver;
        background-size: contain;
      }
    }
    &--off {
      background-image: $soundOff;
      background-size: contain;
      &:hover {
        background-image: $soundOffOver;
        background-size: contain;
      }
    }
    &--lobby {
      top: 10.5rem;
    }
  }
  &__toolbar-music {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 3.6rem;
    height: 2.7rem;
    &--on {
      background-image: $musicOn;
    }
    &--off {
      background-image: $musicOff;
    }
  }

  &__toolbar--tokens:hover &__tooltip {
    &--tokens {
      visibility: visible;
      opacity: 1;
    }
  }

  &__toolbar--tokens-silver:hover &__tooltip {
    &--tokens-silver {
      visibility: visible;
      opacity: 1;
    }
  }

  &__tooltip {
    background-color: $black;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 1);
    background-size: cover;
    position: absolute;
    padding: 0.5rem;
    transition: all 200ms ease-in 50ms;
    &:hover {
      visibility: visible;
      opacity: 1;
    }

    z-index: 100;

    &__title {
      text-align: center;
      margin: 1.5rem;
      color: $kournikova-var;
      font-family: 'Janda Manatee Solid';
      font-size: 1.7rem;
    }
    &__image-token {
      display: block;
      float: left;
      height: 7rem;
      width: 10rem;
      margin: 0;
      background-size: cover;
      &--tokens {
        background: $tooltipTokensImage center no-repeat;
      }
      &--tokens-silver {
        background: $tooltipTokensImage center no-repeat;
      }
    }
    &__description {
      display: block;
      float: left;
      padding-top: 1rem;
      line-height: 1.6rem;
      color: $white;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      text-align: left;
    }
    &--tokens {
      top: 4.8rem;
      left: 3rem;
      visibility: hidden;
      opacity: 0;
    }
    &--tokens-silver {
      top: 4.8rem;
      visibility: hidden;
      opacity: 0;
    }
    &__progress-number {
      color: white;
      height: 3rem;
      width: 10rem;
      z-index: 100;
      font-size: 1.2rem;
      display: flex;
      margin-top: 1rem;
      margin-left: -1.5rem;
    }
    &__next-title {
      margin-left: -3rem;
    }
    &__next-box-current {
      position: absolute !important;
      margin-left: 2rem !important;
      margin-top: 4rem;
    }
    &__next-box-next {
      margin-top: 4rem;
    }
  }

  &__tooltip--title {
    @include tooltip;
    position: relative;
    width: max-content;
    // margin-left: 0rem;
    &__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
    }
  }

  &__tooltip--title--bootom {
    @include tooltip;
    position: relative;
    margin-top: 3rem;
    margin-left: -5.5rem;
    width: max-content;
    &__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
    }
  }

  &__tooltip--title--icons {
    @include tooltip;
    position: relative;
    width: max-content;
    &__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
    }
  }

  &__ranking-points {
    position: relative;
    text-align: right;
    height: 3.6rem;
  }
  &__ranking-points-target {
    text-align: left;
    position: relative;
    height: 3.6rem;
    width: 5rem;
  }
  &__delimiter {
    position: relative;
    height: 3.6rem;
    text-align: center;
    margin: 0rem 1rem 0rem 1rem;
  }

  &__progress__current_title:hover &__tooltip--title--icons,
  &__progress__next_title:hover &__tooltip--title--icons,
  &__progress__current_title--next:hover &__tooltip--title,
  &__progress__next_title:hover &__tooltip--title,
  &__ranking-points:hover &__tooltip--title,
  &__ranking-points-target:hover &__tooltip--title,
  &__toolbar-menu--statistics:hover &__tooltip--title--bootom,
  &__toolbar-menu--achievements:hover &__tooltip--title--bootom,
  &__toolbar-menu--inbox:hover &__tooltip--title--bootom,
  &__toolbar-sound--on:hover &__tooltip--title--bootom,
  &__toolbar-music--off:hover &__tooltip--title--bootom,
  &__toolbar-menu--shop:hover &__tooltip--title--bootom,
  &__toolbar-menu--settings:hover &__tooltip--title--bootom {
    visibility: visible;
    opacity: 0.95;
  }
}
