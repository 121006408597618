.b2b-team {
  position: relative;
  margin: 0rem;
  height: 13.3rem;
  width: 7.8rem;
  margin-top: 1rem;
  border-radius: 2.7rem;
  //display: flex;
  background: $friendCard no-repeat center;
  background-size: contain;
  &--red {
    margin-left: -0.6rem;
  }

  &__header {
    position: absolute;
    top: -2rem;
    z-index: 2;
    color: $light-sky-blue;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 1.6rem;
    width: 16rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
  }

  &__status--offline {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: $friendSatusOffline no-repeat center;
    background-size: contain;
  }

  &__status--busy {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: $friendSatusBusy no-repeat center;
    background-size: contain;
  }

  &__status--online {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: $friendSatusOnline no-repeat center;
    background-size: contain;
  }

  &__status--me {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
  }

  &__name {
    position: absolute;
    left: 50%;
    top: 1.7rem;
    width: 6rem;
    height: 1.7rem;
    border-radius: 0.3rem;
    transform: translate(-50%);
    overflow: hidden;
    color: $white;
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 1.1rem;
    font-family: 'Janda Manatee Solid';
    background: $saphire-var5;
    text-align: center;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    &--red {
      background: $cinnabar;
    }
  }
  &__image {
    position: absolute;
    height: 4.3rem;
    width: 4.3rem;
    margin-top: 3.9rem;
    margin-left: 1.8rem;
    border-radius: 17%;
    &--box {
      z-index: 1000;
    }
  }
  &__image--box &__tooltip {
    visibility: hidden;
    opacity: 0;
  }

  &__image--box:hover &__tooltip {
    visibility: visible;
    opacity: 0.95;
  }

  &__tooltip {
    @include tooltip;
    z-index: 1000;
    min-width: 18rem;
    max-width: 20rem;
    min-height: auto;
    max-height: 18rem;
    border-radius: 0.5rem;
    margin-top: 0rem;
    margin-left: 4rem;
    text-align: center;
    pointer-events: none;
  }

  &__tooltip-name {
    color: $kournikova-var;
    font-family: 'Janda Manatee Solid';
    font-size: 1.5rem;
    letter-spacing: 0.07em;
    padding: 0.5rem;
    text-align: center;
    min-width: 10rem;
    max-width: 16rem;
    overflow: hidden;
  }

  &__players {
    position: relative;
    font-family: 'Janda Manatee Solid';
    height: 1.8rem;
    white-space: nowrap;
    overflow: hidden;
    color: white;
    flex-direction: column-reverse;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: left;
  }

  &__title {
    position: absolute;
    bottom: 1.3rem;
    left: 1.7rem;
    height: 3.1rem;
    width: 3.1rem;
  }
  &__points {
    text-align: center;
    font-family: 'Janda Manatee Solid';
    width: 3rem;
    color: $light-sky-blue;
    position: absolute;
    bottom: 1rem;
    right: 1.4rem;
  }

  &__content {
    display: flex;
    margin-left: 4rem;
  }

  &__content-left {
    flex: 0 0 9rem;
    height: 4.7rem;
    background-size: 4.5rem 4.7rem;
  }

  &__content-right {
    flex: 1 1 100%;
  }

  &__result {
    position: absolute;
    top: 9rem;
    z-index: 2;
    width: 8rem;
    text-align: center;
    display: flex;
  }
  &__result-box {
    position: relative;
    color: $light-sky-blue;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 2rem;
    width: 5rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
    left: 16%;
    &--dot {
      width: 1rem;
      margin-left: 0.8rem;
    }
  }
}
