.combinations {
  position: absolute;
  height: 45.7rem;
  padding: 2rem 4rem;
  margin-left: 5rem;
  overflow: hidden;
  display: flex;
  &__buttons {
    width: 5.8rem;
    height: 34.3rem;
    &--game-button {
      @include button3d;
      width: 4.6rem;
      height: 4.6rem;
      margin-top: 0.8rem;
      padding-top: 0rem;
    }
  }
  &__button-image {
    width: 3.8rem;
    height: 3.8rem;
    z-index: 1;
    &--small {
      height: 3.4rem;
      margin-top: -0.4rem;
    }
    &--club {
      background: $symClub no-repeat center;
      background-size: contain;
    }
    &--spade {
      background: $symSpade no-repeat center;
      background-size: contain;
    }
    &--diamond {
      background: $symDiamond no-repeat center;
      background-size: contain;
    }
    &--heart {
      background: $symHeart no-repeat center;
      background-size: contain;
    }
    &--owl {
      background: $symOwl no-repeat center;
      background-size: contain;
    }
    &--star {
      background: $symStar no-repeat center;
      background-size: contain;
    }
  }
  &__combinations-box {
    width: 17.5rem;
    height: 45.5rem;
    margin-left: 5rem;
  }
  &__combinations-holder {
    height: 4.6rem;
    width: 18.2rem;
    display: flex;
    margin-top: 0.5rem;
    border-radius: 0.7rem;
    background-color: $saphire-var2;
    padding: 0.1rem;
    &--game-button {
      width: 3.8rem;
      height: 3.8rem;
      margin-left: 0.3rem;
      margin-top: 0.3rem;
      padding-top: 0.2rem;
      z-index: 1;
      &-empty {
        background-color: $light-cyan-var3;
        border-color:  $light-cyan-var3; 
      }
    }
    &--opponent {
      margin-top: 2rem;
    }
  }
  &__button-box {
    width: 15.3rem;
    height: 45.5rem;
    &--confirm-button {
      @include button3d;
      height: 4rem;
      width: 12.5rem;
      top: 2.3rem;
      // padding: 0.2rem;
      position: absolute;
      z-index: 2;
    }
    &__result-holder {
      position: absolute;
      width: 12.5rem;
      height: 4.6rem;
      margin-left: -0.3rem;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      background-color: $saphire-var2;
      display: flex;
      &--opponent {
        margin-top: 32.6rem;
      }
    }
    &__hit {
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 1rem;
      margin-top: 1rem;
      margin-left: 0.6rem;
      &--wrong {
        background-color: $golden-poppy;
      }
      &--right {
        background-color: $cinnabar;
      }
      &--no {
        background-color: $lochmara;
      }
    }
    &__point {
      position: absolute;
      opacity: 0;
      height: 3.1rem;
      width: 5.6rem;
      color: $white;
      font-family: 'Janda Manatee Solid';
      font-size: 3rem;
      margin-left: 13rem;
      &--opponent {
        margin-left: 30.5rem;
      }
    }
  }
  &__text-box {
    height: 3.4rem;
    display: flex;
    margin-top: 41rem;
    margin-left: 39rem;
    &--text {
      color: $white;
      font-family: 'Janda Manatee Solid';
      font-size: 1.1rem;
    }
  }
  &__hit-box {
    height: 3.4rem;
    width: 1.5rem;
    &--hit {
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 0.5rem;
      &--wrong {
        margin-top: 0.5rem;
        background-color: $golden-poppy;
      }
      &--right {
        margin-top: 0.3rem;
        background-color: $cinnabar;
      }
    }
  }
}
