.inbox-message {
  min-height: 7.6rem;  
  height: fit-content;
  width: 66.2rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  border-radius: 0.8rem;
  background-color: $bahama-blue;
  // :$inboxMessageBackground center no-repeat;
  &__icon {
    position: absolute;
    height: 5.5rem;
    width: 4.8rem;
    margin-top: 1rem;
    margin-left: 1.7rem;
    background:$owlSystemNotification center no-repeat;
  }
  &__title {
    position: relative;
    height: 1.1rem;
    width: 25rem;
    padding-top: 0.5rem;
    margin-left: 8.3rem;
    color: $inboxTitle;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    &-read {
      color: $prussian-blue;
    }
  }
  &__text {
    position: relative;
    min-height: 1.4rem;
    height: fit-content;
    width: 46.5rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    margin-left: 8.3rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    &-read {
      color: $message-read;
    }
  }
  &__link {
    position: relative;
    width: fit-content;    
    margin-left: 8.3rem;
    color: $inboxTitle;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    padding-bottom: 0.4rem;
    cursor: pointer;    
  }
}
