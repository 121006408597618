.dialog-header {
  position: relative;
  display: flex;
  &__left {
    height: 3.8rem;
    width: 13.6rem;
    background: $dialogHeaderLeft center no-repeat;
    background-size: 13.6rem 3.8rem;
    border-top-left-radius: 1rem;
  }
  &__middle {
    text-align: center;
    font-family: 'Janda Manatee Solid';
    color: $prussian-blue;
    font-size: 2rem;
    width: 70rem;
    height: 3.8rem;
    padding-top: 0.5rem;
    background: $dialogHeaderMiddle center no-repeat;
    background-size: 70rem 3.8rem;
  }
  &__right {
    height: 3.8rem;
    width: 13.6rem;
    background: $dialogHeaderRight center no-repeat;
    background-size: 13.6rem 3.8rem;    
    border-top-right-radius: 1rem;
  }
  &__important {
    background: $dialog-header-important;
    background-color: $dialog-header-important;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &__close {
    height: 3.2rem;
    width: 3.3rem;
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    background: $dialogCloseButton center no-repeat;
    background-size: contain;
    &:hover {
      @include hoverAfter {
        background: $dialogCloseButtonOver center no-repeat;
        background-size: contain;
      }
    }
  }
}
