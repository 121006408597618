.new-app-info {
    @include dialog;
    &__inner {
      @include dialog-inner;
      width: 66rem;
      height: 66rem;
      background-size: contain; 
    }
    &__description {
      @include description;
      height: 10.5rem;
      width: 22rem;
      margin-left: 15rem;
      margin-top: 3rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: 0.05rem;
    }
    &__image-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    &__image {
      position: absolute;
      height: 80%;
      width: 80%;
      margin-top: -10rem;
    }
    &__confirm {
      @include dialog-button-accept;
      display: block;
      margin: 0 auto;
      margin-top: -10rem;
      width: 15rem;
      &:hover {
        @include dialog-button-accept-over;
        width: 15rem;
      }
    }
  }
  