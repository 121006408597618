.daily-rewards-card {
  height: 16.1rem;
  width: 10.9rem;
  margin: 0.8rem 0.6rem;
  border-radius: 0.3rem;
  box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25);
  flex: 0 0 10.9rem;
  &--previous {
    box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25);
    background: $lavander-blue;
    // background: $peviousDayCard center no-repeat;
  }
  &--today {
    background: $canary2;
    box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25);
    // background-image: $todayBackground center no-repeat;
  }
  &--next {
    background: $canary2;
    box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25);
    // background-image: $nextDayBackground center no-repeat;
  }

  &__top {
    height: 11.8rem;
    width: 10.3rem;
    margin: 0.3rem;

    &--previous {
      background: $peviousDayBackgroundTop center no-repeat;
      background-size: contain; 
    }
    &--today {
      background: $nextDayBackgroundTop center no-repeat;
    }
    &--next {
      background: $nextDayBackgroundTop center no-repeat;
      background-size: contain; 
    }
  }
  &__tokens {
    height: 8.6rem;
    &--day1 {
      background: $day1TokensSilver center no-repeat;background-size: contain; 
    }
    &--day2 {
      background:$day2TokensSilver center no-repeat;background-size: contain; 
    }
    &--day3 {
      background:$day3TokensSilver center no-repeat;background-size: contain; 
    }
    &--day4 {
      background:$day4TokensSilver center no-repeat;background-size: contain; 
    }
    &--day5 {
      background:$day5TokensSilver center no-repeat;background-size: contain; 
    }
  }
  &__tokens-multiply {
    height: 2.2rem;
    width: 8.4rem;
    margin: 0.5rem auto;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    position: relative;
    text-align: center;
    background:$peviousDayTokenHolder center no-repeat;background-size: contain; 
    &--today-or-next {
      background:$todayTokenHolder center no-repeat;background-size: contain; 
    }
  }
  &__icon-token {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 1.7rem;
    width: 1.7rem;
    background: $silverTokenIcon center no-repeat;
    background-size: cover;
    margin: 0.3rem;
  }

  &__status {
    margin: 0 auto;
    height: 3.9rem;
    width: 4.4rem;
    &--done {
      background:$peviousDayDone center no-repeat;background-size: contain; 
    }
  }

  &__status-today {
    height: 4rem;
    text-align: center;
    color: $kournikova;
    font-family: 'Janda Manatee Solid';
    font-size: 2.6rem;
    background:$today center no-repeat;background-size: contain; 
    &--frame-box {
      overflow: hidden;
      width: 5.4rem;
      height: 5.9rem;
      margin-top: -6rem;
      margin-left: 2rem;
    }
    &--frame {
      opacity: 0;
      height: 8rem;
      background:$peviousDayDoneFrame center no-repeat;background-size: contain; 
      width: 65.8rem;
    }
  }

  &__status-next {
    height: 4rem;
    padding-top: 0.8rem;
    color: $rust;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    text-align: center;
  }
}
