.b2b-ranklist {
  height: 30.5rem;
  width: 33.5rem;
  margin: -1rem 5rem 0 1.5rem;
  background-size: contain;
  font-family: 'Janda Manatee Solid';
  color: $color-white;
  padding-top: 3.8rem;
  position: absolute;
  &__row {
    display: flex;
    height: 2.45rem;
    width: 100%;
    &--header {
      margin-top: 0rem;
      background: $myPositionBackground no-repeat center;
      background-size: cover;
    }
    &--odd {
      background: rgba($color: $black, $alpha: 0.3);
    }
    &--even {
      background: rgba($color: $black, $alpha: 0.1);
    }
  }

  &__my-row {
    position: relative;
    top: -0.3rem;
    padding-top: 0.1rem;
  }
  &__my-row-box {
    height: 25rem;
    width: 33.5rem;
    position: absolute;
    top: -0.3rem;
    z-index: -100;
    padding-top: 0.1rem;
    &--active {
      z-index: 1000;
    }
    &--cover {
      z-index: 1000;
      margin-top: 0.2rem;
      background-color: rgba(2, 10, 41, 0.9);
    }
  }
  &__item-number {
    font-size: 1.2rem;
    text-align: center;
    margin: 0.5rem;
    width: 1.9rem;
    flex-grow: 0;
    color: $seagull-var3;
    &--expanded {
      color: $white;
    }
  }
  &__name {
    font-size: 1.2rem;
    text-align: left;
    margin: 0.5rem;
    color: $seagull-var3;
    flex-grow: 1;
    width: 5rem;
    display: flex;
    align-items: center;
    &--expanded {
      color: $white;
    }
  }

  &__name .b2b-team__tooltip {
    visibility: hidden;
    opacity: 0;
  }

  &__name:hover .b2b-team__tooltip {
    visibility: visible;
    opacity: 0.95;
  }
  &__item-score-container {
    width: 10rem;
    display: flex;
    align-items: flex-end;
    &--odd {
      background: rgba($color: $black, $alpha: 0.4);
    }
    &--even {
      background: rgba($color: $black, $alpha: 0.2);
    }
  }

  &__item-score {
    font-size: 1.2rem;
    margin: 0.5rem;
    flex-grow: 0;
    min-width: 3.9rem;
    text-align: right;
    color: $seagull-var3;
    &--expanded {
      color: $white;
    }
  }

  &__item-box-active {
    margin-top: 0.5rem;
    z-index: 10000;
    position: relative;
  }

  &__content {
    display: flex;
    margin-left: 4rem;
  }

  &__box {
    position: absolute;
    visibility: hidden;
  }
  &__box-fade-out-in {
    position: absolute;
    animation-iteration-count: 1;
    animation: fade-out-in 5000ms;
    -webkit-animation: fade-out-in 5000ms;
  }

  &__box-fade-out {
    opacity: 0;
    animation: fade 10000ms infinite;
    -webkit-animation: fade 10000ms infinite;
  }

  &__box-fade-in {
    opacity: 0;
    animation: fade 10000ms 5000ms infinite;
    -webkit-animation: fade 10000ms 5000ms infinite;
  }
  &__dropdown {
    display: flex;
    animation: fade-box 500ms linear forwards;
    -webkit-animation: fade-box 500ms linear forwards;
  }

  @keyframes fade-box {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes fade-box {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fade-out-in {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-out-in {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
