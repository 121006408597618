.tournament-result {
  @include absCenter;
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: $dialogBlackBackground center no-repeat;
  background-size: contain;
  &__inner {
    @include absCenter;
    box-sizing: border-box;
    height: 57.4rem;
    width: 51.2rem;
    border: 0.7rem solid $saphire-var3;
    border-radius: 1.8rem;
    background-color: $lochmara;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: $lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain;
  }

  &__description {
    height: 1.8rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    line-height: 1.4rem;
    text-align: center;
    margin-top: 3rem;
  }

  &__trone {
    margin-left: 3.5rem;
    height: 26.5rem;
    width: 43.4rem;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 1.6rem;
    background: $trone center no-repeat;
    background-size: contain;
    &--color-feathers {
      height: 26.5rem;
      width: 43.4rem;
      background: $colorFeathersBackground center no-repeat;
      background-size: contain;
    }
    &--feather {
      height: 1.2rem;
      width: 0.8rem;
      position: absolute;
      margin-top: 7.5rem;
      margin-left: 8rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      &--3 {
        margin-top: 5.5rem;
        margin-left: 16rem;
      }
      &--2 {
        margin-top: 3.5rem;
        margin-left: 24rem;
      }
      &--1 {
        margin-top: 1.5rem;
        margin-left: 32.5rem;
      }
    }

    &--icon-feather {
      background: $tournamentFeather no-repeat center;
      background-size: contain;
      position: absolute;
      font-size: 1.2rem;
      height: 1.7rem;
      width: 2.5rem;
      margin-top: 7.5rem;
      margin-left: 9.5rem;
      &--3 {
        margin-top: 5.5rem;
        margin-left: 17.5rem;
      }
      &--2 {
        margin-top: 3.5rem;
        margin-left: 25.5rem;
      }
      &--1 {
        margin-top: 1.5rem;
        margin-left: 34rem;
      }
    }
    &--profile-picture {
      height: 8.5rem;
      width: 6.8rem;
      position: absolute;
      margin-top: 9.5rem;
      margin-left: 6rem;
      background: $profilePictureFrame center no-repeat;
      background-size: contain;
      &--3 {
        margin-top: 7.5rem;
        margin-left: 14rem;
      }
      &--2 {
        margin-top: 5.5rem;
        margin-left: 22rem;
      }
      &--1 {
        margin-top: 3.5rem;
        margin-left: 30.5rem;
      }
    }
    &--profile-image {
      position: absolute;
      height: 6rem;
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      border-radius: 10%;
    }
    &--profile-name {
      position: absolute;
      height: 1.4rem;
      margin-top: 6.7rem;
      width: 6.8rem;
      color: #8d1313;
      overflow: hidden;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
    }
    &--order {
      height: 1.6rem;
      width: 1.6rem;
      position: absolute;
      margin-top: 20.7rem;
      margin-left: 9rem;
      color: #f6dd5f;
      font-family: 'Janda Manatee Solid';
      font-size: 2rem;
      &--3 {
        margin-left: 16.5rem;
        margin-top: 19.5rem;
        font-size: 3rem;
      }
      &--2 {
        margin-left: 24.5rem;
        margin-top: 18.5rem;
        font-size: 4rem;
      }
      &--1 {
        margin-left: 33rem;
        margin-top: 17.5rem;
        font-size: 5rem;
      }
    }
  }

  &__rewarded {
    height: 9.2rem;
    width: 26.7rem;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color: #1e436f;
    &--open-gift-box {
      height: 5.9rem;
      width: 7.1rem;
      margin: 1.5rem 1.1rem 1.8rem 1.1rem;
      position: absolute;
      background: $openedGiftbox center no-repeat;
      background-size: contain;
    }
    &--award-icon-count {
      float: left;
    }

    &--award-content {
      display: table;
      width: 18rem;
      margin: 0 auto;
      margin-top: 1.5rem;
      position: absolute;
      margin-left: 10rem;
    }

    &--award-tooltip-icon {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 1rem;
      margin-left: 1.25rem;
      &--fly {
        margin-left: -2.8rem;
        position: absolute;
        opacity: 1;
      }
    }

    &--award-tooltip-count {
      height: 1.2rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      line-height: 2.9rem;
      margin-left: 1.3rem;
      text-align: center;
    }
  }

  &__close {
    @include dialog-button-accept;
    display: block;
    margin: 0rem auto;
    &:hover {
      @include dialog-button-accept-over;
    }
  }

  &__close_disabled {
    @include dialog-button-disabled;
    height: 4.625rem;
    width: 12.875rem;
    color: $prussian-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    display: block;
    margin: 0rem auto;
  }
}
