.game-status {
  height: 4.6rem;
  width: 59rem;
  margin: 0.4rem 2rem;
  position: relative;
  background: $statusBar no-repeat center;
  background-size: contain;
  letter-spacing: 0.05em;
  line-height: 2;
  padding-top: 0.6rem;
  z-index: 1;

  &__clock {
    top: 0;
    height: 9.1rem;
    width: 8.5rem;
    position: absolute;
    transform: translate(-40%, -48%);
    color: $saphire-var4;
    font-family: 'Janda Manatee Solid';
    font-size: 4.2rem;
    letter-spacing: 0.05em;
    line-height: 3.6rem;
    padding: 4.3rem 0;
    text-align: center;
    z-index: 2;
    &--blue {
      background: $gameTimerHighlight no-repeat center 3.2rem, $gameTimerBlue no-repeat center 3.2rem,
        $gameTimer no-repeat center;
      background-size: 5.8rem, 6.8rem, contain;
    }
    &--orange {
      background: $gameTimerHighlight no-repeat center 3.2rem,
        $gameTimerOrange no-repeat center 3.2rem, $gameTimer no-repeat center;
        background-size: 5.8rem, 6.8rem, contain;
    }
    &--red {
      background: $gameTimerHighlight no-repeat center 3.2rem, $gameTimerRed no-repeat center 3.2rem,
        $gameTimer no-repeat center;
        background-size: 5.8rem, 6.8rem, contain;
    }
  }

  &__text {
    text-align: center;
    color: $alice-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    z-index: 1;
  }
}
