.new-day {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 42.3rem;
    height: 30rem;
    background:$lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain; 
  }
  &__description {
    @include description;
    height: 5rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 4rem;
    letter-spacing: 0.07rem;
  }
  &__image {
    position: absolute;
    height: 12.8rem;
    width: 10.2rem;
    margin-top: 3rem;
    margin-left: 2.8rem;
    background:$owlInfo center no-repeat;
    background-size: contain; 
  }
  &__close {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 9rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
