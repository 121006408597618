.game {
  height: 70rem;
  width: 76rem;
  background-image: linear-gradient(180deg, #599DCD,  #397BBB, #14264B);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  animation: show ease-in 0.3s;
  transition: hide ease-out 0.4s;
  overflow: hidden;

  @keyframes show {
    0% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
}
