.weekly-tournament {
  height: 30.5rem;
  width: 37.5rem;
  margin: 0rem 5rem 0 -1rem;
  background: rgba($color: #000000, $alpha: 0.3);
  font-family: 'Janda Manatee Solid';
  color: $color-white;
  // padding-top: 3.8rem;
  position: absolute;
  border-radius: 1rem;
  &__header {
    width: 100%;
    background-color: $malibu;
    height: 3rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    color: $anakiwa;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 2rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
  }
  &__container {
    height: 24.5rem;
    overflow-y: scroll;
  }
  &__footer {
    width: 100%;
    background-color: $blue-violet;
    height: 3rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    bottom: 0;
    position: absolute;
  }
  &__help {
    height: 2.8rem;
    width: 2.6rem;
    margin-top: -0.3rem;
    margin-left: 17.6rem;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background: $tournamentHelp no-repeat center;
    background-size: contain;
    &:hover {
      background: $tournamentHelpOver no-repeat center;
      background-size: contain;
    }
  }

  &--image {
    position: absolute;
    height: 16rem;
    width: 16rem;
    left: 8rem;
    top: 7rem;
    opacity: 0.1;
    pointer-events: none;
  }
  // &__mode {
  //   height: 2.8rem;
  //   width: 2.6rem;
  //   cursor: pointer;
  //   position: absolute;
  //   z-index: 1;
  //   margin-top: -0.5rem;
  //   &--friends {
  //     margin-left: 24.4rem;
  //     background: $friends no-repeat center;
  //     background-size: contain;
  //   }
  //   &--titles {
  //     margin-left: 30.8rem;
  //   }
  // }

  &__mode {
    height: 3.5rem;
    width: 7.1rem;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    margin-top: -0.9rem;
    background-size: contain;
    &__friends {
      margin-left: 22.1rem;
      &--shown {
        background: $tournamentTab no-repeat center;
      }
      &--hidden {
        background: 0;
      }
    }
    &__titles {
      margin-left: 28.5rem;
      &--shown {
        background: $tournamentTab no-repeat center;
      }
      &--hidden {
        background: 0;
      }
    }
    &__image {
      height: 2.8rem;
      width: 2.6rem;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      margin-left: 2.1rem;
      margin-top: 0.5rem;
      &--friends {
        background: $friends no-repeat center;
        background-size: contain;
      }
      &--titles {
        background-size: contain;
      }
    }
  }

  &__left--time {
    height: 1.3rem;
    width: 33.5rem;
    color: #11203e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    margin-top: 27.7rem;
    margin-left: 1.5rem;
    position: absolute;
    text-align: center;
  }

  &__tooltip--title {
    @include tooltip;
    margin-top: 2.5rem;
    width: max-content;
    &__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
    }
  }

  &__week-day:hover &__tooltip--title,
  &__daily-highscore-content-right:hover &__feather-tooltip,
  &__box:hover &__tooltip--daily,
  &__help:hover &__tooltip--title {
    visibility: visible;
    opacity: 0.95;
  }

  &__tooltip--daily {
    @include tooltip;
    margin-top: 5rem;
    margin-left: 5rem;
    width: max-content;
    &__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
    }
  }

  &__feather-tooltip {
    @include tooltip;
    margin-top: 2.5rem;
    width: max-content;
    margin-left: 19rem;
    margin-top: 8rem;
  }

  &__row {
    display: flex;
    height: 2.45rem;
    &--active {
      margin-top: 0.3rem;
      background: $myPositionBackground no-repeat center;
      background-size: cover;
    }
  }
  &__open-info-bar {
    display: flex;
    height: 2.5rem;
    background-color: #17294f;
    opacity: 0.2;
  }

  &__my-row {
    position: relative;
    top: -0.3rem;
    padding-top: 0.1rem;
  }
  &__my-row-box {
    height: 25rem;
    width: 33.5rem;
    position: absolute;
    top: -0.3rem;
    z-index: -100;
    padding-top: 0.1rem;
    &--active {
      z-index: 1000;
    }
    &--cover {
      z-index: 1000;
      margin-top: 0.2rem;
      background-color: rgba(2, 10, 41, 0.9);
    }
  }
  &__award--1 {
    width: 1.9rem;
    margin: 0.3rem;
    background: $tournamentAward1 no-repeat center;
    background-size: contain;
    flex-grow: 0;
  }
  &__award--2 {
    width: 1.8rem;
    margin: 0.35rem;
    margin-top: 0.35rem;
    background: $tournamentAward2 no-repeat center;
    background-size: contain;
    flex-grow: 0;
  }
  &__award--3 {
    width: 1.5rem;
    margin: 0.5rem;
    margin-top: 0.5rem;
    background: $tournamentAward3 no-repeat center;
    background-size: contain;
    flex-grow: 0;
  }
  &__award--4 {
    width: 1.4rem;
    margin: 0.55rem;
    margin-top: 0.55rem;
    background: $tournamentAward4 no-repeat center;
    background-size: contain;
    flex-grow: 0;
  }
  &__award--5 {
    width: 1.3rem;
    margin: 0.6rem;
    margin-top: 0.6rem;
    background: $tournamentAward5 no-repeat center;
    background-size: contain;
    flex-grow: 0;
  }

  &__award {
    width: 1.9rem;
    margin: 0.3rem;
    flex-grow: 0;
    &--active {
      visibility: hidden;
    }
  }

  &__item-number {
    font-size: 1.2rem;
    text-align: center;
    margin: 0.5rem;
    width: 1.9rem;
    flex-grow: 0;
    color: $seagull-var3;
    &--expanded {
      color: $white;
    }
  }
  &__name {
    font-size: 1.2rem;
    text-align: left;
    margin: 0.5rem;
    color: $seagull-var3;
    flex-grow: 1;
    width: 5rem;
    &--expanded {
      color: $white;
    }
  }
  &__expand-button {
    height: 0.9rem;
    width: 1.1rem;
    margin: 0.8rem 3.3rem 0.8rem;
    cursor: pointer;
    background: $expandArow no-repeat center;
    background-size: contain;
  }
  &__item-score {
    font-size: 1.2rem;
    margin: 0.5rem;
    flex-grow: 0;
    color: $seagull-var3;
    &--expanded {
      color: $white;
    }
  }
  &__item-feather {
    background: $tournamentFeather no-repeat center;
    background-size: contain;
    font-size: 1.2rem;
    height: 1.7rem;
    width: 2.5rem;
    margin: 0.3rem 1rem;
    flex-grow: 0;
    &--active {
      visibility: hidden;
    }
  }
  &__item-box-active {
    margin-top: 0.5rem;
    z-index: 10000;
    position: relative;
  }
  &__daily-highscore {
    position: absolute;
    display: flex;
    left: 0;
    transition: all 1s;
    z-index: 10;
    height: 7.8rem;
    width: 33.5rem;
    background: $dailyHigescoreBackground no-repeat center;
    background-size: contain;
  }
  &__daily-highscore-content-left {
    flex: 1 1 100%;
  }
  &__daily-highscore-content-right {
    text-align: center;
    flex: 0 0 9.4rem;
  }
  &__daily-highscore-title {
    color: $curious-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    display: inline-block;
    margin: 2rem 1rem 0.3rem 5rem;
  }

  &__daily-highscore-feathers-title {
    padding: 2rem 2rem 0.3rem 0rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    display: inline-block;
  }

  &__daily-highscore-feathers-image {
    margin-left: -2rem;
    margin-bottom: -0.5rem;
    padding: 2rem 2rem 0.3rem 0rem;
    background: $tournamentFeather no-repeat center right;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    display: inline-block;
  }

  &__week-days {
    display: flex;
    padding: 0rem 0 1rem 1.2rem;
    position: absolute;
    margin-top: 4.3rem;
  }

  &__week-day {
    position: relative;
    text-align: center;
    padding-top: 0.3rem;
    height: 2.4rem;
    width: 2.7rem;
    margin-right: 0.3rem;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.07em;
    color: rgba(131, 171, 240, 0.45);
    background: $dayButton no-repeat center right;
    background-size: contain;

    &:not(&--selected) {
      cursor: pointer;
    }
    &:hover:not(&--selected) {
      @include hoverBefore {
        background: $dayButtonOver no-repeat center right;
        background-size: contain;
      }
      background: none;
    }
    &--selected {
      background: $dayButtonSelected no-repeat center right;
      background-size: contain;
    }
  }

  &__total-feathers {
    display: block;
    text-align: center;
    margin: 1rem 1rem 0rem 1rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 2.4rem;
    letter-spacing: 0.04em;
    background: $tournamentFeather no-repeat center right;
  }

  &__total-title {
    display: inline-block;
    color: $cornflower-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.07em;
  }

  &__award-tooltip {
    @include tooltip;
    position: relative;
    height: 12.8rem;
    width: 28.6rem;
    background-color: #000000;
    box-shadow: 0 0 0.5rem NaNpx rgba(0, 0, 0, 0.63);
    pointer-events: none;
    left: 3rem;
    top: 3rem;
  }

  &__award-tooltip-title {
    height: 1.5rem;
    color: #f9d34a;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    line-height: 1.4rem;
    text-align: center;
    margin-top: 1.5rem;
  }

  &__award-icon-count {
    float: left;
  }

  &__award-content {
    display: table;
    margin: 0 auto;
    margin-top: 1.5rem;
  }

  &__award-tooltip-icon {
    height: 2.3rem;
    width: 2.3rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  &__award-tooltip-count {
    height: 1.2rem;
    width: 1.7rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-left: 2rem;
    margin-right: 2 rem;
  }

  &__award--1:hover &__award-tooltip,
  &__award--2:hover &__award-tooltip,
  &__award--3:hover &__award-tooltip,
  &__award--4:hover &__award-tooltip,
  &__award--5:hover &__award-tooltip {
    visibility: visible;
    opacity: 0.95;
  }

  &__tooltip-tournament {
    @include tooltip;
    position: relative;
    top: 0;
    width: 25.7rem;
    height: 32rem;
    border-radius: 0.5rem;
    text-align: center;
    pointer-events: none;
  }

  &__total-feathers-tooltip {
    margin-left: 19rem;
    margin-top: 8rem;
  }

  &__tooltip-name {
    color: $tooltip-name;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    letter-spacing: 0.1em;
    padding: 1rem;
    text-align: center;
    min-width: 13rem;
    &--small {
      font-size: 1.1rem;
      margin-top: -2rem;
      color: $tooltip-name;
    }
    &--green {
      color: $sparkly-green;
    }
  }

  &__content {
    display: flex;
    margin-left: 4rem;
  }

  &__content-left {
    flex: 0 0 9rem;
    height: 4.7rem;
    background-size: 4.5rem 4.7rem;
    &--small {
      height: 3.2rem;
      margin-left: 1rem;
    }
  }

  &__content-right {
    flex: 1 1 100%;
    margin-top: -0.75rem;
    margin-left: -3rem;
    &--small {
      margin-top: 0rem;
    }
  }

  &__won-box {
    position: absolute;
    height: 4.8rem;
    display: flex;
    flex-direction: column-reverse;
    &--game {
      height: 1rem;
      width: 2rem;
      background-color: $saphire-var2;
      margin-top: 0.2rem;
    }
  }

  &__egg-owl {
    margin-top: 0.3rem;
    padding: 0.8rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    text-align: center;
    line-height: 1.167;
    height: 2rem;
  }
  &__rating {
    padding: 0.8rem;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 0.04em;
    line-height: 1.167;
    margin-left: 2rem;
    height: 2rem;
    margin-top: 0.3rem;
  }

  &__rating {
    display: inline-block;
    padding-right: 3rem;
  }

  &__collected-feathers {
    padding: 2rem 2rem;
    color: $color-white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.167;
    text-align: center;
    background: $tournamentFeather no-repeat center left;
    display: inline-block;
    margin: 0 auto;
  }

  &__image {
    position: absolute;
    height: 5.3rem;
    width: 5.3rem;
    border-radius: 2.5rem;
    // margin-top: -1rem;
    margin-left: 1rem;
    background-color: $saphire-var5;
    &--text {
      @include description;
      position: absolute;
      height: 1.8rem;
      width: 5rem;
      font-size: 2.1rem;
      margin-left: 0.3rem;
      margin-top: 2rem;
      text-align: center;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
    }
  }

  &__name:hover &__tooltip-tournament {
    visibility: visible;
    opacity: 0.95;
  }

  &__box {
    position: absolute;
    visibility: hidden;
  }
  &__box-fade-out-in {
    position: absolute;
    animation-iteration-count: 1;
    animation: fade-out-in 5000ms;
    -webkit-animation: fade-out-in 5000ms;
  }

  &__box-fade-out {
    opacity: 0;
    animation: fade 10000ms infinite;
    -webkit-animation: fade 10000ms infinite;
  }

  &__box-fade-in {
    opacity: 0;
    animation: fade 10000ms 5000ms infinite;
    -webkit-animation: fade 10000ms 5000ms infinite;
  }
  &__dropdown {
    display: flex;
    animation: fade-box 500ms linear forwards;
    -webkit-animation: fade-box 500ms linear forwards;
  }

  @keyframes fade-box {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes fade-box {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fade-out-in {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-out-in {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
