.aparteko-progress-loader {
  background: $loadingBackground center no-repeat;
  height: 70rem;
  width: 76rem;
  position: absolute;
  background-size: contain;
  z-index: 500;
  top: 0;
  left: 0;
  animation: show ease-in 0.3s;
  transition: hide ease-out 0.4s;
  border-bottom-left-radius: 1.8rem;
  border-bottom-right-radius: 1.8rem;

  &__loading-progress-frame {
    height: 7.2rem;
    width: 31.8rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.2rem;
    background:$loadingProgressFrame center no-repeat;
    background-size: contain;
  }


  
  &__loading-progress-background {
    height: 1.8rem;
    width: 30.2rem;
     @include absCenter;
     top: 45%;
     background:$loadingProgressBackground center;
     background-size: contain;
     background-size: contain;
     border-radius: 0.25rem;
  }
  &__loading-progress-indicator {
    height: 1.8rem;
    width: 0.5rem;
    transition: all 1s linear;
    background:$loadingProgressLine center;
    background-size: contain;    
    border-radius: 0.25rem;
  }

  &--loaded {
    // animation: hideProgress 0.4s ease-out both;
  }
  @keyframes hideProgress {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes show {
    0% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
}
