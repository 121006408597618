*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box;
  padding: 3rem; }

button {
  outline: none;
  border: none;
  cursor: pointer; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

@font-face {
  font-family: 'Janda Manatee Solid';
  src: url(../../public/fonts/SlagalicaFont2020.ttf) format("truetype"); }

.disabled-blurred-div {
  pointer-events: none;
  opacity: 0.5; }

.disabled-div {
  pointer-events: none;
  opacity: 1; }

.enabled-div {
  opacity: 1; }

.game-button-hidden {
  visibility: hidden; }

.game-button {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #07254d;
  background: #c9efff;
  border: solid 0.5rem #0f2239;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  padding-top: 0rem;
  border-color: #e7f7f9 #e7f7f9 #84c8f6 #84c8f6;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem #0f2239 !important; }
  .game-button:disabled {
    cursor: default; }
  .game-button:disabled {
    cursor: default;
    color: #07254d;
    background: #defcff;
    border: solid 0.5rem #0f2239;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: #defcff;
    background-clip: padding-box; }
  .game-button:hover {
    color: #07254d;
    background: #defcff;
    border: solid 0.5rem #0f2239;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: #defcff;
    background-clip: padding-box; }

.game-button-disabled {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border: solid 0.5rem #0f2239;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  background-color: #6b90ad;
  border-color: #6b90ad;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem #0f2239 !important;
  color: #07254d;
  padding-top: 0rem; }
  .game-button-disabled:disabled {
    cursor: default; }
  .game-button-disabled:disabled {
    cursor: default;
    color: #07254d;
    background: #6b90ad; }
    .game-button-disabled:disabled:hover {
      background: #6b90ad;
      color: #07254d; }

.game-button-blue {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #ffffff;
  background-color: #01a2f1;
  border: solid 0.5rem #0f2239;
  border-color: #01a2f1;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem #0f2239 !important;
  padding-top: 0rem; }
  .game-button-blue:disabled {
    cursor: default; }
  .game-button-blue:disabled {
    color: #ffffff; }

.game-button-red {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #ffffff;
  padding-top: 0rem;
  background-color: #f1483b;
  border: solid 0.5rem #0f2239;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  border-color: #f1483b;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem #0f2239 !important; }
  .game-button-red:disabled {
    cursor: default; }
  .game-button-red:disabled {
    color: #ffffff; }

.game-button-yellow {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #07254d;
  border: solid 0.5rem #0f2239;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  border-color: #ffc600;
  background-clip: padding-box;
  padding-top: 0rem;
  box-shadow: 0 0 0 0.25rem #0f2239 !important;
  background-color: #ffc600; }
  .game-button-yellow:disabled {
    cursor: default; }

.game-button-darkblue {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #07254d;
  background-color: #25477c;
  border: solid 0.5rem #0f2239;
  border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.5rem;
  padding-top: 0rem;
  border-color: #25477c;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem #0f2239 !important; }
  .game-button-darkblue:disabled {
    cursor: default; }

.game-button-blue-red {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #ffffff;
  border: 0rem;
  padding-top: 0rem;
  border-radius: 0.5rem;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.25rem #0f2239 !important;
  background: linear-gradient(90deg, #01a2f1, 50%, #f1483b 50%);
  background-size: cover; }
  .game-button-blue-red:disabled {
    cursor: default; }

.game-button-focused {
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: center;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  align-items: center;
  border-radius: 0.5rem;
  border: #07254d 0.3rem solid;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border-color: #fe9303;
  background: #c9efff; }
  .game-button-focused:disabled {
    cursor: default; }

.rounded-game-button-over {
  box-sizing: border-box;
  width: 20.55rem;
  height: 4.5rem;
  border: 0.375rem solid #07254d;
  border-radius: 2.25rem; }

.rounded-game-button-disabled {
  box-sizing: border-box;
  width: 20.55rem;
  height: 4.5rem;
  border: 0.375rem solid #07254d;
  border-radius: 2.25rem;
  background-color: #defcff; }

.rounded-game-button-blue {
  box-sizing: border-box;
  width: 20.55rem;
  height: 4.5rem;
  border: 0.375rem solid #07254d;
  border-radius: 2.25rem;
  background-color: #01a2f1; }

.rounded-game-button-red {
  box-sizing: border-box;
  width: 20.55rem;
  height: 4.5rem;
  border: 0.375rem solid #07254d;
  border-radius: 2.25rem;
  background-color: #f1483b; }

.rounded-game-button-yellow {
  box-sizing: border-box;
  width: 20.55rem;
  height: 4.5rem;
  border: 0.375rem solid #07254d;
  border-radius: 2.25rem;
  background-color: #ffc600; }

.rounded-game-button-darkblue {
  box-sizing: border-box;
  width: 20.55rem;
  height: 4.5rem;
  border: 0.375rem solid #07254d;
  border-radius: 2.25rem;
  background-color: #25477c; }

.nav {
  display: flex;
  flex: 1 1 100%;
  list-style: none;
  width: 100%;
  position: relative;
  background: url("../../public/assets/images/toolbar/toolbar-background.png?15") no-repeat;
  background-size: contain;
  font-family: 'Janda Manatee Solid';
  z-index: 210; }
  .nav__toolbar {
    height: 2.7rem;
    cursor: pointer;
    background-position: right center;
    background-repeat: no-repeat;
    text-align: right;
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding: 0.8rem 0.8rem 1.8rem 0;
    width: 8.7rem; }
    .nav__toolbar--count {
      height: 1rem;
      color: #ffd304;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 0.8rem;
      float: right;
      margin-top: 0.2rem; }
      .nav__toolbar--count--tokens {
        margin-right: 1rem; }
      .nav__toolbar--count--tokens-silver {
        color: #eceded; }
    .nav__toolbar--tokens {
      background-size: contain;
      background-image: url("../../public/assets/images/toolbar/toolbar-token.png?15");
      color: #ffd304;
      margin: 0.5rem 0rem 0.5rem 2.4rem; }
      .nav__toolbar--tokens:hover {
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-token-over.png?15"); }
    .nav__toolbar--tokens-silver {
      background-size: contain;
      background-image: url("../../public/assets/images/toolbar/toolbar-token-silver.png?15");
      color: #eceded;
      margin: 0.5rem 0rem 0.5rem 0.8rem; }
  .nav__progress {
    display: flex;
    flex: 0 0 32rem;
    float: left;
    position: relative;
    overflow: hidden; }
    .nav__progress__current-box {
      display: flex;
      z-index: 1; }
    .nav__progress__next-box {
      margin-left: -6.5rem;
      position: relative;
      width: 29rem;
      opacity: 0;
      display: flex; }
    .nav__progress__current_title {
      display: inline-block;
      text-align: center;
      padding: 0.2rem;
      margin: 0 1rem;
      width: 6.8rem;
      object-fit: contain; }
      .nav__progress__current_title--next {
        margin-right: 2.4rem; }
    .nav__progress__current_title_image {
      padding: 0.3rem;
      width: 4rem;
      object-fit: contain;
      margin-top: -0.2rem; }
    .nav__progress__next_title_image {
      padding: 0.3rem;
      width: 4rem;
      object-fit: contain;
      margin-top: -0.2rem; }
      .nav__progress__next_title_image--next {
        position: absolute;
        margin-left: -0.5rem; }
    .nav__progress__bar {
      width: 15rem;
      min-width: 15rem;
      display: flex;
      height: 3.6rem;
      background-image: url("../../public/assets/images/toolbar/progress-bar-background.png?15"), url("../../public/assets/images/toolbar/progress-bar-shadow.png?15");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom-left-radius: 2rem;
      margin: 0 0.9rem 0 0;
      position: relative; }
    .nav__progress__star {
      position: relative;
      left: -1.5rem;
      z-index: 56;
      height: 2.7rem;
      width: 3.2rem;
      margin-top: 0.5rem;
      background: url("../../public/assets/images/toolbar/progress-bar-star.png?15") center no-repeat;
      background-size: contain; }
    .nav__progress__percentage {
      position: absolute;
      top: 0.6rem;
      height: 2.4rem;
      z-index: 55;
      display: block;
      background-color: #3bb926;
      border-bottom-left-radius: 1rem; }
    .nav__progress__separator {
      background-size: cover;
      background-image: url("../../public/assets/images/toolbar/toolbar-separator.png?15");
      width: 1.5rem;
      height: 4rem;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin-left: 0.5rem; }
    .nav__progress__next_title {
      text-align: center;
      background-size: contain;
      padding: 0.2rem;
      width: 6.8rem;
      opacity: 0.5;
      height: 4rem;
      margin-left: -0.5rem; }
      .nav__progress__next_title--image {
        margin-left: -0.5rem;
        position: absolute; }
      .nav__progress__next_title:hover {
        opacity: 1; }
  .nav__toolbar-box {
    display: flex;
    margin-left: 54rem;
    position: absolute; }
  .nav__toolbar-menu {
    height: 3.6rem;
    background-position: right center;
    background-repeat: no-repeat;
    text-align: right;
    font-size: 1.2rem;
    background-size: contain;
    line-height: 1.2rem;
    width: 4.5rem;
    margin-left: 0.05rem;
    cursor: pointer;
    transition: all 200ms;
    position: relative; }
    .nav__toolbar-menu--statistics {
      background-size: contain;
      background-image: url("../../public/assets/images/toolbar/toolbar-statistics.png?15"); }
      .nav__toolbar-menu--statistics:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-statistics-over.png?15"); }
      .nav__toolbar-menu--statistics--active {
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-statistic-selected.png?15"); }
    .nav__toolbar-menu--achievements {
      background-size: contain;
      background-image: url("../../public/assets/images/toolbar/toolbar-achievements.png?15"); }
      .nav__toolbar-menu--achievements:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-achievements-over.png?15"); }
      .nav__toolbar-menu--achievements--active {
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-achievements-selected.png?15"); }
    .nav__toolbar-menu--inbox {
      background-size: contain;
      background-image: url("../../public/assets/images/toolbar/toolbar-inbox.png?15"); }
      .nav__toolbar-menu--inbox:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-inbox-over.png?15"); }
      .nav__toolbar-menu--inbox--active {
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-inbox-selected.png?15"); }
    .nav__toolbar-menu--shop {
      background-size: contain;
      background-image: url("../../public/assets/images/toolbar/toolbar-shop.png?15"); }
      .nav__toolbar-menu--shop:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-shop-over.png?15"); }
      .nav__toolbar-menu--shop--active {
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-shop-selected.png?15"); }
    .nav__toolbar-menu--settings {
      width: 3.5rem;
      background-size: contain;
      background-image: url("../../public/assets/images/toolbar/toolbar-settings.png?15"); }
      .nav__toolbar-menu--settings:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-settings-over.png?15"); }
      .nav__toolbar-menu--settings--active {
        width: 3.5rem;
        background-size: contain;
        background-image: url("../../public/assets/images/toolbar/toolbar-settings-selected.png?15"); }
        .nav__toolbar-menu--settings--active:hover::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: contain;
          background-image: url("../../public/assets/images/toolbar/toolbar-settings-selected-over.png?15"); }
  .nav__toolbar-sound-container {
    position: absolute;
    top: 3.6rem;
    right: -1px;
    background-image: url("../../public/assets/images/toolbar/sounds-background.png?15");
    background-size: cover;
    height: 2.7rem;
    width: 7.2rem;
    display: none; }
    .nav__toolbar-sound-container--active {
      display: block; }
  .nav__toolbar-sound {
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    text-decoration: none; }
    .nav__toolbar-sound--on {
      background-image: url("../../public/assets/images/lobby/sound_on.png?15");
      background-size: contain; }
      .nav__toolbar-sound--on:hover {
        background-image: url("../../public/assets/images/lobby/sound-on-over.png?15");
        background-size: contain; }
    .nav__toolbar-sound--off {
      background-image: url("../../public/assets/images/lobby/sound_off.png?15");
      background-size: contain; }
      .nav__toolbar-sound--off:hover {
        background-image: url("../../public/assets/images/lobby/sound-off-over.png?15");
        background-size: contain; }
    .nav__toolbar-sound--lobby {
      top: 10.5rem; }
  .nav__toolbar-music {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 3.6rem;
    height: 2.7rem; }
    .nav__toolbar-music--on {
      background-image: url("../../public/assets/images/toolbar/music-on.png?15"); }
    .nav__toolbar-music--off {
      background-image: url("../../public/assets/images/toolbar/music-off.png?15"); }
  .nav__toolbar--tokens:hover .nav__tooltip--tokens {
    visibility: visible;
    opacity: 1; }
  .nav__toolbar--tokens-silver:hover .nav__tooltip--tokens-silver {
    visibility: visible;
    opacity: 1; }
  .nav__tooltip {
    background-color: #000000;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem black;
    background-size: cover;
    position: absolute;
    padding: 0.5rem;
    transition: all 200ms ease-in 50ms;
    z-index: 100; }
    .nav__tooltip:hover {
      visibility: visible;
      opacity: 1; }
    .nav__tooltip__title {
      text-align: center;
      margin: 1.5rem;
      color: #f9d34a;
      font-family: 'Janda Manatee Solid';
      font-size: 1.7rem; }
    .nav__tooltip__image-token {
      display: block;
      float: left;
      height: 7rem;
      width: 10rem;
      margin: 0;
      background-size: cover; }
      .nav__tooltip__image-token--tokens {
        background: url("../../public/assets/images/toolbar/tooltip-tokens-image.png?15") center no-repeat; }
      .nav__tooltip__image-token--tokens-silver {
        background: url("../../public/assets/images/toolbar/tooltip-tokens-image.png?15") center no-repeat; }
    .nav__tooltip__description {
      display: block;
      float: left;
      padding-top: 1rem;
      line-height: 1.6rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      text-align: left; }
    .nav__tooltip--tokens {
      top: 4.8rem;
      left: 3rem;
      visibility: hidden;
      opacity: 0; }
    .nav__tooltip--tokens-silver {
      top: 4.8rem;
      visibility: hidden;
      opacity: 0; }
    .nav__tooltip__progress-number {
      color: white;
      height: 3rem;
      width: 10rem;
      z-index: 100;
      font-size: 1.2rem;
      display: flex;
      margin-top: 1rem;
      margin-left: -1.5rem; }
    .nav__tooltip__next-title {
      margin-left: -3rem; }
    .nav__tooltip__next-box-current {
      position: absolute !important;
      margin-left: 2rem !important;
      margin-top: 4rem; }
    .nav__tooltip__next-box-next {
      margin-top: 4rem; }
  .nav__tooltip--title {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    position: relative;
    width: max-content; }
    .nav__tooltip--title:hover {
      visibility: visible;
      opacity: 0.95; }
    .nav__tooltip--title__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem; }
  .nav__tooltip--title--bootom {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    position: relative;
    margin-top: 3rem;
    margin-left: -5.5rem;
    width: max-content; }
    .nav__tooltip--title--bootom:hover {
      visibility: visible;
      opacity: 0.95; }
    .nav__tooltip--title--bootom__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem; }
  .nav__tooltip--title--icons {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    position: relative;
    width: max-content; }
    .nav__tooltip--title--icons:hover {
      visibility: visible;
      opacity: 0.95; }
    .nav__tooltip--title--icons__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem; }
  .nav__ranking-points {
    position: relative;
    text-align: right;
    height: 3.6rem; }
  .nav__ranking-points-target {
    text-align: left;
    position: relative;
    height: 3.6rem;
    width: 5rem; }
  .nav__delimiter {
    position: relative;
    height: 3.6rem;
    text-align: center;
    margin: 0rem 1rem 0rem 1rem; }
  .nav__progress__current_title:hover .nav__tooltip--title--icons,
  .nav__progress__next_title:hover .nav__tooltip--title--icons,
  .nav__progress__current_title--next:hover .nav__tooltip--title,
  .nav__progress__next_title:hover .nav__tooltip--title,
  .nav__ranking-points:hover .nav__tooltip--title,
  .nav__ranking-points-target:hover .nav__tooltip--title,
  .nav__toolbar-menu--statistics:hover .nav__tooltip--title--bootom,
  .nav__toolbar-menu--achievements:hover .nav__tooltip--title--bootom,
  .nav__toolbar-menu--inbox:hover .nav__tooltip--title--bootom,
  .nav__toolbar-sound--on:hover .nav__tooltip--title--bootom,
  .nav__toolbar-music--off:hover .nav__tooltip--title--bootom,
  .nav__toolbar-menu--shop:hover .nav__tooltip--title--bootom,
  .nav__toolbar-menu--settings:hover .nav__tooltip--title--bootom {
    visibility: visible;
    opacity: 0.95; }

.b2b-container {
  position: relative;
  display: flex;
  flex: 0 0 70%;
  height: 70rem;
  width: 104rem;
  margin: 1rem auto;
  border-radius: 2rem; }

.container {
  background: #234779;
  position: relative;
  display: flex;
  flex: 0 0 76rem;
  height: 70rem;
  max-width: 76rem;
  border-radius: 2rem;
  border-bottom-right-radius: 0rem; }

.align-center {
  text-align: center; }

.lobby {
  height: 70rem;
  width: 76rem;
  background-image: url("../../public/assets/images/lobby/background-lobby.png?15");
  background-size: contain;
  animation: show ease-in 0.3s backwards;
  animation-delay: 0.2s;
  transition: hide ease-out 0.4s; }
  .lobby__content {
    margin: 2.5rem auto;
    width: 71rem;
    display: flex; }
  .lobby__left, .lobby__right {
    width: 50%;
    padding-top: 15%; }
  .lobby__left {
    position: relative;
    padding-top: 10rem; }
  .lobby__footer {
    height: 20.9rem;
    width: 69.6rem;
    margin: 0 auto;
    margin-top: 5rem; }
  .lobby__b2b-toolbar {
    position: absolute;
    top: 0rem;
    height: 10rem;
    width: 76rem;
    overflow: hidden;
    background: url("../../public/assets/images/b2b/SLB-banner.jpg?15") no-repeat center; }
  .lobby__b2b-version {
    position: absolute;
    margin-top: 12rem;
    margin-left: 3rem;
    color: #ffffff; }
  .lobby__b2b-banner {
    position: absolute;
    top: 40rem;
    height: 10rem;
    width: 76rem;
    overflow: hidden;
    background: url("../../public/assets/images/b2b/header-summer.png?15") no-repeat center; }
  .lobby__my-team {
    text-align: center;
    margin: 3rem 0;
    color: #8fdafa;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem; }
    .lobby__my-team:hover {
      transform: scale(1.15);
      cursor: pointer;
      /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */ }
  .lobby__b2b-active {
    text-align: center;
    margin: 3rem 0;
    color: #fcca1d;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem; }

@keyframes show {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0.4; } }

.game {
  height: 70rem;
  width: 76rem;
  background-image: linear-gradient(180deg, #599DCD, #397BBB, #14264B);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  animation: show ease-in 0.3s;
  transition: hide ease-out 0.4s;
  overflow: hidden; }

@keyframes show {
  0% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@keyframes hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0.4; } }

.puzzle {
  position: absolute;
  height: 42.7rem;
  padding: 1rem 4rem;
  margin: -1rem auto 0 auto; }
  .puzzle__available_letters_container {
    display: flex;
    justify-content: space-between;
    margin: 1.6rem 0 3rem 0;
    height: 3.8rem; }
  .puzzle__choosen_letters_container {
    position: relative;
    margin-top: 0.2rem;
    margin: 0 0 0 0;
    padding: 0.5rem 0 0 0.4rem;
    display: flex;
    height: 5rem;
    width: 54rem;
    background: url("../../public/assets/images/game/puzzle/puzzle-answer-background.png?15") no-repeat;
    background-size: contain; }
    .puzzle__choosen_letters_container--max-word {
      margin: 6.5rem 0;
      background: url("../../public/assets/images/game/puzzle/max-word.png?15") no-repeat 0.4rem center, url("../../public/assets/images/game/puzzle/puzzle-answer-background.png?15") no-repeat center;
      background-size: contain; }
  .puzzle__player_image {
    position: relative;
    margin: 0 0.7rem 0.5rem 0.1rem;
    border-radius: 0.4rem;
    display: flex;
    height: 4rem;
    width: 4rem; }
  .puzzle__choosen_letters_container_computer {
    position: relative;
    margin: 7rem 0 0 0;
    padding: 0.5rem 0 0 0.4rem;
    display: flex;
    height: 5rem;
    width: 54rem;
    font-size: 1.8rem;
    background: url("../../public/assets/images/game/puzzle/puzzle-answer-background.png?15") no-repeat;
    background-size: contain; }
  .puzzle__letter {
    padding-top: 0.1rem;
    height: 3.6rem;
    width: 3.6rem;
    font-size: 1.8rem; }
  .puzzle__choosen_letter {
    height: 3.5rem;
    width: 3.5rem;
    padding-top: 0rem;
    margin-right: 0.2rem;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
    font-size: 1.8rem; }
    .puzzle__choosen_letter-computer {
      color: #ffffff; }
  .puzzle__word-status {
    height: 3rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.3rem;
    letter-spacing: 0.05em;
    color: #a9cae3;
    margin-bottom: 0.4rem;
    line-height: 2.769230769230769; }
  .puzzle__word-status-image {
    height: 2rem;
    width: 2rem;
    padding-right: 0.2rem;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.4rem;
    margin-right: 0.5rem; }
  .puzzle__word-status-text {
    height: 2.4rem;
    display: inline-block; }
    .puzzle__word-status-text--correct {
      color: #92d11c; }
    .puzzle__word-status-text--incorrect {
      color: #07254d; }
  .puzzle__erase-all-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    z-index: 1;
    height: 3rem;
    width: 3.2rem;
    background: url("../../public/assets/images/buttons/erase-all-button.png?15") no-repeat center;
    background-size: contain; }
    .puzzle__erase-all-button:disabled {
      cursor: default;
      background: url("../../public/assets/images/buttons/erase-all-button-over.png?15") no-repeat center;
      background-size: contain; }
      .puzzle__erase-all-button:disabled:hover {
        background: url("../../public/assets/images/buttons/erase-all-button-over.png?15") no-repeat center;
        background-size: contain; }
    .puzzle__erase-all-button:hover::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/buttons/erase-all-button-over.png?15") no-repeat center;
      background-size: contain; }
  .puzzle__erase-button {
    position: absolute;
    top: 10.4rem;
    right: 0.7rem;
    z-index: 1;
    height: 3rem;
    width: 3.2rem;
    background: url("../../public/assets/images/buttons/erase-button.png?15") no-repeat center;
    background-size: contain; }
    .puzzle__erase-button:disabled {
      cursor: default;
      background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
      background-size: contain; }
      .puzzle__erase-button:disabled:hover {
        background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
        background-size: contain; }
    .puzzle__erase-button:hover::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
      background-size: contain; }
  .puzzle__button-random-word {
    color: #434811;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.035em;
    line-height: 2.769230769230769;
    padding: 0.9rem 1.6rem 0.1rem 6.8rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 26rem;
    height: 6.5rem;
    transform: translate(-50%, 45%);
    background: url("../../public/assets/images/game/helpkit-button.png?15") no-repeat center;
    background-size: contain; }
    .puzzle__button-random-word:hover::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/game/helpkit-button-over.png?15") no-repeat center;
      background-size: contain; }
  .puzzle__bonus {
    height: 8.3rem;
    width: 8.5rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(70%, -20%);
    z-index: 2; }
    .puzzle__bonus--longer-word {
      background: url("../../public/assets/images/game/puzzle/longer-word.png?15") no-repeat center/cover;
      background-size: contain; }
    .puzzle__bonus--my-game {
      background: url("../../public/assets/images/game/puzzle/my-game.png?15") no-repeat center/cover;
      background-size: contain; }
  .puzzle__extra-bonus {
    height: 8.3rem;
    width: 8.5rem;
    position: absolute;
    right: 0;
    top: 7rem;
    transform: translate(70%, -20%);
    z-index: 3; }
    .puzzle__extra-bonus--comp_same {
      background: url("../../public/assets/images/game/puzzle/bonus-same-as-computer.png?15") no-repeat center/cover;
      background-size: contain; }
    .puzzle__extra-bonus--comp_longer {
      background: url("../../public/assets/images/game/puzzle/bonus-longer-than-computer.png?15") no-repeat center/cover;
      background-size: contain; }
  .puzzle__submit_word_button {
    box-sizing: border-box;
    padding-top: 0.2rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    align-items: center;
    border-radius: 0.5rem;
    border: #07254d 0.3rem solid;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    color: #07254d;
    background: #c9efff;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: #e7f7f9 #e7f7f9 #84c8f6 #84c8f6;
    background-clip: padding-box;
    padding-top: 0rem;
    box-shadow: 0 0 0 0.25rem #0f2239 !important;
    position: relative;
    height: 4.225rem;
    width: 12.875rem;
    top: 0.2rem;
    padding-top: 0.1rem;
    left: 50%;
    transform: translate(-50%, 0%); }
    .puzzle__submit_word_button:disabled {
      cursor: default; }
    .puzzle__submit_word_button:disabled {
      cursor: default;
      border: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      background-color: #6b90ad;
      border-color: #6b90ad;
      background-clip: padding-box;
      padding-top: 0rem;
      box-shadow: 0 0 0 0.25rem #0f2239 !important;
      color: #07254d; }
      .puzzle__submit_word_button:disabled:hover {
        border: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        background-color: #6b90ad;
        border-color: #6b90ad;
        background-clip: padding-box;
        padding-top: 0rem;
        box-shadow: 0 0 0 0.25rem #0f2239 !important;
        color: #07254d; }
    .puzzle__submit_word_button:hover {
      color: #07254d;
      background: #defcff;
      border: 0rem;
      padding-top: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      border-color: #defcff;
      background-clip: padding-box; }
  .puzzle__stop_button {
    box-sizing: border-box;
    padding-top: 0.2rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    align-items: center;
    border-radius: 0.5rem;
    border: #07254d 0.3rem solid;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    color: #07254d;
    background: #c9efff;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: #e7f7f9 #e7f7f9 #84c8f6 #84c8f6;
    background-clip: padding-box;
    padding-top: 0rem;
    box-shadow: 0 0 0 0.25rem #0f2239 !important;
    height: 4.225rem;
    width: 14rem;
    margin-bottom: 5rem;
    padding-top: 0.1rem;
    top: 0.4rem;
    left: 42.1%;
    transform: translate(-50%, 0%);
    vertical-align: middle;
    position: absolute;
    color: red;
    margin-top: 26rem; }
    .puzzle__stop_button:disabled {
      cursor: default; }
    .puzzle__stop_button:disabled {
      cursor: default;
      border: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      background-color: #6b90ad;
      border-color: #6b90ad;
      background-clip: padding-box;
      padding-top: 0rem;
      box-shadow: 0 0 0 0.25rem #0f2239 !important;
      color: #07254d; }
      .puzzle__stop_button:disabled:hover {
        border: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        background-color: #6b90ad;
        border-color: #6b90ad;
        background-clip: padding-box;
        padding-top: 0rem;
        box-shadow: 0 0 0 0.25rem #0f2239 !important;
        color: #07254d; }
    .puzzle__stop_button:hover {
      color: #07254d;
      background: #defcff;
      border: 0rem;
      padding-top: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      border-color: #defcff;
      background-clip: padding-box; }
    .puzzle__stop_button:disabled {
      color: red; }
      .puzzle__stop_button:disabled:hover {
        color: red; }
    .puzzle__stop_button:hover {
      color: red; }
    .puzzle__stop_button-icon {
      width: 2.4rem;
      height: 3rem;
      margin-left: 0.6rem; }
  .puzzle__opponent_word_solving_progress {
    position: absolute;
    top: 30.8rem;
    width: 50rem;
    height: 5rem;
    pointer-events: none; }
  .puzzle__opponent_word_solving_text {
    height: 3rem;
    vertical-align: middle;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    color: #ffffff;
    line-height: 2.769230769230769;
    display: inline-block;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem; }
  .puzzle__point {
    opacity: 0;
    height: 3rem;
    width: 3.9rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: 0rem;
    z-index: 4;
    pointer-events: none; }
    .puzzle__point--my-point {
      margin-left: 1rem; }
    .puzzle__point--opponent-point {
      margin-left: 1rem; }
    .puzzle__point--bonus-point {
      margin-left: 53rem;
      margin-top: 3.6rem; }
    .puzzle__point--opponent-bonus-point {
      margin-left: 53rem;
      margin-top: 16rem; }
    .puzzle__point--extra-bonus-point {
      margin-left: 53rem;
      margin-top: 10.6rem; }
    .puzzle__point--extra-opponent-bonus-point {
      margin-left: 53rem;
      margin-top: 23rem; }
  .puzzle__bonus_point_holder {
    position: absolute; }
  .puzzle__appeal_opponent_word {
    position: absolute;
    margin-top: 4.8rem;
    background: url("../../public/assets/images/game/puzzle/report-wrong-word.png?15") no-repeat bottom left;
    height: 1.6rem;
    width: 16.5rem;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    color: white;
    text-align: left;
    padding-left: 2.2rem;
    padding-top: 0.1rem;
    cursor: pointer; }
    .puzzle__appeal_opponent_word__icon {
      width: 1.5rem;
      background-image: #d49e1b; }
    .puzzle__appeal_opponent_word__text {
      font-size: 1rem; }

.quiz {
  position: absolute;
  height: 42.7rem;
  padding: 2rem 4rem;
  margin: -1rem auto 0 auto; }
  .quiz__question-progress {
    height: 18.1rem;
    width: 54.4rem;
    z-index: -1;
    margin: 0 auto;
    margin-top: -4rem;
    background: url("../../public/assets/images/game/quiz/question-progress-background.png?15") no-repeat center;
    background-size: contain; }
  .quiz__question {
    text-align: center;
    color: #ecf8fe;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    line-height: 3.6rem;
    margin-top: -14rem;
    width: 49rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 0.05rem;
    position: absolute; }
  .quiz__question--progress {
    position: absolute;
    margin-left: 6rem;
    margin-top: -1.2rem;
    display: flex; }
  .quiz__answers-box {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    margin-left: 0.2rem;
    margin-top: 2.6rem; }
  .quiz__answer-box {
    display: flex;
    position: relative;
    margin-top: 3.5rem; }
  .quiz__point {
    opacity: 0;
    z-index: 10;
    height: 3rem;
    width: 3.9rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: 0rem;
    pointer-events: none; }
    .quiz__point--my-point {
      margin-left: 1rem; }
    .quiz__point--opponent-point {
      margin-left: 23rem; }
  .quiz__option-button-box {
    height: 4rem;
    width: 24rem;
    margin-left: 1.8rem; }
  .quiz__option-button {
    height: 4rem;
    width: 24rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-left: 0.2rem; }
  .quiz__time-text {
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.1rem;
    text-align: center;
    display: inline-block; }
  .quiz__skip-button {
    height: 5rem;
    width: 13.1rem;
    position: absolute;
    margin-top: 20rem;
    margin-left: 47rem;
    background: url("../../public/assets/images/buttons/game-button-skip.png?15") no-repeat center;
    background-size: contain; }
    .quiz__skip-button:hover {
      background: url("../../public/assets/images/buttons/game-button-skip-over.png?15") no-repeat center;
      background-size: contain;
      cursor: pointer; }
    .quiz__skip-button--disabled {
      background: url("../../public/assets/images/buttons/game-button-skip-disabled.png?15") no-repeat center;
      background-size: contain; }
      .quiz__skip-button--disabled:hover {
        background: url("../../public/assets/images/buttons/game-button-skip-disabled.png?15") no-repeat center;
        background-size: contain;
        cursor: default; }
  .quiz__skip-text {
    height: 3.2rem;
    width: 12rem;
    color: #12224f;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    line-height: 3.2rem;
    text-align: center;
    margin-left: 1rem;
    margin-top: 1rem;
    padding-right: 3rem;
    position: absolute; }
  .quiz__time-box {
    width: 11.9rem;
    height: 1.5rem;
    margin-left: 2rem;
    position: absolute;
    border-radius: 0.5rem; }
    .quiz__time-box--my-time {
      margin-top: -1.9rem;
      margin-left: 1.8rem;
      padding-left: 0.3rem;
      left: 0;
      background-color: #01a2f1;
      text-align: left !important;
      background-size: contain; }
    .quiz__time-box--opponent-time {
      margin-top: -1.9rem;
      right: 0;
      padding-right: 0.3rem;
      background-color: #f1483b;
      text-align: right !important;
      background-size: contain; }

.question {
  width: 2.2rem;
  height: 2.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background: url("../../public/assets/images/game/quiz/nobody-answered-question.png?15") no-repeat center;
  background-size: contain; }
  .question__not-answered {
    background: url("../../public/assets/images/game/quiz/nobody-answered-question.png?15") no-repeat center;
    background-size: contain; }
  .question__red-answered {
    background: url("../../public/assets/images/game/quiz/red-answered-question.png?15") no-repeat center;
    background-size: contain; }
  .question__blue-answered {
    background: url("../../public/assets/images/game/quiz/blue-answered-question.png?15") no-repeat center;
    background-size: contain; }
  .question__current {
    width: 3rem;
    height: 3rem;
    margin-top: -0.5rem; }
  .question__current-not-answered {
    background: url("../../public/assets/images/game/quiz/current-nobody-answer-question.png?15") no-repeat center;
    background-size: contain; }
  .question__current-red-answered {
    background: url("../../public/assets/images/game/quiz/current-red-answer-question.png?15") no-repeat center;
    background-size: contain; }
  .question__current-blue-answered {
    background: url("../../public/assets/images/game/quiz/current-blue-answer-question.png?15") no-repeat center;
    background-size: contain; }
  .question__text {
    height: 1.6rem;
    width: 1.8rem;
    color: #ecf8fe;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 3.6rem;
    margin-top: -0.6rem;
    margin-left: 0.2rem;
    opacity: 0;
    text-align: center; }
  .question__text-big {
    margin-left: 0.7rem; }

.association {
  position: absolute;
  height: 42.7rem;
  padding: 1rem 4rem;
  margin: -1rem auto 0 auto; }
  .association__column {
    height: 18.2rem;
    width: 26.6rem;
    position: absolute; }
    .association__column--a {
      margin-left: -0.5rem; }
    .association__column--b {
      margin-left: 38.6rem; }
    .association__column--c {
      margin-top: 23.1rem;
      margin-left: 10rem; }
    .association__column--d {
      margin-top: 23.1rem;
      margin-left: 28rem; }
  .association__hint-box {
    height: 2.7rem;
    width: 16rem; }
  .association__hint {
    height: 2.7rem;
    width: 16rem;
    margin-top: 1rem;
    font-size: 1.4rem;
    padding-top: 0rem; }
    .association__hint__result {
      border-radius: 2rem;
      padding-top: 0.4rem; }
      .association__hint__result:hover {
        border-radius: 2rem; }
    .association__hint__guess_tool {
      pointer-events: none; }
  .association__result_button_box {
    height: 2.7rem;
    width: 16rem;
    border-radius: 1.3rem; }
    .association__result_button_box--a {
      margin-left: 10.4rem; }
    .association__result_button_box--b {
      margin-left: -10.5rem; }
    .association__result_button_box--final {
      height: 4rem;
      width: 26rem;
      border-radius: 2rem;
      margin-left: 15rem; }
  .association__final-result {
    height: 3.2rem;
    width: 24rem;
    margin-top: 19.7rem;
    border-radius: 2rem; }
    .association__final-result:hover {
      border-radius: 2rem; }
  .association__point {
    opacity: 0;
    height: 3rem;
    width: 3.9rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: 0rem;
    z-index: 2; }
    .association__point--a {
      margin-left: 6rem;
      margin-top: -3.7rem; }
    .association__point--b {
      margin-left: 6rem;
      margin-top: -3.7rem; }
    .association__point--c {
      margin-left: -4rem;
      margin-top: -3.7rem; }
    .association__point--d {
      margin-left: 16rem;
      margin-top: -3.7rem; }
    .association__point--final {
      margin-left: 12rem;
      margin-top: -3.7rem; }
  .association__skip-button {
    height: 5rem;
    width: 13.1rem;
    position: absolute;
    margin-top: -5rem;
    margin-left: 40.5rem;
    background: url("../../public/assets/images/buttons/game-button-skip.png?15") no-repeat center;
    background-size: contain; }
    .association__skip-button:hover {
      background: url("../../public/assets/images/buttons/game-button-skip-over.png?15") no-repeat center;
      background-size: contain;
      cursor: pointer; }
    .association__skip-button--disabled {
      background: url("../../public/assets/images/buttons/game-button-skip-disabled.png?15") no-repeat center;
      background-size: contain; }
      .association__skip-button--disabled:hover {
        background: url("../../public/assets/images/buttons/game-button-skip-disabled.png?15") no-repeat center;
        background-size: contain;
        cursor: default; }
  .association__guess {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    background-color: rgba(3, 33, 80, 0.95);
    height: 41.5rem;
    width: 56.1rem;
    margin-top: -0.5rem;
    margin-left: 7rem; }
    .association__guess__button-box {
      width: 16rem;
      margin: 0 auto;
      margin-top: 1.5rem; }
    .association__guess__close {
      position: absolute;
      width: 3.3rem;
      height: 3.2rem;
      margin-left: 51rem;
      margin-top: 1rem;
      background: url("../../public/assets/images/dialogs/dialog-close-button.png?15") no-repeat center;
      background-size: contain; }
      .association__guess__close:hover {
        background: url("../../public/assets/images/dialogs/dialog-close-button-over.png?15") no-repeat center;
        background-size: contain; }
    .association__guess__field {
      margin: 0 auto;
      height: 5rem;
      width: 51.1rem;
      border-radius: 2rem;
      margin-top: 1.3rem;
      background-color: #3d669a; }
      .association__guess__field:hover {
        background-color: #3d669a; }
    .association__guess__letter {
      margin-top: 0.2rem;
      margin-left: 0.2rem;
      height: 4.2rem;
      width: 4.2rem;
      font-size: 1.8rem; }
    .association__guess__choosen_letter {
      height: 4.2rem;
      width: 4.2rem;
      padding-top: 0.2rem;
      margin-right: 0rem;
      font-size: 1.8rem; }
    .association__guess__submit_word_button {
      box-sizing: border-box;
      padding-top: 0.2rem;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      align-items: center;
      border-radius: 0.5rem;
      border: #07254d 0.3rem solid;
      display: flex;
      justify-content: center;
      white-space: nowrap;
      position: absolute;
      height: 4.5rem;
      width: 10rem;
      font-size: 1.6rem;
      margin-left: 43rem;
      margin-top: -5rem; }
      .association__guess__submit_word_button:disabled {
        cursor: default; }
    .association__guess__hard-mode-box {
      display: flex;
      justify-content: center;
      height: 4.2rem;
      margin-top: 0.4rem;
      display: flex; }
    .association__guess__hard-mode {
      height: 5.9rem;
      width: 45.5rem;
      margin: 0 auto;
      border-radius: 2rem;
      margin-top: 1rem;
      margin-left: 4rem; }
      .association__guess__hard-mode--letter {
        width: 4.2rem;
        height: 4.2rem;
        margin-left: 0.5rem;
        background: url("../../public/assets/images/game/association/letter-empty.png?15") no-repeat center;
        background-size: contain; }
    .association__guess__erase-button {
      position: absolute;
      z-index: 1;
      height: 3rem;
      width: 3.2rem;
      margin-top: -4rem;
      margin-left: 48.5rem;
      background: url("../../public/assets/images/buttons/erase-button.png?15") no-repeat center;
      background-size: contain; }
      .association__guess__erase-button:disabled {
        cursor: default;
        background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
        background-size: contain; }
        .association__guess__erase-button:disabled:hover {
          background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
          background-size: contain; }
      .association__guess__erase-button:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
        background-size: contain; }
    .association__guess__rounded-erase-button {
      position: absolute;
      z-index: 1;
      height: 5rem;
      width: 5rem;
      margin-top: -5.5rem;
      margin-left: 50rem;
      background: url("../../public/assets/images/buttons/rounded-erase-button.png?15") no-repeat center;
      background-size: contain; }
      .association__guess__rounded-erase-button:disabled {
        cursor: default;
        background: url("../../public/assets/images/buttons/rounded-erase-button-over.png?15") no-repeat center;
        background-size: contain; }
        .association__guess__rounded-erase-button:disabled:hover {
          background: url("../../public/assets/images/buttons/rounded-erase-button-over.png?15") no-repeat center;
          background-size: contain; }
      .association__guess__rounded-erase-button:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/buttons/rounded-erase-button-over.png?15") no-repeat center;
        background-size: contain; }
    .association__guess__game-buton-box {
      position: absolute; }
    .association__guess__letters-box {
      height: 9.6rem;
      width: 28.2rem;
      margin: 0 auto;
      margin-top: 2.3rem; }
      .association__guess__letters-box--second {
        margin-top: -5rem; }
  .association__result-input {
    height: 3.4rem;
    width: 16rem;
    margin-top: -0.1rem;
    position: absolute;
    font-size: 1.4rem;
    background-color: transparent;
    border-color: transparent;
    border-radius: 2rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
    text-transform: uppercase;
    outline: none; }
    .association__result-input-final {
      width: 24rem;
      padding-top: 0.2rem; }
    .association__result-input--disabled-navy {
      color: #07254d; }
    .association__result-input--disabled-white {
      color: white; }

.matching {
  position: absolute;
  height: 45.7rem;
  width: 62rem;
  padding: 1rem 4.7rem;
  margin: -1rem auto 0 auto;
  overflow: hidden; }
  .matching__progress {
    height: 22rem;
    width: 52.2rem;
    z-index: -1;
    margin-top: -12rem;
    background: url("../../public/assets/images/game/quiz/question-progress-background.png?15") no-repeat center;
    background-size: contain; }
    .matching__progress__question {
      position: absolute;
      width: 83%;
      height: 7rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 12rem;
      border-radius: 1rem;
      font-family: "Janda Manatee Solid";
      font-size: 1.8rem;
      color: #ffffff; }
  .matching__column {
    position: absolute;
    margin-top: -1rem;
    width: 22.5rem; }
    .matching__column--B {
      margin-left: 27rem; }
  .matching__option-button {
    height: 3.2rem;
    width: 21.5rem;
    margin-left: 2rem;
    margin-top: 1rem;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    padding-left: 0.2rem; }
  .matching__point {
    opacity: 0;
    height: 3.1rem;
    width: 5.6rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem;
    position: absolute;
    margin-top: -4rem;
    margin-left: 24rem; }
    .matching__point--red {
      margin-top: -5.2rem;
      margin-left: 48.5rem; }
  .matching__solving {
    margin-top: 10rem;
    margin-left: 1rem;
    position: absolute; }
  .matching__semaphore-box-blue {
    height: 6.3rem;
    width: 16.6rem;
    opacity: 0;
    margin-top: 38rem;
    margin-left: 4.5rem; }
  .matching__semaphore-box-red {
    height: 6.3rem;
    width: 16.6rem;
    opacity: 0;
    margin-top: -6.3rem;
    margin-left: 31.5rem; }
  .matching__semaphore {
    height: 6.3rem;
    width: 16.6rem;
    z-index: 10;
    position: absolute; }
    .matching__semaphore--blue {
      background: url("../../public/assets/images/game/matching/blue-semaphore.png?15") no-repeat center;
      background-size: contain; }
    .matching__semaphore--red {
      background: url("../../public/assets/images/game/matching/red-semaphore.png?15") no-repeat center;
      background-size: contain; }
    .matching__semaphore__image {
      width: 4rem;
      height: 4rem;
      position: absolute;
      border-radius: 1.8rem;
      margin-top: 1rem; }
      .matching__semaphore__image--my {
        margin-left: 1rem; }
      .matching__semaphore__image--opponent {
        margin-left: 11.5rem; }
    .matching__semaphore__count {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.5rem;
      margin-top: 2rem; }
      .matching__semaphore__count--blue {
        margin-left: 7.5rem; }
      .matching__semaphore__count--red {
        opacity: 0;
        margin-left: 2.5rem; }

.my-number {
  position: absolute;
  height: 42.7rem;
  padding: 2rem 4rem;
  margin: -1rem auto 0 auto;
  overflow: hidden;
  font-family: 'Janda Manatee Solid'; }
  .my-number__progress {
    height: 18.1rem;
    width: 54.4rem;
    z-index: -1;
    margin: 0 auto;
    margin-top: -10rem;
    background: url("../../public/assets/images/game/quiz/question-progress-background.png?15") no-repeat center;
    background-size: contain; }
    .my-number__progress__final-number {
      position: absolute;
      height: 5.6rem;
      width: 11.3rem;
      border: 0.3rem solid #07254d;
      background-color: #e9f8fd;
      margin-left: 22rem;
      margin-top: 10rem;
      border-radius: 1rem; }
    .my-number__progress__number {
      text-align: center;
      margin-top: 0.5rem;
      color: #07254d;
      font-family: 'Janda Manatee Solid';
      font-size: 2.8rem; }
      .my-number__progress__number--white {
        margin-top: -0.2rem;
        color: #ffffff; }
    .my-number__progress__number-box {
      position: absolute;
      height: 4.1rem;
      width: 10.3rem;
      margin-top: 16rem;
      border-radius: 1rem;
      border: 0.3rem solid #07254d; }
      .my-number__progress__number-box--blue {
        margin-left: 7rem;
        background-color: #01a2f1; }
      .my-number__progress__number-box--red {
        margin-left: 38rem;
        background-color: #f1483b; }
  .my-number__choosen_numbers_container {
    position: relative;
    margin: 5rem 0 0 0;
    padding: 0.5rem 0 0 0.4rem;
    display: flex;
    height: 5rem;
    width: 54rem;
    background: url("../../public/assets/images/game/puzzle/puzzle-answer-background.png?15") no-repeat center;
    background-size: contain; }
    .my-number__choosen_numbers_container--opponent {
      opacity: 0;
      position: absolute;
      margin: 2.2rem 0 0 0; }
    .my-number__choosen_numbers_container--selected {
      width: 100%;
      font-size: 2.5rem;
      padding-left: 1rem;
      padding-top: 0.3rem;
      letter-spacing: 0.05rem;
      color: #ffffff; }
    .my-number__choosen_numbers_container--opponent-solving {
      display: flex;
      float: left;
      position: absolute;
      height: 3rem;
      vertical-align: middle;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      color: #ffffff;
      line-height: 2.769230769230769;
      margin-bottom: 0.4rem;
      margin-left: 0.4rem; }
  .my-number__point {
    opacity: 1;
    height: 3.1rem;
    width: 5.6rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 3rem; }
  .my-number__player_image {
    position: relative;
    margin: 0 0.7rem 0.5rem 0.3rem;
    border-radius: 0.4rem;
    height: 4rem;
    width: 4rem;
    background-size: contain; }
  .my-number__erase-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    z-index: 1;
    height: 3rem;
    width: 3.2rem;
    background: url("../../public/assets/images/buttons/erase-button.png?15") no-repeat center;
    background-size: contain; }
    .my-number__erase-button:disabled {
      cursor: default;
      background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
      background-size: contain; }
      .my-number__erase-button:disabled:hover {
        background: url("../../public/assets/images/buttons/erase-button-over.png?15") no-repeat center;
        background-size: contain; }
  .my-number__submit_word_button {
    box-sizing: border-box;
    padding-top: 0.2rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    align-items: center;
    border-radius: 0.5rem;
    border: #07254d 0.3rem solid;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    color: #07254d;
    background: #c9efff;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: #e7f7f9 #e7f7f9 #84c8f6 #84c8f6;
    background-clip: padding-box;
    padding-top: 0rem;
    box-shadow: 0 0 0 0.25rem #0f2239 !important;
    position: relative;
    height: 4.225rem;
    width: 12.875rem;
    padding-top: 0.1rem;
    margin-bottom: 5rem;
    top: 1.2rem;
    left: 50%;
    transform: translate(-50%, 0%);
    vertical-align: middle; }
    .my-number__submit_word_button:disabled {
      cursor: default; }
    .my-number__submit_word_button:disabled {
      cursor: default;
      border: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      background-color: #6b90ad;
      border-color: #6b90ad;
      background-clip: padding-box;
      padding-top: 0rem;
      box-shadow: 0 0 0 0.25rem #0f2239 !important;
      color: #07254d; }
      .my-number__submit_word_button:disabled:hover {
        border: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        background-color: #6b90ad;
        border-color: #6b90ad;
        background-clip: padding-box;
        padding-top: 0rem;
        box-shadow: 0 0 0 0.25rem #0f2239 !important;
        color: #07254d; }
    .my-number__submit_word_button:hover {
      color: #07254d;
      background: #defcff;
      border: 0rem;
      padding-top: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      border-color: #defcff;
      background-clip: padding-box; }
  .my-number__stop_button {
    box-sizing: border-box;
    padding-top: 0.2rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    align-items: center;
    border-radius: 0.5rem;
    border: #07254d 0.3rem solid;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    color: #07254d;
    background: #c9efff;
    border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
    border-radius: 0.5rem;
    border-color: #e7f7f9 #e7f7f9 #84c8f6 #84c8f6;
    background-clip: padding-box;
    padding-top: 0rem;
    box-shadow: 0 0 0 0.25rem #0f2239 !important;
    margin-bottom: 5rem;
    top: 1.2rem;
    left: 50%;
    transform: translate(-50%, 0%);
    vertical-align: middle;
    height: 4.225rem;
    width: 14rem;
    padding-top: 0.1rem;
    position: absolute;
    margin-top: 20.2rem;
    color: red; }
    .my-number__stop_button:disabled {
      cursor: default; }
    .my-number__stop_button:disabled {
      cursor: default;
      border: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      background-color: #6b90ad;
      border-color: #6b90ad;
      background-clip: padding-box;
      padding-top: 0rem;
      box-shadow: 0 0 0 0.25rem #0f2239 !important;
      color: #07254d; }
      .my-number__stop_button:disabled:hover {
        border: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        background-color: #6b90ad;
        border-color: #6b90ad;
        background-clip: padding-box;
        padding-top: 0rem;
        box-shadow: 0 0 0 0.25rem #0f2239 !important;
        color: #07254d; }
    .my-number__stop_button:hover {
      color: #07254d;
      background: #defcff;
      border: 0rem;
      padding-top: 0rem;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      border-color: #defcff;
      background-clip: padding-box; }
    .my-number__stop_button:disabled {
      cursor: default;
      color: red; }
    .my-number__stop_button:hover {
      color: red; }
    .my-number__stop_button-icon {
      width: 2.4rem;
      height: 3rem;
      margin-left: 0.6rem; }
  .my-number__numbers-button-container {
    display: flex;
    justify-content: center;
    margin: 1.6rem 0 3rem 0; }
    .my-number__numbers-button-container--opponent {
      margin: 1.6rem 0 0rem 0; }
  .my-number__number-button {
    padding-top: 0rem;
    height: 3.9rem;
    width: 3.9rem;
    font-size: 2.3rem;
    margin-left: 1.5rem;
    color: #07254d;
    background: #c9efff; }
    .my-number__number-button:hover {
      background: #defcff; }
    .my-number__number-button--middle {
      width: 8.4rem; }
    .my-number__number-button--big {
      width: 12.5rem; }
    .my-number__number-button--operation {
      background-color: #6994c7;
      border: solid 0.5rem #0f2239;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      padding-top: 0rem;
      font-size: 2.1rem;
      border-color: #6f9cc5 #6f9cc5 #2e5380 #2e5380;
      background-clip: padding-box;
      box-shadow: 0 0 0 0.25rem #0f2239 !important; }
      .my-number__number-button--operation:disabled {
        cursor: default;
        color: #07254d;
        background-color: #3f566f;
        border-color: #3f566f; }
      .my-number__number-button--operation:hover {
        color: #07254d;
        background-color: #6b90ad;
        border-color: #6b90ad; }
  .my-number__opponent_number_solving_progress {
    position: absolute;
    top: 22.7rem;
    left: 5rem;
    width: 50rem;
    height: 5rem;
    pointer-events: none; }
  .my-number__opponent_number_solving_text {
    height: 3rem;
    vertical-align: middle;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    color: #ffffff;
    line-height: 2.769230769230769;
    display: inline-block;
    margin-bottom: 0.4rem;
    margin-left: 0.4rem; }

.pairs {
  position: absolute;
  height: 48rem;
  padding: 2rem 4rem;
  margin: -1rem auto 0 auto;
  overflow: hidden; }
  .pairs__progress {
    height: 18.1rem;
    width: 50.4rem;
    z-index: -1;
    margin-left: 2rem;
    margin-top: -11rem;
    background: url("../../public/assets/images/game/quiz/question-progress-background.png?15") no-repeat center;
    background-size: contain; }
    .pairs__progress__question {
      position: absolute;
      width: 82%;
      height: 5.6rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 11.5rem;
      border-radius: 1rem;
      margin-left: 0.4rem;
      font-family: "Janda Manatee Solid";
      font-size: 1.8rem;
      color: #ffffff;
      word-wrap: break-word; }
  .pairs__container {
    position: absolute;
    margin-top: 0.5rem;
    width: 21rem;
    margin-left: 2rem; }
    .pairs__container--pairs {
      width: 24.8rem;
      margin-top: 1.1rem;
      height: 3.3rem; }
    .pairs__container--opponent {
      margin-left: 28rem; }
    .pairs__container--text {
      position: absolute;
      z-index: 1000;
      width: 10rem;
      visibility: hidden;
      opacity: 0;
      top: 0;
      border-radius: 0.5rem;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 0.5rem;
      box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
      transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
      opacity: 0;
      visibility: visible;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      margin-left: 15rem;
      margin-top: 4rem;
      border-radius: 2rem;
      text-align: center;
      pointer-events: none;
      background-color: #010a1e;
      box-shadow: 0 0 0.5rem 0.1rem #010a1e; }
      .pairs__container--text:hover {
        visibility: visible;
        opacity: 0.95; }
      .pairs__container--text::after {
        content: "";
        position: absolute;
        opacity: 1;
        top: -120%;
        left: 60%;
        margin-left: -0.6rem;
        border-width: 1.5rem;
        margin-top: 0.1rem;
        border-style: solid;
        border-color: transparent transparent #010a1e transparent; }
    .pairs__container__point {
      position: absolute;
      opacity: 0;
      height: 3.1rem;
      width: 5.6rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 3rem;
      margin-top: -4rem;
      margin-left: 24.5rem;
      z-index: 2;
      pointer-events: none; }

.combinations {
  position: absolute;
  height: 45.7rem;
  padding: 2rem 4rem;
  margin-left: 5rem;
  overflow: hidden;
  display: flex; }
  .combinations__buttons {
    width: 5.8rem;
    height: 34.3rem; }
    .combinations__buttons--game-button {
      box-sizing: border-box;
      padding-top: 0.2rem;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      align-items: center;
      border-radius: 0.5rem;
      border: #07254d 0.3rem solid;
      display: flex;
      justify-content: center;
      white-space: nowrap;
      color: #07254d;
      background: #c9efff;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      border-color: #e7f7f9 #e7f7f9 #84c8f6 #84c8f6;
      background-clip: padding-box;
      padding-top: 0rem;
      box-shadow: 0 0 0 0.25rem #0f2239 !important;
      width: 4.6rem;
      height: 4.6rem;
      margin-top: 0.8rem;
      padding-top: 0rem; }
      .combinations__buttons--game-button:disabled {
        cursor: default; }
      .combinations__buttons--game-button:disabled {
        cursor: default;
        border: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        background-color: #6b90ad;
        border-color: #6b90ad;
        background-clip: padding-box;
        padding-top: 0rem;
        box-shadow: 0 0 0 0.25rem #0f2239 !important;
        color: #07254d; }
        .combinations__buttons--game-button:disabled:hover {
          border: 0rem;
          border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
          border-radius: 0.5rem;
          background-color: #6b90ad;
          border-color: #6b90ad;
          background-clip: padding-box;
          padding-top: 0rem;
          box-shadow: 0 0 0 0.25rem #0f2239 !important;
          color: #07254d; }
      .combinations__buttons--game-button:hover {
        color: #07254d;
        background: #defcff;
        border: 0rem;
        padding-top: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        border-color: #defcff;
        background-clip: padding-box; }
  .combinations__button-image {
    width: 3.8rem;
    height: 3.8rem;
    z-index: 1; }
    .combinations__button-image--small {
      height: 3.4rem;
      margin-top: -0.4rem; }
    .combinations__button-image--club {
      background: url("../../public/assets/images/game/combination/sym_club.png?15") no-repeat center;
      background-size: contain; }
    .combinations__button-image--spade {
      background: url("../../public/assets/images/game/combination/sym_spade.png?15") no-repeat center;
      background-size: contain; }
    .combinations__button-image--diamond {
      background: url("../../public/assets/images/game/combination/sym_diamond.png?15") no-repeat center;
      background-size: contain; }
    .combinations__button-image--heart {
      background: url("../../public/assets/images/game/combination/sym_heart.png?15") no-repeat center;
      background-size: contain; }
    .combinations__button-image--owl {
      background: url("../../public/assets/images/game/combination/sym_owl.png?15") no-repeat center;
      background-size: contain; }
    .combinations__button-image--star {
      background: url("../../public/assets/images/game/combination/sym_star.png?15") no-repeat center;
      background-size: contain; }
  .combinations__combinations-box {
    width: 17.5rem;
    height: 45.5rem;
    margin-left: 5rem; }
  .combinations__combinations-holder {
    height: 4.6rem;
    width: 18.2rem;
    display: flex;
    margin-top: 0.5rem;
    border-radius: 0.7rem;
    background-color: #09254b;
    padding: 0.1rem; }
    .combinations__combinations-holder--game-button {
      width: 3.8rem;
      height: 3.8rem;
      margin-left: 0.3rem;
      margin-top: 0.3rem;
      padding-top: 0.2rem;
      z-index: 1; }
      .combinations__combinations-holder--game-button-empty {
        background-color: #defcff;
        border-color: #defcff; }
    .combinations__combinations-holder--opponent {
      margin-top: 2rem; }
  .combinations__button-box {
    width: 15.3rem;
    height: 45.5rem; }
    .combinations__button-box--confirm-button {
      box-sizing: border-box;
      padding-top: 0.2rem;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      align-items: center;
      border-radius: 0.5rem;
      border: #07254d 0.3rem solid;
      display: flex;
      justify-content: center;
      white-space: nowrap;
      color: #07254d;
      background: #c9efff;
      border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
      border-radius: 0.5rem;
      border-color: #e7f7f9 #e7f7f9 #84c8f6 #84c8f6;
      background-clip: padding-box;
      padding-top: 0rem;
      box-shadow: 0 0 0 0.25rem #0f2239 !important;
      height: 4rem;
      width: 12.5rem;
      top: 2.3rem;
      position: absolute;
      z-index: 2; }
      .combinations__button-box--confirm-button:disabled {
        cursor: default; }
      .combinations__button-box--confirm-button:disabled {
        cursor: default;
        border: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        background-color: #6b90ad;
        border-color: #6b90ad;
        background-clip: padding-box;
        padding-top: 0rem;
        box-shadow: 0 0 0 0.25rem #0f2239 !important;
        color: #07254d; }
        .combinations__button-box--confirm-button:disabled:hover {
          border: 0rem;
          border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
          border-radius: 0.5rem;
          background-color: #6b90ad;
          border-color: #6b90ad;
          background-clip: padding-box;
          padding-top: 0rem;
          box-shadow: 0 0 0 0.25rem #0f2239 !important;
          color: #07254d; }
      .combinations__button-box--confirm-button:hover {
        color: #07254d;
        background: #defcff;
        border: 0rem;
        padding-top: 0rem;
        border-width: 0.4rem 0.4rem 0.5rem 0.4rem;
        border-radius: 0.5rem;
        border-color: #defcff;
        background-clip: padding-box; }
    .combinations__button-box__result-holder {
      position: absolute;
      width: 12.5rem;
      height: 4.6rem;
      margin-left: -0.3rem;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      background-color: #09254b;
      display: flex; }
      .combinations__button-box__result-holder--opponent {
        margin-top: 32.6rem; }
    .combinations__button-box__hit {
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 1rem;
      margin-top: 1rem;
      margin-left: 0.6rem; }
      .combinations__button-box__hit--wrong {
        background-color: #ffc600; }
      .combinations__button-box__hit--right {
        background-color: #f1483b; }
      .combinations__button-box__hit--no {
        background-color: #3869a3; }
    .combinations__button-box__point {
      position: absolute;
      opacity: 0;
      height: 3.1rem;
      width: 5.6rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 3rem;
      margin-left: 13rem; }
      .combinations__button-box__point--opponent {
        margin-left: 30.5rem; }
  .combinations__text-box {
    height: 3.4rem;
    display: flex;
    margin-top: 41rem;
    margin-left: 39rem; }
    .combinations__text-box--text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.1rem; }
  .combinations__hit-box {
    height: 3.4rem;
    width: 1.5rem; }
    .combinations__hit-box--hit {
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 0.5rem; }
      .combinations__hit-box--hit--wrong {
        margin-top: 0.5rem;
        background-color: #ffc600; }
      .combinations__hit-box--hit--right {
        margin-top: 0.3rem;
        background-color: #f1483b; }

.friend {
  position: relative;
  margin: 0rem;
  height: 12.5rem;
  width: 16rem;
  margin-top: 1rem;
  display: flex; }
  .friend--dark {
    background: url("../../public/assets/images/lobby/friends/friend-card-dark-background.png?15") no-repeat center;
    background-size: contain; }
  .friend--light {
    background: url("../../public/assets/images/lobby/friends/friend-card-light-background.png?15") no-repeat center;
    background-size: contain; }
  .friend--invite {
    cursor: pointer;
    background: url("../../public/assets/images/lobby/friends/friend-invite-background.png?15") no-repeat center;
    background-size: contain; }
    .friend--invite:hover::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/lobby/friends/friend-invite-background-over.png?15") no-repeat center;
      background-size: contain; }
  .friend__invite--box {
    width: 9.6rem; }
  .friend__header {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 2;
    transform: translate(-50%, -34%);
    color: #8fdafa;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2.6rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }
  .friend__status--offline {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: url("../../public/assets/images/lobby/friends/friend-satus-offline.png?15") no-repeat center;
    background-size: contain; }
  .friend__status--busy {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: url("../../public/assets/images/lobby/friends/friend-satus-busy.png?15") no-repeat center;
    background-size: contain; }
  .friend__status--online {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: url("../../public/assets/images/lobby/friends/friend-satus-online.png?15") no-repeat center;
    background-size: contain; }
  .friend__status--me {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem; }
  .friend__name {
    position: absolute;
    left: 50%;
    top: 1.6rem;
    transform: translate(-50%);
    color: #8fdafa;
    font-size: 1.2rem;
    font-family: 'Janda Manatee Solid'; }
  .friend__image {
    position: absolute;
    height: 4.3rem;
    width: 4.3rem;
    margin-top: 3.9rem;
    margin-left: 1.8rem;
    border-radius: 17%;
    overflow: hidden; }
    .friend__image--box {
      z-index: 1000; }
      .friend__image--box:hover {
        position: fixed; }
  .friend__title {
    position: absolute;
    bottom: 1.3rem;
    left: 1.7rem;
    height: 3.1rem;
    width: 3.1rem; }
  .friend__points {
    text-align: center;
    font-family: 'Janda Manatee Solid';
    width: 3rem;
    color: #8fdafa;
    position: absolute;
    bottom: 1rem;
    right: 1.4rem; }
  .friend__tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    min-width: 25.7rem;
    height: 33.5rem;
    border-radius: 0.5rem;
    margin-top: -34rem;
    text-align: center;
    pointer-events: none; }
    .friend__tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
  .friend__tooltip-name {
    color: #f9d34a;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    letter-spacing: 0.07em;
    padding: 1rem;
    text-align: center;
    min-width: 13rem; }
  .friend__content {
    display: flex;
    margin-left: 4rem; }
  .friend__content-left {
    flex: 0 0 9rem;
    height: 4.7rem;
    background-size: 4.5rem 4.7rem; }
  .friend__content-right {
    flex: 1 1 100%; }
  .friend__egg-owl, .friend__rating {
    padding: 0.8rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.167;
    height: 2rem;
    margin-top: 0.3rem; }
  .friend__rating {
    background: url("../../public/assets/images/toolbar/progress-bar-star.png?15") no-repeat top right;
    background-size: contain;
    display: inline-block;
    padding-right: 3rem; }
  .friend__collected-feathers {
    padding: 1rem 2rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.567;
    text-align: center;
    background: url("../../public/assets/images/lobby/tournament/tournament-feather.png?15") no-repeat center left;
    background-size: 1.5rem;
    display: inline-block;
    height: 2rem;
    margin: 0 auto;
    padding-bottom: 3rem; }
  .friend__image--box .friend__tooltip {
    visibility: hidden;
    opacity: 0; }
  .friend__image--box:hover .friend__tooltip {
    visibility: visible;
    opacity: 0.95; }
  .friend__buttons {
    position: absolute;
    display: flex;
    bottom: 1.5rem;
    margin: 0 1.1rem;
    width: 7.2rem;
    height: 3.1rem; }
  .friend__button {
    display: inline-block;
    height: 3.1rem;
    cursor: pointer; }
    .friend__button--accept {
      width: 3.5rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: url("../../public/assets/images/lobby/friends/friend-button-accept.png?15") no-repeat center;
      background-size: contain; }
      .friend__button--accept:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        width: 3.5rem;
        background: url("../../public/assets/images/lobby/friends/friend-button-accept-over.png?15") no-repeat center;
        background-size: contain; }
    .friend__button--decline {
      width: 3.5rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: url("../../public/assets/images/lobby/friends/friend-buttons-decline.png?15") no-repeat center;
      background-size: contain; }
      .friend__button--decline:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        left: initial;
        width: 3.5rem;
        background: url("../../public/assets/images/lobby/friends/friend-buttons-decline-over.png?15") no-repeat center;
        background-size: contain; }
    .friend__button--call {
      width: 7.2rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: url("../../public/assets/images/lobby/friends/friend-button-call.png?15") no-repeat center;
      background-size: contain;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      color: #00254e;
      font-size: 1.2rem;
      padding-top: 0.8rem; }
      .friend__button--call:hover {
        background: url("../../public/assets/images/lobby/friends/friend-button-call-over.png?15") no-repeat center;
        background-size: contain; }
    .friend__button--cancel {
      width: 7.2rem;
      margin: 0.1rem;
      margin-top: -0.1rem;
      background: url("../../public/assets/images/lobby/friends/friend-button-cancel.png?15") no-repeat center;
      background-size: contain;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      color: #ffffff;
      font-size: 1.2rem;
      padding-top: 0.8rem; }
      .friend__button--cancel:hover {
        background: url("../../public/assets/images/lobby/friends/friend-button-cancel-over.png?15") no-repeat center;
        background-size: contain; }

.play-now {
  height: 9.2rem;
  width: 26.7rem;
  background: url("../../public/assets/images/lobby/play-now.png?15") no-repeat center;
  background-size: contain;
  position: relative;
  cursor: pointer; }
  .play-now__button {
    width: 26.7rem;
    height: 9.2rem; }
    .play-now__button:hover::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/lobby/play-now-over.png?15") no-repeat center;
      background-size: contain; }
  .play-now__text {
    background: url("../../public/assets/images/lobby/play-now-text.png?15") no-repeat center;
    background-size: contain;
    position: absolute;
    width: 19.7rem;
    height: 6.2rem;
    top: 1.5rem;
    left: 3.4rem;
    z-index: 2;
    object-fit: contain; }
  .play-now__number-of-players {
    position: absolute;
    text-decoration: none;
    color: #07254d;
    bottom: 1rem;
    left: 15rem;
    font-family: 'Janda Manatee Solid';
    z-index: 3;
    font-size: 1.8rem;
    display: inline-block;
    margin-left: 1.5rem; }

.buy-earn-tokens {
  display: flex; }
  .buy-earn-tokens__buy-tokens {
    background: url("../../public/assets/images/lobby/stickers-buy tokens.png?15") no-repeat center;
    background-size: contain;
    height: 11.7rem;
    width: 15.4rem;
    position: relative;
    margin: 0 2.6rem 0 5.8rem; }

.today-booster {
  margin: 2.5rem auto;
  font-family: 'Janda Manatee Solid';
  color: #ffffff;
  font-size: 2.9rem;
  text-align: center;
  height: 8.5rem;
  width: 21rem;
  display: flex; }
  .today-booster__container {
    display: flex;
    height: 4rem; }
  .today-booster__button-plus {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 2.5rem;
    margin-left: 13rem;
    background: url("../../public/assets/images/lobby/booster-add.png?15") no-repeat center;
    background-size: contain;
    position: absolute;
    text-decoration: none;
    color: #ffffff;
    z-index: 1; }
    .today-booster__button-plus:hover {
      background: url("../../public/assets/images/lobby/booster-add-over.png?15") no-repeat center;
      background-size: contain; }
  .today-booster__booster-image {
    width: 6rem;
    height: 6rem;
    background: url("../../public/assets/images/lobby/booster-flame-icon.png?15") no-repeat center;
    background-size: contain;
    z-index: 1; }
  .today-booster__booster-container {
    width: 11.8rem;
    height: 3.1rem;
    border-radius: 2rem;
    margin-left: -3rem;
    margin-top: 1.4rem;
    border: 0.3rem solid #092348;
    background-color: #1f457c;
    overflow: hidden; }
    .today-booster__booster-container--progress {
      height: 3.1rem;
      background-image: linear-gradient(to bottom, #fcca1d, #f39307, #ffa500); }
  .today-booster__text {
    height: 1.7rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    margin-top: -3rem;
    margin-left: -3.5rem;
    text-shadow: -1px 2px 0 #442c03, -1px 1px 0 #442c03, -1px 0px 0 #442c03, 0px -1px 0 #442c03, 1px 0px 0 #442c03; }
  .today-booster__tooltip--text {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    position: relative;
    width: max-content;
    margin-left: 3rem;
    margin-top: 2rem;
    font-size: 1.2rem; }
    .today-booster__tooltip--text:hover {
      visibility: visible;
      opacity: 0.95; }
  .today-booster__tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    width: 31.5rem;
    height: 18.9rem;
    margin-left: 30rem;
    margin-top: 27rem;
    z-index: 2000; }
    .today-booster__tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
    .today-booster__tooltip--title {
      margin: 0 auto;
      color: #f9d34a;
      font-family: 'Janda Manatee Solid';
      font-size: 1.7rem;
      margin-top: 0.5rem; }
      .today-booster__tooltip--title--white {
        font-size: 1.1rem;
        color: #ffffff; }
      .today-booster__tooltip--title--orange {
        font-size: 1.1rem;
        color: #f9d34a;
        margin-bottom: 1.5rem; }
      .today-booster__tooltip--title--green {
        font-size: 1.1rem;
        color: #92d11c; }
      .today-booster__tooltip--title--red {
        font-size: 1.1rem;
        color: #f1483b; }
    .today-booster__tooltip--container {
      display: flex;
      width: 10rem;
      margin: 0 auto; }
    .today-booster__tooltip--divider {
      height: 0.2rem;
      width: 29.3rem;
      background-color: #ffffff; }
    .today-booster__tooltip--image {
      position: absolute;
      width: 6rem;
      height: 6rem;
      margin-top: 10rem;
      margin-left: 3rem;
      background: url("../../public/assets/images/lobby/booster-icon.png?15") center no-repeat;
      background-size: contain;
      border: 0rem; }
    .today-booster__tooltip--plus-image {
      position: absolute;
      width: 2rem;
      height: 2rem;
      margin-top: 14.4rem;
      margin-left: 11.4rem;
      background: url("../../public/assets/images/lobby/booster-add.png?15") center no-repeat;
      background-size: contain;
      border: 0rem; }
  .today-booster__button-plus:hover .today-booster__tooltip--text,
  .today-booster__container:hover .today-booster__tooltip {
    visibility: visible;
    opacity: 0.95; }

.friends {
  position: absolute;
  display: flex;
  padding: 3.8rem 2.3rem 0.6rem 2.3rem;
  background-size: contain;
  height: 16rem;
  width: 70.8rem;
  bottom: 1rem;
  border-top: 0.4rem solid #4373bd;
  background: rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem; }
  .friends__arrow-left, .friends__arrow-right {
    height: 2.9rem;
    width: 2.9rem;
    position: absolute;
    top: 50%; }
  .friends__arrow-left {
    left: -1.5rem;
    background: url("../../public/assets/images/lobby/friends/friend-arrows-left.png?15") no-repeat center;
    background-size: contain; }
    .friends__arrow-left:hover::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/lobby/friends/friend-arrows-left-over.png?15") no-repeat center; }
  .friends__arrow-right {
    right: -1.5rem;
    background: url("../../public/assets/images/lobby/friends/pwfb-arrows-right.png?15") no-repeat center;
    background-size: contain; }
    .friends__arrow-right:hover::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/lobby/friends/pwfb-arrows-right-over.png?15") no-repeat center; }
  .friends__friends--background {
    position: absolute;
    margin-top: -3.5rem;
    display: flex;
    width: 65.3rem;
    overflow: hidden; }
  .friends__title {
    height: 4rem;
    width: 27rem;
    position: absolute;
    margin-top: -8rem;
    left: 0;
    font-size: 2.5rem;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    color: #679dda;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4373bd;
    font-family: 'Janda Manatee Solid';
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; }
  .friends__mode {
    height: 3rem;
    cursor: pointer;
    position: absolute;
    z-index: -1;
    width: 19rem;
    margin-top: -7.4rem;
    border-top: 0.2rem solid #000000;
    border-left: 0.2rem solid #000000;
    border-right: 0.2rem solid #000000;
    font-family: 'Janda Manatee Solid';
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    z-index: 3;
    font-size: 1.8rem;
    background: linear-gradient(#3d425b, #222243, #1b193b);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-size: 1.5rem;
    color: #679dda;
    display: flex;
    justify-content: center;
    align-items: center; }
    .friends__mode--all {
      right: 20rem; }
    .friends__mode--online {
      right: 0; }
  .friends__invite-friend {
    height: 14.5rem;
    width: 9.3rem;
    position: absolute;
    margin-left: 57rem;
    margin-top: 1.7rem;
    background: url("../../public/assets/images/lobby/friends/friend-invite-background.png?15") no-repeat center;
    background-size: contain; }
    .friends__invite-friend:hover {
      background: url("../../public/assets/images/lobby/friends/friend-invite-background-over.png?15") no-repeat center;
      background-size: contain; }

.b2b-countdown {
  height: 8rem;
  width: 75%;
  margin-top: 3rem;
  align-items: center;
  display: inline-block;
  margin-left: 5rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5rem; }
  .b2b-countdown__header {
    position: absolute;
    display: block;
    margin-top: -1.5rem;
    margin-left: 6rem;
    z-index: 2;
    color: #afd1fe;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2.5rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }
  .b2b-countdown__title {
    position: relative;
    display: block;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    color: #afd1fe;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 1.2rem;
    width: 16rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }
  .b2b-countdown__time {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    color: #fede04;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2.8rem;
    width: 16rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }

.b2b-game {
  position: relative;
  margin: 0rem;
  height: 13.5rem;
  width: 16rem;
  margin-top: 1rem;
  margin-left: 1rem;
  display: flex; }
  .b2b-game__time {
    position: absolute;
    top: 10.5rem;
    z-index: 2;
    width: 16rem;
    text-align: center; }
  .b2b-game__time-box {
    position: relative;
    color: #8fdafa;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 1.6rem;
    width: 10rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
    background-color: rgba(3, 33, 80, 0.8);
    border-radius: 1.5rem;
    text-align: center;
    left: 18%;
    margin-top: -0.5rem;
    padding: 0.2rem; }
  .b2b-game__time-clock {
    position: absolute;
    height: 2rem;
    width: 2rem;
    margin-top: -0.3rem;
    margin-left: 3.5rem;
    z-index: 1;
    background: url("../../public/assets/images/lobby/b2b/clock.png?15") no-repeat center;
    background-size: contain; }

.b2b-team {
  position: relative;
  margin: 0rem;
  height: 13.3rem;
  width: 7.8rem;
  margin-top: 1rem;
  border-radius: 2.7rem;
  background: url("../../public/assets/images/lobby/b2b/friend-card.png?15") no-repeat center;
  background-size: contain; }
  .b2b-team--red {
    margin-left: -0.6rem; }
  .b2b-team__header {
    position: absolute;
    top: -2rem;
    z-index: 2;
    color: #8fdafa;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 1.6rem;
    width: 16rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }
  .b2b-team__status--offline {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: url("../../public/assets/images/lobby/friends/friend-satus-offline.png?15") no-repeat center;
    background-size: contain; }
  .b2b-team__status--busy {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: url("../../public/assets/images/lobby/friends/friend-satus-busy.png?15") no-repeat center;
    background-size: contain; }
  .b2b-team__status--online {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem;
    background: url("../../public/assets/images/lobby/friends/friend-satus-online.png?15") no-repeat center;
    background-size: contain; }
  .b2b-team__status--me {
    height: 1.6rem;
    width: 1.6rem;
    top: 0%;
    margin-left: 7.5rem; }
  .b2b-team__name {
    position: absolute;
    left: 50%;
    top: 1.7rem;
    width: 6rem;
    height: 1.7rem;
    border-radius: 0.3rem;
    transform: translate(-50%);
    overflow: hidden;
    color: #ffffff;
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 1.1rem;
    font-family: 'Janda Manatee Solid';
    background: #082551;
    text-align: center;
    padding-left: 0.6rem;
    padding-right: 0.6rem; }
    .b2b-team__name--red {
      background: #f1483b; }
  .b2b-team__image {
    position: absolute;
    height: 4.3rem;
    width: 4.3rem;
    margin-top: 3.9rem;
    margin-left: 1.8rem;
    border-radius: 17%; }
    .b2b-team__image--box {
      z-index: 1000; }
  .b2b-team__image--box .b2b-team__tooltip {
    visibility: hidden;
    opacity: 0; }
  .b2b-team__image--box:hover .b2b-team__tooltip {
    visibility: visible;
    opacity: 0.95; }
  .b2b-team__tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    z-index: 1000;
    min-width: 18rem;
    max-width: 20rem;
    min-height: auto;
    max-height: 18rem;
    border-radius: 0.5rem;
    margin-top: 0rem;
    margin-left: 4rem;
    text-align: center;
    pointer-events: none; }
    .b2b-team__tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
  .b2b-team__tooltip-name {
    color: #f9d34a;
    font-family: 'Janda Manatee Solid';
    font-size: 1.5rem;
    letter-spacing: 0.07em;
    padding: 0.5rem;
    text-align: center;
    min-width: 10rem;
    max-width: 16rem;
    overflow: hidden; }
  .b2b-team__players {
    position: relative;
    font-family: 'Janda Manatee Solid';
    height: 1.8rem;
    white-space: nowrap;
    overflow: hidden;
    color: white;
    flex-direction: column-reverse;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: left; }
  .b2b-team__title {
    position: absolute;
    bottom: 1.3rem;
    left: 1.7rem;
    height: 3.1rem;
    width: 3.1rem; }
  .b2b-team__points {
    text-align: center;
    font-family: 'Janda Manatee Solid';
    width: 3rem;
    color: #8fdafa;
    position: absolute;
    bottom: 1rem;
    right: 1.4rem; }
  .b2b-team__content {
    display: flex;
    margin-left: 4rem; }
  .b2b-team__content-left {
    flex: 0 0 9rem;
    height: 4.7rem;
    background-size: 4.5rem 4.7rem; }
  .b2b-team__content-right {
    flex: 1 1 100%; }
  .b2b-team__result {
    position: absolute;
    top: 9rem;
    z-index: 2;
    width: 8rem;
    text-align: center;
    display: flex; }
  .b2b-team__result-box {
    position: relative;
    color: #8fdafa;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2rem;
    width: 5rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
    left: 16%; }
    .b2b-team__result-box--dot {
      width: 1rem;
      margin-left: 0.8rem; }

.weekly-tournament {
  height: 30.5rem;
  width: 37.5rem;
  margin: 0rem 5rem 0 -1rem;
  background: rgba(0, 0, 0, 0.3);
  font-family: 'Janda Manatee Solid';
  color: #ffffff;
  position: absolute;
  border-radius: 1rem; }
  .weekly-tournament__header {
    width: 100%;
    background-color: #8ebbfe;
    height: 3rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    color: #afd1fe;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }
  .weekly-tournament__container {
    height: 24.5rem;
    overflow-y: scroll; }
  .weekly-tournament__footer {
    width: 100%;
    background-color: #4373bd;
    height: 3rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    bottom: 0;
    position: absolute; }
  .weekly-tournament__help {
    height: 2.8rem;
    width: 2.6rem;
    margin-top: -0.3rem;
    margin-left: 17.6rem;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background: url("../../public/assets/images/lobby/tournament/tournament-help.png?15") no-repeat center;
    background-size: contain; }
    .weekly-tournament__help:hover {
      background: url("../../public/assets/images/lobby/tournament/tournament-help-over.png?15") no-repeat center;
      background-size: contain; }
  .weekly-tournament--image {
    position: absolute;
    height: 16rem;
    width: 16rem;
    left: 8rem;
    top: 7rem;
    opacity: 0.1;
    pointer-events: none; }
  .weekly-tournament__mode {
    height: 3.5rem;
    width: 7.1rem;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    margin-top: -0.9rem;
    background-size: contain; }
    .weekly-tournament__mode__friends {
      margin-left: 22.1rem; }
      .weekly-tournament__mode__friends--shown {
        background: url("../../public/assets/images/lobby/tournament/tournament-tab.png?15") no-repeat center; }
      .weekly-tournament__mode__friends--hidden {
        background: 0; }
    .weekly-tournament__mode__titles {
      margin-left: 28.5rem; }
      .weekly-tournament__mode__titles--shown {
        background: url("../../public/assets/images/lobby/tournament/tournament-tab.png?15") no-repeat center; }
      .weekly-tournament__mode__titles--hidden {
        background: 0; }
    .weekly-tournament__mode__image {
      height: 2.8rem;
      width: 2.6rem;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      margin-left: 2.1rem;
      margin-top: 0.5rem; }
      .weekly-tournament__mode__image--friends {
        background: url("../../public/assets/images/lobby/friends/invite/friends.png?15") no-repeat center;
        background-size: contain; }
      .weekly-tournament__mode__image--titles {
        background-size: contain; }
  .weekly-tournament__left--time {
    height: 1.3rem;
    width: 33.5rem;
    color: #11203e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    margin-top: 27.7rem;
    margin-left: 1.5rem;
    position: absolute;
    text-align: center; }
  .weekly-tournament__tooltip--title {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    margin-top: 2.5rem;
    width: max-content; }
    .weekly-tournament__tooltip--title:hover {
      visibility: visible;
      opacity: 0.95; }
    .weekly-tournament__tooltip--title__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem; }
  .weekly-tournament__week-day:hover .weekly-tournament__tooltip--title,
  .weekly-tournament__daily-highscore-content-right:hover .weekly-tournament__feather-tooltip,
  .weekly-tournament__box:hover .weekly-tournament__tooltip--daily,
  .weekly-tournament__help:hover .weekly-tournament__tooltip--title {
    visibility: visible;
    opacity: 0.95; }
  .weekly-tournament__tooltip--daily {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    margin-top: 5rem;
    margin-left: 5rem;
    width: max-content; }
    .weekly-tournament__tooltip--daily:hover {
      visibility: visible;
      opacity: 0.95; }
    .weekly-tournament__tooltip--daily__text {
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem; }
  .weekly-tournament__feather-tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    margin-top: 2.5rem;
    width: max-content;
    margin-left: 19rem;
    margin-top: 8rem; }
    .weekly-tournament__feather-tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
  .weekly-tournament__row {
    display: flex;
    height: 2.45rem; }
    .weekly-tournament__row--active {
      margin-top: 0.3rem;
      background: url("../../public/assets/images/lobby/tournament/my-position-background.png?15") no-repeat center;
      background-size: cover; }
  .weekly-tournament__open-info-bar {
    display: flex;
    height: 2.5rem;
    background-color: #17294f;
    opacity: 0.2; }
  .weekly-tournament__my-row {
    position: relative;
    top: -0.3rem;
    padding-top: 0.1rem; }
  .weekly-tournament__my-row-box {
    height: 25rem;
    width: 33.5rem;
    position: absolute;
    top: -0.3rem;
    z-index: -100;
    padding-top: 0.1rem; }
    .weekly-tournament__my-row-box--active {
      z-index: 1000; }
    .weekly-tournament__my-row-box--cover {
      z-index: 1000;
      margin-top: 0.2rem;
      background-color: rgba(2, 10, 41, 0.9); }
  .weekly-tournament__award--1 {
    width: 1.9rem;
    margin: 0.3rem;
    background: url("../../public/assets/images/lobby/tournament/tournament-award-1.png?15") no-repeat center;
    background-size: contain;
    flex-grow: 0; }
  .weekly-tournament__award--2 {
    width: 1.8rem;
    margin: 0.35rem;
    margin-top: 0.35rem;
    background: url("../../public/assets/images/lobby/tournament/tournament-award-2.png?15") no-repeat center;
    background-size: contain;
    flex-grow: 0; }
  .weekly-tournament__award--3 {
    width: 1.5rem;
    margin: 0.5rem;
    margin-top: 0.5rem;
    background: url("../../public/assets/images/lobby/tournament/tournament-award-3.png?15") no-repeat center;
    background-size: contain;
    flex-grow: 0; }
  .weekly-tournament__award--4 {
    width: 1.4rem;
    margin: 0.55rem;
    margin-top: 0.55rem;
    background: url("../../public/assets/images/lobby/tournament/tournament-award-4.png?15") no-repeat center;
    background-size: contain;
    flex-grow: 0; }
  .weekly-tournament__award--5 {
    width: 1.3rem;
    margin: 0.6rem;
    margin-top: 0.6rem;
    background: url("../../public/assets/images/lobby/tournament/tournament-award-5.png?15") no-repeat center;
    background-size: contain;
    flex-grow: 0; }
  .weekly-tournament__award {
    width: 1.9rem;
    margin: 0.3rem;
    flex-grow: 0; }
    .weekly-tournament__award--active {
      visibility: hidden; }
  .weekly-tournament__item-number {
    font-size: 1.2rem;
    text-align: center;
    margin: 0.5rem;
    width: 1.9rem;
    flex-grow: 0;
    color: #6eacce; }
    .weekly-tournament__item-number--expanded {
      color: #ffffff; }
  .weekly-tournament__name {
    font-size: 1.2rem;
    text-align: left;
    margin: 0.5rem;
    color: #6eacce;
    flex-grow: 1;
    width: 5rem; }
    .weekly-tournament__name--expanded {
      color: #ffffff; }
  .weekly-tournament__expand-button {
    height: 0.9rem;
    width: 1.1rem;
    margin: 0.8rem 3.3rem 0.8rem;
    cursor: pointer;
    background: url("../../public/assets/images/lobby/tournament/expand-arow.png?15") no-repeat center;
    background-size: contain; }
  .weekly-tournament__item-score {
    font-size: 1.2rem;
    margin: 0.5rem;
    flex-grow: 0;
    color: #6eacce; }
    .weekly-tournament__item-score--expanded {
      color: #ffffff; }
  .weekly-tournament__item-feather {
    background: url("../../public/assets/images/lobby/tournament/tournament-feather.png?15") no-repeat center;
    background-size: contain;
    font-size: 1.2rem;
    height: 1.7rem;
    width: 2.5rem;
    margin: 0.3rem 1rem;
    flex-grow: 0; }
    .weekly-tournament__item-feather--active {
      visibility: hidden; }
  .weekly-tournament__item-box-active {
    margin-top: 0.5rem;
    z-index: 10000;
    position: relative; }
  .weekly-tournament__daily-highscore {
    position: absolute;
    display: flex;
    left: 0;
    transition: all 1s;
    z-index: 10;
    height: 7.8rem;
    width: 33.5rem;
    background: url("../../public/assets/images/lobby/tournament/daily-higescore-background.png.png?15") no-repeat center;
    background-size: contain; }
  .weekly-tournament__daily-highscore-content-left {
    flex: 1 1 100%; }
  .weekly-tournament__daily-highscore-content-right {
    text-align: center;
    flex: 0 0 9.4rem; }
  .weekly-tournament__daily-highscore-title {
    color: #3c6bb3;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    display: inline-block;
    margin: 2rem 1rem 0.3rem 5rem; }
  .weekly-tournament__daily-highscore-feathers-title {
    padding: 2rem 2rem 0.3rem 0rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    display: inline-block; }
  .weekly-tournament__daily-highscore-feathers-image {
    margin-left: -2rem;
    margin-bottom: -0.5rem;
    padding: 2rem 2rem 0.3rem 0rem;
    background: url("../../public/assets/images/lobby/tournament/tournament-feather.png?15") no-repeat center right;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    display: inline-block; }
  .weekly-tournament__week-days {
    display: flex;
    padding: 0rem 0 1rem 1.2rem;
    position: absolute;
    margin-top: 4.3rem; }
  .weekly-tournament__week-day {
    position: relative;
    text-align: center;
    padding-top: 0.3rem;
    height: 2.4rem;
    width: 2.7rem;
    margin-right: 0.3rem;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.07em;
    color: rgba(131, 171, 240, 0.45);
    background: url("../../public/assets/images/lobby/tournament/day-button.png?15") no-repeat center right;
    background-size: contain; }
    .weekly-tournament__week-day:not(.weekly-tournament__week-day--selected) {
      cursor: pointer; }
    .weekly-tournament__week-day:hover:not(.weekly-tournament__week-day--selected) {
      background: none; }
      .weekly-tournament__week-day:hover:not(.weekly-tournament__week-day--selected)::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/lobby/tournament/day-button-over.png?15") no-repeat center right;
        background-size: contain; }
    .weekly-tournament__week-day--selected {
      background: url("../../public/assets/images/lobby/tournament/day-button-selected.png?15") no-repeat center right;
      background-size: contain; }
  .weekly-tournament__total-feathers {
    display: block;
    text-align: center;
    margin: 1rem 1rem 0rem 1rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 2.4rem;
    letter-spacing: 0.04em;
    background: url("../../public/assets/images/lobby/tournament/tournament-feather.png?15") no-repeat center right; }
  .weekly-tournament__total-title {
    display: inline-block;
    color: #83abf0;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.07em; }
  .weekly-tournament__award-tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    position: relative;
    height: 12.8rem;
    width: 28.6rem;
    background-color: #000000;
    box-shadow: 0 0 0.5rem NaNpx rgba(0, 0, 0, 0.63);
    pointer-events: none;
    left: 3rem;
    top: 3rem; }
    .weekly-tournament__award-tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
  .weekly-tournament__award-tooltip-title {
    height: 1.5rem;
    color: #f9d34a;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    line-height: 1.4rem;
    text-align: center;
    margin-top: 1.5rem; }
  .weekly-tournament__award-icon-count {
    float: left; }
  .weekly-tournament__award-content {
    display: table;
    margin: 0 auto;
    margin-top: 1.5rem; }
  .weekly-tournament__award-tooltip-icon {
    height: 2.3rem;
    width: 2.3rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem; }
  .weekly-tournament__award-tooltip-count {
    height: 1.2rem;
    width: 1.7rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-left: 2rem;
    margin-right: 2 rem; }
  .weekly-tournament__award--1:hover .weekly-tournament__award-tooltip,
  .weekly-tournament__award--2:hover .weekly-tournament__award-tooltip,
  .weekly-tournament__award--3:hover .weekly-tournament__award-tooltip,
  .weekly-tournament__award--4:hover .weekly-tournament__award-tooltip,
  .weekly-tournament__award--5:hover .weekly-tournament__award-tooltip {
    visibility: visible;
    opacity: 0.95; }
  .weekly-tournament__tooltip-tournament {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    position: relative;
    top: 0;
    width: 25.7rem;
    height: 32rem;
    border-radius: 0.5rem;
    text-align: center;
    pointer-events: none; }
    .weekly-tournament__tooltip-tournament:hover {
      visibility: visible;
      opacity: 0.95; }
  .weekly-tournament__total-feathers-tooltip {
    margin-left: 19rem;
    margin-top: 8rem; }
  .weekly-tournament__tooltip-name {
    color: #d49e1b;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    letter-spacing: 0.1em;
    padding: 1rem;
    text-align: center;
    min-width: 13rem; }
    .weekly-tournament__tooltip-name--small {
      font-size: 1.1rem;
      margin-top: -2rem;
      color: #d49e1b; }
    .weekly-tournament__tooltip-name--green {
      color: #b3e919; }
  .weekly-tournament__content {
    display: flex;
    margin-left: 4rem; }
  .weekly-tournament__content-left {
    flex: 0 0 9rem;
    height: 4.7rem;
    background-size: 4.5rem 4.7rem; }
    .weekly-tournament__content-left--small {
      height: 3.2rem;
      margin-left: 1rem; }
  .weekly-tournament__content-right {
    flex: 1 1 100%;
    margin-top: -0.75rem;
    margin-left: -3rem; }
    .weekly-tournament__content-right--small {
      margin-top: 0rem; }
  .weekly-tournament__won-box {
    position: absolute;
    height: 4.8rem;
    display: flex;
    flex-direction: column-reverse; }
    .weekly-tournament__won-box--game {
      height: 1rem;
      width: 2rem;
      background-color: #09254b;
      margin-top: 0.2rem; }
  .weekly-tournament__egg-owl {
    margin-top: 0.3rem;
    padding: 0.8rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    text-align: center;
    line-height: 1.167;
    height: 2rem; }
  .weekly-tournament__rating {
    padding: 0.8rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 0.04em;
    line-height: 1.167;
    margin-left: 2rem;
    height: 2rem;
    margin-top: 0.3rem; }
  .weekly-tournament__rating {
    display: inline-block;
    padding-right: 3rem; }
  .weekly-tournament__collected-feathers {
    padding: 2rem 2rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.167;
    text-align: center;
    background: url("../../public/assets/images/lobby/tournament/tournament-feather.png?15") no-repeat center left;
    display: inline-block;
    margin: 0 auto; }
  .weekly-tournament__image {
    position: absolute;
    height: 5.3rem;
    width: 5.3rem;
    border-radius: 2.5rem;
    margin-left: 1rem;
    background-color: #082551; }
    .weekly-tournament__image--text {
      height: 12.3rem;
      width: 33rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-left: 5.1rem;
      margin-top: 3.3rem;
      position: absolute;
      height: 1.8rem;
      width: 5rem;
      font-size: 2.1rem;
      margin-left: 0.3rem;
      margin-top: 2rem;
      text-align: center;
      letter-spacing: 0.1rem;
      line-height: 1.6rem; }
  .weekly-tournament__name:hover .weekly-tournament__tooltip-tournament {
    visibility: visible;
    opacity: 0.95; }
  .weekly-tournament__box {
    position: absolute;
    visibility: hidden; }
  .weekly-tournament__box-fade-out-in {
    position: absolute;
    animation-iteration-count: 1;
    animation: fade-out-in 5000ms;
    -webkit-animation: fade-out-in 5000ms; }
  .weekly-tournament__box-fade-out {
    opacity: 0;
    animation: fade 10000ms infinite;
    -webkit-animation: fade 10000ms infinite; }
  .weekly-tournament__box-fade-in {
    opacity: 0;
    animation: fade 10000ms 5000ms infinite;
    -webkit-animation: fade 10000ms 5000ms infinite; }
  .weekly-tournament__dropdown {
    display: flex;
    animation: fade-box 500ms linear forwards;
    -webkit-animation: fade-box 500ms linear forwards; }

@keyframes fade-box {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3rem); }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); } }

@-webkit-keyframes fade-box {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3rem); }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); } }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade-out-in {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade-out-in {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.b2b-ranklist {
  height: 30.5rem;
  width: 33.5rem;
  margin: -1rem 5rem 0 1.5rem;
  background-size: contain;
  font-family: 'Janda Manatee Solid';
  color: #ffffff;
  padding-top: 3.8rem;
  position: absolute; }
  .b2b-ranklist__row {
    display: flex;
    height: 2.45rem;
    width: 100%; }
    .b2b-ranklist__row--header {
      margin-top: 0rem;
      background: url("../../public/assets/images/lobby/tournament/my-position-background.png?15") no-repeat center;
      background-size: cover; }
    .b2b-ranklist__row--odd {
      background: rgba(0, 0, 0, 0.3); }
    .b2b-ranklist__row--even {
      background: rgba(0, 0, 0, 0.1); }
  .b2b-ranklist__my-row {
    position: relative;
    top: -0.3rem;
    padding-top: 0.1rem; }
  .b2b-ranklist__my-row-box {
    height: 25rem;
    width: 33.5rem;
    position: absolute;
    top: -0.3rem;
    z-index: -100;
    padding-top: 0.1rem; }
    .b2b-ranklist__my-row-box--active {
      z-index: 1000; }
    .b2b-ranklist__my-row-box--cover {
      z-index: 1000;
      margin-top: 0.2rem;
      background-color: rgba(2, 10, 41, 0.9); }
  .b2b-ranklist__item-number {
    font-size: 1.2rem;
    text-align: center;
    margin: 0.5rem;
    width: 1.9rem;
    flex-grow: 0;
    color: #6eacce; }
    .b2b-ranklist__item-number--expanded {
      color: #ffffff; }
  .b2b-ranklist__name {
    font-size: 1.2rem;
    text-align: left;
    margin: 0.5rem;
    color: #6eacce;
    flex-grow: 1;
    width: 5rem;
    display: flex;
    align-items: center; }
    .b2b-ranklist__name--expanded {
      color: #ffffff; }
  .b2b-ranklist__name .b2b-team__tooltip {
    visibility: hidden;
    opacity: 0; }
  .b2b-ranklist__name:hover .b2b-team__tooltip {
    visibility: visible;
    opacity: 0.95; }
  .b2b-ranklist__item-score-container {
    width: 10rem;
    display: flex;
    align-items: flex-end; }
    .b2b-ranklist__item-score-container--odd {
      background: rgba(0, 0, 0, 0.4); }
    .b2b-ranklist__item-score-container--even {
      background: rgba(0, 0, 0, 0.2); }
  .b2b-ranklist__item-score {
    font-size: 1.2rem;
    margin: 0.5rem;
    flex-grow: 0;
    min-width: 3.9rem;
    text-align: right;
    color: #6eacce; }
    .b2b-ranklist__item-score--expanded {
      color: #ffffff; }
  .b2b-ranklist__item-box-active {
    margin-top: 0.5rem;
    z-index: 10000;
    position: relative; }
  .b2b-ranklist__content {
    display: flex;
    margin-left: 4rem; }
  .b2b-ranklist__box {
    position: absolute;
    visibility: hidden; }
  .b2b-ranklist__box-fade-out-in {
    position: absolute;
    animation-iteration-count: 1;
    animation: fade-out-in 5000ms;
    -webkit-animation: fade-out-in 5000ms; }
  .b2b-ranklist__box-fade-out {
    opacity: 0;
    animation: fade 10000ms infinite;
    -webkit-animation: fade 10000ms infinite; }
  .b2b-ranklist__box-fade-in {
    opacity: 0;
    animation: fade 10000ms 5000ms infinite;
    -webkit-animation: fade 10000ms 5000ms infinite; }
  .b2b-ranklist__dropdown {
    display: flex;
    animation: fade-box 500ms linear forwards;
    -webkit-animation: fade-box 500ms linear forwards; }

@keyframes fade-box {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3rem); }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); } }

@-webkit-keyframes fade-box {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3rem); }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); } }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade-out-in {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade-out-in {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.aparteko-logo-loader {
  background: #010107;
  height: 70rem;
  width: 76.2rem;
  position: absolute;
  z-index: 1000;
  background-size: contain;
  top: 0;
  left: 0; }
  .aparteko-logo-loader__logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001; }
  .aparteko-logo-loader__loading-circle {
    background: radial-gradient(circle closest-side, #3a6dbe, #010107);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 30rem;
    width: 30rem;
    color: #ffffff;
    font-size: 2rem;
    opacity: 1; }
    .aparteko-logo-loader__loading-circle--active {
      animation: changeCircle 2s ease-in-out both; }

@keyframes changeCircle {
  0% {
    height: 30rem;
    width: 30rem; }
  100% {
    height: 55rem;
    width: 55rem; } }

.aparteko-progress-loader {
  background: url("../../public/assets/images/loading/loading-background.png?15") center no-repeat;
  height: 70rem;
  width: 76rem;
  position: absolute;
  background-size: contain;
  z-index: 500;
  top: 0;
  left: 0;
  animation: show ease-in 0.3s;
  transition: hide ease-out 0.4s;
  border-bottom-left-radius: 1.8rem;
  border-bottom-right-radius: 1.8rem; }
  .aparteko-progress-loader__loading-progress-frame {
    height: 7.2rem;
    width: 31.8rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.2rem;
    background: url("../../public/assets/images/loading/loading-progress-frame.png?15") center no-repeat;
    background-size: contain; }
  .aparteko-progress-loader__loading-progress-background {
    height: 1.8rem;
    width: 30.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 45%;
    background: url("../../public/assets/images/loading/loading-progress-background.png?15") center;
    background-size: contain;
    background-size: contain;
    border-radius: 0.25rem; }
  .aparteko-progress-loader__loading-progress-indicator {
    height: 1.8rem;
    width: 0.5rem;
    transition: all 1s linear;
    background: url("../../public/assets/images/loading/loading-progress-line.png?15") center;
    background-size: contain;
    border-radius: 0.25rem; }

@keyframes hideProgress {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes show {
  0% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@keyframes hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0.4; } }

.daily-rewards {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .daily-rewards__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 45.6rem;
    width: 68.6rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat; }
  .daily-rewards__description {
    height: 2.3rem;
    width: 68.6rem;
    text-align: center;
    color: #fef6a5;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    margin: 4rem auto; }
  .daily-rewards__accept {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 2.1rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem; }
    .daily-rewards__accept:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }
  .daily-rewards__accept_disabled {
    box-sizing: border-box;
    padding-top: 0.2rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    align-items: center;
    border-radius: 0.5rem;
    border: #07254d 0.3rem solid;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    color: #07254d;
    background-color: #6b90ad;
    display: block;
    margin: 0 auto;
    margin-top: 2.1rem;
    box-sizing: border-box;
    height: 4.525rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem; }
    .daily-rewards__accept_disabled:disabled {
      cursor: default; }

.dialog-header {
  position: relative;
  display: flex; }
  .dialog-header__left {
    height: 3.8rem;
    width: 13.6rem;
    background: url("../../public/assets/images/dialogs/shop/dialog-header-left.png?15") center no-repeat;
    background-size: 13.6rem 3.8rem;
    border-top-left-radius: 1rem; }
  .dialog-header__middle {
    text-align: center;
    font-family: 'Janda Manatee Solid';
    color: #00254e;
    font-size: 2rem;
    width: 70rem;
    height: 3.8rem;
    padding-top: 0.5rem;
    background: url("../../public/assets/images/dialogs/shop/dialog-header-middle.png?15") center no-repeat;
    background-size: 70rem 3.8rem; }
  .dialog-header__right {
    height: 3.8rem;
    width: 13.6rem;
    background: url("../../public/assets/images/dialogs/shop/dialog-header-right.png?15") center no-repeat;
    background-size: 13.6rem 3.8rem;
    border-top-right-radius: 1rem; }
  .dialog-header__important {
    background: #ffdb00;
    background-color: #ffdb00;
    border-radius: 0.5rem 0.5rem 0 0; }
  .dialog-header__close {
    height: 3.2rem;
    width: 3.3rem;
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    background: url("../../public/assets/images/dialogs/dialog-close-button.png?15") center no-repeat;
    background-size: contain; }
    .dialog-header__close:hover::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../public/assets/images/dialogs/dialog-close-button-over.png?15") center no-repeat;
      background-size: contain; }

.daily-rewards-cards {
  height: 21.3rem;
  width: 63rem;
  background: url("../../public/assets/images/dialogs/daily-tokens/cards-holder.png?15") center no-repeat;
  background-size: contain;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1.6rem 2.6rem;
  margin: 0 auto; }

.daily-rewards-card {
  height: 16.1rem;
  width: 10.9rem;
  margin: 0.8rem 0.6rem;
  border-radius: 0.3rem;
  box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25);
  flex: 0 0 10.9rem; }
  .daily-rewards-card--previous {
    box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25);
    background: #bbdaff; }
  .daily-rewards-card--today {
    background: #fff5a4;
    box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25); }
  .daily-rewards-card--next {
    background: #fff5a4;
    box-shadow: 0.4rem 0.4rem 0.1rem 0.1rem rgba(3, 33, 80, 0.25); }
  .daily-rewards-card__top {
    height: 11.8rem;
    width: 10.3rem;
    margin: 0.3rem; }
    .daily-rewards-card__top--previous {
      background: url("../../public/assets/images/dialogs/daily-tokens/pevious-day-background-top.png?15") center no-repeat;
      background-size: contain; }
    .daily-rewards-card__top--today {
      background: url("../../public/assets/images/dialogs/daily-tokens/next-day-background-top.png?15") center no-repeat; }
    .daily-rewards-card__top--next {
      background: url("../../public/assets/images/dialogs/daily-tokens/next-day-background-top.png?15") center no-repeat;
      background-size: contain; }
  .daily-rewards-card__tokens {
    height: 8.6rem; }
    .daily-rewards-card__tokens--day1 {
      background: url("../../public/assets/images/dialogs/daily-tokens/silver-tokens-1.png?15") center no-repeat;
      background-size: contain; }
    .daily-rewards-card__tokens--day2 {
      background: url("../../public/assets/images/dialogs/daily-tokens/silver-tokens-2.png?15") center no-repeat;
      background-size: contain; }
    .daily-rewards-card__tokens--day3 {
      background: url("../../public/assets/images/dialogs/daily-tokens/silver-tokens-3.png?15") center no-repeat;
      background-size: contain; }
    .daily-rewards-card__tokens--day4 {
      background: url("../../public/assets/images/dialogs/daily-tokens/silver-tokens-4.png?15") center no-repeat;
      background-size: contain; }
    .daily-rewards-card__tokens--day5 {
      background: url("../../public/assets/images/dialogs/daily-tokens/silver-tokens-5.png?15") center no-repeat;
      background-size: contain; }
  .daily-rewards-card__tokens-multiply {
    height: 2.2rem;
    width: 8.4rem;
    margin: 0.5rem auto;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    position: relative;
    text-align: center;
    background: url("../../public/assets/images/dialogs/daily-tokens/pevious-day-token-holder.png?15") center no-repeat;
    background-size: contain; }
    .daily-rewards-card__tokens-multiply--today-or-next {
      background: url("../../public/assets/images/dialogs/daily-tokens/today-token-holder.png?15") center no-repeat;
      background-size: contain; }
  .daily-rewards-card__icon-token {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 1.7rem;
    width: 1.7rem;
    background: url("../../public/assets/images/dialogs/daily-tokens/silver-token-icon.png?15") center no-repeat;
    background-size: cover;
    margin: 0.3rem; }
  .daily-rewards-card__status {
    margin: 0 auto;
    height: 3.9rem;
    width: 4.4rem; }
    .daily-rewards-card__status--done {
      background: url("../../public/assets/images/dialogs/daily-tokens/pevious-day-done.png?15") center no-repeat;
      background-size: contain; }
  .daily-rewards-card__status-today {
    height: 4rem;
    text-align: center;
    color: #f9d349;
    font-family: 'Janda Manatee Solid';
    font-size: 2.6rem;
    background: url("../../public/assets/images/dialogs/daily-tokens/today.png?15") center no-repeat;
    background-size: contain; }
    .daily-rewards-card__status-today--frame-box {
      overflow: hidden;
      width: 5.4rem;
      height: 5.9rem;
      margin-top: -6rem;
      margin-left: 2rem; }
    .daily-rewards-card__status-today--frame {
      opacity: 0;
      height: 8rem;
      background: url("../../public/assets/images/dialogs/daily-tokens/pevious-day-done-frame.png?15") center no-repeat;
      background-size: contain;
      width: 65.8rem; }
  .daily-rewards-card__status-next {
    height: 4rem;
    padding-top: 0.8rem;
    color: #a15a0c;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    text-align: center; }

.tournament-result {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .tournament-result__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 57.4rem;
    width: 51.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .tournament-result__description {
    height: 1.8rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    line-height: 1.4rem;
    text-align: center;
    margin-top: 3rem; }
  .tournament-result__trone {
    margin-left: 3.5rem;
    height: 26.5rem;
    width: 43.4rem;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 1.6rem;
    background: url("../../public/assets/images/dialogs/tournament-result/trone.png?15") center no-repeat;
    background-size: contain; }
    .tournament-result__trone--color-feathers {
      height: 26.5rem;
      width: 43.4rem;
      background: url("../../public/assets/images/dialogs/tournament-result/color-feathers-background.png?15") center no-repeat;
      background-size: contain; }
    .tournament-result__trone--feather {
      height: 1.2rem;
      width: 0.8rem;
      position: absolute;
      margin-top: 7.5rem;
      margin-left: 8rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem; }
      .tournament-result__trone--feather--3 {
        margin-top: 5.5rem;
        margin-left: 16rem; }
      .tournament-result__trone--feather--2 {
        margin-top: 3.5rem;
        margin-left: 24rem; }
      .tournament-result__trone--feather--1 {
        margin-top: 1.5rem;
        margin-left: 32.5rem; }
    .tournament-result__trone--icon-feather {
      background: url("../../public/assets/images/lobby/tournament/tournament-feather.png?15") no-repeat center;
      background-size: contain;
      position: absolute;
      font-size: 1.2rem;
      height: 1.7rem;
      width: 2.5rem;
      margin-top: 7.5rem;
      margin-left: 9.5rem; }
      .tournament-result__trone--icon-feather--3 {
        margin-top: 5.5rem;
        margin-left: 17.5rem; }
      .tournament-result__trone--icon-feather--2 {
        margin-top: 3.5rem;
        margin-left: 25.5rem; }
      .tournament-result__trone--icon-feather--1 {
        margin-top: 1.5rem;
        margin-left: 34rem; }
    .tournament-result__trone--profile-picture {
      height: 8.5rem;
      width: 6.8rem;
      position: absolute;
      margin-top: 9.5rem;
      margin-left: 6rem;
      background: url("../../public/assets/images/dialogs/tournament-result/profile-picture-frame.png?15") center no-repeat;
      background-size: contain; }
      .tournament-result__trone--profile-picture--3 {
        margin-top: 7.5rem;
        margin-left: 14rem; }
      .tournament-result__trone--profile-picture--2 {
        margin-top: 5.5rem;
        margin-left: 22rem; }
      .tournament-result__trone--profile-picture--1 {
        margin-top: 3.5rem;
        margin-left: 30.5rem; }
    .tournament-result__trone--profile-image {
      position: absolute;
      height: 6rem;
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      border-radius: 10%; }
    .tournament-result__trone--profile-name {
      position: absolute;
      height: 1.4rem;
      margin-top: 6.7rem;
      width: 6.8rem;
      color: #8d1313;
      overflow: hidden;
      text-align: center;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem; }
    .tournament-result__trone--order {
      height: 1.6rem;
      width: 1.6rem;
      position: absolute;
      margin-top: 20.7rem;
      margin-left: 9rem;
      color: #f6dd5f;
      font-family: 'Janda Manatee Solid';
      font-size: 2rem; }
      .tournament-result__trone--order--3 {
        margin-left: 16.5rem;
        margin-top: 19.5rem;
        font-size: 3rem; }
      .tournament-result__trone--order--2 {
        margin-left: 24.5rem;
        margin-top: 18.5rem;
        font-size: 4rem; }
      .tournament-result__trone--order--1 {
        margin-left: 33rem;
        margin-top: 17.5rem;
        font-size: 5rem; }
  .tournament-result__rewarded {
    height: 9.2rem;
    width: 26.7rem;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color: #1e436f; }
    .tournament-result__rewarded--open-gift-box {
      height: 5.9rem;
      width: 7.1rem;
      margin: 1.5rem 1.1rem 1.8rem 1.1rem;
      position: absolute;
      background: url("../../public/assets/images/dialogs/tournament-result/opened-giftbox.png?15") center no-repeat;
      background-size: contain; }
    .tournament-result__rewarded--award-icon-count {
      float: left; }
    .tournament-result__rewarded--award-content {
      display: table;
      width: 18rem;
      margin: 0 auto;
      margin-top: 1.5rem;
      position: absolute;
      margin-left: 10rem; }
    .tournament-result__rewarded--award-tooltip-icon {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 1rem;
      margin-left: 1.25rem; }
      .tournament-result__rewarded--award-tooltip-icon--fly {
        margin-left: -2.8rem;
        position: absolute;
        opacity: 1; }
    .tournament-result__rewarded--award-tooltip-count {
      height: 1.2rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      line-height: 2.9rem;
      margin-left: 1.3rem;
      text-align: center; }
  .tournament-result__close {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0rem auto; }
    .tournament-result__close:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }
  .tournament-result__close_disabled {
    box-sizing: border-box;
    padding-top: 0.2rem;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    align-items: center;
    border-radius: 0.5rem;
    border: #07254d 0.3rem solid;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    color: #07254d;
    background-color: #6b90ad;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    display: block;
    margin: 0rem auto; }
    .tournament-result__close_disabled:disabled {
      cursor: default; }

.search-opponent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .search-opponent__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 56.8rem;
    height: 44rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat; }
  .search-opponent__content {
    display: flex;
    padding: 2rem 0 0 0;
    justify-content: center; }
  .search-opponent__central-content {
    width: 13rem;
    margin-top: 2.2rem; }
  .search-opponent__matching-arrows {
    text-align: center;
    padding-top: 3.4rem;
    margin: 5rem 1.2rem;
    flex: 0 0 9.5rem;
    height: 10.1rem;
    background: url("../../public/assets/images/lobby/matching-players/matching-dialog-arrows.png?15") center no-repeat;
    background-size: contain;
    transform-origin: center; }
  .search-opponent__matching-vs {
    text-align: center;
    padding-top: 3.4rem;
    margin: 5rem 1.2rem;
    flex: 0 0 9.5rem;
    height: 3rem;
    margin-top: -11.5rem;
    background: url("../../public/assets/images/lobby/matching-players/matching-vs.png?15") center no-repeat;
    background-size: contain; }
  .search-opponent__searching {
    text-align: center;
    margin: 3rem 0;
    color: #fcca1d;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.01em;
    line-height: 1.6111;
    position: absolute;
    margin-top: 27rem;
    margin-left: 20rem;
    pointer-events: none; }
  .search-opponent__timer {
    text-align: center;
    align-items: center;
    margin-top: 3.4rem;
    padding-top: 1rem;
    margin: 5rem 1.2rem;
    flex: 0 0 9.5rem;
    font-family: 'Janda Manatee Solid';
    height: 7rem;
    margin-top: -11rem;
    font-size: 3rem;
    color: white;
    background: url("../../public/assets/images/lobby/matching-players/timer-matching.png?15") center no-repeat; }
  .search-opponent__my_hat {
    top: 3.2rem;
    left: -3.2rem;
    position: absolute;
    width: 17rem;
    height: 17.2rem; }
  .search-opponent__opponents_hat {
    top: 3.2rem;
    right: -3rem;
    position: absolute;
    width: 17rem;
    height: 17.2rem;
    transform: rotateY(180deg); }
  .search-opponent__acceptance_holder {
    height: 10rem; }
    .search-opponent__acceptance_holder__btn {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      margin-top: 2rem; }
      .search-opponent__acceptance_holder__btn:hover {
        box-sizing: border-box;
        height: 4.625rem;
        width: 12.875rem;
        color: #00254e;
        font-family: 'Janda Manatee Solid';
        font-size: 1.6rem;
        background-color: #ffda00;
        background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
        background-size: 100% 100%;
        background-color: #ffda00;
        background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
        background-size: 100% 100%; }
      .search-opponent__acceptance_holder__btn--accept {
        margin-left: 12rem; }
        .search-opponent__acceptance_holder__btn--accept--disabled {
          box-sizing: border-box;
          height: 4.625rem;
          width: 12.875rem;
          color: #00254e;
          font-family: 'Janda Manatee Solid';
          font-size: 1.6rem;
          background-color: #ffda00;
          background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
          background-size: 100% 100%;
          background-color: #ffda00;
          background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
          background-size: 100% 100%; }
      .search-opponent__acceptance_holder__btn--decline {
        margin-left: 6rem; }
        .search-opponent__acceptance_holder__btn--decline--disabled {
          box-sizing: border-box;
          height: 4.625rem;
          width: 12.875rem;
          color: #00254e;
          font-family: 'Janda Manatee Solid';
          font-size: 1.6rem;
          background-color: #ffda00;
          background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
          background-size: 100% 100%;
          background-color: #ffda00;
          background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
          background-size: 100% 100%; }
    .search-opponent__acceptance_holder__decline-text {
      color: #fcca1d;
      font-size: 1.2rem;
      margin-top: 1.4rem;
      font-family: 'Janda Manatee Solid';
      text-align: center; }
    .search-opponent__acceptance_holder__waiting-text {
      color: #ffffff;
      height: 2rem;
      margin-top: 2rem;
      font-size: 1.8rem;
      font-family: 'Janda Manatee Solid';
      text-align: center; }

.search-opponent-card {
  position: relative;
  height: 20rem;
  width: 14.4rem;
  margin-top: 2.2rem; }
  .search-opponent-card__inner {
    position: absolute;
    height: 20rem;
    width: 14.4rem;
    overflow: hidden;
    background: url("../../public/assets/images/lobby/matching-players/matching-dialog-card.png?15") center no-repeat;
    background-size: 14.4rem 20rem;
    box-shadow: 0 0 1rem #3869a3; }
  .search-opponent-card__holder {
    overflow: hidden;
    height: 14rem;
    width: 11.875rem; }
  .search-opponent-card__player-image {
    margin: 0.8rem 0.8rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    height: 54rem;
    z-index: -10;
    margin-top: -57rem; }
    .search-opponent-card__player-image--right {
      position: relative;
      background-size: contain; }
    .search-opponent-card__player-image--left {
      margin-top: -16rem; }
  .search-opponent-card__my-image {
    width: 11.875rem;
    margin-left: 0.85rem;
    margin-top: 1.1rem;
    border-radius: 0.5rem;
    z-index: -9; }
  .search-opponent-card__current-image-holder {
    width: 11.875rem;
    margin-left: 1.3rem;
    margin-bottom: 0.5rem;
    top: -65%;
    position: absolute;
    z-index: -10; }
    .search-opponent-card__current-image-holder--image {
      width: 11.875rem; }
  .search-opponent-card__friendly-image {
    width: 11.875rem;
    margin-left: 0.78rem;
    margin-top: 0.7rem;
    margin-bottom: 0.5rem;
    top: 77%;
    position: absolute;
    z-index: -10; }
  .search-opponent-card__name {
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    line-height: 1.8125;
    text-align: center;
    color: #e9f8fd;
    margin-top: 13.2rem; }
    .search-opponent-card__name--left {
      margin-top: 13.2rem; }
    .search-opponent-card__name--right {
      position: relative;
      margin-top: 13.2rem; }
  .search-opponent-card__footer {
    position: absolute;
    bottom: 1.6rem;
    left: 0;
    right: 0;
    height: 2.8rem;
    display: flex;
    padding: 0 1.2rem;
    width: 100%; }
    .search-opponent-card__footer--left {
      flex-direction: row; }
    .search-opponent-card__footer--right {
      flex-direction: row-reverse; }
  .search-opponent-card__title {
    flex: 0 0 2.8rem;
    height: 2.8rem;
    padding-bottom: 2rem;
    object-fit: contain; }
  .search-opponent-card__rating {
    padding: 0.6rem 0.5rem;
    flex: 0 0 5rem;
    color: #e9f8fd;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.01em;
    line-height: 1.6111; }
    .search-opponent-card__rating--left {
      text-align: right; }
    .search-opponent-card__rating--right {
      text-align: left; }
  .search-opponent-card__star-image {
    flex: 0 0 2.7rem;
    height: 2.6rem;
    margin-top: 0.3rem;
    background: url("../../public/assets/images/dialogs/tournament-result/star-icon.png?15") center no-repeat;
    background-size: contain; }
  .search-opponent-card__scrolling_image {
    width: 13.2rem;
    object-fit: contain; }
  .search-opponent-card__response_image {
    position: absolute;
    width: 3.1rem;
    height: 3.9rem;
    z-index: 3; }
    .search-opponent-card__response_image--left {
      margin-left: 9.8rem;
      margin-top: 1.6rem; }
    .search-opponent-card__response_image--right {
      margin-left: 1.6rem;
      margin-top: 1.6rem; }
  .search-opponent-card__tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    min-width: 25.7rem;
    height: 33.5rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
    text-align: center;
    pointer-events: none;
    visibility: hidden;
    opacity: 0; }
    .search-opponent-card__tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
  .search-opponent-card:hover .search-opponent-card__tooltip {
    visibility: visible;
    opacity: 0.95; }

.semaphore {
  position: relative;
  display: flex;
  height: 12.5rem;
  width: 53.7rem;
  margin: 0 auto;
  margin-top: -1rem;
  z-index: 200;
  transform: translateY(-0.6rem); }

.semaphore-blue-player {
  flex: 0 0 calc(50% - 0.9rem);
  position: relative;
  margin-right: 0.9em;
  background: url("../../public/assets/images/game/semaphore/semaphore-blue-background.png?15") bottom right no-repeat;
  background-size: contain;
  margin-top: 2.6rem;
  height: 10rem; }
  .semaphore-blue-player__player {
    position: absolute;
    height: 6.35rem;
    width: 6.35rem;
    top: 0.75rem;
    z-index: -1;
    border-radius: 0.5rem; }
    .semaphore-blue-player__player--box {
      position: absolute;
      height: 6.35rem;
      width: 6.35rem;
      left: 5.7rem;
      z-index: 1; }
      .semaphore-blue-player__player--box:hover {
        position: fixed; }
  .semaphore-blue-player__hat {
    position: absolute;
    left: -4.8rem;
    top: -4.4rem;
    height: 15.75rem;
    width: 15.535rem;
    pointer-events: none;
    z-index: 3; }
  .semaphore-blue-player__chat {
    position: absolute;
    left: 12.9rem;
    top: 2.7rem;
    height: 3.5rem;
    width: 3.4rem;
    background: url("../../public/assets/images/game/semaphore/semaphore-chet.png?15") center no-repeat;
    background-size: contain; }
  .semaphore-blue-player__arrows {
    position: absolute;
    bottom: 0.6rem;
    left: 5.8rem;
    display: inline-block;
    list-style: none; }
    .semaphore-blue-player__arrows li {
      display: inline-block;
      padding: 0 1rem; }
      .semaphore-blue-player__arrows li a {
        display: inline-block;
        text-decoration: none;
        height: 1.1rem;
        width: 1.2rem; }
    .semaphore-blue-player__arrows li:nth-child(1) a {
      background: url("../../public/assets/images/game/semaphore/semaphore-arrow-right.png?15") center no-repeat;
      background-size: contain; }
      .semaphore-blue-player__arrows li:nth-child(1) a:hover {
        background: url("../../public/assets/images/game/semaphore/semaphore-arrow-right-over.png?15") center no-repeat;
        background-size: contain; }
    .semaphore-blue-player__arrows li:nth-child(2) a {
      background: url("../../public/assets/images/game/semaphore/semaphore-arrow-left.png?15") center no-repeat;
      background-size: contain; }
      .semaphore-blue-player__arrows li:nth-child(2) a:hover {
        background: url("../../public/assets/images/game/semaphore/semaphore-arrow-left-over.png?15") center no-repeat;
        background-size: contain; }
  .semaphore-blue-player__score {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 14rem;
    color: #c7f1ff;
    font-family: 'Janda Manatee Solid';
    font-size: 3.6rem;
    letter-spacing: 0.06em;
    text-align: center;
    pointer-events: none; }
  .semaphore-blue-player__name {
    position: absolute;
    bottom: 2.3rem;
    right: 1.6rem;
    width: 11rem;
    color: #c7f1ff;
    font-family: 'Janda Manatee Solid';
    font-size: 1rem;
    letter-spacing: 0.06em;
    text-align: left; }
  .semaphore-blue-player__short-name {
    position: absolute;
    bottom: 0.4rem;
    right: 1rem;
    width: 19rem;
    text-align: center;
    color: #c7f1ff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.06em;
    text-align: center; }
  .semaphore-blue-player__title {
    position: absolute;
    height: 2.6rem;
    width: 2.7rem;
    bottom: 0.6rem;
    right: 1rem;
    background-size: contain; }
  .semaphore-blue-player__tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    min-width: 25.7rem;
    height: 33.5rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
    text-align: center;
    pointer-events: none; }
    .semaphore-blue-player__tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
  .semaphore-blue-player__player--box .semaphore-blue-player__tooltip {
    visibility: hidden;
    opacity: 0; }
  .semaphore-blue-player__player--box:hover .semaphore-blue-player__tooltip {
    visibility: visible;
    opacity: 0.95; }
  .semaphore-blue-player__offline {
    position: absolute;
    left: 4rem;
    top: 5rem;
    height: 3rem;
    width: 3rem;
    background: url("../../public/assets/images/game/semaphore/disconnected.png?15") center no-repeat;
    background-size: contain; }

.semaphore-red-player {
  flex: 0 0 calc(50% - 0.9rem);
  position: relative;
  margin-left: 0.9em;
  height: 10rem;
  margin-top: 2.6rem;
  background: url("../../public/assets/images/game/semaphore/semaphore-red-background.png?15") bottom left no-repeat;
  background-size: contain; }
  .semaphore-red-player__player {
    position: absolute;
    height: 6.35rem;
    width: 6.35rem;
    top: 0.75rem;
    border-radius: 0.5rem;
    z-index: -1; }
    .semaphore-red-player__player--box {
      position: absolute;
      height: 6.35rem;
      width: 6.35rem;
      right: 5.7rem;
      z-index: 1; }
      .semaphore-red-player__player--box:hover {
        position: fixed; }
  .semaphore-red-player__hat {
    position: absolute;
    right: -4.8rem;
    top: -4.2rem;
    height: 15.75rem;
    width: 15.535rem;
    transform: rotateY(180deg);
    pointer-events: none;
    z-index: 3; }
  .semaphore-red-player__chat {
    position: absolute;
    right: 12.9rem;
    top: 2.7rem;
    height: 3.5rem;
    width: 3.4rem;
    transform: rotateY(180deg);
    background: url("../../public/assets/images/game/semaphore/semaphore-chet.png?15") center no-repeat;
    background-size: contain; }
  .semaphore-red-player__arrows {
    position: absolute;
    bottom: 0.5rem;
    right: 7.8rem;
    display: inline-block;
    list-style: none; }
    .semaphore-red-player__arrows li {
      display: inline-block;
      padding: 0 1rem; }
      .semaphore-red-player__arrows li a {
        display: inline-block;
        text-decoration: none;
        height: 1.1rem;
        width: 1.2rem; }
    .semaphore-red-player__arrows li:nth-child(1) a {
      background: url("../../public/assets/images/game/semaphore/semaphore-arrow-right.png?15") center no-repeat;
      background-size: contain; }
      .semaphore-red-player__arrows li:nth-child(1) a:hover {
        background: url("../../public/assets/images/game/semaphore/semaphore-arrow-right-over.png?15") center no-repeat;
        background-size: contain; }
    .semaphore-red-player__arrows li:nth-child(2) a {
      background: url("../../public/assets/images/game/semaphore/semaphore-arrow-left.png?15") center no-repeat;
      background-size: contain; }
      .semaphore-red-player__arrows li:nth-child(2) a:hover {
        background: url("../../public/assets/images/game/semaphore/semaphore-arrow-left-over.png?15") center no-repeat;
        background-size: contain; }
  .semaphore-red-player__score {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 14rem;
    color: #c7f1ff;
    font-family: 'Janda Manatee Solid';
    font-size: 3.6rem;
    letter-spacing: 0.06em;
    text-align: center; }
  .semaphore-red-player__name {
    position: absolute;
    bottom: 2.3rem;
    left: -1rem;
    width: 14rem;
    text-align: center;
    color: #c7f1ff;
    font-family: 'Janda Manatee Solid';
    font-size: 1rem;
    letter-spacing: 0.06em;
    text-align: right; }
  .semaphore-red-player__short-name {
    position: absolute;
    bottom: 0.4rem;
    left: 1.5rem;
    width: 19rem;
    text-align: center;
    color: #c7f1ff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.06em;
    text-align: center; }
  .semaphore-red-player__title {
    position: absolute;
    height: 2.6rem;
    width: 2.7rem;
    bottom: 0.6rem;
    left: 1rem; }
  .semaphore-red-player__chat-text {
    position: absolute;
    padding: 1.6rem 1.2rem;
    height: 7.2rem;
    width: 8.9rem;
    right: -10rem;
    bottom: -4rem;
    color: #06142f;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    letter-spacing: 0.05em;
    line-height: 1.86;
    text-align: center;
    background: url("../../public/assets/images/game/chat/chat-text-background.png?15") center no-repeat;
    background-size: contain; }
  .semaphore-red-player__emoji {
    position: absolute;
    padding: 1.6rem 1.2rem;
    height: 7.2rem;
    width: 8.9rem;
    right: -10rem;
    top: 2rem;
    background: url("../../public/assets/images/game/chat/chat-emoji-background.png?15") center no-repeat;
    background-size: contain; }
  .semaphore-red-player__tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    min-width: 25.7rem;
    height: 33.5rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
    text-align: center;
    pointer-events: none; }
    .semaphore-red-player__tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
  .semaphore-red-player__player--box .semaphore-red-player__tooltip {
    visibility: hidden;
    opacity: 0; }
  .semaphore-red-player__player--box:hover .semaphore-red-player__tooltip {
    visibility: visible;
    opacity: 0.95; }
  .semaphore-red-player__offline {
    position: absolute;
    right: 4rem;
    top: 5rem;
    height: 3rem;
    width: 3rem;
    background: url("../../public/assets/images/game/semaphore/disconnected.png?15") center no-repeat;
    background-size: contain; }

.emoji-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 2.4rem;
  width: 2.4rem; }

.emoji-icon--0::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") 0 0 no-repeat;
  background-size: contain; }

.emoji-icon--1::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -2.4rem 0 no-repeat;
  background-size: contain; }

.emoji-icon--2::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -4.8rem 0 no-repeat;
  background-size: contain; }

.emoji-icon--3::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -7.2rem 0 no-repeat;
  background-size: contain; }

.emoji-icon--4::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -9.6rem 0 no-repeat;
  background-size: contain; }

.emoji-icon--5::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -12rem 0 no-repeat;
  background-size: contain; }

.emoji-icon--6::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") 0 -3rem no-repeat;
  background-size: contain; }

.emoji-icon--7::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -2.4rem -3rem no-repeat;
  background-size: contain; }

.emoji-icon--8::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -4.8rem -3rem no-repeat;
  background-size: contain; }

.emoji-icon--9::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -7.2rem -3rem no-repeat;
  background-size: contain; }

.emoji-icon--10::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -9.6rem -3rem no-repeat;
  background-size: contain; }

.emoji-icon--11::after {
  background: url("../../public/assets/images/game/chat/chat-emotion-icons.png?15") -12rem -3rem no-repeat;
  background-size: contain; }

.sequence {
  position: absolute;
  padding: 8rem 8rem;
  margin: 0rem 0rem; }
  .sequence__progress {
    height: 2.3rem;
    width: 47.1rem;
    margin: auto;
    background: #092348;
    position: relative; }
  .sequence__game-number {
    color: #3f566f;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.6;
    background: #092348;
    border: 0.5rem solid #092348;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 50%; }
    .sequence__game-number--1 {
      left: 0;
      transform: translate(-50%, -50%); }
    .sequence__game-number--2 {
      left: 33%;
      transform: translate(-50%, -50%); }
    .sequence__game-number--3 {
      left: 67%;
      transform: translate(-50%, -50%); }
    .sequence__game-number--4 {
      left: 100%;
      transform: translate(-50%, -50%); }
    .sequence__game-number--finished {
      background: #6cdb00;
      color: #12284a; }
  .sequence__game-progress {
    height: 1.4rem;
    background: #6cdb00;
    width: 0;
    top: 0.45rem;
    position: absolute;
    z-index: 5; }
    .sequence__game-progress--finished {
      width: 30%; }
    .sequence__game-progress--1 {
      left: 1%; }
    .sequence__game-progress--2 {
      left: 35%; }
    .sequence__game-progress--3 {
      left: 68%; }
  .sequence__game-name {
    color: #12284a;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.8;
    position: absolute;
    z-index: 6;
    top: -0.5rem;
    text-align: center;
    padding-top: 1.5rem;
    width: 15.3rem;
    height: 11.1rem;
    z-index: 100; }
    .sequence__game-name--1 {
      left: -0.3rem;
      transform: translate(-50%, -50%); }
    .sequence__game-name--2 {
      left: 32%;
      transform: translate(-50%, -50%); }
    .sequence__game-name--3 {
      left: 66%;
      transform: translate(-50%, -50%); }
    .sequence__game-name--4 {
      left: calc(100% - 0.3rem);
      transform: translate(-50%, -50%); }
  .sequence__active {
    background: url("../../public/assets/images/game/transition/sequence-pointer.png?15") no-repeat center;
    background-size: contain;
    height: 11.1rem;
    width: 15.3rem;
    margin-top: -6.04rem;
    position: absolute;
    z-index: 10; }
    .sequence__active--1 {
      margin-left: -7.8rem; }
    .sequence__active--2 {
      margin-left: 7.6rem; }
    .sequence__active--3 {
      margin-left: 23.7rem; }
  .sequence__description {
    height: 15.4rem;
    width: 60.6rem;
    border: 0.3rem solid #7eaacf;
    background-color: #507eb0;
    position: absolute;
    z-index: 1;
    top: 4.7rem;
    left: -7rem;
    border-radius: 1rem; }
  .sequence__description-title {
    color: #d6f5ff;
    text-align: center;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1;
    text-transform: uppercase;
    padding: 1.8rem 0 0 0; }
  .sequence__description-text {
    color: #d6f5ff;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.075em;
    line-height: 1.3;
    padding: 1.8rem 1rem;
    text-align: center;
    width: 0rem;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    white-space: nowrap;
    display: none; }
  .sequence__matching-description {
    display: flex;
    flex-wrap: wrap; }
  .sequence__matching-description-half {
    width: 50%;
    padding: 0 1.6rem;
    text-align: center; }
  .sequence__matching-description-title {
    color: #d6f5ff;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.6;
    text-align: center;
    display: block;
    width: 100%; }
    .sequence__matching-description-title--desciprtion {
      position: absolute;
      top: 78%;
      height: 3rem;
      opacity: 0; }
  .sequence__matching-description-found {
    opacity: 0;
    margin-top: 8rem; }
  .sequence__matching-description-button {
    color: #d6f5ff;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    letter-spacing: 0.05em;
    line-height: 1.8;
    text-align: center;
    width: 26rem;
    background-color: #092348;
    border-radius: 1rem;
    border: 0.3rem solid #8fbbda;
    height: 4.08rem;
    overflow: hidden; }

.game-starting-timer {
  height: 22rem;
  width: 20.7rem;
  opacity: 0;
  margin: 0 auto;
  margin-top: 18rem;
  margin-left: 12rem;
  position: absolute;
  background: url("../../public/assets/images/game/semaphore/game-starting-timer.png?15") no-repeat center;
  background-size: contain; }
  .game-starting-timer__seconds-to-start {
    color: #10559b;
    font-family: 'Janda Manatee Solid';
    font-size: 7rem;
    letter-spacing: 0.05em;
    line-height: 1.843;
    text-align: center;
    padding-top: 7rem; }
  .game-starting-timer__text {
    color: #021f3c;
    font-family: 'Janda Manatee Solid';
    font-size: 2.6rem;
    letter-spacing: 0.05em;
    line-height: 1.385;
    text-align: center; }

.game-status {
  height: 4.6rem;
  width: 59rem;
  margin: 0.4rem 2rem;
  position: relative;
  background: url("../../public/assets/images/game/puzzle/status-bar.png?15") no-repeat center;
  background-size: contain;
  letter-spacing: 0.05em;
  line-height: 2;
  padding-top: 0.6rem;
  z-index: 1; }
  .game-status__clock {
    top: 0;
    height: 9.1rem;
    width: 8.5rem;
    position: absolute;
    transform: translate(-40%, -48%);
    color: #07254d;
    font-family: 'Janda Manatee Solid';
    font-size: 4.2rem;
    letter-spacing: 0.05em;
    line-height: 3.6rem;
    padding: 4.3rem 0;
    text-align: center;
    z-index: 2; }
    .game-status__clock--blue {
      background: url("../../public/assets/images/game/puzzle/game-timer-highlight.png?15") no-repeat center 3.2rem, url("../../public/assets/images/game/puzzle/game-timer-blue.png?15") no-repeat center 3.2rem, url("../../public/assets/images/game/semaphore/game-timer.png?15") no-repeat center;
      background-size: 5.8rem, 6.8rem, contain; }
    .game-status__clock--orange {
      background: url("../../public/assets/images/game/puzzle/game-timer-highlight.png?15") no-repeat center 3.2rem, url("../../public/assets/images/game/puzzle/game-timer-orange.png?15") no-repeat center 3.2rem, url("../../public/assets/images/game/semaphore/game-timer.png?15") no-repeat center;
      background-size: 5.8rem, 6.8rem, contain; }
    .game-status__clock--red {
      background: url("../../public/assets/images/game/puzzle/game-timer-highlight.png?15") no-repeat center 3.2rem, url("../../public/assets/images/game/puzzle/game-timer-red.png?15") no-repeat center 3.2rem, url("../../public/assets/images/game/semaphore/game-timer.png?15") no-repeat center;
      background-size: 5.8rem, 6.8rem, contain; }
  .game-status__text {
    text-align: center;
    color: #e9f8fd;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    z-index: 1; }

.game-result {
  position: relative;
  height: 38rem;
  overflow-y: visible;
  animation: 0.2s show ease-in backwards;
  z-index: 2;
  margin-top: -0.5rem; }
  .game-result__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 23.5rem;
    width: 39.9rem;
    margin: 1rem auto;
    margin-left: -6.4rem;
    background: url("../../public/assets/images/game/result-page/result-holder.png?15") no-repeat top center;
    background-size: contain; }
    .game-result__center--friendly {
      background: url("../../public/assets/images/game/result-page/friendly-result-holder.png?15") no-repeat top center;
      background-size: contain; }
  .game-result__progress_holder {
    position: absolute;
    height: 22.5rem;
    width: 50.4rem;
    margin-left: 5rem;
    overflow: hidden; }
  .game-result__progress {
    position: absolute;
    height: 18.1rem;
    width: 50.4rem;
    margin-top: -19rem;
    border-style: solid;
    border-color: #01204d;
    border-width: 0.2rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    background: #1f457c;
    transition: all 1s;
    z-index: 2; }
    .game-result__progress__box {
      display: flex;
      width: 25rem;
      height: 2rem;
      margin: 0 14rem;
      margin-top: 0.75rem; }
    .game-result__progress__point {
      width: 2.1rem;
      color: #01a2f1;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      text-align: right; }
      .game-result__progress__point--opponent {
        color: #f1483b;
        text-align: left; }
    .game-result__progress__text {
      width: 20rem;
      color: #a0dffb;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      text-align: center; }
    .game-result__progress__background {
      height: 7.8rem;
      width: 14.5rem;
      margin-top: -0.4rem;
      margin-left: 18.8rem;
      background: url("../../public/assets/images/game/result-page/result-background.png?15") center no-repeat;
      background-size: contain; }
      .game-result__progress__background--text {
        color: #a0dffb;
        margin-left: 4rem;
        margin-top: 3rem;
        font-family: 'Janda Manatee Solid';
        font-size: 1.4rem;
        text-align: center;
        background-color: transparent; }
      .game-result__progress__background--arrow {
        height: 1.9rem;
        width: 3.2rem;
        background: url("../../public/assets/images/game/result-page/result-arow.png?15") center no-repeat;
        background-size: contain;
        margin: 0 auto;
        cursor: pointer; }
  .game-result__icon {
    position: absolute;
    width: 53rem;
    height: 30rem;
    margin-top: 17rem;
    margin-left: 5rem; }
    .game-result__icon--won {
      background: url("../../public/assets/images/b2b/won.png?15") center no-repeat;
      background-size: contain; }
    .game-result__icon--lose {
      background: url("../../public/assets/images/b2b/lose.png?15") center no-repeat;
      background-size: contain; }
  .game-result__moto {
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    color: #101E39;
    text-align: center;
    line-height: 3rem;
    margin-top: 9.2rem;
    height: 8rem;
    margin-left: 15rem;
    width: 35rem;
    display: inline-block;
    vertical-align: middle; }
  .game-result__result-status {
    height: 3.2rem;
    width: 13.5rem;
    margin-left: 31.2rem;
    margin-top: 3rem;
    z-index: 10;
    position: absolute; }
    .game-result__result-status--win {
      background: url("../../public/assets/images/game/result-page/result-status-you-won.png?15") center no-repeat;
      background-size: contain; }
    .game-result__result-status--lost {
      background: url("../../public/assets/images/game/result-page/result-status-you-lost.png?15") center no-repeat;
      background-size: contain; }
  .game-result__cup {
    position: absolute;
    height: 14.4rem;
    width: 14rem;
    top: 8.5rem;
    left: 4.2rem; }
    .game-result__cup--won {
      background: url("../../public/assets/images/game/result-page/winer-cup.png?15") center no-repeat;
      background-size: contain; }
    .game-result__cup--lost {
      background: url("../../public/assets/images/game/result-page/losse-cup.png?15") center no-repeat;
      background-size: contain; }
  .game-result__today-booster {
    margin-top: -7.5rem;
    margin-left: 3rem;
    pointer-events: none; }
  .game-result__today-booster-inner {
    position: absolute;
    width: 16.7rem;
    opacity: 0;
    background: url("../../public/assets/images/lobby/booster-white-flash.png?15") no-repeat center;
    background-size: contain;
    height: 9.2rem;
    z-index: -1;
    margin-top: -2rem;
    margin-left: -0.4rem; }
  .game-result__high-score {
    position: absolute;
    width: 11.9rem;
    height: 13.8rem;
    top: 13.4rem;
    left: 14.1rem;
    z-index: 5;
    transform: scale(3);
    margin-left: -11rem;
    opacity: 0;
    background: url("../../public/assets/images/game/result-page/high-score-sticker.png?15") no-repeat center;
    background-size: contain;
    pointer-events: none; }
  .game-result__title {
    height: 6rem;
    width: 14rem;
    position: absolute;
    top: 5rem;
    margin-left: -6rem;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -6.5rem; }
    .game-result__title--won {
      background: url("../../public/assets/images/game/result-page/result-status-you-won.png?15") no-repeat center;
      background-size: contain; }
    .game-result__title--lost {
      background: url("../../public/assets/images/game/result-page/result-status-you-lost.png?15") no-repeat center;
      background-size: contain; }
  .game-result__friend-box {
    height: 13.3rem;
    width: 27rem;
    background-color: #406191;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 0.5rem;
    box-shadow: -4px 4px 4px #1f385f;
    display: flex; }
    .game-result__friend-box--text {
      height: 7rem;
      width: 17rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.3rem;
      text-align: justify;
      margin-top: 3.3rem;
      margin-left: 0.8rem;
      margin-right: 0.5rem; }
    .game-result__friend-box--image {
      width: 10.6rem;
      height: 9.6rem;
      margin-top: 2rem;
      background: url("../../public/assets/images/game/result-page/friend-game-icon.png?15") no-repeat center;
      background-size: contain; }
    .game-result__friend-box--comment {
      width: 22rem;
      height: 2.5rem;
      position: absolute;
      border-radius: 0.5rem;
      margin-top: 21.5rem;
      margin-left: 21rem;
      border-color: #09254b; }
  .game-result__score-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 13.3rem;
    width: 23rem;
    margin-left: -6rem;
    margin-top: 2rem;
    z-index: 1; }
  .game-result__score-feather {
    position: absolute;
    height: 3.8rem;
    width: 3.5rem;
    top: 1rem;
    left: 15rem; }
    .game-result__score-feather--won {
      background: url("../../public/assets/images/game/result-page/feather.png?15") no-repeat center center;
      background-size: contain; }
    .game-result__score-feather--lost {
      background: url("../../public/assets/images/game/result-page/black-feather.png?15") no-repeat center;
      background-size: contain; }
  .game-result__score {
    font-family: 'Janda Manatee Solid';
    font-size: 4.8rem;
    letter-spacing: 0.05em;
    line-height: 3.6rem;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .game-result__score--won {
      color: #ffffff; }
    .game-result__score--lost {
      color: #181d26; }
    .game-result__score--won-booster {
      color: #ffffff;
      opacity: 0; }
  .game-result__new-high-score {
    opacity: 0;
    z-index: 1;
    height: 4.8rem;
    width: 20rem;
    background: url("../../public/assets/images/game/result-page/new-high-score-label.png?15") no-repeat center;
    background-size: contain;
    position: absolute;
    bottom: 14rem;
    margin-left: -6.4rem;
    left: 50%;
    transform: translateX(-50%); }
  .game-result__no-high-score {
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    line-height: 2;
    z-index: 4;
    height: 3.2rem;
    margin-left: -6rem;
    position: absolute;
    bottom: 15rem;
    left: 50%;
    transform: translateX(-50%); }
  .game-result__rating {
    height: 5rem;
    width: 12.8rem;
    background: url("../../public/assets/images/game/result-page/result-raiting.png?15") no-repeat top center;
    background-size: contain;
    position: absolute;
    bottom: 1.6rem;
    margin-left: -6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100; }
  .game-result__rating-box {
    height: 2.5rem;
    width: 10rem;
    display: flex;
    margin-top: 27rem;
    position: absolute;
    margin-left: 35rem; }
    .game-result__rating-box--booster {
      margin-left: 19rem; }
  .game-result__rating-star {
    position: absolute;
    width: 2.5rem;
    height: 2.3rem;
    z-index: 100;
    text-align: right;
    margin-top: 0.5rem; }
    .game-result__rating-star--won {
      background: url("../../public/assets/images/toolbar/progress-bar-star.png?15") no-repeat center;
      background-size: contain; }
    .game-result__rating-star--lost {
      background: url("../../public/assets/images/game/result-page/black-star.png?15") no-repeat center;
      background-size: contain; }
    .game-result__rating-star--fly {
      background-size: contain; }
    .game-result__rating-star--booster {
      margin-left: 6rem;
      background: url("../../public/assets/images/game/result-page/trapeze-booster.png?15") no-repeat center;
      background-size: contain; }
    .game-result__rating-star--booster-black {
      margin-left: 6rem;
      background: url("../../public/assets/images/game/result-page/trapeze-booster-black.png?15") no-repeat center;
      background-size: contain; }
  .game-result__won-score {
    z-index: 5;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.05em;
    line-height: 2;
    margin-top: 0rem;
    margin-left: 5rem; }
    .game-result__won-score--won {
      color: #ffffff; }
    .game-result__won-score--lost {
      color: #181d26; }
    .game-result__won-score--booster {
      width: 5.5rem;
      margin-left: 0rem;
      text-align: end; }
      .game-result__won-score--booster--won {
        color: #ffffff; }
      .game-result__won-score--booster--lost {
        color: #181d26; }
  .game-result__booster-applied {
    height: 6.9rem;
    width: 13.1rem;
    position: absolute;
    left: 48%;
    top: 31%;
    right: 20.8rem; }
    .game-result__booster-applied--won {
      color: #ffffff; }
    .game-result__booster-applied--lost {
      color: #181d26;
      opacity: 0.3; }
    .game-result__booster-applied--on {
      background: url("../../public/assets/images/game/result-page/booster-applied-on.png?15") no-repeat center;
      background-size: contain; }
    .game-result__booster-applied--off {
      background: url("../../public/assets/images/game/result-page/booster-applied-off.png?15") no-repeat center;
      background-size: contain; }
  .game-result__label {
    height: 21px;
    color: #09254b;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    line-height: 3.6rem; }
  .game-result__booster-amount {
    background: url("../../public/assets/images/game/result-page/booster-x2.png?15") center no-repeat;
    background-size: contain;
    position: absolute;
    top: 31%;
    left: 18%;
    width: 3rem;
    height: 2.7rem;
    background-size: 3.2rem 2.9rem; }
  .game-result__align-center {
    position: absolute;
    text-align: center;
    opacity: 1;
    width: 100%;
    display: inline-block;
    margin-top: 43rem;
    right: 6.5rem; }
  .game-result__share-button {
    position: absolute;
    width: 16rem;
    height: 4rem;
    margin-top: 25rem;
    margin-left: 24rem; }
  .game-result__button {
    height: 5rem;
    width: 14.5rem;
    background: url("../../public/assets/images/game/result-page/rematch-default.png?15") no-repeat center;
    background-size: contain;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    letter-spacing: 0.01em; }
    .game-result__button:hover {
      background: url("../../public/assets/images/game/result-page/rematch-default-over.png?15") no-repeat center;
      background-size: contain; }
    .game-result__button--text {
      height: 1.7rem;
      background: url("../../public/assets/images/game/result-page/back-to-loby.png?15") no-repeat center;
      background-size: contain; }
    .game-result__button--rematch--default {
      background: url("../../public/assets/images/game/result-page/rematch-default.png?15") no-repeat center; }
    .game-result__button--rematch--waiting {
      background: url("../../public/assets/images/game/result-page/rematch-default.png?15") no-repeat center;
      background-size: contain; }
    .game-result__button--rematch--accept {
      background: url("../../public/assets/images/game/result-page/rematch-accept.png?15") no-repeat center; }
      .game-result__button--rematch--accept:hover {
        background-size: contain;
        background: url("../../public/assets/images/game/result-page/rematch-accept-over.png?15") no-repeat center; }
    .game-result__button--rematch--disabled {
      background: url("../../public/assets/images/game/result-page/rematch-disabled.png?15") no-repeat center; }
      .game-result__button--rematch--disabled:hover {
        background-size: contain;
        background: url("../../public/assets/images/game/result-page/rematch-disabled.png?15") no-repeat center; }
    .game-result__button--rematch-inner {
      background-size: contain;
      margin-left: 0rem;
      margin-right: 0rem;
      pointer-events: none; }
      .game-result__button--rematch-inner--default {
        background: url("../../public/assets/images/game/result-page/rematch-text-default.png?15") no-repeat center; }
      .game-result__button--rematch-inner--disabled {
        background: url("../../public/assets/images/game/result-page/rematch-text-disabled.png?15") no-repeat center; }
      .game-result__button--rematch-inner--accept {
        background: url("../../public/assets/images/game/result-page/rematch-text-accept.png?15") no-repeat center; }
      .game-result__button--rematch-inner--waiting {
        background: url("../../public/assets/images/game/result-page/rematch-text-default.png?15") no-repeat center; }
    .game-result__button--friend {
      margin-top: 33rem;
      margin-left: 24rem; }
  .game-result__appeal-button {
    width: 5rem;
    height: 5rem;
    position: absolute;
    right: 27rem;
    top: 15.5rem;
    background: url("../../public/assets/images/game/result-page/appeal-button.png?15") center no-repeat;
    cursor: pointer; }
    .game-result__appeal-button:hover {
      background: url("../../public/assets/images/game/result-page/appeal-button-over.png?15") center no-repeat; }
    .game-result__appeal-button:disabled {
      background: url("../../public/assets/images/game/result-page/appeal-button-over.png?15") center no-repeat;
      cursor: default; }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.achievements {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 499;
  height: 70rem;
  width: 76rem;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .achievements__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 499;
    height: 68.2rem;
    width: 74.8rem;
    background: url("../../public/assets/images/achievements/achievements-dialog.png?15") no-repeat center center;
    background-size: contain; }
    .achievements__container__close {
      height: 3.9rem;
      width: 3.9rem;
      position: absolute;
      right: 0.3rem;
      top: 5.6rem;
      background: url("../../public/assets/images/dialogs/dialog-close-button.png?15") center no-repeat;
      background-size: contain; }
      .achievements__container__close:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/dialogs/dialog-close-button-over.png?15") center no-repeat;
        background-size: contain; }
  .achievements__season {
    height: 2.6rem;
    position: absolute;
    top: 8.7rem;
    left: 7.6rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    text-align: center; }
    .achievements__season--date {
      width: 22rem;
      left: 22.8rem;
      top: 8.6rem;
      color: #875304; }
    .achievements__season--day {
      width: 23rem;
      left: 47rem;
      top: 8.6rem;
      color: #875304; }
  .achievements__title {
    height: 16.4rem;
    width: 12.2rem;
    position: absolute;
    top: 18rem;
    left: 6rem; }
  .achievements__title-image {
    width: 100%;
    max-height: 11.9rem; }
  .achievements__title-header {
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.3rem;
    margin-top: 1.4rem;
    letter-spacing: 0.035em;
    line-height: 1.384615384615385;
    text-align: center;
    width: 100%; }
  .achievements__subtitle-header {
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.035em;
    line-height: 1.384615384615385;
    text-align: center;
    width: 100%; }
  .achievements__place-count {
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.035em;
    line-height: 1;
    position: absolute; }
    .achievements__place-count--first {
      left: 11rem;
      bottom: 11rem; }
    .achievements__place-count--second {
      left: 6rem;
      bottom: 8.7rem; }
    .achievements__place-count--third {
      left: 16rem;
      bottom: 7.6rem; }
  .achievements__arrow {
    position: absolute;
    right: 1.2rem;
    height: 6.4rem;
    width: 4.4rem; }
    .achievements__arrow--up {
      top: 25.2rem;
      background: url("../../public/assets/images/achievements/achievement-arow-up.png?15") no-repeat center center;
      background-size: contain; }
      .achievements__arrow--up:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/achievements/achievement-arow-up-over.png?15") center no-repeat;
        background-size: contain; }
      .achievements__arrow--up--disabled {
        top: 25.2rem;
        background: url("../../public/assets/images/achievements/achievement-arow-up-disabled.png?15") no-repeat center center;
        background-size: contain; }
        .achievements__arrow--up--disabled:hover::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: unset; }
    .achievements__arrow--down {
      top: 32.8rem;
      background: url("../../public/assets/images/achievements/achievement-arow-down.png?15") no-repeat center center;
      background-size: contain; }
      .achievements__arrow--down:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/achievements/achievement-arow-down-over.png?15") center no-repeat;
        background-size: contain; }
      .achievements__arrow--down--disabled {
        top: 32.8rem;
        background: url("../../public/assets/images/achievements/achievement-arow-down-disabled.png?15") no-repeat center center;
        background-size: contain; }
        .achievements__arrow--down--disabled:hover::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: unset; }
  .achievements__inner {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 13.2rem;
    left: 22.3rem;
    height: 48rem;
    width: 48rem;
    overflow: hidden; }
  .achievements__box {
    background: url("../../public/assets/images/achievements/achievement-card-background.png?15") no-repeat center center;
    background-size: contain;
    width: 9.6rem;
    height: 9.6rem;
    transition: all 1s;
    position: relative; }
  .achievements__opacity:hover {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url("../../public/assets/images/achievements/achievement-card-background.png?15") repeat 0 0; }
  .achievements__box:hover .achievements__tooltip,
  .achievements__opacity:hover .achievements__tooltip {
    visibility: visible;
    opacity: 1; }
  .achievements__item--locked:hover .achievements__box {
    opacity: 0.4; }
  .achievements__item {
    position: relative;
    width: 9.6rem;
    height: 9.6rem; }
    .achievements__item--coming-soon {
      background: url("../../public/assets/images/achievements/achievement-coming-soon.png?15") no-repeat center center;
      background-size: contain; }
    .achievements__item--locked {
      height: 7.7rem;
      width: 6.3rem;
      margin: auto;
      position: relative;
      margin-top: 0.95rem;
      background: url("../../public/assets/images/achievements/achievement-locked.png?15") no-repeat center center;
      background-size: contain; }
    .achievements__item--progress {
      height: 0.5rem;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url("../../public/assets/images/achievements/achievement-progress.png?15");
      background-size: contain; }
  .achievements__tooltip {
    position: absolute;
    z-index: 1000;
    width: 10rem;
    visibility: hidden;
    opacity: 0;
    top: 0;
    border-radius: 0.5rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
    transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
    visibility: visible;
    height: 30rem;
    width: 18rem;
    top: 75%;
    left: 75%;
    pointer-events: none; }
    .achievements__tooltip:hover {
      visibility: visible;
      opacity: 0.95; }
    .achievements__tooltip__title-icon {
      height: 4.8rem;
      display: table; }
    .achievements__tooltip__icon {
      float: left;
      height: 2.8rem;
      width: 2.6rem;
      margin: 1rem;
      vertical-align: middle;
      display: table-cell; }
    .achievements__tooltip__value {
      height: 2.5rem;
      width: 15.3rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.5rem;
      text-align: center;
      margin-left: 1.3rem;
      margin-top: 9rem;
      position: absolute; }
    .achievements__tooltip__title {
      height: 1.3rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      vertical-align: middle;
      display: table-cell;
      line-height: 1.8rem; }
    .achievements__tooltip__separator-line {
      height: 0.2rem;
      width: 16rem;
      margin-bottom: 0.5rem;
      background-color: #985914;
      border-radius: 0.1rem; }
      .achievements__tooltip__separator-line--bottom {
        margin-top: 3rem; }
    .achievements__tooltip__goal-description {
      height: 10rem; }
    .achievements__tooltip__goal {
      height: 1.2rem;
      width: 4rem;
      color: #f7c61e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 2.9rem;
      margin-left: 6.9rem;
      margin-bottom: 1rem; }
    .achievements__tooltip__description {
      height: 3.1rem;
      width: 15.3rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
      margin-left: 1.3rem;
      margin-top: 0.8rem;
      position: absolute; }
    .achievements__tooltip__rewards {
      height: 10.5rem; }
    .achievements__tooltip__reward {
      height: 1.3rem;
      width: 6.9rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.5rem;
      margin-left: 5.6rem;
      margin-top: 1rem; }
    .achievements__tooltip__reward-icon-count {
      float: left; }
    .achievements__tooltip__reward-content {
      display: table;
      margin: 0 auto;
      margin-top: 1.5rem; }
    .achievements__tooltip__reward-icon {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 1rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem; }
    .achievements__tooltip__reward-count {
      height: 1.2rem;
      width: 1.7rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.4rem;
      line-height: 1.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem; }

.inbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .inbox__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 55.9rem;
    width: 72.8rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .inbox__content_background {
    position: absolute;
    box-sizing: border-box;
    height: 39.8rem;
    width: 69.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    border: 0.6rem solid #6994c7;
    border-radius: 2rem;
    z-index: 3;
    pointer-events: none; }
  .inbox__content_holder {
    position: absolute;
    box-sizing: border-box;
    height: 39.4rem;
    width: 68.8rem;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top: 1.2rem;
    border: 0.4rem solid #6994c7;
    border-radius: 2rem;
    overflow: hidden;
    background-color: #082551;
    padding-right: 2rem;
    overflow-y: scroll;
    scrollbar-color: #fc0 rgba(105, 148, 199, 0.2);
    scrollbar-width: thin; }
    .inbox__content_holder::-webkit-scrollbar {
      width: 0.5rem; }
    .inbox__content_holder::-webkit-scrollbar-track {
      background: rgba(105, 148, 199, 0.2); }
    .inbox__content_holder::-webkit-scrollbar-thumb {
      background: #fc0;
      border-radius: 0.3rem; }
  .inbox__scroll_content {
    height: 38.6rem;
    width: 1.3rem;
    margin-top: -16rem;
    background-color: rgba(105, 148, 199, 0.2);
    float: right; }
  .inbox__arrow-up {
    height: 1rem;
    width: 0.7rem;
    margin-top: 0.7rem;
    margin-left: 0.3rem;
    background: url("../../public/assets/images/dialogs/inbox/scroll-arrow.png?15") center no-repeat;
    background-size: contain; }
    .inbox__arrow-up:hover {
      background: url("../../public/assets/images/dialogs/inbox/scroll-arrow-over.png?15") center no-repeat;
      background-size: contain; }
  .inbox__arrow-down {
    height: 1rem;
    width: 0.7rem;
    margin-top: 35rem;
    margin-left: 0.3rem;
    background: url("../../public/assets/images/dialogs/inbox/scroll-arrow-down.png?15") center no-repeat;
    background-size: contain; }
    .inbox__arrow-down:hover {
      background: url("../../public/assets/images/dialogs/inbox/scroll-arrow-down-over.png?15") center no-repeat;
      background-size: contain; }
  .inbox__scroll {
    position: absolute;
    height: 1.9rem;
    width: 1.3rem;
    margin-top: 0.2rem;
    background: url("../../public/assets/images/dialogs/inbox/scroll-button.png?15") center no-repeat;
    background-size: contain; }
  .inbox__description {
    position: absolute;
    height: 5.7rem;
    width: 42.1rem;
    margin-left: 13.5rem;
    margin-top: 17.1rem;
    color: #6893c6;
    font-family: 'Janda Manatee Solid';
    font-size: 2.5rem;
    text-align: center;
    z-index: 1000; }
  .inbox__accept {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin-left: 29rem;
    margin-top: 44rem; }
    .inbox__accept:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.shop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 499;
  height: 70rem;
  width: 76rem;
  background-size: contain;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat; }
  .shop__container {
    overflow: hidden;
    height: 50.5rem;
    width: 67rem;
    padding: 0rem 0.5rem 0rem 1rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #3569a4;
    border: 0.4rem solid #3569a4;
    border-bottom: 0.55rem solid #6298d7; }
  .shop__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../../public/assets/images/dialogs/shop/shop-title.png?15") center no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 3.3rem;
    width: 20rem; }
  .shop__tab-content-background {
    position: relative;
    z-index: 600;
    background: url("../../public/assets/images/dialogs/shop/tab-content-background.png?15") center no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 35.85rem;
    background-size: 98% 100%;
    margin: auto;
    margin-top: -1.5rem; }
  .shop__tab-title {
    text-transform: uppercase;
    padding-top: 4rem;
    height: 2.6rem;
    width: 65.3rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 3.1rem;
    line-height: 1.8rem;
    text-align: center; }
  .shop__tab-description {
    padding-top: 4rem;
    height: 1.8rem;
    width: 65.3rem;
    color: #afcae9;
    font-family: 'Janda Manatee Solid';
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    margin-bottom: -1rem; }
  .shop__tab-content {
    overflow: hidden;
    height: 22.6rem;
    width: 61.4rem;
    margin: auto; }
  .shop__tab-cards {
    margin-left: 0rem;
    height: 25rem;
    overflow: hidden;
    margin-top: 3.5rem;
    transition: margin 700ms;
    padding-top: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .shop__add-tokens-background-png {
    background: url("../../public/assets/images/dialogs/shop/add-tokens-background.png?15") center no-repeat;
    background-size: contain;
    height: 4rem;
    width: 12.9rem;
    background-size: 80% 80%; }
  .shop__tokens {
    float: left;
    color: #f9d349;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    line-height: 4.4rem;
    margin-left: 5rem;
    width: 28%;
    text-align: center; }
  .shop__add-tokens-button {
    float: left;
    background: url("../../public/assets/images/dialogs/shop/add-tokens-button.png?15") center no-repeat;
    background-size: contain;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0.8rem 0rem 0rem 0.3rem; }
  .shop__tabs {
    margin: auto;
    margin-top: 4rem;
    z-index: 100;
    width: 37.8rem;
    margin-left: 16rem; }
  .shop__tab-background {
    position: relative;
    z-index: 100;
    background: url("../../public/assets/images/dialogs/shop/shop-tab-button.png?15") center no-repeat;
    background-size: contain;
    height: 8.7rem;
    width: 15.5rem;
    float: left;
    margin-left: 0.5rem;
    cursor: pointer; }
  .shop__tab-background-selected {
    position: relative;
    z-index: 601;
    background: url("../../public/assets/images/dialogs/shop/shop-tab-button-selected.png?15") center no-repeat;
    background-size: contain;
    height: 8.7rem;
    width: 15.5rem;
    float: left;
    margin-left: 0.5rem;
    cursor: pointer; }
  .shop__helpkit-icon {
    background: url("../../public/assets/images/dialogs/shop/shop-helpkit-icon.png?15") center no-repeat;
    background-size: contain;
    background-size: 60%;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem; }
  .shop__helpkit-icon:hover {
    background-size: 70%; }
  .shop__booster-icon {
    background: url("../../public/assets/images/dialogs/shop/shop-booster-icon.png?15") center no-repeat;
    background-size: contain;
    background-size: 60%;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem; }
  .shop__booster-icon:hover {
    background-size: 70%; }
  .shop__hat-icon {
    background: url("../../public/assets/images/dialogs/shop/shop-hat-icon.png?15") center no-repeat;
    background-size: contain;
    background-size: 60%;
    margin-left: 3.5rem;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem; }
  .shop__hat-icon:hover {
    background-size: 70%; }
  .shop__chat-icon {
    background: url("../../public/assets/images/dialogs/shop/shop-emotion-icon.png?15") center no-repeat;
    background-size: contain;
    background-size: 60%;
    margin-left: 3.5rem;
    height: 8.7rem;
    width: 8.6rem;
    margin-top: -0.5rem; }
  .shop__chat-icon:hover {
    background-size: 70%; }
  .shop__arrow-right {
    background: url("../../public/assets/images/dialogs/shop/shop-arrow-right.png?15") center no-repeat;
    background-size: contain;
    position: absolute;
    left: 59rem;
    top: 15rem;
    height: 10rem;
    width: 8.6rem;
    cursor: pointer; }
  .shop__arrow-right:hover {
    background: url("../../public/assets/images/dialogs/shop/shop-arrow-right-over.png?15") center no-repeat;
    background-size: contain; }
  .shop__arrow-left {
    background: url("../../public/assets/images/dialogs/shop/shop-arrow-left.png?15") center no-repeat;
    background-size: contain;
    position: absolute;
    left: -3.5rem;
    top: 15rem;
    height: 10rem;
    width: 8.6rem;
    cursor: pointer; }
  .shop__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 55.6rem;
    width: 68.6rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: url("../../public/assets/images/dialogs/shop/dialog-background.png?15") center no-repeat; }
  .shop__description {
    height: 2.3rem;
    width: 36.1rem;
    color: #fef6a5;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    margin: 4rem auto; }
  .shop__accept {
    display: block;
    height: 4.5rem;
    width: 13.5rem;
    margin: 1rem auto;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: contain;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem; }
  .shop__item-coast {
    margin: 0.7rem auto;
    height: 2.1rem;
    color: #a15a0c;
    font-family: 'Janda Manatee Solid';
    font-size: 2.6rem;
    line-height: 2.9rem;
    text-align: center;
    position: relative; }
  .shop__buy-text {
    margin: -0.4rem auto;
    height: 4.5rem;
    width: 7.5rem;
    background: url("../../public/assets/images/dialogs/shop/buy-text.png?15") center no-repeat;
    background-size: contain; }
  .shop__buy-done {
    margin-left: 2rem;
    height: 4rem;
    width: 6.4rem;
    background: url("../../public/assets/images/dialogs/shop/buy-done.png?15") center no-repeat;
    background-size: contain; }
  .shop__bought-count {
    height: 2.1rem;
    width: 3rem;
    margin-left: 6rem;
    opacity: 0;
    font-family: 'Janda Manatee Solid';
    font-size: 2.4rem;
    position: absolute;
    text-align: right;
    display: inline-block;
    z-index: 110; }
    .shop__bought-count--booster {
      color: #a15a0c; }
  .shop__animated-buy-done-frame {
    height: 6rem;
    width: 6.4rem;
    margin-left: 2rem;
    margin-top: 10.7rem;
    position: absolute;
    display: inline-block;
    overflow: hidden;
    z-index: 110; }
  .shop__animated-buy-done {
    height: 6rem;
    width: 65.8rem;
    position: relative;
    opacity: 0;
    background: url("../../public/assets/images/dialogs/daily-tokens/pevious-day-done-frame.png?15") center no-repeat;
    background-size: contain;
    z-index: 110; }

.shop-tokens {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 499;
  height: 70rem;
  width: 76rem;
  background-size: contain;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat; }
  .shop-tokens__tab-content-background {
    position: relative;
    z-index: 600;
    background: url("../../public/assets/images/dialogs/shop/tab-content-background.png?15") center no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 35.9rem;
    width: 60rem;
    background-size: 98% 100%;
    margin: auto;
    margin-top: 6.9rem;
    transition: margin 700ms; }
    .shop-tokens__tab-content-background--notab {
      margin-top: 4.2rem; }
  .shop-tokens__tab-background {
    position: relative;
    z-index: 700;
    padding-top: 1.5rem;
    background: url("../../public/assets/images/dialogs/shop/tokens/tokens-shop-tab-background.png?15") center no-repeat;
    background-size: 15rem 6rem;
    height: 6rem;
    width: 15rem;
    float: left;
    margin-left: 7rem;
    margin-right: -4rem;
    margin-top: 2.1rem;
    cursor: pointer;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    text-align: center;
    vertical-align: middle; }
    .shop-tokens__tab-background--double {
      margin-left: 14rem;
      margin-right: -7rem; }
      .shop-tokens__tab-background--double--selected {
        background: url("../../public/assets/images/dialogs/shop/tokens/tokens-shop-middle.png?15") center no-repeat;
        background-size: 15rem 6rem; }
    .shop-tokens__tab-background--selected {
      background: url("../../public/assets/images/dialogs/shop/tokens/tokens-shop-middle.png?15") center no-repeat;
      background-size: 15rem 6rem; }
  .shop-tokens__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 52rem;
    width: 66rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0 1.5rem 1rem 1.5rem #0321509a;
    background: url("../../public/assets/images/dialogs/shop/dialog-background.png?15") center no-repeat; }
  .shop-tokens__description {
    height: 2.3rem;
    width: 36.1rem;
    color: #fef6a5;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    margin: 4rem auto; }
  .shop-tokens__sms-token {
    position: absolute;
    height: 5.1rem;
    width: 38.6rem;
    margin: 11rem;
    margin-top: 5rem;
    border-radius: 1rem;
    border: 0.3rem solid #07254d;
    background-color: #3d669a; }
    .shop-tokens__sms-token__text {
      height: 5.1rem;
      color: #0c2048;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      margin-top: 1rem;
      letter-spacing: 0.05rem;
      text-align: center; }
      .shop-tokens__sms-token__text--send {
        position: absolute;
        height: 5.5rem;
        width: 40rem;
        color: #ffffff;
        margin-top: 14rem;
        margin-left: 10rem; }
      .shop-tokens__sms-token__text--price {
        position: absolute;
        height: 2.5rem;
        width: 35rem;
        color: #0c2048;
        font-size: 1rem;
        margin-top: 23rem;
        margin-left: 13rem; }
      .shop-tokens__sms-token__text--other-buy {
        position: absolute;
        height: 2.2rem;
        width: 22.8rem;
        color: #ffffff;
        font-size: 1.8rem;
        margin-top: 30rem;
        margin-left: 19rem;
        cursor: pointer; }
  .shop-tokens__vaucer-text {
    position: absolute;
    height: 2.2rem;
    width: 43.3rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    text-align: center;
    margin-top: 5rem;
    margin-left: 8rem; }
  .shop-tokens__vaucer-input {
    height: 6.1rem;
    width: 30.1rem;
    font-size: 2.4rem;
    color: #ffffff;
    border-radius: 1rem;
    border: 0.3rem solid #07254d;
    background-color: #3d669a;
    font-family: 'Janda Manatee Solid';
    margin-top: 10rem;
    margin-left: 14.8rem;
    text-align: center;
    text-transform: uppercase; }
  .shop-tokens__voucher-result {
    position: absolute;
    height: 2.2rem;
    width: 56rem;
    margin-left: 2rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    text-align: center;
    margin-top: 4.5rem; }
  .shop-tokens__close {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 5rem; }
    .shop-tokens__close:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }
  .shop-tokens__tab-description {
    padding-top: 5.5rem;
    height: 2rem;
    width: 60rem;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    color: white;
    letter-spacing: 0.08rem;
    text-align: center;
    margin-bottom: 2.5rem; }
  .shop-tokens__tab-content {
    overflow: hidden;
    height: 22.6rem;
    width: 61.4rem;
    margin: auto; }
  .shop-tokens__tab-cards {
    margin-left: 0rem;
    height: 25rem;
    overflow: hidden;
    margin-top: 3.5rem;
    transition: margin 700ms;
    padding-top: 2.5rem;
    padding-left: 6.5rem;
    padding-right: 1.5rem; }
  .shop-tokens__footer-text {
    position: absolute;
    height: 1.7rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    margin-top: 1rem;
    margin-left: 3.5rem; }
    .shop-tokens__footer-text--info {
      cursor: pointer; }
    .shop-tokens__footer-text--security {
      margin-left: 25rem;
      color: #6994c7; }
    .shop-tokens__footer-text--question {
      cursor: pointer;
      margin-left: 53rem; }
  .shop-tokens__security-lock {
    position: absolute;
    height: 2.2rem;
    width: 1.7rem;
    background: url("../../public/assets/images/dialogs/shop/tokens/lock.png?15") center no-repeat;
    background-size: contain;
    margin-left: 40.5rem;
    margin-top: 0.7rem; }

.statistics {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .statistics__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 61rem;
    height: 50.3rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .statistics__percentage_title {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 3.7rem;
    width: 8.5rem;
    font-size: 1.8rem;
    margin-left: 7.4rem;
    margin-top: 4rem;
    text-align: center;
    letter-spacing: 0.1rem;
    line-height: 1.6rem; }
    .statistics__percentage_title--game {
      position: absolute;
      width: 19rem;
      margin-left: 29rem;
      margin-top: -3rem; }
  .statistics__image {
    position: absolute;
    height: 10.2rem;
    width: 10.2rem;
    border-radius: 5rem;
    margin-top: 3rem;
    margin-left: 6.5rem;
    background-color: #082551; }
    .statistics__image--text {
      height: 12.3rem;
      width: 33rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-left: 5.1rem;
      margin-top: 3.3rem;
      height: 3.3rem;
      width: 10.2rem;
      font-size: 3.5rem;
      margin-left: 0rem;
      margin-top: 4.5rem;
      text-align: center;
      letter-spacing: 0.1rem;
      line-height: 1.6rem; }
    .statistics__image--tootlip {
      position: absolute;
      z-index: 1000;
      width: 10rem;
      visibility: hidden;
      opacity: 0;
      top: 0;
      border-radius: 0.5rem;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 0.5rem;
      box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
      transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
      opacity: 0;
      visibility: visible;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      margin-left: 10rem;
      margin-top: 7rem;
      border-radius: 0.5rem;
      text-align: center;
      width: 8rem; }
      .statistics__image--tootlip:hover {
        visibility: visible;
        opacity: 0.95; }
  .statistics__percent-box {
    height: 17.1rem;
    width: 40.1rem;
    background-color: #2b5289;
    margin: 0 auto;
    margin-top: 17rem;
    border-radius: 2rem; }
    .statistics__percent-box--text-letter {
      position: absolute;
      height: 12.3rem;
      width: 33rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-left: 5.1rem;
      margin-top: 3.3rem;
      height: 1.4rem;
      width: 'auto';
      font-size: 1.2rem;
      margin-left: 3rem;
      margin-top: 1.7rem;
      text-align: left;
      letter-spacing: 0.1rem; }
    .statistics__percent-box--text-game {
      position: absolute;
      height: 12.3rem;
      width: 33rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-left: 5.1rem;
      margin-top: 3.3rem;
      height: 2.1rem;
      font-size: 1.8rem;
      margin-left: 14rem;
      margin-top: 1.4rem;
      text-align: center;
      letter-spacing: 0.1rem; }
    .statistics__percent-box--box {
      position: absolute;
      margin-top: 5rem; }
    .statistics__percent-box--text-percent {
      height: 12.3rem;
      width: 33rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-left: 5.1rem;
      margin-top: 3.3rem;
      height: 2.1rem;
      font-size: 1.2rem;
      margin-left: 3rem;
      margin-top: -0.5rem;
      letter-spacing: 0.05rem;
      text-align: left; }
  .statistics__chart {
    position: absolute;
    height: 10.2rem;
    width: 10.2rem;
    margin-left: 25rem; }
  .statistics__won-divider {
    position: absolute;
    width: 33.5rem;
    height: 0.2rem;
    margin-top: -4rem;
    margin-left: 12rem;
    z-index: -1;
    background-color: #09254b; }
  .statistics__won-box {
    position: absolute;
    height: 15rem;
    height: 4rem;
    margin-top: -19.3rem;
    margin-left: 12rem;
    display: flex;
    flex-direction: column-reverse;
    height: 17rem; }
    .statistics__won-box--game {
      height: 1rem;
      width: 3.5rem;
      background-color: #09254b;
      margin-top: 0.2rem; }
    .statistics__won-box--text {
      height: 1.3rem;
      color: #09254b;
      font-family: "Janda Manatee Solid";
      font-size: 1rem;
      margin-top: 0.5rem;
      text-align: center;
      width: 6rem;
      margin-left: -1.5rem; }
  .statistics__image:hover .statistics__image--tootlip {
    visibility: visible;
    opacity: 0.95; }

.won-achievement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .won-achievement__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    height: 52.4rem;
    width: 32.3rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .won-achievement__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 1.8rem;
    width: 32rem;
    font-size: 2rem;
    text-align: center;
    padding-right: 2rem;
    margin: 0 auto;
    margin-top: 3.5rem; }
    .won-achievement__description--title {
      height: 5.6rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      line-height: 1.8rem;
      color: #afcae9;
      font-family: 'Janda Manatee Solid';
      font-size: 1.5rem;
      margin-top: 2rem; }
  .won-achievement__icon {
    height: 9.5rem;
    width: 9.1rem;
    margin-left: 11.5rem;
    margin-top: 2.5rem; }
  .won-achievement__buy-now {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 3.1rem; }
    .won-achievement__buy-now:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }
  .won-achievement__rewarded {
    height: 9.2rem;
    width: 26.7rem;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background-color: #1e436f; }
    .won-achievement__rewarded--open-gift-box {
      height: 5.9rem;
      width: 7.1rem;
      margin: 1.5rem 1.1rem 1.8rem 1.1rem;
      position: absolute;
      background: url("../../public/assets/images/dialogs/tournament-result/opened-giftbox.png?15") center no-repeat;
      background-size: contain; }
    .won-achievement__rewarded--award-icon-count {
      float: left; }
    .won-achievement__rewarded--award-content {
      margin: 0 auto;
      margin-top: 1.5rem;
      position: absolute;
      margin-left: 9rem; }
    .won-achievement__rewarded--award-tooltip-icon {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 1rem;
      margin-left: 1.25rem; }
      .won-achievement__rewarded--award-tooltip-icon--fly {
        margin-left: -2.8rem;
        position: absolute;
        opacity: 1; }
    .won-achievement__rewarded--award-tooltip-count {
      height: 1.2rem;
      width: 1.7rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      line-height: 2.9rem;
      margin-left: 1.5rem;
      text-align: center; }

.congratulation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .congratulation__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: 30rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .congratulation__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    margin-top: 5rem;
    height: 4rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.07rem; }
  .congratulation__image {
    position: absolute;
    height: 12.8rem;
    width: 10.2rem;
    margin-top: 3rem;
    margin-left: 2.8rem;
    background: url("../../public/assets/images/dialogs/dialog-info/owl-info.png?15") center no-repeat;
    background-size: contain; }
  .congratulation__accept {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 9rem; }
    .congratulation__accept:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.equal-score {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .equal-score__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 43.2rem;
    height: 31rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .equal-score__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 8.4rem;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    width: 25.2rem;
    margin-left: 15rem;
    margin-top: 2.6rem; }
  .equal-score__image {
    position: absolute;
    height: 11.3rem;
    width: 12.5rem;
    margin-top: 3rem;
    margin-left: 1.8rem;
    background: url("../../public/assets/images/dialogs/equal-score/owl-scale.png?15") center no-repeat;
    background-size: contain; }
  .equal-score__close {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 8.4rem; }
    .equal-score__close:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.appeal-opponent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .appeal-opponent__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: 30rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .appeal-opponent__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 5.5rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 3.5rem;
    letter-spacing: 0.07rem; }
  .appeal-opponent__image {
    position: absolute;
    height: 12.8rem;
    width: 10.2rem;
    margin-top: 3rem;
    margin-left: 2.8rem;
    background: url("../../public/assets/images/dialogs/dialog-info/owl-info.png?15") center no-repeat;
    background-size: contain; }
  .appeal-opponent__button-box {
    display: flex;
    width: 32rem;
    margin: 0 auto; }
  .appeal-opponent__button {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 9rem;
    margin-left: 2rem;
    margin-right: 2rem; }
    .appeal-opponent__button:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.shop_card--background-purchased {
  background: url("../../public/assets/images/dialogs/daily-tokens/pevious-day-background-top.png?15") center no-repeat;
  background-size: contain;
  height: 11.7rem;
  width: 10.3rem;
  position: relative;
  cursor: default; }

.shop_card--token {
  background: url("../../public/assets/images/dialogs/shop/tokens/token.png?15") center no-repeat;
  background-size: contain;
  margin: 0.1rem 0.5rem 0.1rem 1rem;
  width: 2.5rem;
  height: 2.3rem;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
  vertical-align: bottom; }

.shop_card--token0 {
  background: url("../../public/assets/images/dialogs/shop/tokens/token.png?15") center no-repeat;
  background-size: contain;
  margin: 0.1rem 0.5rem 0.2rem 1rem;
  width: 1.8rem;
  height: 1.6rem;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
  vertical-align: bottom; }

.shop_card--token1 {
  background: url("../../public/assets/images/dialogs/shop/helpkits/icon-help-kit.png?15") center no-repeat;
  background-size: contain;
  margin: 0.1rem 0.5rem 0.2rem 1rem;
  width: 1.8rem;
  height: 1.6rem;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
  vertical-align: bottom; }

.shop_card--token2 {
  background: url("../../public/assets/images/dialogs/shop/booster/icon-booster.png?15") center no-repeat;
  background-size: contain;
  margin: 0.1rem 0.5rem 0.2rem 1rem;
  width: 1.8rem;
  height: 1.6rem;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
  vertical-align: bottom; }

.shop_card--type0 {
  border-radius: 0.5rem;
  height: 16.1rem;
  width: 10.9rem;
  background-color: #fff5a4;
  box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
  margin-left: 0.7rem;
  margin-bottom: 0.5rem;
  float: left;
  padding: 0.3rem;
  cursor: pointer; }
  .shop_card--type0 .counter--type0 {
    background-size: contain;
    background: url("../../public/assets/images/dialogs/shop/booster/booster-count-holder.png?15") center no-repeat;
    margin: 0;
    width: 10rem;
    height: 2rem;
    position: absolute;
    top: 60%;
    z-index: 100; }
  .shop_card--type0 .counter-text {
    position: relative;
    color: white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-align: left; }
  .shop_card--type0 .background {
    background-size: contain;
    background: url("../../public/assets/images/dialogs/shop/booster/booster-card-inner-background.png?15") center no-repeat;
    height: 11.7rem;
    width: 10.3rem;
    position: relative; }
    .shop_card--type0 .background .card {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain; }
  .shop_card--type0 .item-coast {
    text-align: center;
    color: #a15a0c;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    line-height: 4rem; }
  .shop_card--type0 .buy-text {
    margin: 0.7rem auto;
    height: 4.5rem;
    width: 7.5rem;
    text-align: center;
    background-size: contain;
    background: url("../../public/assets/images/dialogs/shop/buy-text.png?15") center no-repeat; }

.shop_card--type1 {
  border-radius: 0.5rem;
  height: 16.1rem;
  width: 10.9rem;
  background-color: #fff5a4;
  box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
  margin-left: 0.7rem;
  margin-bottom: 0.5rem;
  float: left;
  padding: 0.3rem;
  cursor: pointer; }
  .shop_card--type1--purchased {
    border-radius: 0.5rem;
    height: 16.1rem;
    width: 10.9rem;
    margin-left: 0.7rem;
    margin-bottom: 0.5rem;
    float: left;
    padding: 0.3rem;
    cursor: pointer;
    background-color: #bbdaff;
    box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25); }
  .shop_card--type1 .background {
    background-size: contain;
    background: url("../../public/assets/images/dialogs/shop/hats/blue-background.png?15") center no-repeat;
    height: 11.7rem;
    width: 10.3rem;
    position: relative; }
    .shop_card--type1 .background .card {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .shop_card--type1 .background .counter {
      margin: 0;
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .shop_card--type1 .item-coast {
    margin: 0.7rem auto;
    height: 2.1rem;
    color: #a15a0c;
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    line-height: 2.9rem; }
  .shop_card--type1 .buy-text {
    margin: 0.7rem auto;
    height: 4.5rem;
    width: 7.5rem;
    background-size: contain;
    background: url("../../public/assets/images/dialogs/shop/buy-text.png?15") center no-repeat; }

.shop_card--type2 {
  border-radius: 0.5rem;
  height: 16.1rem;
  width: 10.9rem;
  background-color: #fff5a4;
  box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
  margin-left: 0.7rem;
  margin-bottom: 0.5rem;
  float: left;
  padding: 0.3rem;
  cursor: pointer; }
  .shop_card--type2--purchased {
    background-color: #bbdaff;
    box-shadow: 0.4rem 0.4rem 0.1rem rgba(3, 33, 80, 0.25);
    border-radius: 0.5rem;
    height: 16.1rem;
    width: 10.9rem;
    margin-left: 0.7rem;
    margin-bottom: 0.5rem;
    float: left;
    padding: 0.3rem;
    cursor: pointer; }
  .shop_card--type2 .background {
    background-size: contain;
    background: url("../../public/assets/images/dialogs/shop/hats/blue-background.png?15") center no-repeat;
    height: 11.7rem;
    width: 10.3rem; }
    .shop_card--type2 .background .package1 {
      background-size: contain;
      background: url("../../public/assets/images/dialogs/shop/hats/hat-1.png?15") center no-repeat;
      height: 6.3rem;
      width: 7.2rem;
      padding: 5rem 5.5rem; }
    .shop_card--type2 .background .package2 {
      background-size: contain;
      background: url("../../public/assets/images/dialogs/shop/hats/hat-2.png?15") center no-repeat;
      height: 6.3rem;
      width: 7.2rem;
      padding: 5rem 5.5rem; }
    .shop_card--type2 .background .package3 {
      background-size: contain;
      background: url("../../public/assets/images/dialogs/shop/hats/hat-3.png?15") center no-repeat;
      height: 6.3rem;
      width: 7.2rem;
      padding: 5rem 5.5rem; }
    .shop_card--type2 .background .package4 {
      background-size: contain;
      background: url("../../public/assets/images/dialogs/shop/hats/hat-4.png?15") center no-repeat;
      height: 6.3rem;
      width: 7.2rem;
      padding: 5rem 5.5rem; }
    .shop_card--type2 .background .package5 {
      background-size: contain;
      background: url("../../public/assets/images/dialogs/shop/hats/hat-5.png?15") center no-repeat;
      height: 6.3rem;
      width: 7.2rem;
      padding: 5rem 5.5rem; }

.connection-lost {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .connection-lost__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 43.2rem;
    height: 30rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .connection-lost__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 8rem;
    width: 23rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 4rem;
    letter-spacing: 0.07rem; }
  .connection-lost__image {
    position: absolute;
    height: 12.8rem;
    width: 9.5rem;
    margin-top: 3rem;
    margin-left: 3.4rem;
    background: url("../../public/assets/images/dialogs/reconnect/owl-error.png?15") center no-repeat;
    background-size: contain; }
  .connection-lost__confirm {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 6.3rem; }
    .connection-lost__confirm:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.feather-booster {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .feather-booster__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 32.3rem;
    height: 31rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .feather-booster__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 2.4rem;
    width: 32.3rem;
    color: #fef6a5;
    font-size: 2rem;
    margin-left: -0.6rem;
    text-align: center; }
  .feather-booster__holder {
    height: 8rem;
    width: 27rem;
    margin-left: 2.1rem;
    margin-top: 1.8rem;
    border-radius: 0.5rem;
    display: flex;
    background-color: #032150; }
    .feather-booster__holder__booster-icon {
      height: 6rem;
      width: 6rem;
      margin-top: 0.5rem;
      margin-left: 1rem; }
      .feather-booster__holder__booster-icon--fly-image {
        position: absolute;
        height: 6rem;
        width: 6rem; }
    .feather-booster__holder__title {
      height: 12.3rem;
      width: 33rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-left: 5.1rem;
      margin-top: 3.3rem;
      height: 2rem;
      width: auto;
      margin-left: 1rem;
      font-size: 2.5rem;
      text-align: center; }
    .feather-booster__holder__tokens-silver-icon {
      height: 3.9rem;
      width: 6.2rem;
      margin-top: 1.5rem;
      margin-left: 1.5rem;
      background: url("../../public/assets/images/dialogs/feather-booster/icon-tokens-silver-gold.png?15") center no-repeat;
      background-size: contain; }
  .feather-booster__accept {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 2rem; }
    .feather-booster__accept:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }
  .feather-booster__status {
    font-size: 1rem;
    color: white;
    height: 1.6rem;
    letter-spacing: 0.03rem;
    margin-top: 0.8rem;
    text-align: center;
    font-family: 'Janda Manatee Solid'; }

.new-day {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .new-day__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: 30rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .new-day__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 5rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 4rem;
    letter-spacing: 0.07rem; }
  .new-day__image {
    position: absolute;
    height: 12.8rem;
    width: 10.2rem;
    margin-top: 3rem;
    margin-left: 2.8rem;
    background: url("../../public/assets/images/dialogs/dialog-info/owl-info.png?15") center no-repeat;
    background-size: contain; }
  .new-day__close {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 9rem; }
    .new-day__close:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.faq {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain;
  padding: 10rem; }
  .faq__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 55.2rem;
    height: 34.7rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .faq__description-box {
    margin-top: 2rem; }
  .faq__question {
    font-family: 'Janda Manatee Solid';
    width: 47rem;
    margin: 0 auto;
    margin-top: 0.5rem;
    text-align: left;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
    color: #09254b;
    cursor: pointer; }
    .faq__question--answer {
      position: relative;
      font-size: 1.2rem;
      color: #ffffff;
      cursor: auto;
      -moz-user-select: text;
      -webkit-user-select: text; }

.rang-list-help {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .rang-list-help__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: 36rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .rang-list-help__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    width: 39rem;
    height: auto;
    margin-left: 1rem;
    margin-top: 2rem;
    letter-spacing: 0.05rem; }
  .rang-list-help__accept {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 3rem; }
    .rang-list-help__accept:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.reconnect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .reconnect__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 43.2rem;
    height: 22rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .reconnect__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 6rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 3rem;
    letter-spacing: 0.07rem; }
  .reconnect__image {
    position: absolute;
    height: 12.8rem;
    width: 9.5rem;
    margin-top: 1rem;
    margin-left: 3.4rem;
    background: url("../../public/assets/images/dialogs/reconnect/owl-error.png?15") center no-repeat;
    background-size: contain; }
  .reconnect__recconnect-progres-bar {
    display: block;
    position: absolute;
    margin-left: 14rem;
    margin-top: 3rem;
    height: 1.5rem;
    width: 20.1rem;
    background-color: #111d36; }
  .reconnect__recconnect-progress {
    height: 1.5rem;
    background-color: #8cc92e; }

.resume {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .resume__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 43.2rem;
    height: 22rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .resume__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 2.5rem;
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.07rem;
    text-align: center; }
  .resume__resume-game {
    height: 8.7rem;
    width: 31.2rem;
    margin: 3rem auto 1rem auto;
    background: url("../../public/assets/images/dialogs/resume-game/resume-game-button.png?15") no-repeat center;
    background-size: contain;
    position: relative;
    cursor: pointer; }
    .resume__resume-game__button {
      width: 100%;
      height: 8.7rem;
      display: block; }
      .resume__resume-game__button:hover::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/dialogs/resume-game/resume-game-button-over.png?15") no-repeat center;
        background-size: contain; }
    .resume__resume-game--label {
      position: absolute;
      top: 2.5rem;
      left: 9.5rem;
      height: 3.4rem;
      z-index: 2;
      background: url("../../public/assets/images/dialogs/resume-game/resume-game-label.png?15") center no-repeat;
      background-size: contain; }

.server-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .server-info__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 43.2rem;
    height: 22rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .server-info__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 13rem;
    width: 22rem;
    margin-left: 15rem;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 2rem;
    letter-spacing: 0.07rem; }
  .server-info__image {
    position: absolute;
    height: 12.8rem;
    width: 10.2rem;
    margin-top: 1rem;
    margin-left: 3.4rem;
    background: url("../../public/assets/images/dialogs/dialog-info/owl-info.png?15") center no-repeat;
    background-size: contain; }
  .server-info__confirm {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 5.3rem; }
    .server-info__confirm:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.tokens-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain;
  padding: 10rem; }
  .tokens-info__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 45.2rem;
    height: 34.7rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .tokens-info__description-box {
    margin-top: 4rem; }
  .tokens-info__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 4.2rem;
    width: 37rem;
    margin: 0 auto;
    text-align: left;
    letter-spacing: 0.06rem; }
    .tokens-info__description--big {
      margin-top: 1rem; }
    .tokens-info__description--email {
      margin-bottom: -0.5rem;
      text-align: center;
      color: #09254b; }

.tournament-help {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .tournament-help__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 43.2rem;
    height: 37rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .tournament-help__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 20rem;
    width: 33rem;
    letter-spacing: 0.09rem;
    font-size: 1rem;
    color: #fefefe;
    margin: 0 auto;
    margin-top: 3.3rem; }
  .tournament-help__close {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 3rem; }
    .tournament-help__close:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.inbox-message {
  min-height: 7.6rem;
  height: fit-content;
  width: 66.2rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  border-radius: 0.8rem;
  background-color: #1f457c; }
  .inbox-message__icon {
    position: absolute;
    height: 5.5rem;
    width: 4.8rem;
    margin-top: 1rem;
    margin-left: 1.7rem;
    background: url("../../public/assets/images/dialogs/inbox/owl-system-notification.png?15") center no-repeat; }
  .inbox-message__title {
    position: relative;
    height: 1.1rem;
    width: 25rem;
    padding-top: 0.5rem;
    margin-left: 8.3rem;
    color: #d59f1d;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem; }
    .inbox-message__title-read {
      color: #00254e; }
  .inbox-message__text {
    position: relative;
    min-height: 1.4rem;
    height: fit-content;
    width: 46.5rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    margin-left: 8.3rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem; }
    .inbox-message__text-read {
      color: #3768a2; }
  .inbox-message__link {
    position: relative;
    width: fit-content;
    margin-left: 8.3rem;
    color: #d59f1d;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    padding-bottom: 0.4rem;
    cursor: pointer; }

.chat__container {
  position: absolute;
  margin-top: 1.5rem;
  z-index: 1000;
  opacity: 70%; }

.chat__button {
  background: url("../../public/assets/images/game/semaphore/semaphore-chet.png?15") no-repeat center;
  background-size: 3.4rem 3.5rem;
  height: 3.5rem;
  width: 3.4rem;
  margin-left: 11.5rem;
  margin-top: 0.4rem; }
  .chat__button--red {
    margin-left: 39rem;
    background: url("../../public/assets/images/game/semaphore/semaphore-chet-flipped.png?15") no-repeat center; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.chat__tooltip {
  position: absolute;
  z-index: 1000;
  width: 10rem;
  visibility: hidden;
  opacity: 0;
  top: 0;
  border-radius: 0.5rem;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0.5rem;
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.63);
  transition: opacity 200ms linear 50ms, visibility 200ms linear 50ms;
  height: 32.7rem;
  width: 18.5rem;
  margin-left: 13rem;
  margin-top: 3rem;
  visibility: visible;
  opacity: 1;
  z-index: 2;
  animation: fade-in 0.35s ease-in both; }
  .chat__tooltip:hover {
    visibility: visible;
    opacity: 0.95; }
  .chat__tooltip--red {
    margin-left: 27rem; }
  .chat__tooltip__tab-button {
    height: 4.4rem;
    width: 4rem;
    margin-left: 0.2rem;
    background: url("../../public/assets/images/game/chat/tab-button.png?15") no-repeat center;
    background-size: contain; }
    .chat__tooltip__tab-button:hover {
      background: url("../../public/assets/images/game/chat/tab-button-over.png?15") no-repeat center;
      background-size: contain; }
    .chat__tooltip__tab-button__tab-icon {
      position: absolute;
      height: 3rem;
      width: 3.3rem;
      margin-top: -1.8rem;
      margin-left: 0.35rem; }
      .chat__tooltip__tab-button__tab-icon--text {
        background: url("../../public/assets/images/game/chat/chat-tab-icon-text.png?15") no-repeat center;
        background-size: 3rem 3.3rem; }
      .chat__tooltip__tab-button__tab-icon--emoticon {
        background: url("../../public/assets/images/game/chat/chat-tab-icon-emoticon.png?15") no-repeat center;
        background-size: 3rem 3.3rem; }
      .chat__tooltip__tab-button__tab-icon--sticker {
        background: url("../../public/assets/images/game/chat/chat-tab-icon-sticker.png?15") no-repeat center;
        background-size: 3rem 3.3rem; }
      .chat__tooltip__tab-button__tab-icon--sound {
        background: url("../../public/assets/images/game/chat/chat-tab-icon-sound.png?15") no-repeat center;
        background-size: 3rem 3.3rem; }
  .chat__tooltip__tab-box {
    display: flex; }
  .chat__tooltip__tab-button--selected {
    height: 4.4rem;
    width: 4rem;
    background: url("../../public/assets/images/game/chat/tab-button-selected.png?15") no-repeat center;
    background-size: contain; }
  .chat__tooltip__get-more {
    height: 3.6rem;
    width: 18rem;
    margin-left: -0.2rem;
    cursor: pointer;
    background: url("../../public/assets/images/game/chat/shop.png?15") no-repeat center;
    background-size: 18rem 3.6rem; }
    .chat__tooltip__get-more:hover {
      background: url("../../public/assets/images/game/chat/shop-over.png?15") no-repeat center;
      background-size: 18rem 3.6rem; }

.chat__type {
  height: 23.5rem;
  width: 18rem;
  margin-top: 0.2rem;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-color: #fc0 rgba(105, 148, 199, 0.2);
  scrollbar-width: thin; }
  .chat__type::-webkit-scrollbar {
    width: 0.5rem; }
  .chat__type::-webkit-scrollbar-track {
    background: rgba(105, 148, 199, 0.2); }
  .chat__type::-webkit-scrollbar-thumb {
    background: #fc0; }
  .chat__type__sound {
    height: 13.1rem;
    width: 16.4rem;
    background: url("../../public/assets/images/game/chat/chat-sounds-bacground.png?15") no-repeat center;
    background-size: contain; }
  .chat__type__message_no_background {
    z-index: 2;
    opacity: 0;
    position: absolute;
    height: 7.2rem;
    width: 8.9rem;
    margin-left: 11rem;
    margin-top: -6rem;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    pointer-events: none;
    align-items: center; }
  .chat__type__message_no_background_red {
    z-index: 2;
    opacity: 0;
    position: absolute;
    height: 7.2rem;
    width: 8.9rem;
    margin-left: 34rem;
    margin-top: -6rem;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    pointer-events: none;
    align-items: center; }
  .chat__type__message {
    z-index: 2;
    opacity: 0;
    position: absolute;
    height: 7.2rem;
    width: 8.9rem;
    margin-left: 11rem;
    margin-top: -6rem;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #06142f;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    pointer-events: none;
    background: url("../../public/assets/images/game/chat/chat-text-background.png?15") no-repeat center;
    background-size: contain; }
    .chat__type__message--red {
      margin-left: 34rem !important;
      background: url("../../public/assets/images/game/chat/chat-text-background-flipped.png?15") no-repeat center;
      background-size: contain; }
    .chat__type__message--sticker {
      margin-top: -0.5rem;
      height: 7.2rem;
      width: 8.9rem; }
    .chat__type__message--sticker_red {
      margin-top: -0.5rem;
      height: 7.2rem;
      width: 8.9rem;
      transform: scaleX(-1); }
    .chat__type__message--emotions {
      margin-top: -0.5rem;
      height: 4.5rem;
      width: 4.5rem; }
    .chat__type__message__sound {
      margin-top: -1rem;
      height: 4.5rem;
      width: 4.5rem; }
      .chat__type__message__sound--1 {
        background: url("../../public/assets/images/dialogs/shop/sounds/1.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
      .chat__type__message__sound--2 {
        background: url("../../public/assets/images/dialogs/shop/sounds/2.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
      .chat__type__message__sound--3 {
        background: url("../../public/assets/images/dialogs/shop/sounds/3.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
      .chat__type__message__sound--4 {
        background: url("../../public/assets/images/dialogs/shop/sounds/4.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
      .chat__type__message__sound--5 {
        background: url("../../public/assets/images/dialogs/shop/sounds/5.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
      .chat__type__message__sound--6 {
        background: url("../../public/assets/images/dialogs/shop/sounds/6.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
      .chat__type__message__sound--7 {
        background: url("../../public/assets/images/dialogs/shop/sounds/7.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
      .chat__type__message__sound--8 {
        background: url("../../public/assets/images/dialogs/shop/sounds/8.png?15") no-repeat center;
        background-size: 4.5rem 4.5rem; }
    .chat__type__message--no_image {
      width: 0;
      height: 0; }
  .chat__type__element {
    cursor: pointer;
    background-color: #000000; }
    .chat__type__element:hover {
      border: 0.2rem solid #709eec; }
    .chat__type__element--sound-box {
      height: 1.7rem;
      width: 1.7rem;
      margin-left: 1rem;
      margin-right: 1rem;
      background: url("../../public/assets/images/game/chat/lock-1.png?15") no-repeat center;
      background-size: contain; }
      .chat__type__element--sound-box--dark {
        background: url("../../public/assets/images/game/chat/chat-sound-icon-note-dark.png?15") no-repeat center;
        background-size: 1.7rem 1.7rem; }
      .chat__type__element--sound-box--light {
        background: url("../../public/assets/images/game/chat/chat-sound-icon-note-light.png?15") no-repeat center;
        background-size: 1.7rem 1.7rem; }
    .chat__type__element--text {
      margin: 0 auto;
      height: 2.3rem;
      width: 15rem;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 2.6rem; }
      .chat__type__element--text--disable {
        border: none !important;
        cursor: auto;
        opacity: 0.3; }
      .chat__type__element--text--yellow {
        color: #ffea74; }
      .chat__type__element--text--egyptian-blue {
        color: #10559b; }
      .chat__type__element--text--cinnabar {
        color: #f1483b; }
      .chat__type__element--text--lime-green {
        color: #3bb926; }
    .chat__type__element--emotion {
      height: 2.4rem;
      width: 2.5rem;
      margin-left: 0.3rem; }
      .chat__type__element--emotion--disable {
        height: 2.4rem;
        width: 2.5rem;
        border: none !important;
        cursor: auto;
        opacity: 0.3; }
    .chat__type__element--sticker {
      height: 5.2rem;
      width: 5.7rem; }
      .chat__type__element--sticker--disable {
        height: 5.2rem;
        width: 5.7rem;
        border: none !important;
        cursor: auto;
        opacity: 0.3; }
    .chat__type__element--sound {
      background-color: transparent;
      margin: 0 auto;
      height: 2.3rem;
      width: '90%';
      border-radius: 1rem;
      color: #ffea74;
      font-family: 'Janda Manatee Solid';
      font-size: 1.2rem;
      line-height: 2.6rem;
      margin-top: 1rem;
      margin-bottom: -0.5rem; }
      .chat__type__element--sound--disable {
        border: none !important;
        cursor: auto;
        opacity: 0.3; }

.game-content {
  position: relative;
  height: 57rem;
  width: 76rem;
  margin-left: 6rem;
  padding-left: 1rem;
  padding-top: 4rem;
  margin-top: -4rem;
  overflow-y: visible; }

.happy-birthday {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .happy-birthday__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    height: 42.4rem;
    width: 32.3rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .happy-birthday__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 1.8rem;
    width: 32rem;
    font-size: 1.8rem;
    text-align: center;
    padding-right: 2rem;
    margin: 0 auto;
    color: white;
    font-family: 'Janda Manatee Solid';
    margin-top: 2.5rem; }
  .happy-birthday__icon {
    height: 9.5rem;
    width: 9.1rem;
    margin-left: 10.5rem;
    margin-top: 2rem; }
  .happy-birthday__buy-now {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 3.1rem; }
    .happy-birthday__buy-now:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }
  .happy-birthday__rewarded {
    height: 9.2rem;
    width: 26.7rem;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background-color: #1e436f; }
    .happy-birthday__rewarded--open-gift-box {
      height: 5.9rem;
      width: 7.1rem;
      margin: 1.5rem 1.1rem 1.8rem 1.1rem;
      position: absolute;
      background: url("../../public/assets/images/dialogs/tournament-result/opened-giftbox.png?15") center no-repeat;
      background-size: contain; }
    .happy-birthday__rewarded--award-icon-count {
      float: left; }
    .happy-birthday__rewarded--award-content {
      margin: 0 auto;
      margin-top: 1.5rem;
      position: absolute;
      margin-left: 12rem; }
    .happy-birthday__rewarded--award-tooltip-icon {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 1rem;
      margin-left: 1.25rem; }
      .happy-birthday__rewarded--award-tooltip-icon--fly {
        margin-left: -2.8rem;
        position: absolute;
        opacity: 1; }
    .happy-birthday__rewarded--award-tooltip-count {
      height: 1.2rem;
      width: 1.7rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      line-height: 2.9rem;
      margin-left: 1.5rem;
      text-align: center; }

.decline-game {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .decline-game__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: 26rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .decline-game__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 5.2rem;
    width: 40rem;
    line-height: 2.4rem;
    margin-left: 1rem;
    text-align: center;
    margin-right: 1rem;
    font-size: 1.8rem;
    letter-spacing: 0.07rem;
    margin-top: 4rem; }
  .decline-game__button-box {
    display: flex;
    width: 28rem;
    margin: 0 auto; }
  .decline-game__button {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 4rem; }
    .decline-game__button:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.survey {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .survey__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: fit-content;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .survey__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 4.2rem;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
    line-height: 2rem;
    text-align: center;
    margin-right: 5rem;
    margin-left: 5rem;
    margin-top: 9.5rem;
    margin-bottom: 0.5rem; }
  .survey__additional-text {
    min-height: 2rem;
    height: fit-content;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    line-height: 1.4rem;
    text-align: center;
    margin-right: 5rem;
    margin-left: 5rem;
    color: #12284a;
    font-family: 'Janda Manatee Solid'; }
  .survey__image {
    position: absolute;
    height: 20rem;
    width: 20rem;
    margin-top: -9rem;
    margin-left: -4rem;
    background: url("../../public/assets/images/dialogs/survey/earn-tokens.png?15") center no-repeat;
    background-size: contain; }
  .survey__accept {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 0.8rem;
    margin-bottom: 1rem; }
    .survey__accept:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.new-app-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .new-app-info__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 66rem;
    height: 66rem;
    background-size: contain; }
  .new-app-info__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 10.5rem;
    width: 22rem;
    margin-left: 15rem;
    margin-top: 3rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.05rem; }
  .new-app-info__image-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center; }
  .new-app-info__image {
    position: absolute;
    height: 80%;
    width: 80%;
    margin-top: -10rem; }
  .new-app-info__confirm {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: -10rem;
    width: 15rem; }
    .new-app-info__confirm:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%;
      width: 15rem; }

.friend-challenge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .friend-challenge__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: 30rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .friend-challenge__image {
    position: absolute;
    height: 14rem;
    width: 14rem;
    margin-top: 2rem;
    margin-left: 13.2rem;
    border-radius: 1rem; }
  .friend-challenge__button-box {
    display: flex;
    width: 32rem;
    margin: 0 auto; }
  .friend-challenge__button {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 18rem;
    margin-left: 2rem;
    margin-right: 2rem; }
    .friend-challenge__button:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.new-payment-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .new-payment-info__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 48.2rem;
    height: 35rem;
    background: #3768a2;
    background-size: contain; }
  .new-payment-info__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 14.5rem;
    width: 28rem;
    margin-left: 15rem;
    margin-top: 3rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.07rem;
    white-space: pre-wrap; }
  .new-payment-info__image {
    position: absolute;
    height: 12.8rem;
    width: 9.5rem;
    margin-top: 3rem;
    margin-left: 3.4rem;
    background: url("../../public/assets/images/dialogs/dialog-info/owl-tokens-icon.png?15") center no-repeat;
    background-size: contain; }
  .new-payment-info__confirm {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    display: block;
    margin: 0 auto;
    margin-top: 5.3rem;
    opacity: 0; }
    .new-payment-info__confirm:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }
  .new-payment-info__banner {
    height: 34rem;
    width: 28rem;
    left: 28rem;
    top: 3rem;
    background-size: contain;
    position: absolute;
    pointer-events: none;
    background: url("../../public/assets/images/dialogs/dialog-info/tokens-banner.png?15") no-repeat; }
  .new-payment-info__single-token {
    height: 4rem;
    width: 4rem;
    left: 14rem;
    top: 32rem;
    background-size: contain;
    position: absolute;
    pointer-events: none;
    background: url("../../public/assets/images/dialogs/dialog-info/token-banner-single.png?15") no-repeat; }

.b2b-login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 76rem;
  z-index: 10000;
  background: url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
  background-size: contain; }
  .b2b-login__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 30rem;
    width: 43.2rem;
    border: 0.7rem solid #032150;
    border-radius: 1.8rem;
    background-color: #3869a3;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain;
    width: 42.3rem;
    height: 33rem;
    background: #3869a3, url("../../public/assets/images/lobby/friends/invite/dialog-black-background.png?15") center no-repeat;
    background-size: contain; }
  .b2b-login__button-box {
    display: block;
    width: 35rem;
    margin: 0 auto;
    font-size: 1.5rem;
    padding: 0.4rem;
    padding-top: 2rem; }
  .b2b-login__container {
    display: flex;
    justify-content: center; }
  .b2b-login__description {
    height: 4.5rem;
    width: 35rem;
    line-height: 2.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.5rem;
    letter-spacing: 0.07rem;
    font-family: 'Janda Manatee Solid';
    color: #ffffff; }
    .b2b-login__description--error {
      color: #f1483b;
      text-align: center;
      margin-top: 0rem;
      width: 100%;
      color: #dfb465; }
  .b2b-login__input {
    align-self: center;
    height: 3rem;
    width: 15rem;
    margin-top: -3rem;
    padding-left: 0.5rem; }
  .b2b-login__button {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    margin-top: 0rem;
    margin-left: 15rem; }
    .b2b-login__button:hover {
      box-sizing: border-box;
      height: 4.625rem;
      width: 12.875rem;
      color: #00254e;
      font-family: 'Janda Manatee Solid';
      font-size: 1.6rem;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
      background-size: 100% 100%;
      background-color: #ffda00;
      background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
      background-size: 100% 100%; }

.b2b-captain__background {
  position: absolute;
  height: 30rem;
  width: 50.4rem;
  margin-top: -1rem;
  margin-left: 6rem;
  border-style: solid;
  border-color: #01204d;
  border-width: 0.2rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  background: #1f457c;
  z-index: 0;
  padding: 4rem 1rem 1rem 1rem;
  overflow: hidden; }

.b2b-captain__description {
  font-size: 2.1rem;
  color: #a0dffb;
  text-align: center;
  font-family: 'Janda Manatee Solid'; }

.b2b-captain__captain-chosen {
  margin-top: -18rem;
  margin-left: 43rem;
  position: absolute;
  height: 10rem;
  width: 50rem; }

.b2b-captain__sign-up {
  box-sizing: border-box;
  height: 4.625rem;
  width: 12.875rem;
  color: #00254e;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  background-color: #ffda00;
  background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
  background-size: 100% 100%;
  display: block;
  margin: 0 auto;
  margin-top: 3rem; }
  .b2b-captain__sign-up:hover {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
    background-size: 100% 100%; }

.b2b-my-team {
  height: 9rem;
  width: 26.5rem;
  margin-top: 3rem;
  align-items: center;
  display: inline-block;
  margin-left: 5rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5rem; }
  .b2b-my-team__header {
    position: absolute;
    margin-top: -2rem;
    margin-left: 4.1rem;
    width: 70%;
    z-index: 2;
    color: #afd1fe;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2.5rem;
    font-family: 'Janda Manatee Solid'; }
  .b2b-my-team__team-name {
    position: absolute;
    margin-top: -3.5rem;
    margin-left: 13rem;
    min-width: 30%;
    max-width: 45%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    background: #082551;
    border-radius: 1.5rem;
    color: #ffffff;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 1.3rem;
    padding: 0.3rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }
  .b2b-my-team__description {
    height: 12.3rem;
    width: 33rem;
    color: #ffffff;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-left: 5.1rem;
    margin-top: 3.3rem;
    height: 8rem;
    width: 23rem;
    margin-left: 10rem;
    font-size: 1.2rem;
    color: #ffffff;
    line-height: 1.5rem;
    margin-top: 2rem;
    letter-spacing: 0.05rem; }
  .b2b-my-team__me {
    color: #fede04; }
  .b2b-my-team__image {
    position: absolute;
    height: 4.8rem;
    width: 4.8rem;
    margin-left: 3.8rem;
    background-size: contain; }
  .b2b-my-team__change {
    position: absolute;
    text-align: left;
    height: 1.5rem;
    margin-left: 4.3rem;
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    margin-top: 5.5rem;
    letter-spacing: 0.07rem;
    font-family: 'Janda Manatee Solid'; }
  .b2b-my-team__change-label:hover {
    font-size: 1.07rem;
    cursor: pointer;
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */ }
  .b2b-my-team__wait-label {
    color: rgba(0, 0, 0, 0.4); }

.b2b-ranklist {
  height: 30.5rem;
  width: 33.5rem;
  margin: -1rem 5rem 0 1.5rem;
  background-size: contain;
  font-family: 'Janda Manatee Solid';
  color: #ffffff;
  padding-top: 3.8rem;
  position: absolute; }
  .b2b-ranklist__row {
    display: flex;
    height: 2.45rem;
    width: 100%; }
    .b2b-ranklist__row--header {
      margin-top: 0rem;
      background: url("../../public/assets/images/lobby/tournament/my-position-background.png?15") no-repeat center;
      background-size: cover; }
    .b2b-ranklist__row--odd {
      background: rgba(0, 0, 0, 0.3); }
    .b2b-ranklist__row--even {
      background: rgba(0, 0, 0, 0.1); }
  .b2b-ranklist__my-row {
    position: relative;
    top: -0.3rem;
    padding-top: 0.1rem; }
  .b2b-ranklist__my-row-box {
    height: 25rem;
    width: 33.5rem;
    position: absolute;
    top: -0.3rem;
    z-index: -100;
    padding-top: 0.1rem; }
    .b2b-ranklist__my-row-box--active {
      z-index: 1000; }
    .b2b-ranklist__my-row-box--cover {
      z-index: 1000;
      margin-top: 0.2rem;
      background-color: rgba(2, 10, 41, 0.9); }
  .b2b-ranklist__item-number {
    font-size: 1.2rem;
    text-align: center;
    margin: 0.5rem;
    width: 1.9rem;
    flex-grow: 0;
    color: #6eacce; }
    .b2b-ranklist__item-number--expanded {
      color: #ffffff; }
  .b2b-ranklist__name {
    font-size: 1.2rem;
    text-align: left;
    margin: 0.5rem;
    color: #6eacce;
    flex-grow: 1;
    width: 5rem;
    display: flex;
    align-items: center; }
    .b2b-ranklist__name--expanded {
      color: #ffffff; }
  .b2b-ranklist__name .b2b-team__tooltip {
    visibility: hidden;
    opacity: 0; }
  .b2b-ranklist__name:hover .b2b-team__tooltip {
    visibility: visible;
    opacity: 0.95; }
  .b2b-ranklist__item-score-container {
    width: 10rem;
    display: flex;
    align-items: flex-end; }
    .b2b-ranklist__item-score-container--odd {
      background: rgba(0, 0, 0, 0.4); }
    .b2b-ranklist__item-score-container--even {
      background: rgba(0, 0, 0, 0.2); }
  .b2b-ranklist__item-score {
    font-size: 1.2rem;
    margin: 0.5rem;
    flex-grow: 0;
    min-width: 3.9rem;
    text-align: right;
    color: #6eacce; }
    .b2b-ranklist__item-score--expanded {
      color: #ffffff; }
  .b2b-ranklist__item-box-active {
    margin-top: 0.5rem;
    z-index: 10000;
    position: relative; }
  .b2b-ranklist__content {
    display: flex;
    margin-left: 4rem; }
  .b2b-ranklist__box {
    position: absolute;
    visibility: hidden; }
  .b2b-ranklist__box-fade-out-in {
    position: absolute;
    animation-iteration-count: 1;
    animation: fade-out-in 5000ms;
    -webkit-animation: fade-out-in 5000ms; }
  .b2b-ranklist__box-fade-out {
    opacity: 0;
    animation: fade 10000ms infinite;
    -webkit-animation: fade 10000ms infinite; }
  .b2b-ranklist__box-fade-in {
    opacity: 0;
    animation: fade 10000ms 5000ms infinite;
    -webkit-animation: fade 10000ms 5000ms infinite; }
  .b2b-ranklist__dropdown {
    display: flex;
    animation: fade-box 500ms linear forwards;
    -webkit-animation: fade-box 500ms linear forwards; }

@keyframes fade-box {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3rem); }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); } }

@-webkit-keyframes fade-box {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3rem); }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); } }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade-out-in {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade-out-in {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.b2b-game-status {
  height: 8rem;
  width: 75%;
  margin-top: 3rem;
  align-items: center;
  display: inline-block;
  margin-left: 5rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5rem; }
  .b2b-game-status__header {
    position: absolute;
    display: block;
    margin-top: -1.5rem;
    margin-left: 6rem;
    z-index: 2;
    color: #afd1fe;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2.5rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }
  .b2b-game-status__title {
    margin-top: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    color: #fede04;
    text-shadow: -0.2rem -0.2rem 0 #06142f, 0 -0.2rem 0 #06142f, 0.2rem -0.2rem 0 #06142f, 0.2rem 0 0 #06142f, 0.2rem 0.2rem 0 #06142f, 0 0.2rem 0 #06142f, -0.2rem 0.2rem 0 #06142f, -0.2rem 0 0 #06142f;
    font-size: 2.8rem;
    font-family: 'Janda Manatee Solid';
    text-align: center; }

.collaboration {
  font-family: 'Janda Manatee Solid';
  background: url("../../public/assets/images/b2b/collaboration-background.png?15") no-repeat center center;
  height: 70rem;
  width: 100%;
  position: relative;
  z-index: 1000;
  background-size: contain;
  top: 0;
  left: -1rem; }
  .collaboration__hide-picture {
    height: 4rem;
    width: 5rem;
    background: white;
    position: absolute;
    z-index: 1000000;
    bottom: 0;
    top: 36rem; }
  .collaboration__chat-header {
    display: flex;
    position: absolute;
    z-index: 1000;
    height: 4rem;
    width: 26rem;
    margin-left: 1rem;
    background-color: #23467E; }
  .collaboration__chat-header-btn {
    width: 13rem;
    background-color: #A2C9F0;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    font-size: 1.4rem;
    height: 100%;
    color: white;
    padding-top: 1.5rem;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem; }
    .collaboration__chat-header-btn--active {
      font-weight: bold;
      background-color: white;
      color: #23467E;
      text-decoration: underline; }
      .collaboration__chat-header-btn--active:hover {
        cursor: default; }
    .collaboration__chat-header-btn:hover {
      cursor: pointer;
      font-weight: bold; }
  .collaboration__chat {
    position: absolute;
    border: 0;
    width: 26rem;
    margin-left: 1rem; }
    .collaboration__chat div.arena-chat {
      height: 44.5rem; }
    .collaboration__chat div.live-chat-room {
      background: #A2C9F0;
      height: 38rem; }
    .collaboration__chat div.chat-room--chat--header {
      display: none;
      background-color: #23467E !important; }
    .collaboration__chat div.react-input-emoji--container {
      font-size: 1rem !important; }
    .collaboration__chat div.chat-room--private--group-channels--menu--container {
      display: none; }
    .collaboration__chat div.chat-room--tabs--container {
      display: none; }
  .collaboration__tp-cookies {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color: white;
    margin-top: 6rem;
    margin-left: 4rem; }
    .collaboration__tp-cookies a:link {
      color: #A2C9F0; }

.status__section-title {
  margin-bottom: 0;
  padding: 0.5rem 0;
  color: #14264c;
  background-color: #fede04;
  text-align: center;
  display: flex;
  margin: 0rem auto; }

.status__button {
  box-sizing: border-box;
  height: 4.625rem;
  width: 12.875rem;
  color: #00254e;
  font-family: 'Janda Manatee Solid';
  font-size: 1.6rem;
  background-color: #ffda00;
  background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
  background-size: 100% 100%;
  display: block;
  margin: 0rem auto;
  width: 11rem;
  height: 80%; }
  .status__button:hover {
    box-sizing: border-box;
    height: 4.625rem;
    width: 12.875rem;
    color: #00254e;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept.png?15") center no-repeat;
    background-size: 100% 100%;
    background-color: #ffda00;
    background: url("../../public/assets/images/buttons/dialog-button-accept-over.png?15") center no-repeat;
    background-size: 100% 100%;
    width: 11.5rem;
    height: 85%; }
