*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  padding: 3rem;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@font-face {
  font-family: 'Janda Manatee Solid';
  src: url(../../public/fonts/SlagalicaFont2020.ttf) format('truetype')//format('truetype');
}

.disabled-blurred-div {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-div {
  pointer-events: none;
  opacity: 1;
}

.enabled-div {
  opacity: 1;
}
