.statistics {
    @include dialog;
    &__inner {
      @include dialog-inner;
      width: 61rem;
      height: 50.3rem;
      background:$lochmara, $dialogBlackBackground center no-repeat;
      background-size: contain; 
    }
    &__percentage_title {
      @include description;
      height: 3.7rem;
      width: 8.5rem;
      font-size: 1.8rem;
      margin-left: 7.4rem;
      margin-top: 4rem;
      text-align: center;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
      &--game {
        position: absolute;
        width: 19rem;
        margin-left: 29rem;
        margin-top: -3rem;
      }
    }
    &__image {
      position: absolute;
      height: 10.2rem;	
      width: 10.2rem;
      border-radius: 5rem;
      margin-top: 3rem;
      margin-left: 6.5rem;
      background-color: $saphire-var5;
      &--text {
        @include description;
        height: 3.3rem;
        width: 10.2rem;
        font-size: 3.5rem;
        margin-left: 0rem;
        margin-top: 4.5rem;
        text-align: center;
        letter-spacing: 0.1rem;
        line-height: 1.6rem;
      }
      &--tootlip {
        @include tooltip;
        opacity: 0;
        visibility: visible;
        color: $white;
        font-family: 'Janda Manatee Solid';
        margin-left: 10rem;
        margin-top: 7rem;
        border-radius: 0.5rem;
        text-align: center;
        width: 8rem;
      } 
    }
    &__percent-box {
        height: 17.1rem;	
        width: 40.1rem;
        background-color: $navy-blue;
        margin: 0 auto;
        margin-top: 17rem;
        border-radius: 2rem;
        &--text-letter {
            position: absolute;
            @include description;
            height: 1.4rem;  
            width: 'auto';          
            font-size: 1.2rem;
            margin-left: 3rem;
            margin-top: 1.7rem;
            text-align: left;
            letter-spacing: 0.1rem;      
        }
        &--text-game {
            position: absolute;
            @include description;
            height: 2.1rem;
            font-size: 1.8rem;
            margin-left: 14rem;
            margin-top: 1.4rem;
            text-align: center;
            letter-spacing: 0.1rem;      
        }
        &--box {
            position: absolute;
            margin-top: 5rem;            
        }
        &--text-percent {
            @include description;
            height: 2.1rem;
            font-size: 1.2rem;
            margin-left: 3rem;
            margin-top: -0.5rem;
            letter-spacing: 0.05rem;
            text-align: left;
        }
    }
    &__chart {
      position: absolute;
      height: 10.2rem;
      width: 10.2rem;
      margin-left: 25rem;
    }
    &__won-divider {
      position: absolute;
      width: 33.5rem;
      height: 0.2rem;
      margin-top: -4rem;
      margin-left: 12rem;
      z-index: -1;
      background-color: $saphire-var2;
    }
    &__won-box {
      position: absolute;
      height: 15rem;
      height: 4rem;
      margin-top: -19.3rem;
      margin-left: 12rem;
      display: flex;
      flex-direction: column-reverse;
      height: 17rem;
      &--game {
        height: 1rem;
        width: 3.5rem;
        background-color: $saphire-var2;
        margin-top: 0.2rem;
      }
      &--text {
        height: 1.3rem;	
        color: $saphire-var2;
        font-family: "Janda Manatee Solid";	
        font-size: 1rem;
        margin-top: 0.5rem;
        text-align: center;
        width: 6rem;
        margin-left: -1.5rem;
      }
    }
    &__image:hover &__image--tootlip {
      visibility: visible;
      opacity: 0.95;
    }
  
  }
