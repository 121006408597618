.equal-score {
  @include dialog;
  &__inner {
    @include dialog-inner;
    width: 43.2rem;
    height: 31rem;
    background:$lochmara, $dialogBlackBackground center no-repeat;
    background-size: contain; 
  }
  &__description {
    @include description;
    height: 8.4rem;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    width: 25.2rem;
    margin-left: 15rem;
    margin-top: 2.6rem;
  }
  &__image {
    position: absolute;
    height: 11.3rem;
    width: 12.5rem;
    margin-top: 3rem;
    margin-left: 1.8rem;
    background:$owlScale center no-repeat;
    background-size: contain; 
  }
  &__close {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 8.4rem;
    &:hover {
      @include dialog-button-accept-over;
    }
  }
}
