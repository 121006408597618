.lobby {
  height: 70rem;
  width: 76rem;
  background-image: $backgroundLobby;
  background-size: contain;
  animation: show ease-in 0.3s backwards;
  animation-delay: 0.2s;
  transition: hide ease-out 0.4s;
  &__content {
    margin: 2.5rem auto;
    // margin-top: -1.5rem;
    width: 71rem;
    display: flex;
  }
  &__left,
  &__right {
    width: 50%;
    padding-top: 15%;
  }
  &__left {
    position: relative;
    padding-top: 10rem;
  }

  &__footer {
    height: 20.9rem;
    width: 69.6rem;
    margin: 0 auto;
    margin-top: 5rem;
    // margin-bottom: 1.5rem;
  }
  &__b2b-toolbar
  {
    position: absolute;
    top: 0rem;
    height: 10rem;
    width: 76rem;
    overflow: hidden;
    background: $b2bHeaderLeague no-repeat center;
  }
  &__b2b-version {
    position: absolute;
    margin-top: 12rem;
    margin-left:3rem ;
    color: $white
  }
  &__b2b-banner
  {
    position: absolute;
    top: 40rem;
    height: 10rem;
    width: 76rem;
    overflow: hidden;
    background: $b2bHeader no-repeat center;
  }

  &__my-team
  {
    text-align: center;
    margin: 3rem 0;
    color: $light-sky-blue;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    &:hover {
      transform: scale(1.15);
      cursor: pointer; /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
  }

  &__b2b-active
  {
    text-align: center;
    margin: 3rem 0;
    color: $turbo;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }    
    30% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
}
