.daily-rewards {
  @include dialog;
  &__inner {
    @include absCenter;
    box-sizing: border-box;
    height: 45.6rem;
    width: 68.6rem;
    border: 0.7rem solid $saphire-var3;
    border-radius: 1.8rem;
    background-color: $lochmara;
    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(3, 33, 80, 0.4);
    background: $lochmara, $dialogBlackBackground center no-repeat;
  }

  &__description {
    height: 2.3rem;
    width: 68.6rem;
    text-align: center;
    color: $canary;
    font-family: 'Janda Manatee Solid';
    font-size: 1.7rem;
    margin: 4rem auto;
  }

  &__accept {
    @include dialog-button-accept;
    display: block;
    margin: 0 auto;
    margin-top: 2.1rem;
    &:hover {
      @include dialog-button-accept-over;
    }
    color: $prussian-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
  }

  &__accept_disabled {
    @include dialog-button-disabled;
    display: block;
    margin: 0 auto;
    margin-top: 2.1rem;
    box-sizing: border-box;
    height: 4.525rem;
    width: 12.875rem;
    color: $prussian-blue;
    font-family: 'Janda Manatee Solid';
    font-size: 1.6rem;
  }
}
