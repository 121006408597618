.rang-list-help {
    @include dialog;
    &__inner {
      @include dialog-inner;
      width: 42.3rem;
      height: 36rem;
      background:$lochmara, $dialogBlackBackground center no-repeat;
      background-size: contain; 
    }
    &__description {
      @include description;
      width: 39rem;
      height: auto;
      margin-left: 1rem;
      margin-top: 2rem;
      letter-spacing: 0.05rem;
    }
    &__accept {
      @include dialog-button-accept;
      display: block;
      margin: 0 auto;
      margin-top: 3rem;
      &:hover {
        @include dialog-button-accept-over;
      }
    }
  }
  