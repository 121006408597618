.friends {
  position: absolute;
  display: flex;
  padding: 3.8rem 2.3rem 0.6rem 2.3rem;
  background-size: contain;
  height: 16rem;
  width: 70.8rem;
  bottom: 1rem;
  border-top: 0.4rem solid $blue-violet;
  background: rgba($color: $black, $alpha: 0.4);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  &__arrow-left,
  &__arrow-right {
    height: 2.9rem;
    width: 2.9rem;
    position: absolute;
    top: 50%;
  }
  &__arrow-left {
    left: -1.5rem;
    background: $friendArrowsLeft no-repeat center;
    background-size: contain;
    &:hover {
      @include hoverAfter {
        background: $friendArrowsLeftOver no-repeat center;
      }
    }
  }
  &__arrow-right {
    right: -1.5rem;
    background: $pwfbArrowsRight no-repeat center;
    background-size: contain;
    &:hover {
      @include hoverAfter {
        background: $pwfbArrowsRightOver no-repeat center;
      }
    }
  }
  &__friends--background {
    position: absolute;
    margin-top: -3.5rem;
    display: flex;
    width: 65.3rem;
    overflow: hidden;
  }
  &__title {
    height: 4rem;
    width: 27rem;
    position: absolute;
    margin-top: -8rem;
    left: 0;
    font-size: 2.5rem;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    color: $havelock-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue-violet;
    font-family: 'Janda Manatee Solid';
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  &__mode {
    height: 3rem;
    cursor: pointer;
    position: absolute;
    z-index: -1;
    width: 19rem;
    margin-top: -7.4rem;
    border-top: 0.2rem solid $black;
    border-left: 0.2rem solid $black;
    border-right: 0.2rem solid $black;
    font-family: 'Janda Manatee Solid';
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    z-index: 3;
    font-size: 1.8rem;
    background: linear-gradient(#3d425b, #222243, #1b193b);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-size: 1.5rem;
    color: $havelock-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    &--all {
      right: 20rem;
    }
    &--online {
      right: 0;
    }
  }
  &__invite-friend {
    height: 14.5rem;
    width: 9.3rem;
    position: absolute;
    margin-left: 57rem;
    margin-top: 1.7rem;
    background: $friendInviteBackground no-repeat center;
    background-size: contain;
    &:hover {
      background: $friendInviteBackgroundOver no-repeat center;
      background-size: contain;
    }
  }
}
