.b2b-my-team {
  height: 9rem;
  width: 26.5rem;
  margin-top: 3rem;
  align-items: center;
  display: inline-block;
  margin-left: 5rem;
  background-color: rgba($color: black, $alpha: 0.4);
  border-radius: 5rem;
  &__header {
    position: absolute;
    margin-top: -2rem;
    margin-left: 4.1rem;
    width: 70%;
    z-index: 2;
    color: $anakiwa;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 2.5rem;
    font-family: 'Janda Manatee Solid';
  }
  &__team-name {
    position: absolute;
    margin-top: -3.5rem;
    margin-left: 13rem;
    min-width: 30%;
    max-width: 45%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    background: $saphire-var5;
    border-radius: 1.5rem;
    color: $white;
    text-shadow: -0.2rem -0.2rem 0 $saphire, 0 -0.2rem 0 $saphire, 0.2rem -0.2rem 0 $saphire, 0.2rem 0 0 $saphire, 0.2rem 0.2rem 0 $saphire, 0 0.2rem 0 $saphire, -0.2rem 0.2rem 0 $saphire, -0.2rem 0 0 $saphire;
    font-size: 1.3rem;
    padding: 0.3rem;
    font-family: 'Janda Manatee Solid';
    text-align: center;
  }
  &__description {
    @include description;
    height: 8rem;
    width: 23rem;
    margin-left: 10rem;
    font-size: 1.2rem;
    color: $white;
    line-height: 1.5rem;
    margin-top: 2rem;
    letter-spacing: 0.05rem;    
  }
  &__me {
    color: $golden-yellow;
  }
  &__image {
    position: absolute;
    height: 4.8rem;
    width: 4.8rem;
    margin-left: 3.8rem;
    background-size: contain;
  }
  &__change {
    position: absolute;
    text-align: left;
    height: 1.5rem;
    margin-left: 4.3rem;
    font-size: 1rem;
    line-height: 1rem;
    color: $white;
    margin-top: 5.5rem;
    letter-spacing: 0.07rem;
    font-family: 'Janda Manatee Solid';
  }
  &__change-label {
    &:hover {
      font-size: 1.07rem;
      cursor: pointer; /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
  }
  &__wait-label {
    color: rgba($color: black, $alpha: 0.4);
  }
}
