
.call {
  position: relative; /* To make it a "positioned" container so children layout works */
  height: 25rem;
  width: 100%;
  border-radius: 2rem;
}

.large-tiles {
  height: 48rem;
  width: 100%;
  position: relative;
  /* left: 50%;
  transform: translate(-50%, 0); */
  
  display: block;
  /* grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); */
  align-items: center;
  /* overflow-y: scroll; */
}

.small-tiles {
  height: 19.5rem;
  position: relative;
  width: 100%;
  /* margin: auto; */
  margin-top: 0.8rem;
}

.small-tile-holder {
  position: absolute;
  width: 46%;
  height: 50%;
  border-radius: 2rem;
  overflow: hidden;
  margin-left: 1rem;
}

.pos-0{
  left: 0.1rem;
  top: 0.1rem;
}
.pos-1{
  right:1rem;
  top:0.1rem;
}
.pos-2{
  left:0.1rem;
  bottom:-0.3rem;
}
.pos-3{
  right:1rem;
  bottom:-0.3rem;
}
