.aparteko-logo-loader {
  background: $dark-green;
  height: 70rem;
  width: 76.2rem;
  position: absolute;
  z-index: 1000;
  background-size: contain;
  top: 0;
  left: 0;

  &__logo-image {
    @include absCenter;
    z-index: 1001;
  }
  &__loading-circle {
    background: radial-gradient(circle closest-side, $curious-blue-var, #010107);
    @include absCenter;
    border-radius: 50%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 30rem;
    width: 30rem;
    color: $white;
    font-size: 2rem;
    opacity: 1;
    &--active {
      animation: changeCircle 2s ease-in-out both;
    }
  }
}
@keyframes changeCircle {
  0% {
    height: 30rem;
    width: 30rem;
  }
  100% {
    height: 55rem;
    width: 55rem;
  }
}
