$color-text-yellow: #ffd304;
$color-text-green: #c2d12f;
$color-text-blue: #8fdafa;
$color-text-silver: #eceded;

$white: #ffffff;
$color-white: #ffffff;
$black: #000000;

$transparent-black: rgba(0, 0, 0, 0.9);
$lime-green: #3bb926;
$bahia: #92d11c;
$green: #356e00;
$fuego: #c2d12f;
$forest-green: #34a522;
$sparkly-green: #b3e919;
$lawn-green: #6cdb00;
$mantis: #7bc46f;
$dark-green: #010107;
$citrus: #a09d05;

$kournikova: #f9d349;
$kournikova-var: #f9d34a;
$canary: #fef6a5;
$canary2: #fff5a4;
$golden-yellow: #fede04;
$turbo: #fcca1d;
$gold: #ffd304;
$golden-poppy: #ffc600;
$school-bus-yellow: #ffda00;
$witch-haze: #ffeb73;
$super-gold: #f39307;
$orange: #ffa500;
$dialog-header-important: #ffdb00;

$rust: #a15a0c;

$sunset-orange: #f24638;
$cinnabar: #f1483b;

$bahama-blue: #1f457c;
$bahama-blue-var: #25477c;
$black-pearl: #181d26;
$sherpa-blue: #021f3c;
$saphire: #06142f;
$saphire-var: #092348;
$saphire-var2: #09254b;
$saphire-var3: #032150;
$saphire-var4: #07254d;
$saphire-var5: #082551;
$saphire-var6: #0321509a;
$saphire-var7: #0c2048;
$cyprus: #12284a;
$prussian-blue: #00254e;
$egyptian-blue: #10559b;
$lochmara: #3869a3;
$deep-sky-blue: #01a2f1;
$deep-sky-blue-var: #00a3f2;
$deep-sky-dark-blue: #3d669a;
$curious-blue: #3c6bb3;
$curious-blue-var: #3a6dbe;
$steel-blue: #507eb0;
$navy-blue: #2b5289;
$light-sky-blue: #8fdafa;
$anakiwa: #afd1fe;
$malibu: #8ebbfe;
$blue-violet: #4373bd;
$havelock-blue: #679dda;
$sky-blue: #a0dffb;
$sky-light-blue: #e7f7f9;
$sky-normal-blue: #84c8f6;
$cornflower-blue: #83abf0;
$alice-blue: #eaf9fe;
$light-cyan: #c7f1ff;
$light-cyan-var: #d6f5ff;
$light-cyan-var2: #c9efff;
$light-cyan-var3: #defcff;
$lavander-blue: #bbdaff;
$seagull: #7eaacf;
$seagull-var: #8fbbda;
$seagull-var2: #6b90ad;
$seagull-var3: #6eacce;
$danube: #6994c7;
$french-pass: #a9cae3;
$tropical-blue: #afcae9;
$alice-blue: #e9f8fd;

$valencia: #3f566f;
$verdun-green: #434811;
$verdun-green-var: #47530f;
$tooltip-name: #d49e1b;

$inboxTitle: #d59f1d;
$booster-text-shadow: #442c03;
$button-border: #0f2239;

$button-dark-left: #2e5380;
$button-dark-right: #6f9cc5;

$dark-tooltip: #010a1e;
$result-holder-border: #01204d;
$result-holder-background: #1f457c;

$message-read: #3768a2;

$color-text-gold: #fede04;
$background-1: #14264c;
