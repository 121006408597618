.tokens-info {
    @include dialog;
    padding: 10rem;
    &__inner {
      @include dialog-inner;
      width: 45.2rem;
      height: 34.7rem;
      background:$lochmara, $dialogBlackBackground center no-repeat;
      background-size: contain; 
    }
    &__description-box {
      margin-top: 4rem;
    }
    &__description {
      @include description;
      height: 4.2rem;
      width: 37rem;
      margin: 0 auto; 
      text-align: left;
      letter-spacing: 0.06rem;
      &--big {
        margin-top: 1rem;
      }
      &--email {
        margin-bottom: -0.5rem;
        text-align: center;
        color: $saphire-var2;
      }
    }
  }
  