.pairs {
    position: absolute;
    height: 48rem;
    padding: 2rem 4rem;
    margin: -1rem auto 0 auto;
    overflow: hidden;
      &__progress {
        height: 18.1rem;
        width: 50.4rem;
        z-index: -1;
        margin-left: 2rem;
        margin-top: -11rem;
        background: $questionProgressBackground no-repeat center;
        background-size: contain;
        &__question {
            position: absolute;
            width: 82%;
            height: 5.6rem;
            text-align: center;
            margin: 0 auto;
            margin-top: 11.5rem;
            border-radius: 1rem;
            margin-left: 0.4rem;
            font-family: "Janda Manatee Solid";	
            font-size: 1.8rem;
            color: $white;
            word-wrap: break-word;
        }    
    }
    &__container {
        position: absolute;
        margin-top: 0.5rem;
        width: 21rem;
        margin-left: 2rem;
        &--pairs {
            width: 24.8rem;
            margin-top: 1.1rem;
            height: 3.3rem;
        }
        &--opponent {
            margin-left: 28rem;
        }
        &--text {
            @include tooltip;
            opacity: 0;
            visibility: visible;
            color: $white;
            font-family: 'Janda Manatee Solid';
            margin-left: 15rem;
            margin-top: 4rem;
            border-radius: 2rem;
            text-align: center;
            pointer-events: none;
            background-color: $dark-tooltip;            
            box-shadow: 0 0 0.5rem 0.1rem rgba(1, 10, 30, 1);
            &::after {
                content: "";
                position: absolute;
                opacity: 1;
                top: -120%;
                left: 60%;
                margin-left: -0.6rem;
                border-width: 1.5rem;
                margin-top: 0.1rem;
                border-style: solid;
                border-color:  transparent transparent $dark-tooltip transparent; 
            }
        }
        &__point {
            position: absolute;
            opacity: 0;
            height: 3.1rem;
            width: 5.6rem;
            color: $white;
            font-family: 'Janda Manatee Solid';
            font-size: 3rem;
            margin-top: -4rem;
            margin-left: 24.5rem;
            z-index: 2;
            pointer-events: none;
        }    
    }

}