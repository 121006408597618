.game-result {
  position: relative;
  height: 38rem;
  overflow-y: visible;
  animation: 0.2s show ease-in backwards;
  z-index: 2;
  margin-top: -0.5rem;

  &__center {
    @include absCenter;
    height: 23.5rem;
    width: 39.9rem;
    margin: 1rem auto;
    margin-left: -6.4rem;
    background: $resultHolder no-repeat top center;
    background-size: contain;
    &--friendly {
      background: $friendlyResultHolder no-repeat top center;
      background-size: contain;
    }
  }

  &__progress_holder {
    position: absolute;
    height: 22.5rem;
    width: 50.4rem;
    margin-left: 5rem;
    overflow: hidden;
  }

  &__progress {
    position: absolute;
    height: 18.1rem;
    width: 50.4rem;
    margin-top: -19rem;
    border-style: solid;
    border-color: $result-holder-border;
    border-width: 0.2rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    background: $result-holder-background;
    transition: all 1s;
    z-index: 2;
    &__box {
      display: flex;
      width: 25rem;
      height: 2rem;
      margin: 0 14rem;
      margin-top: 0.75rem;
    }
    &__point {
      width: 2.1rem;
      color: $deep-sky-blue;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      text-align: right;
      &--opponent {
        color: $cinnabar;
        text-align: left;
      }
    }
    &__text {
      width: 20rem;
      color: $sky-blue;
      font-family: 'Janda Manatee Solid';
      font-size: 1.8rem;
      text-align: center;
    }
    &__background {
      height: 7.8rem;
      width: 14.5rem;
      margin-top: -0.4rem;
      margin-left: 18.8rem;
      background: $resultBackground center no-repeat;
      background-size: contain;
      &--text {
        color: $sky-blue;
        margin-left: 4rem;
        margin-top: 3rem;
        font-family: 'Janda Manatee Solid';
        font-size: 1.4rem;
        text-align: center;
        background-color: transparent;
      }
      &--arrow {
        height: 1.9rem;
        width: 3.2rem;
        background: $resultArow center no-repeat;
        background-size: contain;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
  &__icon {
    position: absolute;
    width: 53rem;
    height: 30rem;
    margin-top: 17rem;
    margin-left: 5rem;
    &--won {
      background: $b2bWon center no-repeat;
      background-size: contain;
    }
    &--lose {
      background: $b2bLose center no-repeat;
      background-size: contain;
    }
  }
  &__moto {
    font-family: 'Janda Manatee Solid';
    font-size: 2rem;
    color: #101E39;
    text-align: center;
    line-height: 3rem;
    margin-top: 9.2rem;
    height: 8rem;
    margin-left: 15rem;
    width: 35rem;
    display: inline-block;
    vertical-align: middle;
  }

  &__result-status {
    height: 3.2rem;
    width: 13.5rem;
    margin-left: 31.2rem;
    margin-top: 3rem;
    z-index: 10;
    position: absolute;
    &--win {
      background: $resultStatusYouWon center no-repeat;
      background-size: contain;
    }
    &--lost {
      background: $resultStatusYouLost center no-repeat;
      background-size: contain;
    }
  }
  &__cup {
    position: absolute;
    height: 14.4rem;
    width: 14rem;
    top: 8.5rem;
    left: 4.2rem;
    &--won {
      background: $winerCup center no-repeat;
      background-size: contain;
    }
    &--lost {
      background: $losseCup center no-repeat;
      background-size: contain;
    }
  }

  &__today-booster {
    margin-top: -7.5rem;
    margin-left: 3rem;
    pointer-events: none;
  }

  &__today-booster-inner {
    position: absolute;
    width: 16.7rem;
    opacity: 0;
    background: $boosterWhiteFlash no-repeat center;
    background-size: contain;
    height: 9.2rem;
    z-index: -1;
    margin-top: -2rem;
    margin-left: -0.4rem;
  }

  &__high-score {
    position: absolute;
    width: 11.9rem;
    height: 13.8rem;
    top: 13.4rem;
    left: 14.1rem;
    z-index: 5;
    transform: scale(3);
    margin-left: -11rem;
    opacity: 0;
    background: $highScoreSticker no-repeat center;
    background-size: contain;
    pointer-events: none;
  }

  &__title {
    height: 6rem;
    width: 14rem;
    position: absolute;
    top: 5rem;
    margin-left: -6rem;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -6.5rem;
    &--won {
      background: $resultStatusYouWon no-repeat center;
      background-size: contain;
    }
    &--lost {
      background: $resultStatusYouLost no-repeat center;
      background-size: contain;
    }
  }

  &__friend-box {
    height: 13.3rem;
    width: 27rem;
    background-color: #406191;
    @include absCenter;
    z-index: 1;
    border-radius: 0.5rem;
    box-shadow: -4px 4px 4px #1f385f;
    display: flex;
    &--text {
      height: 7rem;
      width: 17rem;
      color: #ffffff;
      font-family: 'Janda Manatee Solid';
      font-size: 1.3rem;
      text-align: justify;
      margin-top: 3.3rem;
      margin-left: 0.8rem;
      margin-right: 0.5rem;
    }
    &--image {
      width: 10.6rem;
      height: 9.6rem;
      margin-top: 2rem;
      background: $friendGameIcon no-repeat center;
      background-size: contain;
    }
    &--comment {
      width: 22rem;
      height: 2.5rem;
      position: absolute;
      border-radius: 0.5rem;
      margin-top: 21.5rem;
      margin-left: 21rem;
      border-color: $saphire-var2;
    }
  }

  &__score-holder {
    @include absCenter;
    height: 13.3rem;
    width: 23rem;
    margin-left: -6rem;
    margin-top: 2rem;
    z-index: 1;
  }

  &__score-feather {
    position: absolute;
    height: 3.8rem;
    width: 3.5rem;
    top: 1rem;
    left: 15rem;
    &--won {
      background: $feather no-repeat center center;
      background-size: contain;
    }
    &--lost {
      background: $blackFeather no-repeat center;
      background-size: contain;
    }
  }
  &__score {
    font-family: 'Janda Manatee Solid';
    font-size: 4.8rem;
    letter-spacing: 0.05em;
    line-height: 3.6rem;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    &--won {
      color: $white;
    }
    &--lost {
      color: $black-pearl;
    }
    &--won-booster {
      color: $white;
      opacity: 0;
    }
  }

  &__new-high-score {
    opacity: 0;
    z-index: 1;
    height: 4.8rem;
    width: 20rem;
    background: $newHighScoreLabel no-repeat center;
    background-size: contain;
    position: absolute;
    bottom: 14rem;
    margin-left: -6.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &__no-high-score {
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    line-height: 2;
    z-index: 4;
    height: 3.2rem;
    margin-left: -6rem;
    position: absolute;
    bottom: 15rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &__rating {
    height: 5rem;
    width: 12.8rem;
    background: $resultRaiting no-repeat top center;
    background-size: contain;
    position: absolute;
    bottom: 1.6rem;
    margin-left: -6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }
  &__rating-box {
    height: 2.5rem;
    width: 10rem;
    display: flex;
    margin-top: 27rem;
    position: absolute;
    margin-left: 35rem;
    &--booster {
      margin-left: 19rem;
    }
  }
  &__rating-star {
    position: absolute;
    width: 2.5rem;
    height: 2.3rem;
    z-index: 100;
    text-align: right;
    margin-top: 0.5rem;
    &--won {
      background: $progressBarStar no-repeat center;
      background-size: contain;
    }
    &--lost {
      background: $blackStar no-repeat center;
      background-size: contain;
    }
    &--fly {
      background-size: contain;
    }
    &--booster {
      margin-left: 6rem;
      background: $trapezeBooster no-repeat center;
      background-size: contain;
    }
    &--booster-black {
      margin-left: 6rem;
      background: $trapezeBoosterBlack no-repeat center;
      background-size: contain;
    }
  }
  &__won-score {
    z-index: 5;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    letter-spacing: 0.05em;
    line-height: 2;
    margin-top: 0rem;
    margin-left: 5rem;
    &--won {
      color: $white;
    }
    &--lost {
      color: $black-pearl;
    }
    &--booster {
      width: 5.5rem;
      margin-left: 0rem;
      text-align: end;
      &--won {
        color: $white;
      }
      &--lost {
        color: $black-pearl;
      }
    }
  }
  &__booster-applied {
    height: 6.9rem;
    width: 13.1rem;
    position: absolute;
    left: 48%;
    top: 31%;
    right: 20.8rem;
    &--won {
      color: $white;
    }
    &--lost {
      color: $black-pearl;
      opacity: 0.3;
    }
    &--on {
      background: $boosterAppliedOn no-repeat center;
      background-size: contain;
    }
    &--off {
      background: $boosterAppliedOff no-repeat center;
      background-size: contain;
    }
  }
  &__label {
    height: 21px;
    color: #09254b;
    font-family: 'Janda Manatee Solid';
    font-size: 1.8rem;
    line-height: 3.6rem;
  }
  &__booster-amount {
    background: $boosterX2 center no-repeat;
    background-size: contain;
    position: absolute;
    top: 31%;
    left: 18%;
    width: 3rem;
    height: 2.7rem;
    background-size: 3.2rem 2.9rem;
  }
  &__align-center {
    position: absolute;
    text-align: center;
    opacity: 1;
    width: 100%;
    display: inline-block;
    margin-top: 43rem;
    right: 6.5rem;
  }
  &__share-button {
    position: absolute;
    width: 16rem;
    height: 4rem;
    margin-top: 25rem;
    margin-left: 24rem;
  }
  &__button {
    height: 5rem;
    width: 14.5rem;
    background: $rematchDefault no-repeat center;
    background-size: contain;
    color: $white;
    font-family: 'Janda Manatee Solid';
    font-size: 1.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    letter-spacing: 0.01em;
    &:hover {
      background: $rematchDefaultOver no-repeat center;
      background-size: contain;
    }
    &--text {
      height: 1.7rem;
      background: $backLobby no-repeat center;
      background-size: contain;
    }
    &--rematch {
      &--default {
        background: $rematchDefault no-repeat center;
      }
      &--waiting {
        background: $rematchDefault no-repeat center;
        background-size: contain;
      }
      &--accept {
        background: $rematchAccept no-repeat center;
        &:hover {
          background-size: contain;
          background: $rematchAcceptOver no-repeat center;
        }
      }
      &--disabled {
        background: $rematchDisabled no-repeat center;
        &:hover {
          background-size: contain;
          background: $rematchDisabled no-repeat center;
        }
      }
    }
    &--rematch-inner {
      background-size: contain;
      margin-left: 0rem;
      margin-right: 0rem;
      pointer-events: none;
      &--default {
        background: $rematchTextDefault no-repeat center;
      }
      &--disabled {
        background: $rematchTextDisabled no-repeat center;
      }
      &--accept {
        background: $rematchTextAccept no-repeat center;
      }
      &--waiting {
        background: $rematchTextDefault no-repeat center;
      }
    }
    &--friend {
      margin-top: 33rem;
      margin-left: 24rem;
    }
  }

  &__appeal-button {
    width: 5rem;
    height: 5rem;
    position: absolute;
    right: 27rem;
    top: 15.5rem;
    background: $appealButton center no-repeat;
    cursor: pointer;
    &:hover {
      background: $appealButtonOver center no-repeat;
    }
    &:disabled {
      background: $appealButtonOver center no-repeat;
      cursor: default;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
